import React, { Component } from 'react'

export function bindOnChangeToValue(InnerComponent) {
  const result = class Wrapped extends Component {
    constructor(props) {
      super()
      this.state = {
        ...props,
      }
    }

    render() {
      return (
        <InnerComponent
          {...this.state}
          onChange={v => this.setState({
            value: v,
          })}
        />
      )
    }
  }
  result.displayName = InnerComponent.name
  return result
}
