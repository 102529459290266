import React from 'react'

const SettingsSection = ({ title, subtitle, children }) => (
  <div>
    <h2>
      {title}
      :
    </h2>
    <p>{subtitle}</p>
    <div>{children}</div>
  </div>
)

export default SettingsSection
