import moment from 'moment'
import { useSelector } from 'react-redux'
import { pick, isUndefined, isEmpty } from 'lodash'
import { useCurrentMoment } from './useCurrentMoment'

function isToday(today, dateToCompare) {
  return today.format('MM/DD/YYYY') === moment(dateToCompare).format('MM/DD/YYYY')
}

export function useReservation() {
  const reservation = useSelector(state => state.property.reservation)
  const currentDate = useCurrentMoment()
  const stayMode = !isEmpty(reservation) && !isUndefined(reservation)
  const { beginDate, endDate } = pick(reservation, ['beginDate', 'endDate'])
  const isCheckInDay = stayMode && isToday(currentDate, beginDate)
  const isCheckOutDay = stayMode && isToday(currentDate, endDate)
  const { checkinTime, checkoutTime } = pick(reservation, ['checkinTime', 'checkoutTime'])
  const checkinDatetime = reservation && moment(`${reservation.beginDate}T${reservation.checkinTime}:00`)
  const checkoutDatetime = reservation && moment(`${reservation.endDate}T${reservation.checkoutTime}:00`)
  return {
    stayMode,
    isCheckInDay,
    isCheckOutDay,
    beginDate,
    endDate,
    checkinTime,
    checkoutTime,
    checkinDatetime,
    checkoutDatetime,
  }
}

export function useNextReservation() {
  const reservation = useSelector(state => state.property.nextReservation)
  const currentDate = useCurrentMoment()

  if (reservation) {
    const stayMode = !isEmpty(reservation) && !isUndefined(reservation)
    const { beginDate, endDate } = pick(reservation, ['beginDate', 'endDate'])
    const isCheckInDay = stayMode && isToday(currentDate, beginDate)
    const isCheckOutDay = stayMode && isToday(currentDate, endDate)
    const { checkinTime, checkoutTime } = pick(reservation, ['checkinTime', 'checkoutTime'])
    const checkinDatetime = moment(`${reservation.beginDate}T${reservation.checkinTime}:00`)
    const checkoutDatetime = moment(`${reservation.endDate}T${reservation.checkoutTime}:00`)
    return {
      stayMode,
      isCheckInDay,
      isCheckOutDay,
      beginDate,
      endDate,
      checkinTime,
      checkoutTime,
      checkinDatetime,
      checkoutDatetime,
    }
  }
  return null
}
