import { useEffect, useContext } from 'react'
import moment from 'moment'

import { ServiceWorkerContext } from './ServiceWorker'

const Metadata = () => {
  console.info(`Running version: ${process.env.REACT_APP_SENTRY_RELEASE}`)

  const { fetchUpdate } = useContext(ServiceWorkerContext)

  useEffect(() => {
    const midnight = moment().set({
      seconds: 59,
      minutes: 59,
      hours: 23,
    })

    const millisecondsBeforeMidnight = midnight.diff(moment())
    console.info(`Refreshing code at: ${midnight.format()} (${millisecondsBeforeMidnight}ms)`)
    const _timeout = setTimeout(fetchUpdate, millisecondsBeforeMidnight)
    return () => clearTimeout(_timeout)
  }, [fetchUpdate])

  return null
}

export default Metadata
