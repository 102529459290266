import React from 'react'
import classNames from 'classnames'
import { noop } from 'lodash'
import { Link } from 'react-router-dom'

import '../styles/BlueButton.scss'

const BlueButton = ({
  to, label, primary, size, onClick, disabled = false, className,
}) => {
  const props = {
    className: classNames(
      'BlueButton class',
      {
        primary,
        disabled,
      },
      size,
      className,
    ),
    onClick: disabled ? noop : onClick,
  }

  let Component = 'div'
  if (to) {
    if (typeof to === 'string' && to.startsWith('http')) {
      Component = 'a'
      props.href = disabled ? null : to
    } else {
      Component = Link
      props.to = disabled ? null : to
    }
  }

  return <Component {...props}>{label}</Component>
}

export default BlueButton
