import React, { Component } from 'react'

import '../styles/PlusMinusToggle.scss'

export default class PlusMinusToggle extends Component {
  render() {
    const {
      max = 100, min = 0, value, units = {}, onChange,
    } = this.props

    return (
      <div className={`PlusMinusToggle ${onChange && 'editable'}`}>
        <div
          className="circle"
          style={{
            lineHeight: '30px',
          }}
          onClick={() => onChange(Math.max(min, Math.min(max, value - 1)))}
        >
          -
        </div>
        <div className="value">
          {value}
          {' '}
          {value > 1 ? units.plural : units.singular}
        </div>
        <div className="circle" onClick={() => onChange(Math.max(min, Math.min(max, value + 1)))}>
          +
        </div>
      </div>
    )
  }
}
