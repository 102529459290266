import React from 'react'
import { Card } from 'semantic-ui-react'
// import { PhoneReviewIcon } from '../components/icons'
import TimedLink from '../components/TimedLink'
import '../styles/SendToPhoneContainer.scss'
import Thanks_ICON from '../images/icons/checked-screen.png'

const SendToPhoneResponseThanks = ({ history }) => {
  const response = 'Thank You'
  //   const { section, id } = match.params
  return (
    <Card className="SendToPhoneContainer response">
      <div className="response-wrapper">
        {/* <PhoneReviewIcon width={'291px'} height={'300px'} /> */}
        <img src={Thanks_ICON} alt="Thank You" />
        <h3 className="response-text">{response}</h3>
      </div>
      <TimedLink label="Finish" action={() => history.push('/home')} />
    </Card>
  )
}

export default SendToPhoneResponseThanks
