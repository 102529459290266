import React from 'react'
import { Icon } from 'semantic-ui-react'
import { TrackLink } from '../metrics'
import { MESSAGES } from '../../constants/messages'

// todo: track name?
const MoreLink = ({ to, analytics = true }) => {
  return (
    <div className="MoreLink">
      <TrackLink to={to} className="link" name="View More Recommendation" source="HomeContainer" analytics={analytics}>
        <div className="label">{MESSAGES.VIEW_ALL}</div>
        <Icon name="chevron right" />
      </TrackLink>
    </div>
  )
}

export default MoreLink
