import React from 'react'
import PhoneNumber from '../PhoneNumber'

const ServiceContact = ({ phone, promoExpirationDays }) => {
  const expirationSentance = `Must book ${
    promoExpirationDays ? `within ${promoExpirationDays} days ` : 'soon '
  } for the discount!`

  return (
    <div className="card-section ServiceContact">
      {phone && (
        <div className="phone">
          Reservations:
          {' '}
          <PhoneNumber>{phone}</PhoneNumber>
        </div>
      )}
      <div className="deadline">{expirationSentance}</div>
    </div>
  )
}

export default ServiceContact
