import React from 'react'

import { connectWithPath } from '../../resources/utils'
import MapCenterButton from './MapCenterButton'
import activityIcon from '../../images/icons/map/map-key-marker-activities@2x.png'
import restaurantIcon from '../../images/icons/map/map-key-marker-restaurant@2x.png'
import directoryIcon from '../../images/icons/map/map-key-marker-directory@2x.png'
import recommendedIcon from '../../images/icons/map/map-key-marker-recommended@2x.png'
import { MESSAGES } from '../../constants/messages'

function getMarkerIcon(iconType) {
  switch (iconType) {
    case 'activities': {
      return activityIcon
    }
    case 'restaurant': {
      return restaurantIcon
    }
    case 'directory': {
      return directoryIcon
    }
    case 'recommendedIcon':
      return recommendedIcon
    default:
      return null
  }
}

const MapLegend = ({
  isOnline, hidden, onCenter, onChangeFilter,
}) => {
  if (isOnline) {
    return (
      <div className="legend-container">
        <div className="legend">
          {['restaurant', 'activities', 'directory'].map(type => (
            <label htmlFor={type} key={type}>
              <input id={type} type="checkbox" checked={!hidden[type]} name={type} onChange={onChangeFilter} />
              <img
                style={{
                  height: 30,
                }}
                src={getMarkerIcon(type)}
                alt="example marker"
              />
              {' '}
              {type}
            </label>
          ))}
          <label className="recommended">
            <img style={{ height: 30 }} src={recommendedIcon} alt="recommended marker" />
            {' '}
            {MESSAGES.RECOMMENDED}
          </label>
        </div>
        <MapCenterButton onClick={onCenter} />
      </div>
    )
  }
  return null
}

export default connectWithPath({
  isOnline: 'networkStatus.isOnline',
})(MapLegend)
