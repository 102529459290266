import React from 'react'
import Icon from './Icon'

const PropertyIcon = props => (
  <Icon viewBox="0 0 78 54" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.5a1.5 1.5 0 0 1 3 0V38h73.5a1.5 1.5 0 0 1 1.415 2c.055.156.085.325.085.5v12a1.5 1.5 0 1 1-3 0V41H3v11.5a1.5 1.5 0 1 1-3 0v-51zM23 23a7 7 0 1 1-14 0 7 7 0 0 1 14 0zm44-7H27v16h48v-8a8 8 0 0 0-8-8z"
      fill="currentColor"
    />
  </Icon>
)
export default PropertyIcon
