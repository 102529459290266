import React from 'react'
import { sortBy } from 'lodash'
import { connect } from 'react-redux'
import { TrackLink } from '../metrics'
import { formatPrice } from '../../utils/formatters'
import { ServiceIcon } from '../icons'
import '../../styles/SlideshowServicePromo.scss'
import { MESSAGES } from '../../constants/messages'

const SlideshowServicePromo = ({ service, setInteractive }) => {
  const {
    name, id, iconURL, summarySubtitle,
  } = service
  const { price, priceType } = formatPrice({
    currency: 'US',
    ...service,
  })
  const servicePrice = `${price} ${priceType}`

  return (
    <div className="SlideshowServicePromo">
      <img src={iconURL} alt={summarySubtitle} />
      <section>
        <div>
          <h4>
            <ServiceIcon />
            {MESSAGES.OUR_SERVICES}
          </h4>
          <h3>{name}</h3>
          {!!price && <h4>{servicePrice}</h4>}
          {!!summarySubtitle && <p>{summarySubtitle}</p>}
        </div>
        <TrackLink
          onClick={setInteractive}
          className="button"
          to={`/services/${id}`}
          name={name}
          source="SlideshowServicePromo"
        >
          {MESSAGES.VIEW_DETAILS}
        </TrackLink>
      </section>
    </div>
  )
}

const mapStateToProps = (state, { placeholderIndex }) => {
  const services = sortBy(
    Object.values(state.services).filter(({ smart }) => smart.slideshow),
    'position',
  )
  const index = Math.abs(placeholderIndex) % services.length
  const service = services[index]
  return {
    service,
  }
}

export default connect(mapStateToProps)(SlideshowServicePromo)
