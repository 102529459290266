import React from 'react'
import {
  get, isNull, isUndefined, isEmpty,
} from 'lodash'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import timeZones from '../../constants/timeZones'
import { MESSAGES } from '../../constants/messages'

const Greeting = () => {
  const { reservation, timezone } = useSelector(state => state.property)
  const partyName = get(reservation, 'partyName')
  const nameToDisplay = !isEmpty(partyName)
    && !isUndefined(partyName)
    && !isNull(partyName)
    && partyName !== 'Guest'
    && partyName.toLowerCase() !== 'visitor'
    && partyName.toLowerCase().includes('blocked') === false
    ? partyName.toLowerCase()
    : 'Welcome'

  const getGreetingText = () => {
    let greetingText = 'Welcome'
    if (timezone === timeZones.US_HAWAII) {
      greetingText = 'Aloha'
    }
    return greetingText
  }

  return (
    <div className={classNames('Greeting', { small: nameToDisplay.length > 16 })}>
      {partyName && nameToDisplay !== 'Welcome' && <div className="greeting-text">{getGreetingText()}</div>}
      <div className="greeting-name">{nameToDisplay}</div>
    </div>
  )
}

export default Greeting
