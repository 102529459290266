import React from 'react'
import Icon from './Icon'

const DirectoryIcon = props => (
  <Icon width={56} height={107} viewBox="0 0 56 107" fill="none" {...props}>
    <path
      d="M52.467 45.987L27.5 103.5 3.55 47.327a24.46 24.46 0 01-.824-16.96l.148-.466a23.991 23.991 0 015.303-9.108l.89-.957c10.021-10.784 27.152-10.844 37.401-.275a28.523 28.523 0 016.973 12.105 21.423 21.423 0 01-.974 14.321z"
      stroke="#fff"
      strokeWidth={3}
    />
    <circle cx={28} cy={39.5} r={9} fill={props.dotColor ? props.dotColor : '#FFF'} />
  </Icon>
)
export default DirectoryIcon
