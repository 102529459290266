import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { makeAPIRequest } from '../utils/helpers'
import CodeInputOutputScreen from '../components/CodeInputOutputScreen'
import CodeInputOutput from '../components/CodeInputOutput'
import sendIcon from '../images/icons/send.png'
import { MESSAGES } from '../constants/messages'

const SendToPhoneSimpleContainer = ({ history, organizationId }) => {
  const confirmFn = async output => makeAPIRequest('sms/passcode', {
    method: 'POST',
    data: {
      organizationId,
      phone: output,
    },
  }).then(response => {
    console.log('response', response)
    history.goBack()
  })

  const confirmButton = (
    <Fragment>
      <img className="confirm-button-img" alt="next" src={require('../images/icons/send.png')} />
      <div className="confirm-button-text">{MESSAGES.SEND}</div>
    </Fragment>
  )

  const inputOutputComponent = (
    <CodeInputOutput outputType="phone" confirmFn={confirmFn} confirmButton={confirmButton} />
  )
  const backComponent = (
    <div className="link" onClick={history.goBack}>
      {MESSAGES.CANCEL}
    </div>
  )

  return (
    <CodeInputOutputScreen
      title="Get your passcode"
      subtitle="Enter your mobile number and we'll send you a text"
      inputOutputComponent={inputOutputComponent}
      backComponent={backComponent}
    />
  )
}

export default connect(state => ({
  organizationId: state.property.organizationId,
}))(SendToPhoneSimpleContainer)
