import React from 'react'

const Windy = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2_167)">
      <path d="M7.81106 16.5845H22.3848C24.8433 16.5845 26.4908 15.0702 26.4908 12.8317C26.4908 11.6203 25.9965 10.5143 25.1221 9.79005C24.2984 9.10534 23.2592 8.85516 22.2074 9.10534C21.7765 9.21068 21.4977 9.65838 21.5864 10.1061C21.6371 10.3299 21.7638 10.5143 21.9412 10.6328C22.1187 10.7513 22.3341 10.7908 22.5495 10.7513C23.1198 10.6196 23.6647 10.7513 24.0956 11.1068C24.5772 11.5018 24.856 12.1339 24.856 12.8317C24.856 14.7937 22.7903 14.8991 22.3721 14.8991H7.81106C7.36751 14.8991 7 15.2809 7 15.7418C7 16.2026 7.36751 16.5845 7.81106 16.5845Z" fill="white" />
      <path d="M22.3848 21.6935H7.81106C7.36751 21.6935 7 22.0753 7 22.5362C7 22.9971 7.36751 23.3789 7.81106 23.3789H22.3848C22.8917 23.3789 23.3099 23.8135 23.3099 24.3402C23.3099 24.8669 22.8917 25.3014 22.3848 25.3014C21.9412 25.3014 21.5737 25.6832 21.5737 26.1441C21.5737 26.605 21.9412 26.9868 22.3848 26.9868C23.7915 26.9868 24.932 25.8018 24.932 24.3402C24.932 22.8786 23.7915 21.6935 22.3848 21.6935Z" fill="white" />
      <path d="M28.1889 14.7674C27.7454 14.7674 27.3779 15.1492 27.3779 15.6101C27.3779 17.1902 25.7938 18.2831 23.5253 18.2831H7.84907C7.40552 18.2831 7.03801 18.665 7.03801 19.1258C7.03801 19.5867 7.40552 19.9685 7.84907 19.9685H23.5127C26.6935 19.9685 28.9873 18.1382 28.9873 15.6101C28.9873 15.1492 28.6198 14.7674 28.1763 14.7674H28.1889Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2_167">
        <rect width="22" height="18" fill="white" transform="translate(7 9)" />
      </clipPath>
    </defs>
  </svg>
)

export default Windy
