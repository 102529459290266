/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Switch, Route } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { CheckIn, CheckInWizard, TermsOfService } from '../components/onboarding'
import '../styles/Onboarding.scss'
import { useCustomization } from '../hooks/useCustomization'
import defaultBackground from '../images/onboarding/onboarding-ocean.jpg'

const ONBOARDING_VIEWS = [
  {
    path: 'checkin',
    component: CheckIn,
    props: ({ welcome, reservation }) => ({
      welcome,
      reservation,
    }),
  },
  {
    path: 'impressions',
    component: CheckInWizard,
    props: ({
      host, reservation, quote, property,
    }) => ({
      host,
      reservation,
      quote,
      property,
    }),
  },
  {
    path: 'terms',
    component: TermsOfService,
  },
]

const OnboardingContainer = ({ location, ...props }) => {
  const background = get(props.welcome, 'background') || defaultBackground

  return (
    <div
      className="onboarding-fullscreen"
      style={{
        background: `url(${background})`,
      }}
    >
      <TransitionGroup className="onboarding-content">
        <Switch location={location}>
          {ONBOARDING_VIEWS.map(view => {
            const View = view.component
            const viewProps = view.props && view.props(props)
            const show = location.pathname.includes(view.path)
            return (
              <Route
                key={view.path}
                path={`/onboarding/${view.path}`}
                render={() => (
                  <CSSTransition key={view.path} in={show} classNames="fade" timeout={500}>
                    <View {...viewProps} />
                  </CSSTransition>
                )}
              />
            )
          })}
        </Switch>
      </TransitionGroup>
    </div>
  )
}

const mapStateToProps = state => {
  const property = get(state, 'property')
  const welcomeState = useCustomization('welcome', state)
  const { 1: welcome } = Object.entries(welcomeState).find(
    ([key, value]) => (key === 'custom' && value.status) || key === 'default',
  )

  return {
    reservation: property.reservation || {
      contact: {},
    },
    host: property.host,
    quote: property.checkout.hostQuote,
    welcome,
    property,
  }
}

export default connect(mapStateToProps)(OnboardingContainer)
