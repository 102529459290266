import React from 'react'
import qs from 'qs'
import { pick } from 'lodash'
import { withRouter } from 'react-router-dom'

export const withPropsFromLocation = keys => WrappedComponent => {
  const WithPropsFromLocationComponent = props => {
    // Priority:
    // search > state > props
    const { location } = props
    const state = pick(location.state, keys) || {}
    const search = pick(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
      keys,
    )
    return <WrappedComponent {...props} {...state} {...search} />
  }
  return withRouter(WithPropsFromLocationComponent)
}
