import React, { useState, useEffect } from 'react'
import HomePromotion from '../home/HomePromotion'
import CheckoutPromotion from '../checkout/CheckoutPromotion'
import { SlideshowServicePromo } from '../slideshow'
import { useServices } from '../../resources/services'
import useInterval from '../../hooks/useInterval'
import { filterLateCheckoutAvailability } from '../../utils/services'
import { useNextReservation, useReservation } from '../../hooks/useReservation'

// Promote ENUMS - checkout, slideshow, homepage
const SITUATIONS = {
  home: {
    situation: 'home',
    promoteKey: 'homepage',
    component: HomePromotion,
  },
  checkout: {
    situation: 'checkout',
    promoteKey: 'checkout',
    component: CheckoutPromotion,
  },
  slideshow: {
    situation: 'slideshow',
    promoteKey: 'slideshow',
    component: SlideshowServicePromo,
  },
}

const getRandomPromotion = services => services[Math.floor(Math.random() * services.length)]

const PromoteService = ({ situation, changeInterval, ...rest }) => {
  const { component: PromotionComponent, promoteKey } = SITUATIONS[situation]
  let { services } = useServices(promoteKey)
  const nextReservation = useNextReservation()
  const currentReservation = useReservation()
  if (nextReservation && currentReservation) {
    services = filterLateCheckoutAvailability(currentReservation, nextReservation, services)
  }
  const [currentPromotion, setCurrentPromotion] = useState()

  useEffect(() => {
    setCurrentPromotion(getRandomPromotion(services))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(services)])

  useInterval(() => setCurrentPromotion(getRandomPromotion(services)), changeInterval)

  return currentPromotion ? <PromotionComponent promotion={currentPromotion} {...rest} /> : null
}

export default PromoteService

PromoteService.defaultProps = {
  changeInterval: 30000,
}
