import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setHideFooter } from '../../actions/device'
import { MESSAGES } from '../../constants/messages'
import { withPropsFromLocation } from '../utils'

const ServiceInstructions = ({ history }) => {
  const { state } = history.location
  const backLink = `/services/${state.id}`

  const dispatch = useDispatch()
  const hideFooter = data => dispatch(setHideFooter(data))

  useEffect(() => {
    hideFooter({
      value: true,
    })
    return () => {
      hideFooter({
        value: false,
      })
    }
  }, [])

  return (
    <div className="Card max-height ServiceDetailContainer">
      <div className="p-4 service-instructions-wrapper">
        <h2>{MESSAGES.SERVICE_INSTRUCTIONS}</h2>
        {state.html && (
          <div
            className="service-instructions"
            dangerouslySetInnerHTML={{
              __html: state.html,
            }}
          />
        )}
        <Link to={backLink} className="link instructions-back-link">
          {MESSAGES.BACK_TO_SERVICE_DETAILS}
        </Link>
      </div>
    </div>
  )
}

export default withPropsFromLocation()(ServiceInstructions)
