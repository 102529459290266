import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useCountdownTimer } from '../hooks/useCountdownTimer'
import '../styles/TimedLink.scss'

const TimedLink = ({ label, action, timeLimit }) => {
  const seconds = useCountdownTimer(timeLimit)

  useEffect(() => {
    if (seconds <= 0) {
      return action
    }
  }, [seconds, action])

  return (
    <div className="TimedLink link" onClick={action}>
      <span className="label">
        {label}
        {' '}
      </span>
      <span
        className={classNames('seconds-remaining', {
          finished: seconds <= 0,
        })}
      >
        (
        {seconds}
        )
      </span>
    </div>
  )
}

export default TimedLink

TimedLink.defaultProps = {
  timeLimit: 10,
}
