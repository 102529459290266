import React from 'react'

import withCodeRefresh from '../withCodeRefresh'
import SettingsSection from './SettingsSection'
import BlueButton from '../BlueButton'

const RefreshButton = withCodeRefresh(({ refresh, ...props }) => (
  <BlueButton label="Refresh" onClick={refresh} {...props} />
))

const RefreshSection = () => (
  <SettingsSection title="Device">
    <RefreshButton />
  </SettingsSection>
)
export default RefreshSection
