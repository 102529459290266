/* eslint-disable prefer-const */
import axios from 'axios'
import  { get, isEmpty, isEqual } from 'lodash'
import btoa from 'btoa'

import Logger from '../logger/logger'
import { getToken, setToken, isGrandfathered, getSettings, getProperty, getDeviceType } from './persistent-state'
import { ANALYTICS_ENTITY_TYPES } from '../constants/appConstants'
import { createErrorLogs, saveLogsToLocalStorage } from '../logger'
import { kiosk } from '../api'

export const UNAUTHENTICATED_API = process.env.REACT_APP_PING_URL
export const ROOT_API_URL = process.env.REACT_APP_API_URL

let prefix = UNAUTHENTICATED_API.split(':')[0]
if (prefix && !prefix.includes('http')) {
  if (prefix.includes('localhost')) {
    prefix = 'http:'
  } else {
    prefix = 'https:'
  }
} else {
  prefix = ''
}

export const NODE_API_URL = '//localhost:3000'

export const authenticate = ({ pin, passcode }) => {
  let path = 'token'

  if (isGrandfathered()) {
    path += '/grandfathered'
  }

  // log request
  Logger.http({
    module: path,
    metadata: { pin, passcode },
    apiInfo: { endpoint: `${UNAUTHENTICATED_API}/${path}`, method: 'POST' },
  })

  return axios.post(`${UNAUTHENTICATED_API}/${path}`, { pin, passcode }).then(response => {
    // log response
    Logger.http({
      module: path,
      apiInfo: { endpoint: `${UNAUTHENTICATED_API}/${path}`, method: 'POST' },
      httpResponse: response.data,
    })

    setToken(response.data.token)
    return response
  })
}

export async function makeAPIRequest(path, options = {}) {
  const token = getToken()
  const propertyId = getProperty().id || null

  // logging
  const sessionId = { propertyId }
  if (!propertyId) {
    delete sessionId.propertyId
    sessionId.pin = getSettings().pin
  }

  // todo: test what removing this does. eventually, we should be able to rely on token
  // if (!settings.pin && !token) {
  //   clearAll()
  //   return
  // }

  // for older versions, if they attempt to get fresh data, first authenticate, then return the initial data request
  if (isGrandfathered()) {
    const settings = getSettings()
    await authenticate({ pin: settings.pin })
    return makeAPIRequest(path)
  }

  options.headers = {
    ...options.headers,
    Accept: 'application/JSON',
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/JSON',
  }

  try {
    // TODO: Track property pin differently (const response = await axios(`${ROOT_API_URL}/${path}?pin=${settings.pin}`, options))

    Logger.http({
      sessionId,
      module: path,
      metadata: options.data,
      apiInfo: { endpoint: `${ROOT_API_URL}/${path}`, method: options.method },
    })

    const response = await axios(`${ROOT_API_URL}/${path}`, options)
    Logger.http({
      sessionId,
      module: path,
      apiInfo: { endpoint: `${ROOT_API_URL}/${path}`, method: options.method },
      httpResponse: response.data,
    })

    return response.data
  } catch (err) {
    const error = get(err, ['response', 'data', 'error'])
    // log error
    createErrorLogs(error, path, options)
    Logger.error({
      sessionId,
      module: `${ROOT_API_URL}/${path}`,
      error: err,
      metadata: options.data,
    })
    if (!!error && error !== 'SequelizeValidationError') {
      if (typeof error === 'object' && error.message) {
        throw new Error(error.message)
      } else {
        throw new Error(error)
      }
    } else {
      throw err
    }
  }
}

export async function makeSendSMSRequest(recipient, message) {
  return makeAPIRequest('sms', {
    method: 'POST',
    data: { recipient, message },
  })
}

export const getFirst = (obj, paths, defaultValue) => {
  for (let i = 0; i < paths.length; i += 1) {
    const path = paths[i]
    const value = get(obj, path)
    if (!isEmpty(value)) {
      return value
    }
  }
  return defaultValue
}

export function preloadPropertyAssets(propertyJSON) {
  const imageURLsHash = {}

  // The built-in JSON.stringify method allows us to hit each key-value pair
  // in the JSON. We can use this to traverse the tree and collect image URLs
  // without being dependent on it's structure.
  JSON.stringify(propertyJSON, (key, value) => {
    if (typeof value === 'string' && value.startsWith('http') && (value.endsWith('jpg') || value.endsWith('png'))) {
      imageURLsHash[value] = true
    }
    return value
  })

  // Filter down the images we found to skip @2x versions on a 1x display and vice versa
  // (This prevents us from grabbing the built-in slideshow images twice.)
  const imageURLs = Object.keys(imageURLsHash).filter(url => {
    if (window.devicePixelRatio < 2 && url.includes('@2x')) return false
    if (window.devicePixelRatio >= 2 && url.includes('@1x')) return false
    return true
  })

  console.log(`Preloading ${imageURLs.length} images`)

  // Fetch all of the images to prime the browser cache, 10 at a time.
  const concurrency = 10
  const next = img => {
    const url = imageURLs.pop()
    if (!url) {
      img.src = ''
      return
    }
    img.src = url
  }
  for (let ii = 0; ii < concurrency; ii++) {
    const img = new Image()
    img.onload = () => next(img)
    next(img)
  }
}

const shortenUrlRequest = async ({ url }) => {
  const response = await makeAPIRequest('shorten', {
    method: 'POST',
    data: { url },
  })
  return response.shortLink
}

export async function redirectRequest({
  url,
  extra,
  referer,
  advertisementId = null,
  entityId = null,
  entityType = '',
}) {
  const data = {
    propertyId: getProperty()?.id,
    sessionId: localStorage.getItem('metrics.sessionId'),
    serialNo: localStorage.getItem('serialNumber'),
    demo: !(getDeviceType() !== 'demo' && !getProperty().isArchived),
    referer,
    url,
    extra,
  }

  if (getProperty().reservation?.id) {
    data.reservationId = getProperty()?.reservation?.id
  }

  if (advertisementId) {
    data.advertisementId = advertisementId
  }

  if (
    entityId &&
    (entityType === ANALYTICS_ENTITY_TYPES.ADVERTISEMENT || entityType === ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS)
  ) {
    data.entityId = entityId
    data.entityType = entityType
  }

  if (getDeviceType() !== 'demo' && !getProperty().isArchived) {
    data.demo = false
  }

  url = `${prefix}${UNAUTHENTICATED_API}/redirect/metrics?data=${btoa(JSON.stringify(data))}`
  let req
  const urls = JSON.parse(localStorage.getItem('urls'))
  if (urls && urls.length && entityId && entityType) {
    const getUrlObj = urls.find(item => item.id === entityId && item.type === entityType && item.referer === referer)
    if (getUrlObj && getUrlObj.longUrl && getUrlObj.longUrl === url) {
      req = getUrlObj.shortUrl
    } else if (getUrlObj && getUrlObj.longUrl && getUrlObj.longUrl !== url) {
      req = await shortenUrlRequest({ url })
      if (req !== url) {
        const newUrlsArr = urls.map(item => {
          if (item.id === entityId && item.type === entityType) {
            return {
              id: entityId,
              type: entityType,
              longUrl: url,
              shortUrl: req,
              referer,
            }
          }
          return item
        })
        localStorage.setItem('urls', JSON.stringify(newUrlsArr))
      }
    } else {
      req = await shortenUrlRequest({ url })
      if (req !== url) {
        const newUrlObj = {
          id: entityId,
          type: entityType,
          longUrl: url,
          shortUrl: req,
          referer,
        }
        const newUrlsArr = [...urls, newUrlObj]
        localStorage.setItem('urls', JSON.stringify(newUrlsArr))
      }
    }
  } else {
    req = await shortenUrlRequest({ url })
    if (req !== url) {
      const newUrlObjArr = [
        {
          id: entityId,
          type: entityType,
          longUrl: url,
          shortUrl: req,
          referer,
        },
      ]
      localStorage.setItem('urls', JSON.stringify(newUrlObjArr))
    }
  }
  return req === url ? false : req
}

export async function sendLogsToServer() {
  return new Promise(async (resolve, reject) => {
    try {
      const logs = JSON.parse(localStorage.getItem('logs'))
      if (!logs) resolve(false)

      const property = getProperty()
      const serialNumber = localStorage.getItem('serialNumber')
      const headers = serialNumber ? { 'X-GVG-Device-SerialNumber': serialNumber } : {}
      const updatedLogs = logs.map(log => {
        return {
          ...log,
          propertyId: property?.id,
        }
      })
      const response = await kiosk.post('/deviceLogs', updatedLogs, { headers })
      if (response.status === 200) {
        localStorage.setItem('logs', JSON.stringify([]))
        resolve(true)
      }
    } catch (error) {
      console.error(error)
      reject(error)
    }
  })
}

export function getAppState(currentPropertyState) {
  const oldPropertyState = JSON.parse(localStorage.getItem('previousPropertyState'))

  if (!oldPropertyState) {
    localStorage.setItem('previousPropertyState', JSON.stringify(currentPropertyState))

    let { logs, previousPropertyState, previousMessageState,messages, urls, ...localStorageItems } = { ...localStorage }

    localStorageItems = {
      ...localStorageItems,
      property: JSON.stringify(currentPropertyState),
    }
    return { ...localStorageItems }
  }

  const { token: prevToken, ...previousPropertyData } = oldPropertyState
  const { token: currToken, ...currentPropertyData } = currentPropertyState

  if (isEqual(previousPropertyData, currentPropertyData)) {
    let { logs, property, previousPropertyState,previousMessageState, messages,urls, ...localStorageItems } = { ...localStorage }
    return localStorageItems
  }
  if (!isEqual(previousPropertyData, currentPropertyData)) {
    localStorage.setItem('previousPropertyState', JSON.stringify(currentPropertyState))
    let { property, logs, previousPropertyState,previousMessageState, messages,urls, ...localStorageItems } = { ...localStorage }
    localStorageItems = {
      ...localStorageItems,
      property: JSON.stringify(currentPropertyState),
    }
    return localStorageItems
  }
}

export function logAppErrors(error, errorInfo) {
  // const { logs, previousPropertyState, previousMessageState, ...localStorageItems } = { ...localStorage }
  // const log = {
  //   module: 'app-error',
  //   metadata: {
  //     value: {
  //       error,
  //       errorInfo,
  //       appState: { ...localStorageItems },
  //     },
  //   },
  // }
  // saveLogsToLocalStorage(log)
}

export function calculateLocalStorageSize() {
  let totalSize = 0
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    const value = localStorage.getItem(key)
    totalSize += key.length + value.length
  }
  return totalSize
}

export function clearLocalStorage(key) {
  localStorage.removeItem(key)
}

export function computeLocalStorageSize() {
  const localStorageSize = calculateLocalStorageSize()
  const maxLocalStorageSize = 1 * 1024 * 1024

  if (localStorageSize > maxLocalStorageSize) {
    // sendLogsToServer()
    // clearLocalStorage('logs')
  }
}

export function sortRecommendationList(restaurantList) {
  return [...restaurantList].sort((a, b) => (
    a.content.isRecommended === b.content.isRecommended ? 0
      : a.content.isRecommended ? -1 : 1
  ));
}
