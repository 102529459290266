import React from 'react'

import useSlideshow from '../../hooks/useSlideshow'
import { TrackLink } from '../metrics'
import { SlideshowArrow } from '../icons'
import '../../styles/Arrows.scss'

const Arrows = () => {
  const { previous, next } = useSlideshow()
  return (
    <div className="Arrows">
      <TrackLink to="/" onClick={previous} className="ArrowPrevious" source="Home Previous Arrow" name="Artwork">
        <SlideshowArrow data-cy="btn-arrow-previous" />
      </TrackLink>
      <TrackLink to="/" onClick={next} className="ArrowNext" source="Home Next Arrow" name="Artwork">
        <SlideshowArrow data-cy="btn-arrow-next" />
      </TrackLink>
    </div>
  )
}

export default Arrows
