import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  get, map, countBy, orderBy,
} from 'lodash'
import { PageHeader } from '../components/pages'
import { TrackLink } from '../components/metrics'
import { RecommendationsListItem } from '../components/recommendations'
import { useCustomization } from '../hooks/useCustomization'
import '../styles/PropertyInfoContainer.scss'
import { setOverlay } from '../actions/device'

import restaurantImage from '../images/restaurant-default.png'
import activityImage from '../images/activity-default.png'
import directoryImage from '../images/directory-default.png'

export const LIST_OPTIONS = [
  {
    key: 'restaurants',
    quoteKey: 'restaurant',
    title: 'Restaurants',
    link: '/recommendations/restaurants',
    image: restaurantImage,
    cypress: 'restaurants',
  },
  {
    key: 'activities',
    quoteKey: 'activity',
    title: 'Activities',
    link: '/recommendations/activities',
    image: activityImage,
    cypress: 'activities',
  },
  {
    key: 'directory',
    quoteKey: 'directory',
    title: 'Directory',
    link: '/recommendations/directory',
    image: directoryImage,
    cypress: 'directory',
  },
]
const orderCategories = recommendations => {
  const countedCategories = map(countBy(recommendations, 'content.category'), (count, category) => ({
    category,
    count,
  }))
  return map(orderBy(countedCategories, 'count', 'desc'), 'category').slice(0, 10)
}

const RecommendationItem = ({
  section: {
    key, quoteKey, title, link, image, cypress,
  }, hostQuotes,
}) => {
  const recommendations = useCustomization(key)
  const hostQuote = get(hostQuotes, quoteKey)

  const hasItems = recommendations && recommendations.length > 0
  const img = hasItems && (image || recommendations[0].content.imageURL)
  const categories = hasItems && orderCategories(recommendations)

  return hasItems ? (
    <TrackLink key={title} name={title} className='option' source='RecommendationsContainer' to={link} analytics={false}>
      <RecommendationsListItem
        title={title}
        subtitle={hostQuote}
        img={img}
        categories={categories}
        link={link}
        cypress={cypress}
      />
    </TrackLink>
  ) : null
}

const RecommendationsContainer = ({ setOverlay }) => {
  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])

  const hostQuotes = useCustomization('recommendationQuotes')

  return (
    <div className='Card full-height RecommendationsContainer'>
      <div className='card-section header border-bottom px-0 mx-4'>
        <PageHeader title='Our Recommendations' />
      </div>
      <div className='card-section recommendation-items'>
        {LIST_OPTIONS.map(section => (
          <RecommendationItem key={section.key} section={section} hostQuotes={hostQuotes} />
        ))}
      </div>
    </div>
  )
}

export default connect(null, {
  setOverlay,
})(RecommendationsContainer)
