import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { metrics } from '../../api'

const TrackLink = ({
  as: AsComponent,
  to,
  onClick,
  action,
  source,
  name,
  entityId,
  entityType,
  template,
  section,
  analytics = true,
  ...props
}) => {
  const trackOnClick = evt => {
    try {
      metrics.track(action, {
        extra: {
          source, name, template, section, extraInfo: to,
        },
        entityId,
        entityType,
        analytics,
      })
    } catch {
      console.log('Error')
    }
    if (onClick) {
      return onClick(evt)
    }
  }
  return <AsComponent {...props} to={to} onClick={trackOnClick} />
}

TrackLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  source: PropTypes.string.isRequired,
  action: PropTypes.string,
  name: PropTypes.string,
  template: PropTypes.string,
}

TrackLink.defaultProps = {
  action: 'click',
  as: Link,
}

export default TrackLink
