import React from 'react'
import Icon from './Icon'

const ChevronIcon = props => (
  <Icon viewBox="0 0 16 16" fill="none" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      d="M5.108 16.41L3.59 15.108 9.683 8 3.59.892 5.108-.41l6.651 7.76a1 1 0 0 1 0 1.301L5.108 16.41z"
    />
  </Icon>
)

export default ChevronIcon
