import React from 'react'
import Icon from './Icon'

const FullScreenVideoIcon = props => (
  <Icon width="60" height="60" viewBox="0 0 16.5 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="6.5" cy="6.5" r="6.5" fill="black" />
    <path
      d="M4 4L5.38889 5.375L4 4ZM4 8.98611L5.38889 7.61111L4 8.98611ZM9 8.98611L7.625 7.61111L9 8.98611ZM8.98611 4L7.61111 5.375L8.98611 4ZM7.75 4H9V5.25L7.75 4ZM9 7.75V9H7.75L9 7.75ZM5.25 9H4V7.75L5.25 9ZM4 5.25V4H5.25"
      fill="#FBDADA"
    />
    <path
      d="M4 5.25V4H5.25M4 4L5.38889 5.375L4 4ZM4 8.98611L5.38889 7.61111L4 8.98611ZM9 8.98611L7.625 7.61111L9 8.98611ZM8.98611 4L7.61111 5.375L8.98611 4ZM7.75 4H9V5.25L7.75 4ZM9 7.75V9H7.75L9 7.75ZM5.25 9H4V7.75L5.25 9Z"
      stroke="white"
      strokeWidth="0.56"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default FullScreenVideoIcon
