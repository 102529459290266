import React from 'react'
import classNames from 'classnames'
import { CheckedCircleIcon, WarningIcon, ForbiddenIcon } from '../icons'
import '../../styles/DetailFooter.scss'
import ConnectHostIcon from '../icons/ConnectHostIcon'

const STATUSES = [
  {
    test: status => status.includes('FAILED'),
    icon: WarningIcon,
    className: 'warning',
  },
  {
    test: status => status.includes('DECLINED'),
    icon: ForbiddenIcon,
    className: 'danger',
  },

  {
    test: status => status.includes('SERVICE_INFO_REQUEST'),
    icon: ConnectHostIcon,
  },
  {
    test: () => true,
    icon: CheckedCircleIcon,
  },
]

const DetailStatus = ({
  status, sentence, showIcon = true, onClick = null,
}) => {
  const defaultSentence = `Your request has been updated to: ${status}`

  let index = 0
  let Icon
  let className
  do {
    const { test } = STATUSES[index]
    if (test(status)) {
      Icon = STATUSES[index].icon
      className = STATUSES[index].className
      break
    }
    index += 1
  } while (index < STATUSES.length)

  const conditionalInnerStyle = status === 'CONNECT_HOST'
    ? {
      width: '55%',
    }
    : {}
  return (
    <div className={classNames('request-status', className)} style={conditionalInnerStyle} onClick={onClick}>
      {showIcon && (
        <div className="request-status-icon">
          <Icon />
        </div>
      )}
      {className === 'warning'
        ? (
          <div className='conditional-warning'>
            <p>Your service cannot be processed.</p>
            <p>Please connect with your host for the details.</p>
          </div>
        )
        : sentence || defaultSentence}
    </div>
  )
}

export default DetailStatus
