import React from 'react'
import Icon from './Icon'

const BackIcon = props => (
  <Icon viewBox="0 0 16 16" {...props} width="1em" height="1em">
    <path
      fill="currentColor"
      d="M8 5a10.07 10.07 0 0 0-6 2.017V5H0v5a1 1 0 0 0 1 1h5V9H2.722A8.058 8.058 0 0 1 8 7a8.06 8.06 0 0 1 5.657 2.343l1.414-1.414A10.068 10.068 0 0 0 8 5z"
    />
  </Icon>
)

export default BackIcon
