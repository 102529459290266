import TextSwitch from '../../components/TextSwitch'
import { bindOnChangeToValue } from '../helpers'

export default {
  Component: bindOnChangeToValue(TextSwitch),
  examples: [
    {
      name: 'Basic Use',
      props: {
        value: true,
        on: 'Yes',
        off: 'No',
        onChange: () => {},
      },
    },
  ],
}
