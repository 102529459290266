import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import avatarPlaceholder from '../images/icons/avatar_placeholder.png'
import recBadge from '../images/icon-recommendedbadge-lg.png'
import '../styles/HostQuote.scss'
import { MESSAGES } from '../constants/messages'

const HostQuote = ({
  host, quote, className, isRecommended, showName, orgName, header, dark,
}) => {
  if (quote && quote.length > 0) {
    return (
      <div
        className={classNames('HostQuote', className, {
          dark,
        })}
      >
        <div className="avatar">
          <img src={host.photoURL ? host.photoURL : avatarPlaceholder} className="avatar-pic" alt="host" />
          {isRecommended && <img src={recBadge} className="recommended-badge" alt="rec-badge" />}
        </div>
        <div className="quote-wrapper">
          <div className="quote">
            {header ? (
              <div className="quote-header">{header}</div>
            ) : (
              <div className="quote-header">{MESSAGES.FROM_YOUR_HOST}</div>
            )}
            <div className="quote-text" data-cy="quote-text">
              {quote}
            </div>
            {showName && (
              <div className="quote-source">
                <div className="quote-name">{host.name}</div>
                <div className="quote-org">{orgName}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
  return null
}

const mapStateToProps = state => ({
  host: state.property.host,
  orgName: state.property.organization.name,
})

export default connect(mapStateToProps)(HostQuote)

HostQuote.defaultProps = {
  isRecommended: false,
  showName: false,
  dark: false,
}
