import React from 'react'
import Icon from './Icon'

const AmenitiesIcon = props => (
  <Icon width={138} height={77} viewBox="0 0 138 77" fill="none" {...props}>
    <path
      d="M134.5 2H79a2 2 0 00-2 2v69.5a2 2 0 002 2h55.5a2 2 0 002-2V4a2 2 0 00-2-2z"
      stroke="#fff"
      strokeWidth={2.5}
    />
    <circle cx={91.5} cy={15.5} r={4} stroke="#fff" strokeWidth={2} />
    <circle cx={106.5} cy={38.5} r={15.5} stroke="#fff" strokeWidth={3} />
    <path
      d="M97.5 37.5l2.884 2.596a3.001 3.001 0 004.129-.109l.366-.366a3 3 0 014.242 0l.129.13a3 3 0 004.364-.129L115.5 37.5"
      stroke="#C4C4C4"
      strokeWidth={2}
    />
    <path
      d="M2 27v29a4 4 0 004 4h55.39a4 4 0 003.999-4.108l-.784-29A4 4 0 0060.607 23H6a4 4 0 00-4 4zM17.5 60v4.5M49 60v4.5"
      stroke="#fff"
      strokeWidth={2.5}
    />
  </Icon>
)
export default AmenitiesIcon
