import React from 'react'
import { Menu } from 'semantic-ui-react'
import { Route, Link, BrowserRouter } from 'react-router-dom'

import '../styles/playground/index.scss'

// load the contents of the fixtures folder dynamically
const context = require.context('./fixtures/', true, /\.(js)$/)
const fixtures = []
context.keys().forEach(filename => {
  fixtures.push(context(filename).default)
})

// Use a custom JSON replacer method to stringify the provided props,
// but omit chunks that are common between all the examples. (like forecast)
// Also supports stringifying functions, which are normally omitted.

function propSummaryWithNamedChunks(props, namedJSONChunks = {}) {
  const replacer = (k, v) => {
    for (const key of Object.keys(namedJSONChunks)) {
      /* eslint-disable-line no-unused-vars */
      if (namedJSONChunks[key] === v) {
        return `<${key}>`
      }
    }
    if (v instanceof Function) {
      return v.toString()
    }
    return v
  }
  return JSON.stringify(props, replacer, 2).replace(/\\n/g, '\n')
}

function ExamplePageFactory({ Component, examples, namedJSONChunks = {} }) {
  return () => (
    <div>
      {examples.map(({ name, props }) => (
        <div className="example" key={name}>
          <h2>{name}</h2>
          {/* <pre>{propSummaryWithNamedChunks(props, namedJSONChunks)}</pre> */}
          <div className="preview">
            <Component {...props} />
          </div>
        </div>
      ))}
      {Object.keys(namedJSONChunks).length > 0 && (
        <h1
          style={{
            margin: 20,
            marginTop: 100,
          }}
        >
          Prop Data
        </h1>
      )}
      {Object.keys(namedJSONChunks).map((key) => (
        <div className="named-chunk" key={key}>
          <h2>{key}</h2>
          <pre
            style={{
              maxHeight: 400,
              overflow: 'scroll',
            }}
          >
            {propSummaryWithNamedChunks(namedJSONChunks[key])}
          </pre>
        </div>
      ))}
    </div>
  )
}

function nameFor(componentClass) {
  return componentClass.displayName || componentClass.name
}

export default class Index extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div className="playground">
          <div className="sidebar">
            <Menu pointing secondary vertical>
              {fixtures.map(({ Component }) => (
                <Link key={nameFor(Component)} to={`/playground/${nameFor(Component)}`}>
                  <Menu.Item name={nameFor(Component)} />
                </Link>
              ))}
            </Menu>
          </div>
          <div className="content">
            {fixtures.map(fixture => (
              <Route
                exact
                key={nameFor(fixture.Component)}
                path={`/playground/${nameFor(fixture.Component)}`}
                component={ExamplePageFactory(fixture)}
              />
            ))}
          </div>
        </div>
      </BrowserRouter>
    )
  }
}
