import React from 'react'

const CheckedCircleIcon = props => (
  <svg viewBox="0 0 64 64" {...props}>
    <g strokeWidth={5} fill="none" stroke="#5cd5e7" strokeLinecap="round" strokeMiterlimit={10} strokeLinejoin="round">
      <path data-color="color-2" d="M18 34l8 8 20-20" />
      <circle cx={32} cy={32} r={29} strokeWidth={4} />
    </g>
  </svg>
)

export default CheckedCircleIcon
