import React from 'react'
import Icon from './Icon'

const ArrowIcon = props => (
  <Icon viewBox="0 0 16 16" {...props} width="1em" height="1em">
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
      <path data-color="color-2" d="M.5 8.5h15" />
      <path d="M10.5 3.5l5 5-5 5" />
    </g>
  </Icon>
)

export default ArrowIcon
