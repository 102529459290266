import React, { Component } from 'react'
import {
  withRouter, Route, Switch, Redirect,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import axios from 'axios'
import gvglogo from './images/gvg-logo-main.png'
import iconbell from './images/icons/bell-icon-solid.svg'
import { getDeviceType, getPropertyConfirmed, hasSetPasscode } from './utils/persistent-state';
import { sendLogsToServer , makeAPIRequest, logAppErrors } from './utils/helpers'

import { metrics } from './api'
import './styles/App.scss'
import {
  HomeContainer,
  MapContainer,
  MessageContainer,
  DevicePINContainer,
  PropertyInfoContainer,
  PropertyInfoItemsContainer,
  PropertyInfoDetailContainer,
  ServicesContainer,
  ServiceDetailContainer,
  SendToPhoneContainer,
  FooterContainer,
  SleepModeContainer,
  OnboardingContainer,
  PasscodeSimpleContainer,
  SendToPhoneSimpleContainer,
  SettingsPublicContainer,
  SettingsPrivateContainer,
  SendToPhoneResponse,
  CheckOutWizardContainer,
  DeviceLoadPreviewContainer,
  TipsContainer,
  RecommendationsContainer,
  RecommendationsListContainer,
  RecommendationContainer,
  AboutUsContainer,
} from './containers'
import PasscodeContainer from './components/PasscodeScreen'
import OutOfOrder from './components/OutOfOrder'
import ErrorPage from './components/ErrorPage'
import OnscreenKeyboard from './components/OnscreenKeyboard'
import OfflineWarning from './components/OfflineWarning'
import { BackgroundFooter } from './components'
import { Slideshow, SlideshowContent, Arrows } from './components/slideshow'

import { contactForBifurcatedCheckout, ContactRequest, ContactSendToPhone } from './components/contact'

import {
  fetchPropertyAndState, fetchPropertyWeather, unpair,
} from './actions/property'
import { getUnreadMessages } from './utils/messages'
import { clearCache } from './ClearCache'
import { WEATHER_REFETCH_TIME } from './constants/appConstants'
import SendToPhoneResponseThanks from './containers/SendToPhoneResponseThanks'
import ServiceInstructions from './components/services/ServiceInstructions'
import ServiceChoices from './components/services/ServiceChoices'
import { Mixpanel } from './api/mixpanel'
import ConnectWithHost from './components/services/ConnectWithHost'
import MessagesHistoryListingContainer from './containers/MessagesHistoryListingContainer'
import MessageSendToPhone from './components/contact/MessageSendToPhone'
import { AboutUsSendToPhone } from './components/about'
import ContactHostContainer from './containers/ContactHostContainer'
import ContactRequestContainer from './containers/ContactRequestContainer'
import PropertyInfoSendToPhone from './components/propertyInfo/PropertyInfoSendToPhone'

const RETURN_TO_IDLE_DELAY = process.env.REACT_APP_IDLE_SCREEN_TIME_IN_MINUTES * 60 * 1000

const RANDOM_NUMBER = Math.floor(Math.random() * (300 - 135) + 135) // random number in seconds between 2.5 and 5 minutes
const PROPERTY_REFRESH = RANDOM_NUMBER * 1000
const BUILD_CHECK = 900 * 1000
const SEND_TO_SERVER_DELAY = Math.floor(Math.random() * (30 - 20 + 1) + 20) * 60 * 1000; // random number in minutes between 20 to 30 minutes

const rootElem = document.getElementById('root')
window.previousLocation = {
  pathname: '',
}

class App extends Component {
  constructor(props) {
    super(props)

    this._idleTimeout = null
    this.state = {
      interactive: false,
      caughtError: false,
      tapped: false,
      brandingActive: false,
    }
  }

  componentDidMount() {
    this.subscribeListeners(true)
    this._mounted = true
    this.moveToForcedPageIfNecessary()
    this._fetchTimer = setInterval(this.refresh, PROPERTY_REFRESH)
    this._fetchWeatherTimer = setInterval(this.refreshWeather, WEATHER_REFETCH_TIME)
    this._fetchBuildTimer = setInterval(this.refreshBuild, BUILD_CHECK)
    // this.logsIntervalId = setInterval(sendLogsToServer, SEND_TO_SERVER_DELAY);
    this.refresh()
    this.refreshWeather()

    if (window.location && !window.location.href.includes('onboarding')) {
      this.onToggleCardVisible()
    }
    let motionDetectCount = 0
    let noMotionDetectCount = 0
    const detectMotion = async () => {
      axios({
        method: 'get',
        url: 'http://localhost:6001/api/lcddevice/sensor/motiondetected',
        timeout: 2000,
      })
        .then(response => {
          console.log(`motion detected: ${response.data.MotionDetected}`)
          const { tapped } = this.state

          if (response.data.MotionDetected === true && !tapped) {
            if (motionDetectCount === 0) {
              this.setState({ interactive: true })
              noMotionDetectCount = 0
            }
            motionDetectCount++
          } else {
            if (noMotionDetectCount === 0) {
              motionDetectCount = 0

              this.restartIdleTimeout()
            }
            noMotionDetectCount++
          }
        })
        .catch(error => console.error(error))
    }
    if (process.env.REACT_APP_ENV !== 'development') {
      this._detectMotionTimer = setInterval(detectMotion, 1100)
      detectMotion()
    }

    if (window.location && window.location.href && (window.location.href.includes('aboutus') || window.location.href.includes('messages'))) {
      this.setState({ brandingActive: true })
    }
  }

  UNSAFE_componentWillReceiveProps() {
    window.previousLocation = this.props.location
  }

  componentDidUpdate(prevProps) {
    this.moveToForcedPageIfNecessary()
    if (this.props.location !== prevProps.location) {
      const path = this.props.location.pathname
        ? this.props.location.pathname === '/'
          ? 'slideshow'
          : this.props.location.pathname
        : ''
      // Mixpanel.track('Page View Display', { path })
    }

    if (window.location && window.location.href && window.location.href.includes('messages')) {
      if (!this.state.brandingActive) {
        this.setState({ brandingActive: true })
      }
    }

    if (window.location && window.location.href && (!window.location.href.includes('aboutus') && !window.location.href.includes('messages'))) {
      if (this.state.brandingActive) {
        this.setState({ brandingActive: false })
      }
    }
  }



  componentWillUnmount() {
    this._mounted = false
    clearTimeout(this._reloadTimer)
    clearInterval(this._fetchTimer)
    clearInterval(this._fetchWeatherTimer)
    clearInterval(this._fetchBuildTimer)
    // clearInterval(this.logsIntervalId);
    this.subscribeListeners(false)
  }

  subscribeListeners = onMount => {
    if (onMount) {
      rootElem.addEventListener('click', this.handleUserEngagement, { passive: false })
      rootElem.addEventListener('scroll', this.handleUserEngagement, { passive: false })
      rootElem.addEventListener('touchmove', this.handleUserEngagement, { passive: false })
    } else {
      rootElem.removeEventListener('scroll', this.handleUserEngagement, { passive: false })
      rootElem.removeEventListener('click', this.handleUserEngagement, { passive: false })
      rootElem.removeEventListener('touchmove', this.handleUserEngagement, { passive: false })
    }
  }

  handleUserEngagement = () => {
    this.resetTimer()
  }

  onSlideshowLink = () => {
    this.setState({ interactive: true })
  }

  onTap = () => {
    const { interactive, brandingActive } = this.state
    if (brandingActive) {
      this.setState({ brandingActive: false })
    }
    if (!interactive) {
      metrics.start()
      this.setState({ tapped: true }, () => this.onToggleCardVisible())
    } else {
      metrics.end()
      this.onToggleCardVisible()
    }
  }

  onToggleCardVisible = () => {
    const { history } = this.props

    this.setState({ interactive: !this.state.interactive }, () => {
      if (!this.state.interactive) {
        setTimeout(() => {
          history.push('/')
          localStorage.setItem('saved-pagination', '')
        }, 400)
      }
      setTimeout(() => {
        this.restartIdleTimeout()
      }, 3000)
    })
  }

  onHiddenButtonClick = async () => {
    const {
      location: { pathname },
      history,
    } = this.props
    if (pathname.includes('services')) {
      const contactRequest = await makeAPIRequest('reservation/pending-reservation-service', {
        method: 'DELETE',
      })
      if (contactRequest.success) {
        window.location.reload()
      }
    } else {
      localStorage.removeItem('hasViewedOnboarding')
      localStorage.removeItem('checkoutSurveyComplete')
      history.push('/onboarding/checkin')
      this.setState({ interactive: true })
    }
  }

  navToBranding = async () => {
    const { history } = this.props
    history.push('/aboutus')
    this.setState({ brandingActive: true })
  }

  navToListing = async () => {
    const { history } = this.props
    history.push('/messages')
    this.setState({ brandingActive: true })
  }

  refresh = () => {
    const { settings, dispatch } = this.props
    const demoUpdated = localStorage.getItem('demoUpdated')
    if (!demoUpdated) {
      if (getDeviceType() === 'demo') {
        localStorage.removeItem('deviceType')
        localStorage.setItem('demoUpdated', true)
      }
    }
    try {
      dispatch(fetchPropertyAndState(settings))
    } catch (err) {
      console.log(err)
      throw new Error(err)
    }
  }

  refreshBuild = () => {
    try {
      clearCache()
    } catch (err) {
      window.location.reload()
      console.log(err)
      // throw new Error(err)
    }
  }

  refreshWeather = () => {
    const { dispatch } = this.props

    try {
      dispatch(fetchPropertyWeather())
    } catch (error) {
      console.error(error)
    }
  }

  moveToForcedPageIfNecessary() {
    const {
      property,
      history,
      location: { pathname },
    } = this.props

    if (pathname.includes('device-pairing') && property && Object.keys(property).length) {
      history.push('/')
    }

    if (
      this.props.history.location
      && this.props.history.location.state
      && this.props.history.location.state.justCheckedOut
    ) {
      if (!this.state.interactive) this.setState({ interactive: true })
    }
    if (pathname.includes('device-preview')) {
      if (!this.state.interactive) this.setState({ interactive: true })
    } else if (!hasSetPasscode()) {
      // Move the user to initial passcode setup and then to device pairing if there is no property
      if (!pathname.includes('passcode-screen') && !pathname.includes('device-pairing')) {
        history.push('/passcode-screen')
      } 
    } else if (!property || Object.keys(property).length === 0) {
      if (!pathname.includes('device-pairing')) history.push('/device-pairing')
    } else {
      const res = property.reservation

      // Move the user to onboarding if the reservation flag is unset
      if (res && localStorage.getItem('hasViewedOnboarding') !== `${res.id}`) {
        if (!pathname.includes('onboarding')) {
          history.push('/onboarding/checkin')
          this.setState({ interactive: true })
        }
      }
      // Move the user off of onboarding back to no stay mode if the reservation disappears
      if (!res || (res && localStorage.getItem('hasViewedOnboarding') === `${res.id}`)) {
        if (pathname.includes('onboarding/checkin')) {
          history.push('/home')
        }
      }
    }
  }

  restartIdleTimeout() {
    clearTimeout(this._idleTimeout)
    if (this.state.interactive) {
      this._idleTimeout = setTimeout(() => {
        if (!this._mounted) {
          return
        }
        this.onToggleCardVisible()
      }, RETURN_TO_IDLE_DELAY)
    } else {
      setTimeout(() => {
        this.setState({ tapped: false })
      }, RETURN_TO_IDLE_DELAY)
    }
  }

  resetTimer() {
    this.restartIdleTimeout()
  }

  render() {
    const {
      property,
      device: { hideFooter },
      messages,
      messagesHistory,
    } = this.props
    const { interactive, caughtError } = this.state

    if (this.props.device.isBlacklisted) {
      return (
        <OutOfOrder />
      )
    }

    if (!property || Object.keys(property).length === 0) {
      return (
        <Switch>
          <Route path="/passcode-screen" component={PasscodeContainer} />
          <Route path="/device-pairing" component={DevicePINContainer} />
          <Route path="/device-preview" component={DeviceLoadPreviewContainer} />
        </Switch>
      )
    }


    const totalUnReadMessages = messagesHistory.filter(msg => msg.status === 'UNREAD')?.length
    if (getPropertyConfirmed() === 'no') {
      if (property || Object.keys(property).length !== 0) {
        unpair()
        window.location.reload()
      }
    }

    const containerClass = window.location.href.includes('phone') ? 'phone-wrapper interactive' : 'slide-show-wrapper'

    return (
      <div>
        <ToastContainer style={{ marginLeft: 0, top: 0 }} hideProgressBar closeButton={false} position="top-center" />
        <Slideshow onClick={this.onTap} pause={interactive} />

        {!this.state.brandingActive && interactive && (
          <div className="topbar">
            <div onClick={this.navToBranding} className="logo-main">
              <img src={gvglogo} alt="gvg-logo" />
            </div>
            <div onClick={this.onHiddenButtonClick} className="secret-button-for-service-request" />
            <div onClick={this.navToListing} className="notifcation-bell">
              <img src={iconbell} alt="bell-icon" />
              {totalUnReadMessages ? <small>{totalUnReadMessages}</small> : null}
            </div>
          </div>
        )}

        <div
          className={`foreground-vertical-flexbox ${containerClass} ${interactive && 'interactive'} ${!interactive
            && 'fixed-promo'}`}
        >
          <OfflineWarning />
          <div className="if-interactive">
            <Route path=":path(/)" component={Arrows} />
          </div>
          <div style={{ flex: 1 }} />
          <div className="Content if-interactive">
            <Switch>
              <Route exact path="/home" component={HomeContainer} />
              <Route exact path="/aboutus" component={AboutUsContainer} />
              <Route exact path="/aboutus/phone" component={AboutUsSendToPhone} />
              <Route exact path="/messages" component={MessagesHistoryListingContainer} />
              <Route exact path="/map" component={MapContainer} />
              <Route exact path="/map/:id" component={MapContainer} />
              <Route exact path="/house-rules" component={TipsContainer} />

              <Route exact path="/property-info" component={PropertyInfoContainer} />
              <Route exact path="/property-info/:section" component={PropertyInfoItemsContainer} />
              <Route exact path="/property-info/:section/:id" component={PropertyInfoDetailContainer} />

              <Route exact path="/recommendations" component={RecommendationsContainer} />
              <Route exact path="/recommendations/:section" component={RecommendationsListContainer} />
              <Route exact path="/recommendations/:section/:id" component={RecommendationContainer} />
              <Route exact path="/services" component={ServicesContainer} />
              <Route exact path="/services/:id" component={ServiceDetailContainer} />
              <Route exact path="/services/:id/serviceInstructions" component={ServiceInstructions} />
              <Route exact path="/services/:id/serviceChoices" component={ServiceChoices} />
              <Route exact path="/services/:id/connectWithHost" component={ConnectWithHost} />
              <Route exact path="/contact" component={ContactHostContainer} />
              <Route exact path="/contact/phone" component={ContactSendToPhone} />
              <Route exact path="/contact/phone/bifurcated" component={contactForBifurcatedCheckout} />
              <Route exact path="/contact/phone/announcement" component={MessageSendToPhone} />
              <Route exact path="/contact/request" component={ContactRequestContainer} />
              <Route exact path="/contact/phone/propertyInfo" component={PropertyInfoSendToPhone} />
            </Switch>
          </div>
          <Route
            exact
            path="/"
            render={props => (
              <SlideshowContent interactive={interactive} setInteractive={this.onSlideshowLink} {...props} />
            )}
          />

          <Route exact path="/entersleepmode" component={SleepModeContainer} />
          <Switch>
            <Route path="/onboarding/" component={OnboardingContainer} />
            <Route path="/onboarding-complete" />
            <Route path="/admin-passcode-screen" component={PasscodeContainer} />
            <Redirect from="/onboarding/1" to="/onboarding/checkin" />
            <Redirect from="/onboarding/2" to="/onboarding/impressions" />
            <Redirect from="/onboarding/4" to="/onboarding/terms" />

            <Route path="/passcode-screen" component={PasscodeContainer} />
            <Route path="/passcode-screen-simple" component={PasscodeSimpleContainer} />

            <Route path="/settings" component={SettingsPublicContainer} />
            <Redirect from="/pm-settings" to="/settings" />
            <Route path="/admin-settings" component={SettingsPrivateContainer} />

            <Route path="/device-pairing" component={DevicePINContainer} />
            <Route path="/device-preview" component={DeviceLoadPreviewContainer} />
            {/* todo: get rid of route below and update cypress tests */}
            <Route path="/send-to-phone" component={SendToPhoneContainer} />
            <Route path="/send-to-phone-simple" component={SendToPhoneSimpleContainer} />
            <Route exact path="/:section/:id/phone" component={SendToPhoneContainer} />
            <Route exact path="/recommendations/:section/:id/phone" component={SendToPhoneContainer} />
            <Route path="/:section/:id/phone/success" component={SendToPhoneResponse} />
            <Route path="/phone/success" component={SendToPhoneResponseThanks} />
            <Route exact path="/checkout" component={CheckOutWizardContainer} />
            {!hideFooter && !this.state.brandingActive && <Route path="/*" render={FooterContainer} />}

          </Switch>
          <BackgroundFooter onClose={this.onTap} className="if-interactive" />
        </div>

        <OnscreenKeyboard />
        <Helmet>
          <title>
            [
            {process.env.REACT_APP_ENV}
            ] NEC Phase 1
          </title>
        </Helmet>
        {messages.length > 0 && <MessageContainer interactive={interactive} messages={messages} onTap={this.onTap} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { messages = [], messagesHistory = [] } = state.messages
  return {
    messages,
    property: state.property,
    settings: state.settings,
    device: state.device,
    messagesHistory,
  }
}

export default withRouter(connect(mapStateToProps)(App))
