import React, { Component } from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { PageHeader, DetailFooter } from '../pages'
import { RequestButton } from '../services'
import {
  Checklist, AnimateFadeWrapper, Checkbox, PropertyInfoOverview,
} from '..'

import { toggleChecked, resetChecklist } from '../../resources/checklists'
import { setOverlay } from '../../actions/device'

import '../../styles/Card.scss'
import '../../styles/ContactContainer.scss'
import { MESSAGES } from '../../constants/messages'

class ContactContainer extends Component {
  state = {
    isUrgent: false,
  }

  componentDidMount() {
    this.props.setOverlay({ value: true })
  }

  componentWillUnmount() {
    this.props.setOverlay({ value: false })
  }

  toggleChecked = item => {
    const { toggleChecked } = this.props
    toggleChecked('contact', item)
  }

  toggleUrgent = () => {
    this.setState({ isUrgent: !this.state.isUrgent })
  }

  goBack = () => {
    const { resetChecklist, history } = this.props
    history.goBack()
    resetChecklist('contact')
  }

  render() {
    const {
      property, message, checklist, requestEnabled, urgentEnabled,
    } = this.props
    const { isUrgent } = this.state
    const hasChecklist = checklist.length !== 0

    return (
      <div className="Card full-height ContactContainer">
        <div className="card-section header border-bottom">
          <PageHeader title="Contact Host">
            {requestEnabled ? (
              <div>{message}</div>
            ) : (
              <div>
                <PropertyInfoOverview property={property} />
                {MESSAGES.DEFAULT_CONTACT_MESSAGE}
              </div>
            )}
          </PageHeader>
        </div>

        {requestEnabled ? (
          <>
            {hasChecklist && (
              <AnimateFadeWrapper key="checkout-list" className="card-section stretch checkout-list-container">
                <Checklist
                  toggleChecked={this.toggleChecked}
                  items={checklist}
                  subtitle="What type of issue would you like to report?"
                />
              </AnimateFadeWrapper>
            )}
            {/* {urgentEnabled && (
              <Checkbox
                title="Is this an URGENT request?"
                label="Yes, please respond as soon as possible"
                checked={isUrgent}
                onClick={this.toggleUrgent}
              />
            )} */}
            <DetailFooter className="bottom stretch">
              <RequestButton
                to={{
                  pathname: '/contact/phone',
                  state: {
                    isUrgent,
                    topics: checklist.filter(({ checked }) => checked),
                  },
                }}
              >
                {MESSAGES.SEND_REQUEST}
              </RequestButton>
              <div onClick={this.goBack} className="link">
                {MESSAGES.CANCEL}
              </div>
            </DetailFooter>
          </>
        ) : (
          <DetailFooter className="bottom stretch">
            <div onClick={this.goBack} className="link">
              {MESSAGES.BACK}
            </div>
          </DetailFooter>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ property, checklists }) => {
  const {
    message, requestEnabled, urgentEnabled, topics,
  } = get(property, ['customizations', 'contact'], {})
  return {
    property,
    message,
    requestEnabled,
    urgentEnabled,
    checklist: checklists.contact,
    topics,
  }
}
export default connect(mapStateToProps, { toggleChecked, resetChecklist, setOverlay })(ContactContainer)
