import React from 'react'

const HailIcon = props => (
  <svg width="36px" height="36px" viewBox="0 0 36 36" {...props}>
    <g stroke="white" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M11,25 L11,26 C8.23857625,26 6,23.7614237 6,21 C6,19.3641822 6.7855533,17.9118401 8.00000001,16.9996338 C8.00019791,13.1338088 11.1341288,10 15,10 C17.0709055,10 18.9317713,10.899287 20.2133866,12.3286503 C20.7682064,12.1163246 21.3705187,12 22,12 C23.8340618,12 25.4374824,12.9874923 26.3075821,14.4597972 C28.4759039,15.3639795 30,17.5040039 30,20 C30,22.9729963 27.8377133,25.4409635 25,25.9170416 L25,24.8999819 C27.2822403,24.4367116 29,22.4189579 29,20 C29,17.963055 27.770894,16.1534531 25.9227075,15.382766 L25.6158386,15.2548028 L25.4466816,14.9685704 C24.7310882,13.7577073 23.4318617,13 22,13 C21.5042701,13 21.022484,13.0897402 20.5708013,13.2625961 L19.9281855,13.5085207 L19.4688475,12.9962291 C18.3368953,11.7337826 16.728759,11 15,11 C11.6864087,11 9.00016964,13.6861097 9.00000001,16.999685 L8.99997444,17.4992017 L8.60057767,17.7992002 C7.59959317,18.5510689 7,19.7247698 7,21 C7,23.209139 8.790861,25 11,25 Z M19.4285714,24 L21.5171573,24 C21.6276142,24 21.7171573,24.0895431 21.7171573,24.2 C21.7171573,24.2530433 21.6960859,24.3039141 21.6585786,24.3414214 L14.510576,31.489424 C14.4324711,31.5675289 14.3058381,31.5675289 14.2277333,31.489424 C14.1780572,31.439748 14.1580874,31.3676505 14.1751261,31.2994955 L15.75,25 L14.265146,25 C14.154689,25 14.065146,24.9104569 14.065146,24.8 C14.065146,24.7814167 14.067736,24.7629241 14.0728412,24.7450558 L15.9585555,18.1450558 C15.983087,18.0591955 16.0615642,18 16.1508603,18 L21.6966922,18 C21.8071491,18 21.8966922,18.0895431 21.8966922,18.2 C21.8966922,18.2270854 21.8911906,18.2538885 21.8805212,18.2787839 L19.4285714,24 Z M17.9120324,25 L20.483461,19 L16.7543014,19 L15.32573,24 L17.0307764,24 L15.8457731,28.7400134 L19.5857864,25 L17.9120324,25 Z"
          fill="#000000"
        />
      </g>
    </g>
  </svg>
)
export default HailIcon
