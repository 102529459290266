import axios from 'axios'
import Logger from '../logger/logger'

const local = axios.create({
  baseURL: 'http://localhost:3000',
})

local.interceptors.request.use(config => {
  // log request
  Logger.http({
    module: config.path,
    metadata: config.data,
    apiInfo: {
      endpoint: config.path,
      method: config.method,
    },
  })
  return config
})

local.interceptors.response.use(
  response => {
    // log response
    Logger.http({
      module: response.path,
      apiInfo: {
        endpoint: response.path,
        method: response.method,
      },
      httpResponse: response.data,
    })
    return response
  },
  error => {
    Logger.error({
      error,
      module: 'local',
    })
  },
)

export default local
