import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { SendToPhoneContainer } from '../../containers'
import { withPropsFromLocation } from '../utils'
import { setOverlay } from '../../actions/device'
import { sendPropertyInfoDetailsOnSMS } from '../../actions/property'
import { MESSAGES } from '../../constants/messages'

const PropertyInfoSendToPhone = ({ history, setOverlay, sendPropertyInfoDetailsOnSMS, ...props }) => {
  const { state } = props.location

  useEffect(() => {
    setOverlay({ value: true })
    return () => setOverlay({ value: false })
  }, [])

  const onSend = async phone => {
    await sendPropertyInfoDetailsOnSMS({
      phone,
      id: state.id,
      name: state.name,
      resourceURL: state.resourceURL,
      mediaType: state.mediaType,
    })
    history.push('/phone/success')
  }

  return (
    <SendToPhoneContainer
      {...props}
      meta="Standard text messaging rates may apply."
      title={MESSAGES.PROPERTY_INFO}
      description="Enter your number to receive this property info on your phone"
      onSend={onSend}
    />
  )
}

export default connect(null, { setOverlay, sendPropertyInfoDetailsOnSMS })(
  withPropsFromLocation([''])(PropertyInfoSendToPhone)
)
