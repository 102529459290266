import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'

class ChecklistItem extends Component {
  onClick = () => {
    const { toggleChecked, item } = this.props
    toggleChecked(item)
  }

  render() {
    const { item } = this.props // ameneties

    // const amenitiesNames = amenities.map(item => item.name.toLowerCase())
    // let newString = item.text
    // amenitiesNames.forEach(name => {
    //   if (item.text.toLowerCase().includes(name)) {
    //     newString = reactStringReplace(newString, name, (match, i) => {
    //       return (
    //         <Link onClick={e => e.stopPropagation()} key={i} to={`/property-info/Amenities/${startCase(name)}`}>
    //           {match}
    //         </Link>
    //       )
    //     })
    //   }
    // })

    return (
      <div className="checklist-item" onClick={this.onClick}>
        <Icon name={item.checked ? 'check circle outline' : 'circle outline'} size="large" />
        <span className={`${item.checked && 'checked'}`} data-cy="checkout-item">
          {item.text}
          {' '}
          {/* newString  */}
        </span>
      </div>
    )
  }
}

export default ChecklistItem
