import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import { withErrorBoundary } from 'react-error-boundary'
import { address } from '../utils/formatters'
import BackLink from '../components/BackLink'
import Map, { MapLegend } from '../components/Map'
import TravelTimeEstimate from '../components/TravelTimeEstimate'
import { getPlacesForSectionName } from '../reducers/property_reducer'

import ErrorComponent from '../ErrorComponent'

import '../styles/MapContainer.scss'
import activityRecommendedIcon from '../images/icons/map/map-marker-activities-recommended.svg'
import activityIcon from '../images/icons/map/map-marker-activities.svg'
import restaurantRecommendedIcon from '../images/icons/map/map-marker-restaurant-recommended.svg'
import restaurantIcon from '../images/icons/map/map-marker-restaurant.svg'
import directoryRecommendedIcon from '../images/icons/map/map-marker-directory-recommended.svg'
import directoryIcon from '../images/icons/map/map-marker-directory.svg'
import { logAppErrors } from '../utils/helpers'

function getMarkerIcon(iconType, isRecommended) {
  switch (iconType) {
    case 'activities': {
      return isRecommended ? activityRecommendedIcon : activityIcon
    }
    case 'restaurant': {
      return isRecommended ? restaurantRecommendedIcon : restaurantIcon
    }
    case 'directory': {
      return isRecommended ? directoryRecommendedIcon : directoryIcon
    }
    default:
      return null
  }
}

function buildInfoContent(marker, history, map) {
  const onOpenDetails = () => {
    history.push(`/recommendations/${marker.representedSection}/${marker.id}`)
  }
  if (marker.home) {
    return (
      <div className="place-overlay">
        <div>
          <h5>{marker.title}</h5>
        </div>
        <div>{marker.address[0]}</div>
        <div>{marker.address[1]}</div>
      </div>
    )
  }
  const item = marker.representedPlace

  return (
    <div className="place-overlay">
      {item.isRecommended && <div className="recommendation">{item.hostQuote || ''}</div>}
      <div style={{
        display: 'flex', alignItems: 'start', maxWidth: 470, userSelect: 'none',
      }}
      >
        <img
          style={{
            width: 140, height: 90, objectFit: 'cover', borderRadius: 8, marginRight: 20,
          }}
          src={item.content.imageURL}
          alt="place"
        />
        <div style={{ flex: 1 }}>
          <button type="button" onClick={onOpenDetails}>
            <h5>{marker.title}</h5>
          </button>
          <div>{`${item.price ? `${item.price} • ` : ''}${item.content.category}`}</div>
          <a /* eslint-disable-line jsx-a11y/anchor-is-valid */
            style={{
              textAlign: 'right',
              marginTop: 10,
              display: 'block',
            }}
          >
            <TravelTimeEstimate origin={map.props.home.position} destination={marker.position} />
          </a>
        </div>
      </div>
    </div>
  )
}

class MapContainer extends Component {
  state = {
    hidden: {},
    customMap: null,
    customCenter: {},
    mapZoom: null,
  }

  // onSetMap = mapEl => {
  //   if (!this._mapEl && mapEl) {
  //     const initiallySelected = this.props.allMarkers.find(m => m.id === this.props.match.params.id)
  //     if (initiallySelected) {
  //       mapEl.showInfoPanel(initiallySelected)
  //     }
  //   }
  //   this._mapEl = mapEl
  // }

  setMapWithCenter = (map, center, zoom) => {
    this.setState({ customMap: map, customCenter: center, mapZoom: zoom })
  }

  onChangeMarkers = evt => {
    const { hidden } = this.state
    const type = evt.target.name
    this.setState({
      hidden: {
        ...hidden,
        [type]: !hidden[type],
      },
    })
  }

  render() {
    const {
      homePosition, homeAddress, allMarkers, match,
    } = this.props
    const { hidden } = this.state
    const { id } = match.params
    const filteredMarkers = allMarkers.filter(m => !hidden[m.iconType])
    return (
      <Card centered className="MapContainer">
        <Card.Content>
          <BackLink style={BackLink.Style.RoundButton} />
          <MapLegend
            hidden={hidden}
            onChangeFilter={this.onChangeMarkers}
            onCenter={() => {
              this.state.customMap && this.state.customMap.setCenter(this.state.customCenter)
              this.state.mapZoom && this.state.customMap.setZoom(this.state.mapZoom)
            }}
          />
          <Map
            home={{ title: 'Home', position: homePosition, address: homeAddress }}
            markerInfoContentBuilder={buildInfoContent}
            markers={filteredMarkers}
            id={id}
            setMapWithCenter={this.setMapWithCenter}
          />
        </Card.Content>
      </Card>
    )
  }
}

const mapStateToProps = storeState => {
  const { latitude, longitude } = storeState.property

  const placeToMarker = (place, section, iconType) => {
    return {
      representedPlace: place,
      representedSection: section,
      id: place.id,
      iconType,
      icon: {
        url: getMarkerIcon(iconType, place.isRecommended),
      },
      address: place.content.location.address1,
      title: place.name,
      position: {
        lat: place.content.location.coordinates.latitude,
        lng: place.content.location.coordinates.longitude,
      },
    }
  }

  let allMarkers = []

  allMarkers = allMarkers.concat(
    (getPlacesForSectionName(storeState, 'restaurants') || []).map(place => placeToMarker(place, 'restaurants', 'restaurant')),
  )
  allMarkers = allMarkers.concat(
    (getPlacesForSectionName(storeState, 'activities') || []).map(place => placeToMarker(place, 'activities', 'activities')),
  )
  allMarkers = allMarkers.concat(
    (getPlacesForSectionName(storeState, 'directory') || []).map(place => placeToMarker(place, 'directory', 'directory')),
  )

  return {
    homePosition: { lat: latitude, lng: longitude },
    homeAddress: address(storeState.property),
    allMarkers,
  }
}

const ComponentWithErrorBoundary = withErrorBoundary(MapContainer, {
  FallbackComponent: props => ErrorComponent({ ...props, component: 'Map Container' }),
  onError(error, info) {
    logAppErrors(error, info)
    console.log({ error, info })
    // Do something with the error
    // E.g. log to an error logging client here
  },
})

export default connect(mapStateToProps)(ComponentWithErrorBoundary)
