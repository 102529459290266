import React, { PureComponent } from 'react'
import KeyboardButton from './KeyboardButton'

import LatinLayout from './layouts/LatinLayout'
import CyrillicLayout from './layouts/CyrillicLayout'
import SymbolsLayout from './layouts/SymbolsLayout'
import NumericLayout from './layouts/NumericLayout'

import BackspaceIcon from './icons/BackspaceIcon'
import LanguageIcon from './icons/LanguageIcon'
import ShiftIcon from './icons/ShiftIcon'

/*
This file is derived from github.com/WiaczeslawP/react-screen-keyboard
with a few specific changes to suit our needs:
- the layouts specify the first line of the keyboard. It's not hardcoded.
- the layouts specify the placement of the Backspace key.
- onClick has been changed to onMouseDown to prevent focus from changing during editing,
	which was causing the keyboard to dismiss itself.
- the keys "repeat" if you tap and hold which is especially nice for backspace.
*/
export default class Keyboard extends PureComponent {
  static defaultProps = {
    leftButtons: [],
    rightButtons: [],
    isFirstLetterUppercase: true,
    isNumeric: false,
    layouts: [CyrillicLayout, LatinLayout],
  }

  state = {
    currentLayout: 0,
    showSymbols: false,
    uppercase: this.isUppercase(),
  }

  handleLanguageClick = () => {
    this.setState({
      currentLayout: (this.state.currentLayout + 1) % this.props.layouts.length,
      showSymbols: false,
    })

    this.props.inputNode.focus()
  }

  handleShiftClick = () => {
    this.setState({ uppercase: !this.state.uppercase })

    this.props.inputNode.focus()
  }

  // handleSymbolsClick = () => {
  //   this.setState({ showSymbols: !this.state.showSymbols })

  //   this.props.inputNode.focus()
  // }

  handleLetterButtonClick = key => {
    const { inputNode } = this.props
    const {
      value, selectionStart, selectionEnd, maxLength,
    } = inputNode

    const nextValue = value.substring(0, selectionStart) + key + value.substring(selectionEnd)

    // Max Length Validaton
    if ((maxLength > 0 && nextValue.length <= maxLength) || maxLength <= 0) {
      inputNode.value = nextValue
      if (this.props.onClick) {
        this.props.onClick(nextValue)
      }
      inputNode.focus()
      inputNode.setSelectionRange(selectionStart + 1, selectionStart + 1)

      this.setState({ uppercase: this.isUppercase() })
      inputNode.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }

  handleBackspaceClick = () => {
    const { inputNode } = this.props
    const {
      value, selectionStart, selectionEnd, maxLength,
    } = inputNode
    let nextValue
    let nextSelectionPosition
    if (selectionStart === selectionEnd) {
      nextValue = value.substring(0, selectionStart - 1) + value.substring(selectionEnd)
      nextSelectionPosition = selectionStart - 1
    } else {
      nextValue = value.substring(0, selectionStart) + value.substring(selectionEnd)
      nextSelectionPosition = selectionStart
    }
    nextSelectionPosition = nextSelectionPosition > 0 ? nextSelectionPosition : 0

    // Max Length Validaton
    if ((maxLength > 0 && nextValue.length <= maxLength) || maxLength <= 0) {
      inputNode.value = nextValue
      if (this.props.onClick) {
        this.props.onClick(nextValue)
      }
      inputNode.focus()
      inputNode.setSelectionRange(nextSelectionPosition, nextSelectionPosition)

      this.setState({ uppercase: this.isUppercase() })
      inputNode.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }

  // getSymbolsKeyValue() {
  //   if (this.state.showSymbols) {
  //     return this.props.layouts[this.state.currentLayout].symbolsKeyValue
  //   }
  //   return SymbolsLayout.symbolsKeyValue
  // }

  getKeys() {
    let keysSet
    if (this.state.showSymbols) {
      keysSet = SymbolsLayout.layout
    } else {
      keysSet = this.props.layouts[this.state.currentLayout].layout
    }
    return this.state.uppercase ? keysSet.map(keyRow => keyRow.map(key => (key.length === 1 && key.match(/[a-z]/i) ? key.toUpperCase() : key))) : keysSet
  }

  isUppercase() {
    const { inputNode, isFirstLetterUppercase } = this.props
    return (
      inputNode.type !== 'password'
      && inputNode.dataset.type !== 'email'
      && !inputNode.value.length
      && isFirstLetterUppercase
    )
  }

  renderKeyRows() {
    const keys = this.getKeys()
    return keys.map((row, i) => (
      <div className="keyboard-row" key={`row-${i}`}>
        {i === keys.length - 1 && (
          <KeyboardButton value={<ShiftIcon />} classes="keyboard-shiftButton" onMouseDown={this.handleShiftClick} />
        )}
        {row.map(button => (
          <KeyboardButton
            value={button === 'Backspace' ? <BackspaceIcon /> : button}
            onMouseDown={button === 'Backspace' ? this.handleBackspaceClick : this.handleLetterButtonClick}
            classes={button === 'Backspace' ? 'keyboard-backspaceButton' : ''}
            key={button}
          />
        ))}
        {/* show this empty block only for numeric keyboard */}
        {i === keys.length - 1 && this.props.layouts[this.state.currentLayout].symbolsKeyValue === '123' && (
          <KeyboardButton
            value=""
            classes="keyboard-symbolButton"
            onMouseDown={(val, e) => {
              e && e.preventDefault()
            }}
          />
        )}
      </div>
    ))
  }

  renderNumeric() {
    const keys = NumericLayout.layout
    const { leftButtons, rightButtons } = this.props
    return (
      <div className="keyboard numeric-keyboard">
        {keys.map((row, i) => (
          <div className="keyboard-row" key={`row-${i}`}>
            {row.map(button => (
              <KeyboardButton value={button} onMouseDown={this.handleLetterButtonClick} key={button} />
            ))}
            {i === keys.length - 1 && (
              <KeyboardButton
                value={<BackspaceIcon />}
                classes="keyboard-backspaceButton"
                onMouseDown={this.handleBackspaceClick}
              />
            )}
          </div>
        ))}
        <div className="keyboard-row">
          {leftButtons}
          {rightButtons}
        </div>
      </div>
    )
  }

  renderAlphanumeric() {
    const { leftButtons, rightButtons, inputNode } = this.props
    return (
      <div className="keyboard" onMouseDown={e => e.preventDefault()}>
        {this.renderKeyRows()}
        <div className="keyboard-row">
          {leftButtons}
          {this.props.layouts.length > 1 ? (
            <KeyboardButton
              value={<LanguageIcon />}
              classes="keyboard-languageButton"
              onMouseDown={this.handleLanguageClick}
            />
          ) : null}
          {inputNode.dataset.type === 'email' ? (
            <KeyboardButton value="@" classes="keyboard-atButton" onMouseDown={this.handleLetterButtonClick} />
          ) : null}
          <KeyboardButton value={' '} classes="keyboard-spaceButton" onMouseDown={this.handleLetterButtonClick} />
          {inputNode.dataset.type === 'email' ? (
            <KeyboardButton value="." classes="keyboard-fullstopButton" onMouseDown={this.handleLetterButtonClick} />
          ) : null}
          {rightButtons}
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.inputNode) {
      return null
    }
    return this.props.isNumeric ? this.renderNumeric() : this.renderAlphanumeric()
  }
}
