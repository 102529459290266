import React from 'react'
import { MESSAGES } from '../../constants/messages'

const OrderDeadlineModal = ({ setShowModal, organizationName }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-content p-4">
          <h2 className="mb-0">{MESSAGES.UNAVAILABLE_SERVICE}</h2>
          <p>
            {MESSAGES.UNAVAILABLE_SERVICE_DUE_TO_DEADLINE} 
          </p>
          <p className="short-note pt-0">{organizationName}</p>
        </div>
        <div className="modal-text-btn flex-grow-0 pt-4">
          <button
            onClick={() => {
              setShowModal(false)
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrderDeadlineModal
