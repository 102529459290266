import React, { useEffect } from 'react'

import useSlideshow from '../../hooks/useSlideshow'
import CrossFadingBackground from './CrossFadingBackground'
import AdvertisementBackground from './AdvertisementBackground'

const Slideshow = ({ pause, ...props }) => {
  const {
    current, stop, start, placeholderIndex,
  } = useSlideshow()

  useEffect(() => {
    if (pause) {
      stop()
    } else {
      start()
    }
    // stop and start aren't changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pause])

  let SlideshowComponent = null
  switch (current.type) {
    case 'advertisementBackground':
      SlideshowComponent = AdvertisementBackground
      break
    case 'background':
    default:
      SlideshowComponent = CrossFadingBackground
  }

  return <SlideshowComponent {...props} {...current.props} placeholderIndex={placeholderIndex} />
}

export default Slideshow
