import React, { useEffect } from 'react'
import classNames from 'classnames'

import MessageContent from './MessageContent'
import MessageFooter from './MessageFooter'
import MessageHeader from './MessageHeader'
import { ANALYTICS, ANALYTICS_ENTITY_TYPES } from '../../constants/appConstants'
import { metrics } from '../../api'

const MessageItem = ({
  title = null,
  content = null,
  category = null,
  id = null,
  media = null,
  qrCode = null,
  onGotIt = null,
  recordAnalytics = false,
  externalLink = null,
  setIsLargerContent = null,
  setFullScreenMode,
}) => {
  useEffect(() => {
    recordAnalytics
      && metrics.track('impression', {
        extra: {
          source: 'Display Announcement',
          name: 'Display Announcement',
          announcement_name: title,
        },
        entityId: id,
        entityType: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
        referer: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
        isImpressionEvent: true,
        analytics: false,
      })
  }, [])

  useEffect(() => {
    if (content && content.length > 300 && setIsLargerContent) {
      setIsLargerContent(true)
    }
  }, [content, setIsLargerContent])

  const onOK = () => {
    onGotIt()
  }

  return (
    <div className={classNames('message-alert', { 'alert-msg': category === 'ALERT' })}>
      <MessageHeader title={title} media={media} category={category} setFullScreenMode={setFullScreenMode} />
      <MessageContent content={content} />
      <MessageFooter onOK={onOK} qrCode={qrCode} id={id} title={title} externalLink={externalLink} />

    </div>
  )
}

export default MessageItem
