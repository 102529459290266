import { connect } from 'react-redux'
import { set, get } from 'lodash'

export const connectWithPath = (paths, mapDispatchToProps) => {
  const mapStateToProps = state => {
    const output = {}
    Object.keys(paths).forEach(key => {
      const path = paths[key]
      set(output, key, get(state, path, {}))
    })
    return output
  }
  return connect(mapStateToProps, mapDispatchToProps)
}
