import { sortBy } from 'lodash'
import { useSelector } from 'react-redux'

function useServices(location) {
  const services = sortBy(Object.values(useSelector(state => state.services)), ['position', 'id'])
  return {
    services: location ? services.filter(({ smart }) => smart[location]) : services,
  }
}

export default useServices
