import {
  get, values, reduce, keys,
} from 'lodash'

export const transformFeedbackValues = (questions, feedbacks) => {
  const transformedValues = reduce(
    keys(feedbacks),
    (accum, currKey) => {
      const currentQuestion = questions[currKey]
      const rating = get(feedbacks, `${currKey}.rating`, false)

      if (rating) {
        const { topic } = currentQuestion
        const { question } = currentQuestion

        let extra
        const keywords = get(feedbacks, `${currKey}.keywords`, false)

        if (keywords) {
          const keywordQuestion = get(currentQuestion, 'keywords.question')
          extra = {
            question: keywordQuestion,
            keywords,
          }
        }

        return (accum = {
          ...accum,
          [currKey]: {
            topic,
            question,
            rating,
            extra,
          },
        })
      }
      return accum
    },
    {},
  )

  return values(transformedValues)
}
