import React from 'react'
import { Icon } from 'semantic-ui-react'
import '../../styles/PropertyInfo.scss'

const PropertyInfoListItem = ({ icon, title, subtitle }) => {
  const CategoryIcon = icon
  return (
    <div className="PropertyInfoListItem">
      <CategoryIcon size="110" />
      <div className="category-header">
        <div className="category-title">{title}</div>
        <div>{subtitle}</div>
      </div>
      <div className="category-arrow">
        <Icon name="chevron right" size="big" />
      </div>
    </div>
  )
}

export default PropertyInfoListItem
