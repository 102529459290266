import React from 'react'
import Icon from './Icon'

const SuppliesIcon = props => (
  <Icon width={139} height={109} viewBox="0 0 139 109" fill="none" {...props}>
    <path
      d="M48.609 57.206l-39.515.608a5.173 5.173 0 00.08 10.347h39.594a2.232 2.232 0 010 4.464H8.669a4.67 4.67 0 10.036 9.339l42.135-.325C57.017 81.593 62 76.572 62 70.394c0-7.363-6.03-13.3-13.391-13.187zM15.391 106.794l39.515-.608a5.174 5.174 0 00-.08-10.347H15.233a2.232 2.232 0 010-4.464h40.099a4.67 4.67 0 10-.036-9.339l-42.135.325C6.983 82.407 2 87.428 2 93.606c0 7.363 6.03 13.3 13.391 13.187z"
      stroke="#fff"
      strokeWidth={2.5}
    />
    <path d="M91 18v10M97.666 18v10M104.333 18v10M111 18v10" stroke="#C4C4C4" strokeWidth={1.5} />
    <path
      d="M115.647 2h-28.23A1.917 1.917 0 0085.5 3.917v7.833a1.75 1.75 0 01-1.75 1.75A1.75 1.75 0 0082 15.25V27a2 2 0 002 2h34a2 2 0 002-2V14.7a1.2 1.2 0 00-1.2-1.2 1.8 1.8 0 01-1.8-1.8V3.353c0-.747-.606-1.353-1.353-1.353z"
      stroke="#fff"
      strokeWidth={2.5}
    />
    <rect
      x={111.096}
      y={46.091}
      width={10.163}
      height={22.844}
      rx={4.5}
      transform="rotate(-36.146 111.096 46.09)"
      stroke="#fff"
      strokeWidth={3}
    />
    <path
      d="M70 55.813V104a3 3 0 003 3h61a3 3 0 003-3V56.294c0-3.789-1.046-7.505-3.022-10.738l-1.276-2.089a12.336 12.336 0 00-3.033-3.367 24.8 24.8 0 00-15.064-5.1H82.65c-1.02 0-1.96.55-2.46 1.437l-9.545 16.92A5 5 0 0070 55.812z"
      stroke="#fff"
      strokeWidth={2.5}
    />
  </Icon>
)
export default SuppliesIcon
