import React from 'react'

import useSlideshow from '../../hooks/useSlideshow'
import { SlideshowCheckoutMessage } from '../checkout'
import SlideshowInfoCard from './SlideshowInfoCard'
import AdvertisementContent from './AdvertisementContent'

const SlideshowContent = ({ interactive, setInteractive }) => {
  const { current, placeholderIndex, index } = useSlideshow()

  if (current.props.isPlaceholder) {
    return (
      <AdvertisementContent
        setInteractive={setInteractive}
        id={index}
        interactive={interactive}
        placeholderIndex={placeholderIndex}
      />
    )
  }

  return (
    <>
      <SlideshowCheckoutMessage setInteractive={setInteractive} />
      <SlideshowInfoCard
        masterpiece={current.props}
        id={index}
        interactive={interactive}
        setInteractive={setInteractive}
      />
    </>
  )
}

export default SlideshowContent
