import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import { fetchPropertyAndState } from '../actions/property'
import { updateSettings } from '../actions/settings'
import * as PersistentState from '../utils/persistent-state'

import '../styles/PasscodeScreen.scss'

const url = new URL(window.location.href)
const forcePIN = url.searchParams.get('pin')
const token = url.searchParams.get('token')

class DeviceLoadPreviewContainer extends Component {
  async componentDidMount() {
    const settings = {
      pin: forcePIN,
    }
    PersistentState.clearAll()
    PersistentState.setDeviceType('demo')
    localStorage.setItem('demoUpdated', true)
    localStorage.setItem('isPreviewMode', true)
    PersistentState.setToken(token)
    // PersistentState.setSetPasscode(true)
    PersistentState.setPasscode(111111)
    await this.props.dispatch(updateSettings(settings))
    await this.props.dispatch(fetchPropertyAndState(settings))
    this.props.history.push('/home')
  }

  render() {
    return (
      <div className="SettingsContainer Win10Theme DevicePairing">
        <Loader active inline="centered" />
      </div>
    )
  }
}

const mapStateToProps = () => ({})

export default withRouter(connect(mapStateToProps)(DeviceLoadPreviewContainer))
