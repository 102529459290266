import React from 'react'
import Icon from './Icon'

const PhoneIcon = props => (
  <Icon width={19} height={34} viewBox="0 0 80 126" fill="none" {...props}>
    <path
      d="M67.4419 0.398926H12.0873C5.53864 0.398926 0.225586 5.67233 0.225586 12.1721V114.206C0.225586 120.706 5.53864 125.979 12.0873 125.979H67.4419C73.9905 125.979 79.3036 120.706 79.3036 114.206V12.1721C79.3036 5.67233 73.9905 0.398926 67.4419 0.398926ZM39.7646 118.131C35.3906 118.131 31.8568 114.623 31.8568 110.282C31.8568 105.941 35.3906 102.433 39.7646 102.433C44.1386 102.433 47.6724 105.941 47.6724 110.282C47.6724 114.623 44.1386 118.131 39.7646 118.131ZM67.4419 91.641C67.4419 93.2599 66.1074 94.5843 64.4764 94.5843H15.0527C13.4217 94.5843 12.0873 93.2599 12.0873 91.641V15.1154C12.0873 13.4966 13.4217 12.1721 15.0527 12.1721H64.4764C66.1074 12.1721 67.4419 13.4966 67.4419 15.1154V91.641Z"
      fill="#40A4B2"
    />
    {/* <rect x={1} y={1} width={17} height={32} rx={3} stroke="currentColor" strokeWidth={2} />
    <rect x={7} y={27} width={5} height={2} rx={1} fill="currentColor" /> */}
  </Icon>
)

{ /* <svg width="80" height="126" viewBox="0 0 80 126" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M67.4419 0.398926H12.0873C5.53864 0.398926 0.225586 5.67233 0.225586 12.1721V114.206C0.225586 120.706 5.53864 125.979 12.0873 125.979H67.4419C73.9905 125.979 79.3036 120.706 79.3036 114.206V12.1721C79.3036 5.67233 73.9905 0.398926 67.4419 0.398926ZM39.7646 118.131C35.3906 118.131 31.8568 114.623 31.8568 110.282C31.8568 105.941 35.3906 102.433 39.7646 102.433C44.1386 102.433 47.6724 105.941 47.6724 110.282C47.6724 114.623 44.1386 118.131 39.7646 118.131ZM67.4419 91.641C67.4419 93.2599 66.1074 94.5843 64.4764 94.5843H15.0527C13.4217 94.5843 12.0873 93.2599 12.0873 91.641V15.1154C12.0873 13.4966 13.4217 12.1721 15.0527 12.1721H64.4764C66.1074 12.1721 67.4419 13.4966 67.4419 15.1154V91.641Z" fill="#40A4B2"/>
</svg> */ }
export default PhoneIcon
