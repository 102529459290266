const address = (value, mapping = {}) => {
  const updatedMapping = {
    addressLine1: null,
    addressLine2: null,
    addressCity: null,
    addressState: null,
    addressZIP: null,
    ...mapping,
  }
  const get = v => value[updatedMapping[v] || v]
  const empty = v => get(v) && get(v).length && get(v) !== 'null'
  const output = []
  output.push(
    ['addressLine1', 'addressLine2']
      .filter(empty)
      .map(get)
      .join(', '),
  )
  output.push(
    `${['addressCity', 'addressState']
      .filter(empty)
      .map(get)
      .join(', ')
      .trim()} ${['addressZIP']
      .filter(empty)
      .map(get)
      .join(' ')
      .trim()}`,
  )
  return output
}

export default address
