import React from 'react'
import { formatPrice } from '../../utils/formatters'
import { TrackLink } from '../metrics'
import '../../styles/ServiceListItem.scss'
import MediaDisplay from '../MediaDisplay'
import { Mixpanel } from '../../api/mixpanel'
import { getLateCheckoutAvailability, getMinMaxPrices } from '../../utils/services'
import { MESSAGES } from '../../constants/messages'

const ServiceListItem = props => {
  const {
    name,
    summarySubtitle,
    id,
    feeType,
    customFeeMessage,
    serviceMedias,
    nextReservation,
    currentReservation,
    template,
    serviceChoices = null,
    extra = {},
  } = props
  const { price, priceType } = formatPrice(props)
  const item = serviceMedias[0]

  /** If there is any reservation after 10 hours of the checkout,
   *  the system will disable the late checkout service and a service message will display.
   *  The guest will not be able to avail the late checkout in this case. */
  const isLateCheckoutAvailable = getLateCheckoutAvailability(
    currentReservation,
    nextReservation,
    extra && extra.disableOnBtbRes,
  )

  const { minPrice, maxPrice } = getMinMaxPrices(serviceChoices || []) || {}

  const onServiceDetails = () => {
    Mixpanel.track('Click Service Detail', {
      name: 'Service Detail',
      source: 'Service List',
    })
  }

  return (
    <div className="list-block">
      <TrackLink
        onClick={onServiceDetails}
        to={`/services/${id}`}
        name={name}
        className={`ServiceListItem ${template === 'late_checkout' && !isLateCheckoutAvailable ? 'disabled' : ''}`}
        source="ServiceListItem"
      >
        <MediaDisplay className="service-image" item={item} preview />
        <div className="summary">
          <div className="summary-content">
            <div className="service-name">{name}</div>

            <div className="service-price price-has-type">
              {/** Show a price range in case of bundle prices */}
              {template === 'bundle' && (
                <div className="service-price-figure">
                  {minPrice}
                  {maxPrice && ` to ${maxPrice}`}
                </div>
              )}

              {template !== 'bundle' && (
                <>
                  <div className="service-price-figure">{feeType === 'custom' ? customFeeMessage : price}</div>
                  <div className="service-price-type">{priceType}</div>
                </>
              )}
            </div>

            <div className="service-summary">{summarySubtitle}</div>
          </div>
        </div>
      </TrackLink>
      {/* {template === 'late_checkout' && true && <p className="unavailable-msg">Service unavailable due to next reservation </p>} */}
      {template === 'late_checkout' && !isLateCheckoutAvailable && (
        <p className="unavailable-msg">
          {' '}
          {MESSAGES.UNAVAILABLE_SERVICE_DUE_TO_NEXT_RESERVATION}
          {' '}
        </p>
      )}
    </div>
  )
}

export default ServiceListItem
