import React from 'react'
import { useHistory } from 'react-router'
import { ANALYTICS, ANALYTICS_ENTITY_TYPES } from '../../constants/appConstants'
import { MESSAGES } from '../../constants/messages'

import { PhoneIcon } from '../icons'
import QR from '../QRCode'

const MessageFooter = ({
  onOK, qrCode, id, title, externalLink,
}) => {
  const history = useHistory()

  const sendLinkToPhone = () => {
    history.push('/contact/phone/announcement', {
      messageId: id,
      messageTitle: title,
      referer: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
      externalLink,
    })
  }
  return (
    <>
      {qrCode && (
        <div className="announcement-footer">
          <div className="qr-md phone" onClick={sendLinkToPhone}>
            <PhoneIcon />
            <label>{MESSAGES.VIEW_ON_YOUR_PHONE}</label>
          </div>

          <div className="qr-md scan">
            <QR url={externalLink} name={title} messageId={id} source={ANALYTICS.ANNOUNCEMENT} referer={ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS} />
            <label>{MESSAGES.SCAN_FOR_DETAILS}</label>
          </div>
        </div>
      )}

      <div
        className="message-footer-btn"
      >
        <span>
          <i>&nbsp;</i>
          <span onClick={() => onOK()}>{`${MESSAGES.GOT_IT}!`}</span>
        </span>
      </div>
    </>
  )
}

export default MessageFooter
