import React, { useState } from 'react'

const Quantity = ({ onChange, isChoiceSelected }) => {
  const [quantity, setQuantity] = useState(1)
  const increment = () => {
    const value = quantity + 1
    setQuantity(value)
    onChange(value)
  }

  const decrement = () => {
    if (quantity > 1) {
      const value = quantity - 1

      setQuantity(value)
      onChange(value)
    }
  }

  return (
    <div className={`num-counter ${!isChoiceSelected ? 'invisible' : ''}`}>
      <span onClick={decrement}>-</span>
      <span>{quantity}</span>
      <span onClick={increment}>+</span>
    </div>
  )
}

export default Quantity
