import React from 'react'

const PartlyCloudy = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18.2865 14.1277C17.9451 14.1277 17.6684 13.8509 17.6684 13.5096C17.6684 12.2515 18.6918 11.2275 19.9505 11.2275C20.2918 11.2275 20.5685 11.5043 20.5685 11.8456C20.5685 12.1869 20.2918 12.4637 19.9505 12.4637C19.3739 12.4637 18.9045 12.933 18.9045 13.5096C18.9045 13.8509 18.6278 14.1277 18.2865 14.1277Z" fill="white" />
    <path d="M28.003 14.8245C28.0611 14.5792 28.0913 14.3268 28.0913 14.0726C28.0913 12.2829 26.6354 10.8275 24.8463 10.8275C24.5145 10.8275 24.1868 10.8779 23.8739 10.9763C23.014 9.75555 21.5954 9 20.0766 9C17.5925 9 15.5587 10.9638 15.4449 13.4201C13.8094 13.8782 12.6159 15.3893 12.6159 17.1451C12.6159 19.2784 14.351 21.0135 16.4843 21.0135H26.6365C28.4262 21.0135 29.8816 19.5576 29.8816 17.7679C29.8816 16.4785 29.1201 15.3437 28.0025 14.8245H28.003ZM26.6371 19.778H16.4849C15.0331 19.778 13.8526 18.597 13.8526 17.1457C13.8526 15.7721 14.9246 14.6195 16.2935 14.5211L16.7522 14.488L16.6994 14.0311C16.684 13.8989 16.6763 13.7662 16.6763 13.6364C16.6763 11.7615 18.2016 10.2355 20.0772 10.2355C21.385 10.2355 22.5915 11 23.1521 12.1822L23.3643 12.629L23.7838 12.3671C24.102 12.168 24.47 12.0631 24.8463 12.0631C25.9545 12.0631 26.8558 12.9644 26.8558 14.0726C26.8558 14.4394 26.7562 14.7985 26.5672 15.1102L26.2164 15.6909L26.8896 15.7751C27.8916 15.9007 28.6472 16.757 28.6472 17.7679C28.6472 18.8761 27.7459 19.7774 26.6377 19.7774L26.6371 19.778Z" fill="white" />
    <path d="M23.6825 21.0141H22.815C22.8446 21.1599 22.8606 21.3104 22.8606 21.4645C22.8606 22.7095 21.8473 23.7229 20.6023 23.7229H9.19434C7.56294 23.7229 6.23614 22.3961 6.23614 20.7647C6.23614 19.221 7.44087 17.9256 8.97923 17.8154L9.49478 17.778L9.43552 17.2648C9.41834 17.1167 9.40945 16.9674 9.40945 16.8216C9.40945 14.7143 11.1238 13 13.2311 13C13.9149 13 14.5739 13.1861 15.1463 13.5185C15.2441 13.4823 15.3442 13.4498 15.4456 13.4213C15.4621 13.0575 15.522 12.7055 15.6186 12.3677C14.8956 11.9795 14.0779 11.7644 13.2311 11.7644C10.4838 11.7644 8.24146 13.9659 8.17509 16.6971C6.34636 17.1558 5 18.8216 5 20.7641C5 23.0769 6.88147 24.9578 9.19434 24.9578H20.6028C22.5294 24.9578 24.0967 23.3904 24.0967 21.4639C24.0967 21.3116 24.0861 21.1617 24.0671 21.0136H23.6825V21.0141Z" fill="white" />
  </svg>
)
export default PartlyCloudy
