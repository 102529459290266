import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { findLast, get } from 'lodash'

import { NumberParam, useQueryParam } from 'use-query-params'
import { withRouter } from 'react-router'
import {
  CustomService, RebookService, LateCheckoutService, MidstayCleanService,
} from '../components/services'
import { isBeforeOrderDeadline } from '../utils/services'
import { setOverlay, setHideFooter } from '../actions/device'
import { useReservation } from '../hooks/useReservation'

const SERVICE_TEMPLATES = {
  custom: {
    TemplateComponent: CustomService,
  },
  rebook: {
    TemplateComponent: RebookService,
  },
  late_checkout: {
    TemplateComponent: LateCheckoutService,
  },
  mid_stay_clean: {
    TemplateComponent: MidstayCleanService,
  },
  bundle: {
    TemplateComponent: CustomService,
  },
}

const ServiceDetailContainer = ({
  service,
  serviceRequest,
  canRequest,
  organizationName,
  isBeforeOrderDeadline,
  setOverlay,
  setHideFooter,
  host,
  checkoutTime,
  history,
}) => {
  const { isCheckOutDay, stayMode } = useReservation()

  useEffect(() => {
    setOverlay({ value: true })
    setHideFooter({ value: true })
    return () => {
      setOverlay({ value: false })
      setHideFooter({ value: false })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { TemplateComponent } = SERVICE_TEMPLATES[service.template] || {}
  const [advertisementId] = useQueryParam('advertisementId', NumberParam)

  const navigateToServices = () => {
    setOverlay({ value: false })
    setHideFooter({ value: false })
    history.push('/services')
  }
  return TemplateComponent ? (
    <TemplateComponent
      service={service}
      serviceRequest={serviceRequest}
      organizationName={organizationName}
      canRequest={canRequest}
      isBeforeOrderDeadline={isBeforeOrderDeadline}
      advertisementId={advertisementId}
      checkoutTime={checkoutTime}
      isCheckOutDay={isCheckOutDay}
      host={host}
      stayMode={stayMode}
      navigateToServices={navigateToServices}
    />
  ) : null
}

const mapStateToProps = ({ property }, ownProps) => {
  const { reservation, services, host } = property
  const reservationServices = property.reservationServices || []
  const serviceId = Number(ownProps.match.params.id, 10)
  const organizationName = property.organization.name
  const hasCheckedOut = get(reservation, 'id') === parseInt(localStorage.getItem('hasCheckedOut'), 10)
  const serviceRequest = findLast(
    reservationServices.filter(
      reservationService => reservationService.serviceId === serviceId
        && reservationService.propertyId === property.id
        && (reservation && !hasCheckedOut ? reservation.id === reservationService.reservationId : true),
    ),
  )

  const service = services.find(service => service.id === serviceId) || {}

  return {
    service,
    organizationName,
    serviceRequest,
    canRequest: hasCheckedOut ? false : !!reservation,
    isBeforeOrderDeadline: isBeforeOrderDeadline(reservation || {}, service.orderDeadline),
    host,
    checkoutTime: (property.customizations && property.customizations.checkInOut) ? property.customizations.checkInOut.checkoutTime : undefined,
  }
}

export default connect(mapStateToProps, { setOverlay, setHideFooter })(withRouter(ServiceDetailContainer))
