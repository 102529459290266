import React, { Component } from 'react'

import Keyboard from './screen-keyboard/Keyboard'
import NumericLayout from './screen-keyboard/layouts/NumericLayout'
import LatinLayout from './screen-keyboard/layouts/LatinLayout'

import './screen-keyboard/Keyboard.scss'
import '../styles/OnscreenKeyboard.scss'

const fallbackInputNode = document.createElement('input')

export class SlideWithKeyboard extends Component {
  render() {
    return <div className="slide-with-keyboard">{this.props.children}</div>
  }
}

export default class OnscreenKeyboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputNode: null,
    }
  }

  componentDidMount() {
    document.addEventListener('focus', this.onRecomputeFocusedInput, true)
    document.addEventListener('blur', this.onRecomputeFocusedInput, true)
  }

  componentWillUnmount() {
    clearTimeout(this._stillFocusedTimeout)
    document.removeEventListener('focus', this.onRecomputeFocusedInput, true)
    document.removeEventListener('blur', this.onRecomputeFocusedInput, true)
  }

  setInputNode = node => {
    if (this.state.inputNode === node) {
      return
    }
    if (node) {
      document.body.classList.add('keyboard-present')
    } else {
      document.body.classList.remove('keyboard-present')
    }
    this.setState({ inputNode: node })
  }

  onRecomputeFocusedInput = () => {
    window.requestAnimationFrame(() => {
      const active = document.activeElement
      this.setInputNode(active && (active.nodeName === 'INPUT' || active.nodeName === 'TEXTAREA') && active.type !== 'checkbox' ? active : null)
    })
  }

  render() {
    const { inputNode } = this.state

    const layout = ['tel', 'number'].includes(inputNode && inputNode.getAttribute('type')) ? NumericLayout : LatinLayout

    return (
      <div className={`OnscreenKeyboard Layout${layout.symbolsKeyValue} ${inputNode && 'open'}`} tabIndex={-1}>
        <Keyboard
          layouts={[layout]}
          inputNode={inputNode || fallbackInputNode}
          closeKeyboard={() => this.setState({ inputNode: null })}
        />
      </div>
    )
  }
}
