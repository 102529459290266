import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { CurrentWeather } from '../components/Weather'
import { ContactIcon, HomeIcon } from '../components/icons'
import { TrackLink } from '../components/metrics'
import '../styles/FooterContainer.scss'
import { MESSAGES } from '../constants/messages'

class CurrentTime extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      now: Date.now(),
    }
  }

  componentDidMount() {
    this._interval = setInterval(() => {
      this.setState({
        now: Date.now(),
      })
    }, 10)
  }

  componentWillUnmount() {
    clearInterval(this._interval)
  }

  render() {
    // If the browser reports we're in UTC, force-switch to PDT. Note that
    // because the timezone offset of "pacific time" will change in Nov.,
    // this might be an hour off again.
    let tzCorrection = 0
    if (new Date().getTimezoneOffset() === 0) {
      tzCorrection = -7 * 60 // pacific daylight time
    }
    const d = new Date(this.state.now + tzCorrection * 1000 * 60)
    const ampm = d.getHours() >= 12 ? 'pm' : 'am'
    return (
      <div data-cy="minutes">
        {`${d.getHours() % 12 || 12}:${d.getMinutes() < 10 ? '0' : ''}${d.getMinutes()}${ampm}`}
      </div>
    )
  }
}

const CardFooter = () => {
  const weatherAndTime = (
    <div className="right">
      <CurrentWeather />
      <CurrentTime />
    </div>
  )

  const contactUs = (
    <div>
      <TrackLink
        className={classNames('contact-us', 'fade')}
        source="FooterContainer"
        name="Contact Us"
        to="/contact"
        data-cy="btn-contact"
      >
        <div className="contact-icon button outline round x-small">
          <ContactIcon />
        </div>
        <div>{MESSAGES.CONTACT_HOST}</div>
      </TrackLink>
    </div>
  )

  return (
    <div className="CardFooter">
      {contactUs}
      <TrackLink
        as={NavLink}
        source="FooterContainer"
        name="Home Button"
        to="/home"
        data-cy="btn-"
        className="HomeButton"
        analytics={false}
      >
        <div className="button round outline small" data-cy="btn-home">
          <HomeIcon />
        </div>
      </TrackLink>
      {weatherAndTime}
    </div>
  )
}

export default CardFooter
