import React from 'react'

const TropicalStormIcon = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M24.5103 17.2483H17.7133C16.5456 17.2483 15.4475 16.7928 14.6216 15.9654C13.9423 15.285 13.5686 14.3805 13.5686 13.4185C13.5686 12.4565 13.9428 11.5515 14.6216 10.8715C15.7815 9.70951 17.6687 9.70951 18.8286 10.8715C19.2964 11.3402 19.5544 11.9637 19.5544 12.6265C19.5544 13.2892 19.2964 13.9127 18.8286 14.3814C18.4357 14.775 17.9134 14.9917 17.3577 14.9917C16.8021 14.9917 16.2798 14.775 15.8869 14.3814C15.5637 14.0577 15.3803 13.6268 15.371 13.1689C15.3656 12.8977 15.5809 12.6731 15.8511 12.6677C16.1194 12.6613 16.346 12.8775 16.3514 13.1487C16.3553 13.3516 16.4367 13.5428 16.5799 13.6862C16.7878 13.8945 17.064 14.009 17.3577 14.009C17.6515 14.009 17.9276 13.8945 18.1356 13.6862C18.4186 13.4027 18.574 13.0264 18.574 12.6255C18.574 12.2246 18.4181 11.8482 18.1356 11.5647C17.3582 10.786 16.0929 10.786 15.3155 11.5647C14.8217 12.0595 14.5495 12.7173 14.5495 13.4175C14.5495 14.1176 14.8217 14.775 15.3155 15.2702C15.9561 15.9119 16.808 16.2651 17.7138 16.2651H24.5108C24.7815 16.2651 25.0012 16.4853 25.0012 16.7565C25.0012 17.0277 24.7815 17.2478 24.5108 17.2478L24.5103 17.2483Z" fill="white" />
    <path d="M13.1551 25.3191C12.3935 25.3191 11.6313 25.0287 11.0516 24.4475C9.64946 23.0428 9.64946 20.7577 11.0516 19.3531C11.8775 18.5257 12.9751 18.0702 14.1434 18.0702H24.5103C24.781 18.0702 25.0007 18.2903 25.0007 18.5616C25.0007 18.8328 24.781 19.0529 24.5103 19.0529H14.1434C13.2375 19.0529 12.3856 19.4061 11.7451 20.0478C10.7255 21.0692 10.7255 22.7314 11.7451 23.7528C12.5225 24.5315 13.7878 24.5315 14.5651 23.7528C14.8481 23.4693 15.0036 23.093 15.0036 22.692C15.0036 22.2911 14.8476 21.9148 14.5651 21.6313C14.1365 21.2019 13.4386 21.2019 13.0099 21.6313C12.8662 21.7748 12.7853 21.9659 12.7814 22.1688C12.776 22.44 12.5533 22.6552 12.2812 22.6498C12.0104 22.6444 11.7956 22.4199 11.801 22.1487C11.8103 21.6907 11.9933 21.2604 12.317 20.9361C13.1281 20.1234 14.4479 20.1234 15.2591 20.9361C15.727 21.4048 15.985 22.0283 15.985 22.6911C15.985 23.3539 15.7275 23.9773 15.2591 24.446C14.6794 25.0268 13.9173 25.3176 13.1556 25.3176L13.1551 25.3191Z" fill="white" />
  </svg>

)
export default TropicalStormIcon
