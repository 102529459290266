import React from 'react'
import Icon from './Icon'

const RecommendedBadgeIcon = props => (
  <Icon viewBox="-4 4 59 70" fill="none" {...props}>
    <path
      d="M20.028 68.528v-14.5L23.5 56l6.528-1.972L36.5 56l3.028-1.472v14l-9.5-6.028-10 6.028z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M52.814 22.745l-1.268 6.314 1.493 6.266-4.256 4.834-1.839 6.172-6.102 2.057-4.678 4.427-6.315-1.27-6.264 1.494-4.835-4.255-6.172-1.84-2.058-6.102-4.425-4.678 1.268-6.314-1.493-6.265 4.255-4.834 1.84-6.172 6.101-2.059 4.68-4.425 6.314 1.268 6.264-1.493 4.834 4.255 6.172 1.84 2.06 6.102 4.424 4.678z"
      fill="currentColor"
    />
    <path d="M16 29.889L22.3 37 43 21" stroke="#000" strokeWidth={2.5} strokeLinecap="round" />
  </Icon>
)
export default RecommendedBadgeIcon
