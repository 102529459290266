import React from 'react'
import classNames from 'classnames'

import { TrackLink } from '../metrics'
import '../../styles/DetailFooter.scss'

const RequestButton = ({
  to, onClick, disabled, template, ...props
}) => {
  const className = classNames('button full-width', {
    disabled,
  })
  return to ? (
    <TrackLink
      className={className}
      action="request"
      to={to}
      template={template}
      onClick={onClick}
      source="RequestButton"
      {...props}
    />
  ) : (
    <div className={className} onClick={onClick} {...props} />
  )
}

export default RequestButton
