import React from 'react'
import { isUndefined } from 'lodash'
import classNames from 'classnames'
import { TrackLink } from '../metrics'
import '../../styles/InfoCard.scss'
import defaultBackground from '../../images/default-property-info-background.jpg'
import playOverlayIcon from '../../images/play-overlay.png'

const InfoCardWrapper = ({ to, children, ...rest }) => (!isUndefined(to) ? (
  <TrackLink to={to} {...rest}>
    {children}
  </TrackLink>
) : (
  <div {...rest}>{children}</div>
))

const getThumbnail = url => {
  if (url.match(/\.(jpeg|jpg|gif|png)$/) != null) return url
  return null
}

const InfoCard = ({
  background, mediaType, meta, name, to, iconSize, className,
}) => {
  const isIcon = background && background.includes('standard-icons')
  const isVideo = mediaType && mediaType.toLowerCase() === 'video'
  const videoThumbnail = isVideo && getThumbnail(background)
  const iconBackground = defaultBackground

  return (
    <InfoCardWrapper
      to={to}
      name={name}
      className={classNames('InfoCard', className, {
        isVideo: isVideo && !videoThumbnail,
      })}
      style={{
        backgroundImage: `url(${isIcon ? iconBackground : background})`,
      }}
      source="HomeContainer" // TODO: Pass in source
    >
      {/* TODO: Bit confusing having src={background} for the icon. Refactor with carousels  */}
      {isIcon && (
        <div className={classNames('icon', iconSize)}>
          <img src={background} alt="icon" />
        </div>
      )}
      {isVideo && (
        <>
          {background && <video className="play-overlay" src={background} />}
          <div
            className="play-overlay"
            style={{
              backgroundImage: `url(${playOverlayIcon})`,
            }}
          />
        </>
      )}
      {meta && <div className="meta">{meta}</div>}
    </InfoCardWrapper>
  )
}

export default InfoCard

InfoCard.defaultProps = {
  iconSize: 'm',
}
