import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import '../styles/BackgroundFooter.scss'
import withCodeRefresh from './withCodeRefresh'
import {
  RefreshIcon, CloseIcon, SleepIcon, SettingsIcon,
} from './icons'
import { Mixpanel } from '../api/mixpanel'
import { MESSAGES } from '../constants/messages'

const RefreshButton = withCodeRefresh(({ refresh, ...props }) => (
  <RefreshIcon
    onClick={() => {
      // Mixpanel.track('Click Refresh', {
      //   name: 'Refresh',
      //   source: 'Home',
      // })
      refresh()
    }}
    {...props}
  />
))

const BackgroundFooter = ({ onClose, className }) => {
  const onSleepMode = () => {
    // Mixpanel.track('Click Sleep Mode', {
    //   name: 'Sleep Mode',
    //   source: 'Home',
    // })
  }

  const onSettings = () => {
    // Mixpanel.track('Click Settings', {
    //   name: 'Settings',
    //   source: 'Home',
    // })
  }

  const onCloseContainer = () => {
    // Mixpanel.track('Click Cross Button', {
    //   name: 'Cross Button',
    //   source: 'Home',
    // })
  }
  return (
    <div className={classNames('BackgroundFooter', className)}>
      <div>
        <RefreshButton className="clickable-if-interactive" />
      </div>
      <div>
        <div onClick={onClose} className="close-button background-button large clickable-if-interactive">
          <CloseIcon onClick={onCloseContainer} data-cy="link-close-home" />
        </div>
      </div>
      <div>
        <Link
          onClick={onSleepMode}
          to="/entersleepmode"
          className="sleep-button background-button clickable-if-interactive"
        >
          <SleepIcon />
          <div>{MESSAGES.SLEEP}</div>
        </Link>
        <Link
          onClick={onSettings}
          to="/settings"
          className="settings-button background-button clickable-if-interactive"
        >
          <SettingsIcon />
        </Link>
      </div>
    </div>
  )
}

export default BackgroundFooter
