import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import moment from 'moment'
import { connectWithPath } from '../../resources/utils'
import { HostQuote } from '..'
import { CheckoutTime } from '.'
import {
  SuitcaseIcon, ChecklistIcon, SuitcaseTickIcon,
} from '../icons'
import PromoteService from '../services/PromoteService'
import { ImpressionsForBifurcated } from '../checkInOut'

import { makeAPIRequest } from '../../utils/helpers'

import '../../styles/CheckoutOption.scss'
import { useCustomization } from '../../hooks/useCustomization'
import { setOverlay } from '../../actions/device'
import { metrics } from '../../api'
import { MESSAGES } from '../../constants/messages'
import { getDeviceType } from '../../utils/persistent-state'

const PreCheckedOutMessage = () => (
  <>
    <span>{MESSAGES.THANK_YOU}</span>
    <br />
    <span>{MESSAGES.FOR_STAYING_WITH_US}</span>
  </>
)

const steps = [
  {
    question: 'Did You find this digital display helpful?',
  },
  {
    question: 'Which of these word best describe your rating?',
    keywords: {
      question: 'Which of these words best describe your stay?',
      answers: {
        positive: ['Comfortable', 'Enjoyable', 'Great value', 'Welcoming', 'Would return'],
        negative: ['Uncomfortable', 'Too expensive', 'Poor service', 'Messy', 'Would not return'],
      },
    },
  },
]

const CheckedOutMessage = () => <span>{MESSAGES.YOU_ARE_ALL_SET}</span>

const CheckoutOption = ({
  history, property, className, setOverlay,
}) => {
  const [step, setStep] = useState(0)
  const [values, setValues] = useState({})
  const [error, setError] = useState(false)
  const [step3Rating, setStep3Rating] = useState(false)
  const [, setIsSubmitting] = useState()
  const quote = useCustomization('checkoutDetails.checkoutMessage') || 'Thank you for staying with us'
  const removeCheckoutMessageFromCheckout = useCustomization('checkoutDetails.removeFromCheckOut')
  const reservationId = get(property, 'reservation.id', 0)
  const hasCheckedOut = reservationId === parseInt(localStorage.getItem('hasCheckedOut'), 10)
  const [showThanksFeedback, setThanksFeedbackVisibility] = useState(false)
  useEffect(() => {
    setOverlay({
      value: true,
    })
    const step3Rating = JSON.parse(localStorage.getItem('Step3-rating'))
    setStep3Rating(step3Rating)
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])
  const next = () => {
    if (!error) {
      // TODO: Improve with API what happens when skipping
      if (step === steps.length - 1) {
        return finishAction(values)
          .then(continueAction())
          .catch(setError(true))
      }
      setStep(step + 1)
    } else {
      setStep(step + 1)
      setError(false)
    }
  }
  const back = () => {
    if (step !== 0) {
      setStep(step - 1)
      setValues({})
    }
  }

  const finishAction = () => {
    const reservationId = get(property, 'reservation.id', null)
    localStorage.setItem('hasCheckedOut', reservationId)
    localStorage.setItem('checkedoutTime', moment().format())
    setStep3Rating(false)
    setThanksFeedbackVisibility(true)
    localStorage.setItem('Step3-rating', false)
    const feedbacks = [
      {
        topic: 'device',
        question: 'Was the GuestView Guide Display helpful?',
        rating: values[0].rating,
        extra: {
          question: 'Which of these word best describe your rating?',
          keywords: values[1] && values[1].keywords,
        },
      },
    ]
    return makeAPIRequest('reservation/checkout', {
      method: 'POST',
      data: {
        reservationId,
        feedbacks,
        demoMode: getDeviceType() === 'demo',
      },
    })
  }

  const continueAction = () => {
    history.push('/home')
  }

  return (
    <div className={classNames('CheckoutOption', className)}>
      <div className="inner-card-section title-container">
        <div className="title">{!hasCheckedOut ? <PreCheckedOutMessage /> : <CheckedOutMessage />}</div>
        <div className="checkout-time-above">
          {`${MESSAGES.CHECKOUT_TIME}:`}
          {' '}
          <CheckoutTime />
        </div>
      </div>
      <div className="inner-card-section">
        {!removeCheckoutMessageFromCheckout && <HostQuote quote={quote} showName dark />}
      </div>
      <div className="inner-card-section checkout-time-cta">
        {hasCheckedOut ? (
          <div className="checkout-closure">
            <div className="has-icon-and-close">
              <div className="checked-out-icon">
                <SuitcaseTickIcon />
              </div>
              <div
                className="close-button"
                onClick={() => {
                  localStorage.setItem('noGuestMode', true)
                  history.push('/home')
                }}
              >
                {/* <span className="button round x-small close">
                  <CloseIcon data-cy="link-close-home" />
                </span> */}
                <span className="button-text">
                  <b>{MESSAGES.CLOSE}</b>
                </span>
              </div>
            </div>
            {showThanksFeedback && <div className="thanks-feedback-message">{MESSAGES.THANKS_FOR_FEEDBACK}</div>}
          </div>
        ) : (
          <div className="checkout-buttons">
            <div className="checkout-button checkout-instructions" onClick={() => history.push('/checkout')}>
              <ChecklistIcon />
              <div className="button-text">{MESSAGES.CHECKOUT_INSTRUCTIONS}</div>
            </div>
            <div
              className="checkout-button checkout-now"
              onClick={() => {
                metrics.track('click', {
                  extra: {
                    source: 'CheckoutPromotion',
                    name: 'Checkout Instructions',
                    extraInfo: {
                      pathname: '/checkout',
                    },
                  },
                })
                history.push('/checkout', {
                  checkoutNow: true,
                })
              }}
            >
              <div className="button round">
                <SuitcaseIcon />
              </div>
              <div className="button-text">{MESSAGES.CHECKOUT_NOW}</div>
            </div>
          </div>
        )}
      </div>
      {step3Rating && hasCheckedOut ? (
        <div>
          {' '}
          <div className="CheckInOutWizard">
            <AnimatePresence exitBeforeEnter>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <ImpressionsForBifurcated
                  stepKey={step}
                  question={steps[step]}
                  values={values}
                  setValues={setValues}
                  next={next}
                  setIsSubmitting={setIsSubmitting}
                  isFace={step === 0}
                  finishAction={finishAction}
                  back={back}
                  exit={{
                    opacity: 0,
                    transition: {
                      delay: 3,
                      duration: 5,
                    },
                  }}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      ) : (
        <PromoteService situation="checkout" />
      )}
    </div>
  )
}

export default withRouter(
  connectWithPath(
    {
      property: 'property',
    },
    {
      setOverlay,
    },
  )(CheckoutOption),
)
