import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'
import { motion } from 'framer-motion'
import { withErrorBoundary } from 'react-error-boundary'
import { DisplayWifi, HomeNavigation } from '../components'
import { HomeContent } from '../components/home'
import { useReservation } from '../hooks/useReservation'
import { CheckoutOption } from '../components/checkout'
import '../styles/Card.scss'
import '../styles/HomeContainer.scss'
import { setOverlay } from '../actions/device'
import { refreshMessages } from '../actions/property'
import { useCustomization } from '../hooks/useCustomization'
import ErrorComponent from '../ErrorComponent'
import { logAppErrors } from '../utils/helpers'

const HomeContainer = ({ setOverlay, location, refreshMessages }) => {
  const { reservation, organization } = useSelector(state => state.property)
  const { isCheckOutDay, checkoutTime } = useReservation()
  const wifi = useCustomization('wifi')
  const reservationId = get(reservation, 'id', 0)
  const hasCheckedOut = reservationId === parseInt(localStorage.getItem('hasCheckedOut'), 10)
  let displayCheckout = true
  const noGuestMode = localStorage.getItem('noGuestMode')
  const justCheckedIn = get(location, 'state.justCheckedIn')

  useEffect(() => {
    setOverlay({
      value: true,
    })
    // refreshMessages();
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])

  if (isCheckOutDay) {
    const checkedoutTime = localStorage.getItem('checkedoutTime')
    if (hasCheckedOut) {
      const timeDiff = moment().diff(checkedoutTime, 'minute')
      if (timeDiff >= 15) {
        localStorage.setItem('noGuestMode', true)
        displayCheckout = false
      }
    }
    const overTime = moment().diff(moment(checkoutTime, 'HH:mm'), 'minute')
    if (overTime >= 60) {
      localStorage.setItem('noGuestMode', true)
      localStorage.setItem('hasCheckedOut', reservationId)
      displayCheckout = false
    }
  }

  const initial = {
    opacity: justCheckedIn ? 0 : 1,
  }
  const animate = {
    opacity: 1,
  }
  const exit = {
    opacity: 0,
  }

  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={{
        duration: 0.6,
      }}
      className="Card full-height HomeContainer"
    >
      <div className="card-section">{wifi && <DisplayWifi />}</div>
      {isCheckOutDay && displayCheckout && !noGuestMode ? (
        <div className="card-section white stretch no-padding-x margin-x margin-y">
          <CheckoutOption className="home" />
        </div>
      ) : (
        <HomeContent organization={organization} wifi={wifi} />
      )}
      <div className="card-section no-padding-x margin-x bottom border-top">
        <HomeNavigation />
      </div>
    </motion.div>
  )
}

const ComponentWithErrorBoundary = withErrorBoundary(HomeContainer, {
  FallbackComponent: props => ErrorComponent({
    ...props,
    component: 'Home Container',
  }),
  onError(error, info) {
    logAppErrors(error, info)
    console.log({
      error,
      info,
    })
  },
})

export default connect(null, {
  setOverlay,
  refreshMessages,
})(ComponentWithErrorBoundary)
