import { useState, useEffect } from 'react'
import moment from 'moment'

export function useCurrentMoment() {
  const [date, setDate] = useState(Date.now())

  useEffect(() => {
    const timerID = setInterval(() => setDate(new Date()), 60000)
    return function cleanup() {
      clearInterval(timerID)
    }
  })

  return moment(date)
}
