import {
  FETCH_PROPERTY_MESSAGES_REQUEST,
  FETCH_PROPERTY_MESSAGES_SUCCESS, FETCH_PROPERTY_MESSAGES_FAILURE, CREATE_MESSAGES_HISTORY_REQUEST, CREATE_MESSAGES_HISTORY_SUCCESS, CREATE_MESSAGES_HISTORY_FAILURE, MARK_MESSAGE_AS_READ, FETCH_MESSAGE_HISTORY_REQUEST, FETCH_MESSAGE_HISTORY_SUCCESS, FETCH_MESSAGE_HISTORY_FAILURE, REFRESH_MESSAGES_IN_STATE,
} from '../actions/actionTypes'
import { getUnreadMessages } from '../utils/messages'

const initialState = {
  messages: [],
  loading: false,
  error: '',
  messagesHistory: [],
}

export function messages(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROPERTY_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_PROPERTY_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.messages,
        loading: false,
        error: '',
      }

    case REFRESH_MESSAGES_IN_STATE:
      return {
        ...state,
        messages: JSON.parse(localStorage.getItem('messages')),
        loading: false,
        error: '',
      }

    case FETCH_PROPERTY_MESSAGES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case CREATE_MESSAGES_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_MESSAGES_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case CREATE_MESSAGES_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case MARK_MESSAGE_AS_READ:
      // const state = { ...state.property.config, messageRead: true }
      // Object.assign(state.config || { ...state.config, messageRead: true })
      // const state = { ...state.config, messageRead: true }
      // const localStoredMessages = JSON.parse(localStorage.getItem('messages'))
      // const unreadMessages = localStoredMessages ? getUnreadMessages(localStoredMessages, action.property) : []

      return {
        // ...state,
        // messages: unreadMessages,
        messages: state.messages.map(message => {
          if (message.id === action.messageId) {
            return {
              ...message,
              propertyMessage: {
                ...message.propertyMessage,
                status: 'READ',
              },
            }
          }
          return message
        }).filter(m => m.propertyMessage.status === 'UNREAD'),
      }

    case FETCH_MESSAGE_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case FETCH_MESSAGE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        messagesHistory: action.history,
      }

    case FETCH_MESSAGE_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
