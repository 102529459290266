import React from 'react'
import Icon from './Icon'

const UnmuteIcon = props => (
  <Icon viewBox="0 0 42 42" fill="none" width={40} height={40} {...props}>
    <path
      d="M24.0156 0.734375C23.7031 0.578125 23.3646 0.5 23 0.5C22.375 0.5 21.8281 0.708333 21.3594 1.125L10.8125 10.5H4.25C3.20833 10.5521 2.32292 10.9167 1.59375 11.5938C0.916667 12.2708 0.552083 13.1302 0.5 14.1719V21.5938C0.552083 22.6875 0.916667 23.5729 1.59375 24.25C2.32292 24.9271 3.20833 25.2656 4.25 25.2656H10.8125L21.3594 34.6406C21.8281 35.2656 22.375 35.5521 23 35.5C23.3646 35.5 23.7031 35.4219 24.0156 35.2656C24.9531 34.7969 25.4479 34.0417 25.5 33V3C25.4479 1.90625 24.9531 1.15104 24.0156 0.734375ZM22.9219 32.9219L11.6719 22.9219H4.25C3.46875 22.8698 3.05208 22.4531 3 21.6719V14.1719C3.05208 13.4427 3.46875 13.026 4.25 12.9219H11.6719L22.8438 3L22.9219 32.9219ZM36.3594 5.73438C35.6823 5.36979 35.1094 5.47396 34.6406 6.04688C34.276 6.72396 34.4062 7.29688 35.0312 7.76562C36.75 8.96354 38.0781 10.4479 39.0156 12.2188C40.0052 13.9896 40.5 15.9167 40.5 18C40.5 20.0833 40.0052 22.0104 39.0156 23.7812C38.0781 25.5521 36.75 27.0365 35.0312 28.2344C34.4062 28.7031 34.276 29.276 34.6406 29.9531C34.901 30.3177 35.2656 30.5 35.7344 30.5C35.9427 30.5 36.151 30.4219 36.3594 30.2656C38.4427 28.8594 40.0833 27.0885 41.2812 24.9531C42.4271 22.8177 43 20.5 43 18C43 15.5 42.4271 13.1823 41.2812 11.0469C40.0833 8.91146 38.4427 7.14062 36.3594 5.73438ZM32.2188 11.9844C31.5417 11.6198 30.9688 11.724 30.5 12.2969C30.1354 12.974 30.2656 13.5469 30.8906 14.0156C32.2448 15.0052 32.9479 16.3333 33 18C32.9479 19.6667 32.2448 20.9948 30.8906 21.9844C30.2656 22.4531 30.1354 23.026 30.5 23.7031C30.7604 24.0677 31.125 24.25 31.5938 24.25C31.8021 24.25 32.0104 24.1719 32.2188 24.0156C33.2604 23.3385 34.0677 22.4531 34.6406 21.3594C35.2135 20.3177 35.5 19.1979 35.5 18C35.5 16.8021 35.2135 15.6823 34.6406 14.6406C34.0677 13.5469 33.2604 12.6615 32.2188 11.9844Z"
      fill="white"
    />
  </Icon>
)

export default UnmuteIcon
