import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { metrics } from '../../api'
import { PropertyIcon, RestaurantsIcon, MapIcon } from '../icons'
import { useCountdownTimer } from '../../hooks/useCountdownTimer'
import { MESSAGES } from '../../constants/messages'
import { useCustomization } from '../../hooks/useCustomization'

const BUTTON_ICONS = [PropertyIcon, RestaurantsIcon, MapIcon]

const CheckInButton = () => {
  const history = useHistory()
  const welcome = useCustomization('welcome')
  const {
    1: { checkinButtonText },
  } = Object.entries(welcome).find(([key, value]) => (key === 'custom' && value.status) || key === 'default')
  const { reservation } = useSelector(state => state.property)
  const [currentIcon, setCurrentIcon] = useState(0)

  const seconds = useCountdownTimer(2.5)

  useEffect(() => {
    if (seconds <= 0) {
      setCurrentIcon(currentIcon >= BUTTON_ICONS.length - 1 ? 0 : currentIcon + 1)
    }
  }, [seconds, currentIcon])

  const Icon = BUTTON_ICONS[currentIcon]

  const onCheckInBtn = () => {
    const startTime = new Date(reservation.start)
    const checkinTime = new Date()
    const difference = (checkinTime.getTime() - startTime.getTime()) / (1000 * 3600)
    metrics.track('click', {
      extra: {
        name: 'Check In',
        source: 'Checkin Button',
      },
      timeDifference: difference,
      analytics: true,
    })
    localStorage.setItem('hasViewedOnboarding', reservation.id)
    history.push('/onboarding/impressions')
  }
  return (
    <div className="checkin-cta">
      <div className="checkin-button">
        <div className="button round large animated infinite pulse" onClick={onCheckInBtn} />
        <div className="icon-wrapper">
          <Icon />
        </div>
      </div>
      <span className="button-text">{checkinButtonText}</span>
    </div>
  )
}

export default CheckInButton
