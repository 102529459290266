import { useContext, createContext } from 'react'

const SlideshowContext = createContext()

export const useSlideshow = () => {
  const context = useContext(SlideshowContext)
  return context
}

export default SlideshowContext
