import React from 'react'
import modalClose from '../../images/icons/modal-close.svg'

const TermsConditionModal = ({
  onAccept, onCancel, terms, acceptsTerms, closeModal,
}) => (
  <div className="modal-overlay">
    <div className="modal-container terms-conditions-modal">
      <button className="terms-close" onClick={onCancel}>
        <img src={modalClose} alt="cross-icon" />
      </button>
      <div className="modal-content">
        <h1>Terms and Conditions</h1>
        {/* <p className="date-created">
          General Site Usage
          <span>Last Revised: December 16, 2013</span>
        </p> */}
        <div
          className="terms-content"
          dangerouslySetInnerHTML={{
            __html: terms,
          }}
        />
      </div>
      <div className="terms-check">
        {/* <input type="checkbox" id="terms-condition" onChange={onAccept} /> */}
        <button type="button" onClick={acceptsTerms ? closeModal : onAccept}>
          I agree to all the terms and conditions
        </button>
      </div>
    </div>
  </div>
)

export default TermsConditionModal
