import React, { useState } from 'react'
import messageGraphic from '../../images/tail-pointer.png'
import alertGraphic from '../../images/alert-banner.png'
import alertIcon from '../../images/icons/alert.svg'
import CarouselPreview from '../common/CarouselPreview'
import { MESSAGES } from '../../constants/messages'

// const mediacopy = [
// //   {
// //     mediaType: "IMAGE",
// //     mediaUrl: "https://nec-assets.s3.amazonaws.com/default-images/img_service_generic-1280x960.jpg",
// //     id: 1,
// //     mediaOrder: 1,
// //     mediaDuration: 1,
// //   },
//   {
//     mediaType: 'IMAGE',
//     mediaUrl: 'https://nec-assets.s3.amazonaws.com/default-images/img_service_latecheckout-1280x960.jpg',
//     id: 3,
//     mediaOrder: 3,
//     mediaDuration: 1,
//   },
//   {
//     mediaType: 'IMAGE',
//     mediaUrl: 'https://nec-assets.s3.amazonaws.com/uploaded-media/68b1d7aa-e11e-40eb-9eab-5d9f35d105aa_picture.jpg',
//     id: 2,
//     mediaOrder: 2,
//     mediaDuration: 1,
//   },
//   {
//     mediaType: 'VIDEO',
//     mediaUrl:
//       'https://nec-assets.s3.amazonaws.com/uploaded-video-converted/e76e1726-93e6-416c-b7d4-953c1a78f64e_file_example_MOV_480_700kB1opt.mp4',
//     id: 1094,
//     mediaOrder: 4,
//     mediaDuration: 30,
//   },
// ]

const MessageHeader = ({
  title, media = [], category, setFullScreenMode = null,
}) => {
  const isAlertMessage = category === 'ALERT'
  const hasMedia = media.length > 0
  const showAlertIcon = !!(isAlertMessage && hasMedia)
  const showAlertImage = !!(isAlertMessage && !hasMedia)
  const [fullScreen, setfullScreen] = useState(false)

  const openVideoInFullMode = isFullScreen => {
    setFullScreenMode && setFullScreenMode(isFullScreen)
    setfullScreen(isFullScreen)
  }

  return (
    <>
      <div className="message-top">{MESSAGES.MESSAGE_FROM_YOUR_HOST}</div>
      {hasMedia ? (
        <div className={`carousel-container ${fullScreen ? 'has-fullscreen-video' : ''} `}>
          <CarouselPreview medias={media} setFullScreen={openVideoInFullMode} slide={false} />
        </div>
      ) // <img className="message-banner" src="https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg" alt="" />
        : null}

      {!hasMedia && !isAlertMessage && <img className="message-graphic" src={messageGraphic} alt="" />}
      {showAlertImage && <img className="alert-banner" src={alertGraphic} alt="" />}
      {showAlertIcon && <img className="alert-icon" src={alertIcon} alt="" />}
      <div className={isAlertMessage ? 'alert-title' : 'message-title'}>{title}</div>
    </>
  )
}

export default MessageHeader;
