import React from 'react'
import Icon from './Icon'

const SuitcaseIcon = props => (
  <Icon viewBox="0 0 79 65" fill="none" {...props} width="1em" height="1em">
    <path
      d="M28.5 17.5V9.26A7.26 7.26 0 0 1 35.76 2h8.48a7.26 7.26 0 0 1 7.26 7.26v8.24m-23 0h23m-23 0H20m31.5 0H59m-39 0H8.85A6.85 6.85 0 0 0 2 24.35v33.595A5.555 5.555 0 0 0 7.555 63.5H20m0-46v46m0 0h39m0-46h11.593A6.407 6.407 0 0 1 77 23.907v33.186a6.407 6.407 0 0 1-6.407 6.407H59m0-46v46"
      stroke="#000"
      strokeWidth={5}
    />
  </Icon>
)

export default SuitcaseIcon
