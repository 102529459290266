import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { withErrorBoundary } from 'react-error-boundary'
import { connect } from 'react-redux'
import { isArray } from 'lodash'
import { BackIcon } from '../components/icons'
import { PageHeader } from '../components/pages'
import { useCustomization } from '../hooks/useCustomization'
import { InfoCard } from '../components/information'
import { getFirst, logAppErrors } from '../utils/helpers'
import { LIST_OPTIONS } from './PropertyInfoContainer'
import '../styles/PropertyInfoContainer.scss'
import { setOverlay } from '../actions/device'
import ErrorComponent from '../ErrorComponent'

const getBackground = infoItem => {
  const image = getFirst(infoItem.content, ['imageURL', ['steps', 0, 'imageURL'], 'iconURL'])
  return image || getFirst(infoItem, ['imageURL'])
}

const getMediaType = item => {
  if (item.url) return 'video'
  return item.mediaType || (item.content && item.content.mediaType)
}

const PropertyInfoItemsContainer = ({ setOverlay }) => {
  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])

  const { section } = useParams()
  const { title, subtitle } = LIST_OPTIONS.find(({ key }) => key === section)

  let items = useCustomization(section)
  if (!isArray(items)) items = [items]

  return (
    <div className="Card full-height PropertyInfoListCard">
      <div className="card-section header border-bottom">
        <PageHeader title={title}>
          <strong>{subtitle}</strong>
        </PageHeader>
      </div>
      <div className="info-items card-section stretch scroll">
        {items.map(item => {
          const backgroundImage = getBackground(item)
          return (
            <InfoCard
              key={item.id}
              id={item.id}
              to={`/property-info/${section}/${item.id}`}
              name={item.name}
              background={backgroundImage}
              url={item.url}
              mediaType={getMediaType(item)}
              meta={<div>{decodeURIComponent(encodeURIComponent(item.name || item.title))}</div>}
            />
          )
        })}
      </div>
      <div className="card-section footer">
        <Link className="link with-icon" to="/property-info">
          <BackIcon />
          {' '}
          Property Info
        </Link>
      </div>
    </div>
  )
}

const ComponentWithErrorBoundary = withErrorBoundary(PropertyInfoItemsContainer, {
  FallbackComponent: props => ErrorComponent({
    ...props,
    component: 'Property Info Items Container',
  }),
  onError(error, info) {
    logAppErrors(error, info)
    console.log({
      error,
      info,
    })
  },
})

export default connect(null, {
  setOverlay,
})(ComponentWithErrorBoundary)
