import React from 'react'
import Icon from './Icon'

const HouseRulesIcon = props => (
  <Icon width={78} height={108} viewBox="0 0 78 108" fill="none" {...props}>
    <path
      d="M18 19H7a5 5 0 00-5 5v77.5a5 5 0 005 5h64a5 5 0 005-5V24a5 5 0 00-5-5H60m-42 0v-6a2 2 0 012-2h11v-1a8 8 0 018-8v0a8 8 0 018 8v1h11a2 2 0 012 2v6m-42 0v8a2 2 0 002 2h38a2 2 0 002-2v-8m-.5 24.5h-33M26.5 57h33M26.5 70h33M26.5 83h33"
      stroke="#fff"
      strokeWidth={2.5}
    />
    <circle cx={22.5} cy={44} r={3} fill="#fff" />
    <circle cx={22.5} cy={57} r={3} fill="#fff" />
    <circle cx={22.5} cy={70} r={3} fill="#fff" />
    <circle cx={22.5} cy={83} r={3} fill="#fff" />
  </Icon>
)
export default HouseRulesIcon
