import { useMachine } from '@xstate/react'
import { findLast, get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Mixpanel } from '../../api/mixpanel'
import serviceMachine, { canTransitionTo } from '../../machines/service'
import { formatPrice, parseNumber } from '../../utils/formatters'
import { isApprovalPending, isBeforeOrderDeadline, setSeriveChoiceFormValues } from '../../utils/services'
import { DetailFooter, DetailStatus } from '../pages'
import OrderDeadlineModal from './OrderDeadlineModal'
import RequestButton from './RequestButton'
import RequestSummaryModal from './RequestSummaryModal'
import ServiceTerms from './ServiceTerms'
import { RadioGroupInput } from './RadioGroupInput'
import Quantity from './Quantity'
import { setOverlay, setHideFooter } from '../../actions/device'
import { MESSAGES } from '../../constants/messages'

const ServiceChoices = ({
  history,
  service,
  serviceRequest,
  isBeforeOrderDeadline,
  canRequest,
  organizationName,
  advertisementId,
  host,
  checkoutTime,
  isCheckOutDay,
  stayMode,
  setOverlay,
  setHideFooter,
}) => {
  const {
    id,
    name,
    allowQuantity,
    requireAcceptTerms,
    terms,
    paymentProviderId,
    serviceChoices = [],
    serviceNote,
    allowMultiSelection,
    phone,
    template,
  } = service
  const context = {
    hasTerms: (terms || '').length > 0,
    needsTerms: requireAcceptTerms,
    hasQuantity: false,
    quantity: 1,
    hasPayment: !!paymentProviderId,
    acceptTerms: false,
  }

  const backLink = `/services/${service.id}`
  const [current, send] = useMachine(serviceMachine, { context })
  const { acceptTerms, hasPayment } = current.context
  const [isAlreadyRequested, setIsAlreadyRequested] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [formState, setFormState] = useState([])
  const [selectedChoiceId, setSelectedChoiceId] = useState(null)
  const connectWithHostNumber = phone || host ? host.contactNumber : null
  const { serviceChoice } = service

  /** If multi select is not enable then filter the selected choice option from form values  */
  const selectedChoices = allowMultiSelection
    ? formState.filter(choice => !!choice.isChecked)
    : formState.filter(choice => choice.id === selectedChoiceId)

  const { price, priceType } = formatPrice({ currency: 'US', ...service }) 
  const trackServiceRequest = () => {
    Mixpanel.track('Click Service Request', {
      name: 'Custom Service Request',
      source: 'Service Detail Container',
      serviceName: service.name,
      servcieID: service.id,
    })
  }

  const onRequest = () => {
    let feeRates;
    serviceChoices.forEach((choice) => {
      feeRates = choice.feeRates.map((rate) => ({ feeType: rate.feeType, price: rate.price }));
    })
    Mixpanel.track('Bundle Service Request', {
      name: 'Custom Service Request',
      source: 'Service Detail Container',
      serviceName: service.name,
      servicePrice: price,
      servicePriceType: priceType,
      serviceID: service.id,
    })
    /**
     * If the same service is requested again before the approval/decline of the service from the PM side,
     * The Guest should get a message => This service has already been requested, Please call [Host number] for assistance
     */

    if (canRequest) {
      if (isBeforeOrderDeadline) {
        trackServiceRequest()
        send('REQUEST')
      }

      if (!isBeforeOrderDeadline) setShowModal(true)

      return
    }

    if (!canRequest) {
      send('REQUEST')
      trackServiceRequest()
    }
  }

  const onRequestConfirmation = () => {
    const selectedChoices = allowMultiSelection
      ? formState.filter(choice => !!choice.isChecked)
      : formState.filter(choice => choice.id === selectedChoiceId)

    const quantity = selectedChoices.reduce((prev, current) => {
      return (prev += current.quantity)
    }, 0)

    const state = {
      quantity,
      acceptTerms,
      title: MESSAGES.MOBILE_NUMBER_REQUEST,
      onSuccess: `/services/${id}/phone/success`,
      name,
      section: 'services',
      meta: MESSAGES.MOBILE_NUMBER_TERMS_OF_USE,
      advertisementId,
      selectedChoices,
      template,
    }
    if (hasPayment) {
      state.title = MESSAGES.MOBILE_PAYMENT_PROMPT
    }
    history.push(`/services/${id}/phone`, state)
  }

  const closeSummaryModal = () => send('BACK')

  const setFormValues = (choiceId, key, value) => {
    setSeriveChoiceFormValues({
      serviceChoices,
      choiceId,
      key,
      value,
      formState,
      setFormState,
      allowMultiSelection,
    })
  }

  const connectWithHost = () => {
    const state = {
      serviceName: name,
      customerServiceNumber: connectWithHostNumber,
      serviceId: id,
    }
    history.push(`/services/${id}/connectWithHost`, state)
  }

  useEffect(() => {
    setOverlay({ value: true })
    setHideFooter({ value: true })
    return () => {
      setOverlay({ value: false })
      setHideFooter({ value: false })
    }
  }, [setOverlay, setHideFooter])


  useEffect(() => {
    if (!isAlreadyRequested && isApprovalPending(serviceRequest, checkoutTime, isCheckOutDay, stayMode)) {
      setIsAlreadyRequested(true)
    }
  }, [serviceRequest])
  
  return (
    <div className="Card max-height ServiceDetailContainer">
      {showModal && <OrderDeadlineModal organizationName={organizationName} setShowModal={setShowModal} />}

      {current.matches('phone') && (
      <RequestSummaryModal
        serviceNote={serviceNote}
        onConfirm={onRequestConfirmation}
        onBack={closeSummaryModal}
        selectedChoices={selectedChoices}
      />
      )}
      <div
        className={`p-4 service-choices-wrapper ${
          current.matches({ extra: 'terms' }) || current.matches('terms') ? 'terms-visible' : ''
        }`}
      >
        <div className="device-header-body">
          <div className="choice-title-head">
            <h3 className="main-title">Service Choices</h3>
            {selectedChoices?.length ? (
              <span>{`${String(selectedChoices?.length).padStart(2, '0')} Selected`}</span>
            ) : null}
          </div>
          {/* <p>Last service request: 12 hours before checkout</p> */}
          <p className="weight-500 mb-3 p-head">Please select from the following options:</p>
          <div className="choices-list-container">
            {serviceChoices.map(choice => {
              const isChoiceSelected = selectedChoices.find(sc => sc.id === choice.id)
              return (
                <RenderChoice serviceChoices={serviceChoices}>
                  <ChoiceContent
                    choice={choice}
                    allowMultiSelection={allowMultiSelection}
                    onChange={value => {
                      setFormValues(choice.id, 'choiceChecked', value)
                    }}
                    selectedChoiceId={selectedChoiceId}
                    setSelectedChoiceId={setSelectedChoiceId}
                  />
                  <ChoiceFooter choice={choice} onChange={value => setFormValues(choice.id, 'feeRates', value)}>
                    {allowQuantity && (
                    <Quantity
                      onChange={value => setFormValues(choice.id, 'quantity', value)}
                      isChoiceSelected={isChoiceSelected}
                    />
                    )}
                  </ChoiceFooter>
                </RenderChoice>
              )
            })}
          </div>
        </div>
      </div>

      {!current.matches('service') && (current.matches({ extra: 'terms' }) || current.matches('terms')) && (
      <ServiceTerms
        terms={terms}
        acceptsTerms={acceptTerms}
        onClick={requireAcceptTerms ? () => send('ACCEPT') : null}
      />
      )}
      <DetailFooter>
        {!isAlreadyRequested && (
        <DetailStatus
          status="SERVICE_INFO_REQUEST"
          sentence="Connect with the host about this service"
          onClick={connectWithHost}
        />
        )}
        {isAlreadyRequested && (
        <DetailStatus
          status="CONNECT_HOST"
          showIcon={false}
          sentence={`This service has already been requested, Please call ${
            connectWithHostNumber ? parseNumber(connectWithHostNumber) : 'host'
          } for assistance.`}
          customInnerStyle={{ width: '55%' }}
        />
        )}

        <RequestButton
          onClick={onRequest}
          disabled={!canTransitionTo(current, 'REQUEST') || isAlreadyRequested || !selectedChoices?.length}
        >
          {current.matches('service') ? 'Request Service' : 'Next'}
        </RequestButton>
        <Link to={backLink} className="link d-flex justify-content-center align-items-center">
          <Link to={backLink} className="link ml-2">
            Back
          </Link>
        </Link>
      </DetailFooter>
    </div>
  )
}

const mapStateToProps = ({ property }, ownProps) => {
  const { reservation, services, host } = property
  const reservationServices = property.reservationServices || []
  const serviceId = Number(ownProps.match.params.id, 10)
  const organizationName = property.organization.name
  const hasCheckedOut = get(reservation, 'id') === parseInt(localStorage.getItem('hasCheckedOut'), 10)
  const serviceRequest = findLast(
    reservationServices.filter(
      reservationService => reservationService.serviceId === serviceId
        && reservationService.propertyId === property.id
        && (reservation ? reservation.id === reservationService.reservationId : true),
    ),
  )

  const service = services.find(service => service.id === serviceId) || {}
  const {
    advertisementId, checkoutTime, isCheckOutDay, stayMode,
  } = ownProps?.location?.state
  return {
    service,
    organizationName,
    serviceRequest,
    canRequest: hasCheckedOut ? false : !!reservation,
    isBeforeOrderDeadline: isBeforeOrderDeadline(reservation || {}, service.orderDeadline),
    advertisementId,
    host,
    checkoutTime,
    isCheckOutDay,
    stayMode,
  }
}

export default withRouter(connect(mapStateToProps, { setOverlay, setHideFooter })(ServiceChoices))

const RenderChoice = ({ children }) => {
  return <div className="choices-list">{children}</div>
}

const ChoiceContent = ({
  choice, onChange, allowMultiSelection, selectedChoiceId, setSelectedChoiceId,
}) => (
  <div className="choice-content-wrapper">
    <div className="choice-content">
      <img src={choice.serviceChoiceMedias[0]?.mediaUrl} alt='service-img' />
      <div>
        <h4>{choice.title}</h4>
        {choice.detailHTML && (
          <div className="service-details" dangerouslySetInnerHTML={{ __html: choice.detailHTML }} />
        )}
      </div>
    </div>

    {/** Treat choices as radio buttons in case of non-multi select */}
    {!allowMultiSelection && (
      <div className="list-selector">
        <input
          type="checkbox"
          name="selectedChoice"
          value={choice.id}
          id={choice.id}
          onChange={({ target }) => {
            const { checked } = target
            onChange(checked)
            setSelectedChoiceId(choice.id)
          }}
          checked={selectedChoiceId === choice.id}
        />
        <label htmlFor={choice.id} />
      </div>
    )}

    {/** Treat choices as check boxes in case of non-multi select */}
    {allowMultiSelection && (
      <div className="list-selector">
        <input
          type="checkbox"
          id={choice.id}
          onChange={({ target }) => {
            const { checked } = target
            onChange(checked)
          }}
        />
        <label htmlFor={choice.id} />
      </div>
    )}
  </div>
)

const ChoiceFooter = ({ choice, children, onChange }) => (
  <div className="choice-list-actions">
    <div className="radio-options">
      <RadioGroupInput
        options={choice.feeRates.map((feeRate) => {
          let priceString

          // Free case
          if (feeRate.feeType === 'free') priceString = ''

          // Fixed/Hourly/Daily case
          if (feeRate.feeType !== 'custom' && feeRate.feeType !== 'free') {
            const { price } = formatPrice({
              price: Number(feeRate.price),
              currency: 'US',
              feeType: feeRate.feeType,
            })
            priceString = price || ''
          }

          // Custom case
          if (feeRate?.feeType === 'custom') priceString = feeRate.customFeeMessage

          return {
            value: feeRate.feeType,
            label: `${feeRate.feeType !== 'custom' ? feeRate.feeType : ''}${!['free', 'custom'].includes(feeRate.feeType) ? ':' : ''
            } ${priceString}`,
          }
        })}
        name="feeType"
        onChange={onChange}
        defaultValue={choice?.feeRates[0]?.feeType}
        identifier={`${choice.id}`}
      />
    </div>
    {children}
  </div>
)
