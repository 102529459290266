import React from 'react'
import { get } from 'lodash'
import { InfoCard } from '.'
import yelpImageForRating from '../pages/YelpRating'
import { RecommendedBadgeIcon } from '../icons'
import { Carousel, SectionTitle } from '../ui'
import { useRestaurants } from '../../hooks/useRestaurants'
import { sortRecommendationList } from '../../utils/helpers'
import '../../styles/RestaurantMeta.scss'

const RestaurantMeta = ({ restaurant: { name, content } }) => {
  const rating = get(content, 'providerData.rating')
  const category = get(content, 'category')
  const isRecommended = get(content, 'isRecommended')
  return (
    <div className="RestaurantMeta">
      <div className="name">{name}</div>
      {isRecommended && (
        <div className="badge">
          <RecommendedBadgeIcon height={50} width={36} />
        </div>
      )}
      <div className="rating">
        {rating && yelpImageForRating(rating)}
        {' '}
        <span>{category}</span>
      </div>
    </div>
  )
}

const LocalRestaurants = ({ yelpRecommendations, resetIdleTimer }) => {
  let { restaurants } = useRestaurants(12)
  const recommendedRestaurants = restaurants.filter(restaurant => restaurant.content.isRecommended)
  let title = 'Our recommendations'
  if (!yelpRecommendations) {
    if (recommendedRestaurants.length < 3) {
      title = 'Local restaurants'
      restaurants = restaurants.slice(0, 3)
    } else {
      restaurants = restaurants.slice(0, recommendedRestaurants.length)
    }
  }
  const sortedRestaurants = sortRecommendationList(restaurants);
  return (
    <>
      <SectionTitle title={title} more="/recommendations/restaurants" analytics={false} />
      <Carousel columns={3}>
        {sortedRestaurants
          && sortedRestaurants.map(restaurant => {
            const imageURL = get(restaurant, 'content.imageURL')
            return (
              <InfoCard
                key={restaurant.id}
                id={restaurant.id}
                to={`/recommendations/restaurants/${restaurant.id}`}
                background={imageURL}
                name={restaurant.name}
                meta={<RestaurantMeta restaurant={restaurant} />}
              />
            )
          })}
      </Carousel>
    </>
  )
}

export default LocalRestaurants
