let cacheClearFlag = false

const refreshCacheAndReload = async () => {
  if (window.caches) {
    // Service worker cache should be cleared with caches.delete()
    await window.caches.keys().then(names => {
      names.forEach(cache => {
        window.caches.delete(cache)
      })
    })
  }
  // delete browser cache and hard reload
  window.location.reload(true)
}

export function clearCache() {
  fetch(`${process.env.REACT_APP_PING_URL}/build/display-build`)
    .then(response => response.json())
    .then(meta => {
      const latestVersionNumber = meta.buildNumber
      const currVersionNumber = localStorage.getItem('buildNumber')
      const shouldClearCache = currVersionNumber !== latestVersionNumber
      if (shouldClearCache && cacheClearFlag) {
        refreshCacheAndReload()
        localStorage.setItem('buildNumber', latestVersionNumber)
        cacheClearFlag = false
      } else if (shouldClearCache) {
        cacheClearFlag = true
      }
    })
}
