import React, { useState } from 'react'

const RadioInput = ({
  name, label, value, isChecked, handleChange, identifier,
}) => {
  const handleRadioChange = e => {
    const { value } = e.currentTarget
    handleChange(value)
  }

  return (
    <span>
      <input
        type="checkbox"
        className="custom-radio"
        name={name}
        id={identifier}
        checked={isChecked}
        value={value}
        onChange={handleRadioChange}
      />
      <label
        htmlFor={identifier}
        style={{
          textTransform: 'capitalize',
        }}
      >
        {label}
      </label>
    </span>
  )
}

export const RadioGroupInput = ({
  name, options, onChange, defaultValue, identifier,
}) => {
  const [selectedInput, setSelectedInput] = useState(defaultValue)

  const handleChange = inputValue => {
    setSelectedInput(inputValue)
    onChange(inputValue)
  }

  return (
    <>
      {options.map(option => {
        const { label, value } = option
        const uniqueIdentifier = value + identifier
        return (
          <>
            <RadioInput
              name={name}
              value={value}
              label={label}
              isChecked={selectedInput === value}
              handleChange={handleChange}
              identifier={uniqueIdentifier}
            />
            {/* <label for={value}></label> */}
          </>
        )
      })}
    </>
  )
}
