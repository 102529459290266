import React from 'react'
import classNames from 'classnames'
import { WifiIcon } from './icons'
import { useCustomization } from '../hooks/useCustomization'

import '../styles/DisplayWifi.scss'
import { MESSAGES } from '../constants/messages'

const WifiPassword = ({ name, value }) => {
  return (
    <>
      <div className="WifiCredential">
        <div className="label">{`${MESSAGES.WIFI}:`}</div>
        <div className="value">{name}</div>
      </div>
      <div className="WifiCredential">
        <div className="label">{`${MESSAGES.PASSWORD}:`}</div>
        <div className="value">{value}</div>
      </div>
    </>
  )
}

const WifiInstructions = ({ label, name, value }) => (
  <>
    <div className="title">
      <div className="label">{label}</div>
      <div className="value">{name}</div>
    </div>
    <div className="subtitle">{value}</div>
  </>
)
WifiInstructions.defaultProps = {
  label: 'Wifi Network: ',
}

const WifiConnectWithWebsite = props => <WifiInstructions label="Go to: " {...props} />

const DisplayWifi = ({ theme, ...props }) => {
  const wifi = useCustomization('wifi')
  if (!wifi) {
    return (
      <div className="Wifi WifiPassword dark">
        <div className="WifiIcon">
          <WifiIcon />
        </div>
      </div>
    )
  }
  const { wifiFormat, wifiName, wifiPass } = wifi

  let WifiComponent = null
  let className = null

  switch (wifiFormat) {
    case 'instruction':
      WifiComponent = WifiInstructions
      className = 'WifiInstructions'
      break

    case 'url':
      WifiComponent = WifiConnectWithWebsite
      className = 'WifiInstructions'
      break

    default:
      WifiComponent = WifiPassword
      className = 'WifiPassword'
      break
  }

  return (
    <div className={classNames('Wifi', theme, className)}>
      <div className="WifiIcon">
        <WifiIcon />
      </div>
      <div className="WifiComponent">
        <WifiComponent name={wifiName} value={wifiPass} {...props} />
      </div>
    </div>
  )
}

DisplayWifi.defaultProps = {
  theme: 'dark',
}

export default DisplayWifi
