import React from 'react'

import { updateBrightness, getBrightness } from '../../actions/device'
import { connectWithPath } from '../../resources/utils'
import SliderSection from './SliderSection'

const BrightnessSection = props => <SliderSection title="Brightness" wait={500} {...props} />

export default connectWithPath(
  {
    defaultValue: 'device.brightness',
  },
  {
    updateValue: updateBrightness,
    getValue: getBrightness,
  },
)(BrightnessSection)
