import React from 'react'
import Icon from './Icon'

const ChecklistIcon = props => (
  <Icon viewBox="0 0 67 80" fill="none" {...props} width="1em" height="1em">
    <path
      d="M57 2H10a8 8 0 0 0-8 8v59.6a8 8 0 0 0 8 8h47a8 8 0 0 0 8-8V10a8 8 0 0 0-8-8zM32.24 22.16H52.4"
      stroke="#000"
      strokeWidth={3}
      strokeLinecap="square"
    />
    <path
      d="M13.34 22.16l2.52 2.52 7.56-7.56M32.24 39.8H52.4M13.34 39.8l2.52 2.52 7.56-7.56M32.24 57.44H52.4M17.12 61.22a3.78 3.78 0 1 0 0-7.56 3.78 3.78 0 0 0 0 7.56z"
      stroke="#000"
      strokeWidth={3}
      strokeLinecap="square"
    />
  </Icon>
)

export default ChecklistIcon
