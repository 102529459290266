import React from 'react'

const DustIcon = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3_441)">
      <path d="M17.0384 15.1451H8.70082C8.31397 15.1451 8 15.4621 8 15.8526C8 16.2432 8.31397 16.5602 8.70082 16.5602H17.0384C17.4253 16.5602 17.7393 16.2432 17.7393 15.8526C17.7393 15.4621 17.4253 15.1451 17.0384 15.1451Z" fill="white" />
      <path d="M17.0384 11H8.70082C8.31397 11 8 11.317 8 11.7076C8 12.0982 8.31397 12.4152 8.70082 12.4152H17.0384C17.4253 12.4152 17.7393 12.0982 17.7393 11.7076C17.7393 11.317 17.4253 11 17.0384 11Z" fill="white" />
      <path d="M17.0384 23.4359H8.70082C8.31397 23.4359 8 23.7529 8 24.1435C8 24.5341 8.31397 24.8511 8.70082 24.8511H17.0384C17.4253 24.8511 17.7393 24.5341 17.7393 24.1435C17.7393 23.7529 17.4253 23.4359 17.0384 23.4359Z" fill="white" />
      <path d="M17.0384 19.2908H8.70082C8.31397 19.2908 8 19.6078 8 19.9984C8 20.389 8.31397 20.706 8.70082 20.706H17.0384C17.4253 20.706 17.7393 20.389 17.7393 19.9984C17.7393 19.6078 17.4253 19.2908 17.0384 19.2908Z" fill="white" />
      <path d="M28.2992 15.1451H19.9616C19.5747 15.1451 19.2607 15.4621 19.2607 15.8526C19.2607 16.2432 19.5747 16.5602 19.9616 16.5602H28.2992C28.686 16.5602 29 16.2432 29 15.8526C29 15.4621 28.686 15.1451 28.2992 15.1451Z" fill="white" />
      <path d="M19.9616 12.4152H28.2992C28.686 12.4152 29 12.0982 29 11.7076C29 11.317 28.686 11 28.2992 11H19.9616C19.5747 11 19.2607 11.317 19.2607 11.7076C19.2607 12.0982 19.5747 12.4152 19.9616 12.4152Z" fill="white" />
      <path d="M28.2992 23.4359H19.9616C19.5747 23.4359 19.2607 23.7529 19.2607 24.1435C19.2607 24.5341 19.5747 24.8511 19.9616 24.8511H28.2992C28.686 24.8511 29 24.5341 29 24.1435C29 23.7529 28.686 23.4359 28.2992 23.4359Z" fill="white" />
      <path d="M28.2992 19.2908H19.9616C19.5747 19.2908 19.2607 19.6078 19.2607 19.9984C19.2607 20.389 19.5747 20.706 19.9616 20.706H28.2992C28.686 20.706 29 20.389 29 19.9984C29 19.6078 28.686 19.2908 28.2992 19.2908Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3_441">
        <rect width="21" height="13.8511" fill="white" transform="translate(8 11)" />
      </clipPath>
    </defs>
  </svg>
)
export default DustIcon
