import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class KeyboardButton extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
    classes: PropTypes.string,
    onMouseDown: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
  }

  static defaultProps = {
    classes: '',
    autofocus: false,
    isDisabled: false,
  }

  componentWillUnmount() {
    clearInterval(this._repeatInterval)
  }

  handleClick = e => {
    if (e) {
      this._repeatInterval = setInterval(() => this.handleClick(), 350)
      e.preventDefault()
      e.stopPropagation()
    }
    this.props.onMouseDown(this.props.value, e)
  }

  handleClickUp = () => {
    clearInterval(this._repeatInterval)
  }

  render() {
    return (
      <button
        type="button"
        className={`keyboard-button ${this.props.classes}`}
        onMouseDown={this.props.isDisabled ? null : this.handleClick}
        onMouseUp={this.props.isDisabled ? null : this.handleClickUp}
        disabled={this.props.isDisabled}
      >
        {this.props.value}
      </button>
    )
  }
}
