import { Component } from 'react'
import { connect } from 'react-redux'

import { setOnline, setOffline } from '../resources/networkStatus'

class Offline extends Component {
  componentDidMount() {
    const { setOnline, setOffline } = this.props
    window.addEventListener('offline', setOffline)
    window.addEventListener('online', setOnline)
  }

  componentWillUnmount() {
    const { setOnline, setOffline } = this.props
    window.removeEventListener('offline', setOffline)
    window.removeEventListener('online', setOnline)
  }

  render() {
    const { children } = this.props
    return children
  }
}

export default connect(null, {
  setOnline,
  setOffline,
})(Offline)
