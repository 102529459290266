import React from 'react'
import Icon from './Icon'

const FilterIcon = ({ ...props }) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M15.895.553A1.001 1.001 0 0 0 15 0H1a1.001 1.001 0 0 0-.8 1.6L6 9.333V15a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V9.333L15.8 1.6c.227-.303.263-.708.095-1.047z"
    />
  </Icon>
)

export default FilterIcon
