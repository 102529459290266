import React from 'react'
import Icon from './Icon'

const PoliciesIcon = props => (
  <Icon width={74} height={99} viewBox="0 0 74 99" fill="none" {...props}>
    <path
      d="M58.5 97h5.796a6.528 6.528 0 004.616-1.912v0a12.25 12.25 0 003.588-8.662V2h-65v77m51 18H8.812a6.033 6.033 0 01-5.09-2.794v0A11.014 11.014 0 012 88.293V79h5.5m51 18v0a10.302 10.302 0 01-1.77-7.739L58.5 79h-51M27 25h33M27 37h33M27 49h33M27 61h33"
      stroke="#fff"
      strokeWidth={2.5}
    />
    <circle cx={23} cy={25} r={3} fill="#fff" />
    <circle cx={23} cy={37} r={3} fill="#fff" />
    <circle cx={23} cy={49} r={3} fill="#fff" />
    <circle cx={23} cy={61} r={3} fill="#fff" />
  </Icon>
)
export default PoliciesIcon
