import {
  FETCH_PROPERTY_WEATHER_SUCCESS,
  FETCH_PROPERTY_WEATHER_REQUEST,
  FETCH_PROPERTY_WEATHER_FAILURE,
} from '../actions/actionTypes'

const initialState = {
  weather: {},
  forecast: [],
  loading: false,
  error: '',
}

export function weather(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROPERTY_WEATHER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_PROPERTY_WEATHER_SUCCESS:
      const { forecast = [], weather = {} } = action.weatherData
      return {
        ...state,
        weather,
        forecast,
        loading: false,
        error: '',
      }

    case FETCH_PROPERTY_WEATHER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}
