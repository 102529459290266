import React from 'react'
import Icon from './Icon'

{ /* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 1C4.1344 1 1 4.1344 1 8C1 11.8656 4.1344 15 8 15C11.8656 15 15 11.8656 15 8C15 4.1344 11.8656 1 8 1Z" stroke="#3E92CC" stroke-width="1.21739" stroke-miterlimit="10"/>
<path d="M6.93457 6.93457H8.15196V11.3476" stroke="#3E92CC" stroke-width="1.21739" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.47852 11.5H9.82634" stroke="#3E92CC" stroke-width="1.21739" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M7.99987 3.51074C7.80424 3.51074 7.613 3.56875 7.45034 3.67744C7.28768 3.78613 7.1609 3.94061 7.08604 4.12135C7.01117 4.30209 6.99158 4.50097 7.02975 4.69284C7.06791 4.88471 7.16212 5.06096 7.30045 5.19929C7.43878 5.33763 7.61503 5.43183 7.8069 5.47C7.99878 5.50816 8.19766 5.48858 8.3784 5.41371C8.55914 5.33885 8.71362 5.21207 8.82231 5.0494C8.93099 4.88674 8.989 4.6955 8.989 4.49987C8.989 4.23754 8.88479 3.98595 8.69929 3.80045C8.5138 3.61495 8.26221 3.51074 7.99987 3.51074Z" fill="#3E92CC"/>
</svg> */ }

const InformationIcon = () => (
  <Icon width="66" height="71" viewBox="0 0 16 16" fill="none">
    <path
      d="M8 1C4.1344 1 1 4.1344 1 8C1 11.8656 4.1344 15 8 15C11.8656 15 15 11.8656 15 8C15 4.1344 11.8656 1 8 1Z"
      stroke="#3E92CC"
      strokeWidth="1.21739"
      strokeMiterlimit="10"
    />
    <path
      d="M6.93457 6.93457H8.15196V11.3476"
      stroke="#3E92CC"
      strokeWidth="1.21739"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.47852 11.5H9.82634"
      stroke="#3E92CC"
      strokeWidth="1.21739"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.99987 3.51074C7.80424 3.51074 7.613 3.56875 7.45034 3.67744C7.28768 3.78613 7.1609 3.94061 7.08604 4.12135C7.01117 4.30209 6.99158 4.50097 7.02975 4.69284C7.06791 4.88471 7.16212 5.06096 7.30045 5.19929C7.43878 5.33763 7.61503 5.43183 7.8069 5.47C7.99878 5.50816 8.19766 5.48858 8.3784 5.41371C8.55914 5.33885 8.71362 5.21207 8.82231 5.0494C8.93099 4.88674 8.989 4.6955 8.989 4.49987C8.989 4.23754 8.88479 3.98595 8.69929 3.80045C8.5138 3.61495 8.26221 3.51074 7.99987 3.51074Z"
      fill="#3E92CC"
    />
  </Icon>
)

export default InformationIcon
