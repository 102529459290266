import { PROPERTY_FETCH_SUCCESS } from '../actions/actionTypes'

const ONLINE = 'networkStatus/ONLINE'
const OFFLINE = 'networkStatus/OFFLINE'

export const setOnline = () => ({
  type: ONLINE,
})
export const setOffline = () => ({
  type: OFFLINE,
})

const initialStatus = {
  isOnline: navigator.onLine,
}

export default (state = initialStatus, action) => {
  switch (action.type) {
    case ONLINE:
    case PROPERTY_FETCH_SUCCESS:
      return {
        ...state,
        isOnline: true,
      }
    case OFFLINE:
      return {
        ...state,
        isOnline: false,
      }
    default:
      return state
  }
}
