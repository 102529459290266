import React, { Component } from 'react'
import { MESSAGES } from '../constants/messages'
import PhoneNumber from './PhoneNumber'

export default class PropertyInfoOverview extends Component {
  render() {
    const {
      addressCity, addressCountry, addressState, addressZIP, addressLine1, host,
    } = this.props.property

    let displayNumber = 'any time'
    let displayPhoneExt = ''
    const emergencyNumber = {
      us: '911',
      usa: '911',
      japan: '119',
      'united states': '911',
      'united states of america': '911',
      canada: '911',
    }[addressCountry.toLowerCase()]

    if (host) {
      displayNumber = host.contactNumber
      displayPhoneExt = host.phoneExt ? host.phoneExt : ''
    }

    return (
      <div className="PropertyInfoOverview">
        <div className="info-box">
          <div className="info-section">
            <div className="info-value" data-cy="property-info-address">
              {`${MESSAGES.ADDRESS}:`}
              {' '}
              {addressLine1}
              ,
              {' '}
              {addressCity}
              ,
              {' '}
              {addressState}
              {' '}
              {addressZIP}
            </div>
          </div>
          <div className="info-section">
            <div className="info-value">
              {`${MESSAGES.CUSTOMER_SERVICE}:`}
              <span data-cy="property-info-host-phone">
                <PhoneNumber>{displayNumber}</PhoneNumber>
              </span>
              {displayPhoneExt && <span>ext. </span>}
              <span data-cy="property-info-host-phone-ext">{displayPhoneExt}</span>
            </div>
          </div>
          <div className="info-section">
            <div className="info-value">
              {`${MESSAGES.EMERGENCIES}:`}
              {' '}
              {emergencyNumber ? `${emergencyNumber}` : 'N/A'}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
