import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import '../styles/Card.scss'
import { connect } from 'react-redux'
import { withErrorBoundary } from 'react-error-boundary'
import { AboutUsContent, AboutUsFooter, AboutUsHeader } from '../components/about'
import ErrorComponent from '../ErrorComponent'
import { setOverlay } from '../actions/device'
import { CloseIcon } from '../components/icons'
import { logAppErrors } from '../utils/helpers'

const AboutUSContainer = ({ history, setOverlay }) => {
  const sendLinkToPhone = () => {
    history.push('aboutus/phone')
  }

  const sendBack = () => {
    history.goBack()
  }

  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])

  return (
    <motion.div className="Card full-height is-about closeIcon-parent">
      <div onClick={sendBack} className='close-icon-aboutus'>
        <CloseIcon />
      </div>
      <AboutUsHeader />
      <AboutUsContent />
      <AboutUsFooter sendLinkToPhone={sendLinkToPhone} />
    </motion.div>
  )
}

const ComponentWithErrorBoundary = withErrorBoundary(AboutUSContainer, {
  FallbackComponent: props => ErrorComponent({
    ...props,
    component: 'About Us Container',
  }),
  onError(error, info) {
    logAppErrors(error, info)
    console.log({
      error,
      info,
    })
  },
})

export default connect(null, {
  setOverlay,
})(ComponentWithErrorBoundary)
