import React from 'react'
import { parsePhoneNumber } from 'libphonenumber-js'

const PhoneNumber = ({ children }) => {
  let phone = children
  if (!phone) {
    return null
  }
  try {
    const phoneNumber = parsePhoneNumber(children)
    phone = phoneNumber.formatNational()
  } catch (error) {
    console.warn(`Couldn't parse phone number ${children}`, error)
  }
  return <div>{phone}</div>
}

export default PhoneNumber
