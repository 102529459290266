import axios from 'axios'

import { getToken } from '../utils/persistent-state'

const kiosk = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

kiosk.interceptors.request.use(config => {
  const token = getToken()
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  }
  return config
})

export default kiosk
