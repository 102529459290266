import moment from 'moment'
import { isEqual } from 'lodash'
import {
  PROPERTY_FETCH_SUCCESS,
  MARK_MESSAGE_AS_READ,
  PROPERTY_HAS_ERRORED,
  UNPAIRED,
  FETCH_PROPERTY_WEATHER_SUCCESS,
  FETCH_PROPERTY_WEATHER_REQUEST,
  FETCH_PROPERTY_WEATHER_FAILURE,
  FETCH_PROPERTY_MESSAGES_REQUEST,
  FETCH_PROPERTY_MESSAGES_SUCCESS,
  FETCH_PROPERTY_MESSAGES_FAILURE,
  UPDATE_SETTINGS,
  FETCH_MESSAGE_HISTORY_REQUEST,
  FETCH_MESSAGE_HISTORY_SUCCESS,
  FETCH_MESSAGE_HISTORY_FAILURE,
  REFRESH_MESSAGES_IN_STATE,
  DEVICE_BLACKLISTED,
} from './actionTypes'

import {
  clearProperty,
  clearPasscode,
  clearToken,
  getSettings,
  setSettings,
  setPropertyConfirmed,
} from '../utils/persistent-state'
import { setOffline } from '../resources/networkStatus'
import { computeLocalStorageSize, getAppState, makeAPIRequest } from '../utils/helpers'
import { getUnreadMessages, sycnMessagesWithServer, updateLocalMessageStatus } from '../utils/messages'
import { MESSAGE_STATUS } from '../constants/appConstants'
import { isBeforeOrderDeadline } from '../utils/services'
import { saveLogsToLocalStorage } from '../logger'

export function makeServiceRequest(reservationId, serviceId, phone, parameters, propertyId) {
  return async dispatch => {
    const serviceRequest = await makeAPIRequest('reservation/service', {
      method: 'POST',
      data: {
        reservationId: reservationId || null,
        serviceId,
        phone,
        parameters,
        propertyId,
      },
    })

    dispatch({
      type: 'services/REQUEST',
      payload: serviceRequest,
    })
  }
}

export function makeContactRequest(phone, parameters, reservationId) {
  return async dispatch => {
    const contactRequest = await makeAPIRequest('contact', {
      method: 'POST',
      data: {
        ...parameters,
        phone,
        reservationId,
      },
    })

    dispatch({
      type: 'contact/REQUEST',
      payload: contactRequest,
    })
  }
}

export function makeReviewReminderRequest(phone, reservationId) {
  return async dispatch => {
    const contactRequest = await makeAPIRequest('reservation/review_reminder', {
      method: 'POST',
      data: {
        phone,
        reservationId,
      },
    })

    dispatch({
      type: 'contact/REQUEST',
      payload: contactRequest,
    })
  }
}

function validateAndCompletePropertyObject(property) {
  const validatedData = property
  if (!property.nickname) {
    validatedData.nickname = ''
  }
  if (!property.config) {
    validatedData.config = ''
  }
  if (!property.id) {
    validatedData.id = 0
  }
  if (!property.addressLine1) {
    validatedData.addressLine1 = ''
  }
  if (!property.addressLine2) {
    validatedData.addressLine2 = ''
  }

  if (!property.addressCity) {
    validatedData.addressCity = ''
  }

  if (!property.addressState) {
    validatedData.addressState = ''
  }
  if (!property.addressZIP) {
    validatedData.addressZIP = ''
  }

  if (!property.addressCountry) {
    validatedData.addressCountry = ''
  }

  if (!property.timezone) {
    validatedData.timezone = ''
  }
  if (!property.addressZIP) {
    validatedData.addressZIP = ''
  }

  if (!property.latitude) {
    validatedData.latitude = 0
  }
  if (!property.longitude) {
    validatedData.longitude = 0
  }
  if (!property.photoURL) {
    validatedData.photoURL = ''
  }
  if (!property.devicePIN) {
    validatedData.devicePIN = ''
  }

  if (!property.externalServiceId) {
    validatedData.externalServiceId = ''
  }

  if (!property.clientRefreshBit) {
    validatedData.clientRefreshBit = ''
  }

  if (!property.isArchived) {
    validatedData.isArchived = false
  }

  if (!property.organization) {
    validatedData.organization = {
      reservationServiceConfig: {},
      customizations: {
        promos: {},
      },
    }
  }

  if (!property.host) {
    validatedData.host = {}
  }

  if (!property.artModeBundle) {
    validatedData.artModeBundle = {
      images: [],
    }
  }

  if (!property.knowledgebase) {
    validatedData.knowledgebase = []
  }

  if (!property.activities) {
    validatedData.activities = []
  }

  if (!property.restaurant) {
    validatedData.restaurant = []
  }

  if (!property.services) {
    validatedData.services = []
  }

  if (!property.advertisement) {
    validatedData.advertisement = []
  }

  if (!property.checkout) {
    validatedData.checkout = {}
  }

  if (!property.customizations) {
    validatedData.customizations = {
      amenities: [],
      supplies: [],
      policies: [],
      restaurants: [],
      activities: [],
      directory: [],
      recommendationQuotes: {},
      contact: {},
      welcome: {},
      recommendations: {},
      checkoutDetails: {},
      checkoutChecklist: {},
      checkInOut: {},
      rules: {
        ruleList: [],
      },
      wifi: {
        wifiFormat: '',
        wifiName: '',
        wifiPass: '',
      },
    }
  }
  if (!property.reservationServices) {
    validatedData.reservationServices = []
  }
  if (!property.messages) {
    validatedData.messages = []
  }
  return validatedData
}

export function fetchPropertyAndState(settings = {}) {
  return async dispatch => {
    if (!settings.pin) {
      return
    }
    const serialNumber = localStorage.getItem('serialNumber')
    const headers = serialNumber ? { 'X-GVG-Device-SerialNumber': serialNumber } : {}
    try {
      const checkedOutReservationId = localStorage.getItem('hasCheckedOut')
      const property = await makeAPIRequest('property', {
        params: { checkedOutReservationId },
        headers,
      })

      fetchPropertyMessages(dispatch, property)
      dispatch(fetchMessageHistory(property.reservation, property.customizations.checkInOut))
      const modifiedPropertyObject = await validateAndCompletePropertyObject(property)
      if (modifiedPropertyObject.reservation) {
        modifiedPropertyObject.services = modifiedPropertyObject.services.filter(service =>
          isBeforeOrderDeadline(modifiedPropertyObject.reservation, service.orderDeadline)
        )
        modifiedPropertyObject.advertisement = modifiedPropertyObject.advertisement.filter(ads => {
          if (ads.type !== 'Service') {
            return true
          }
          return modifiedPropertyObject.services.some(service => service.id === ads.serviceId)
        })
      }

      computeLocalStorageSize()

      // const log = {
      //   module: 'app-state',
      //   metadata: { appState: getAppState(modifiedPropertyObject) },
      // }
      // saveLogsToLocalStorage(log)

      dispatch({
        type: DEVICE_BLACKLISTED,
        value: false,
      })
      await dispatch({
        type: PROPERTY_FETCH_SUCCESS,
        property: { ...modifiedPropertyObject },
      })
      await dispatch({
        type: UPDATE_SETTINGS,
      })
      // const checkoutTime = property.customizations.checkInOut && property.customizations.checkInOut.checkoutTime
      // if (checkoutTime) {
      //   const checkoutTimeDate = moment(`${moment().format('YYYY MM DD')} ${checkoutTime}`, 'YYYY MM DD kk:mm')
      //   const timeDiff = checkoutTimeDate.diff(moment(), 'minutes')
      //   if (timeDiff < 1 && timeDiff > -5) {
      //     await dispatch({
      //       type: CLEAR_SERVICE_STATUS,
      //     })
      //   }
      // }
    } catch (error) {
      if (error.message === 'Device is blacklisted') {
        dispatch({
          type: DEVICE_BLACKLISTED,
          value: true,
        })
      }
      if (!error.response) {
        // this means the API server is not reachable
        dispatch(setOffline())
      }
      dispatch({
        type: PROPERTY_HAS_ERRORED,
      })
      console.log(error)
    }
  }
}
export function markMessageAsRead(messageId, data) {
  const updateHistory = true

  return async (dispatch, getState) => {
    const { property } = getState()
    const noGuestMode = localStorage.getItem('noGuestMode')

    let reservationId = property?.reservation ? property?.reservation?.id : null
    if (reservationId && noGuestMode) {
      // If guest has checked out, the message won't be read with reservation id
      reservationId = null
    }

    dispatch({
      type: MARK_MESSAGE_AS_READ,
      messageId,
      property,
    })

    try {
      if (!localStorage.getItem('isPreviewMode')) {
        const response = await makeAPIRequest(`property/message-read/${messageId}`, {
          method: 'PUT',
          data: { ...data, reservationId },
          params: {
            updateHistory,
          },
        })

        if (response.status === 'success') {
          const localStroredMessages = JSON.parse(localStorage.getItem('messages'))
          const message = localStroredMessages.find(msg => msg.id === messageId)
          updateLocalMessageStatus(message, MESSAGE_STATUS.READ)
        }
      }

      // fetchPropertyMessages(dispatch, property)
    } catch (err) {
      console.log(err)
    }
  }
}

export const unpair = () => {
  return async (dispatch) => {
    try {
      clearProperty()
      clearPasscode()
      clearToken()
      setPropertyConfirmed('no')
      localStorage.removeItem('deviceType')
      localStorage.removeItem('phoneNumber')
      localStorage.removeItem('reservationServices')
      localStorage.removeItem('messages')
      // localStorage.removeItem('previousPropertyState')
      // localStorage.removeItem('previousMessageState')

      const settings = getSettings()
      delete settings.pin
      setSettings(settings)

      dispatch({
        type: UNPAIRED,
      })
    } catch (error) {
      console.error('Error occurred while sending logs to server while unpairing:', error)
      // Handle the error case as needed
      return {
        error: 'Error occurred while sending logs to server during unpairing',
      }
    }
  }
}

const validateWeatherApi = weatherObj => {
  const validatedWeatherObj = weatherObj
  if (!weatherObj.forecast) {
    validatedWeatherObj.forecast = []
  }
  if (!weatherObj.weather) {
    validatedWeatherObj.weather = {
      icon: '',
      main: {},
      weather: [],
    }
  }
  return validatedWeatherObj
}

export function fetchPropertyWeather() {
  return async (dispatch, getState) => {
    const { property } = getState()
    const { addressZIP, latitude, longitude, timezone } = property
    dispatch({
      type: FETCH_PROPERTY_WEATHER_REQUEST,
    })
    try {
      const weatherData = await makeAPIRequest('property/weather', {
        method: 'GET',
        params: { addressZIP, latitude, longitude, timezone },
      })
      const validatedWeatherObj = await validateWeatherApi(weatherData)
      dispatch({
        type: FETCH_PROPERTY_WEATHER_SUCCESS,
        weatherData: validatedWeatherObj,
      })
    } catch (error) {
      dispatch({
        type: FETCH_PROPERTY_WEATHER_FAILURE,
        error,
      })
    }
  }
}

export function makeConnectHostRequest(parameters) {
  return async () => {
    await makeAPIRequest('contact/host', {
      method: 'POST',
      data: {
        ...parameters,
      },
    })
    //  dispatch({
    //    type: 'contact/REQUEST',
    //    payload: contactRequest,
    //  })
  }
}

export function sendMessageDetailsRequestOnSMS(parameters) {
  return async () => {
    await makeAPIRequest('property/viewMessageOnSMS', {
      method: 'POST',
      data: {
        ...parameters,
      },
    })
  }
}


export function sendPropertyInfoDetailsOnSMS(parameters) {
  return async () => {
    await makeAPIRequest('property/viewPropertyInfoOnSMS', {
      method: 'POST',
      data: {
        ...parameters,
      },
    })
  }
}

export async function fetchPropertyMessages(dispatch, property = null) {
  dispatch({
    type: FETCH_PROPERTY_MESSAGES_REQUEST,
  })
  try {
    const messagesData = await makeAPIRequest('property/messages', {
      method: 'GET',
    })
    await sycnMessagesWithServer(messagesData.messages, property?.reservation, dispatch)

    // Setting a delay of 2 seconds explicitly to get messages sync
    setTimeout(async () => {
      const localStoredMessages = JSON.parse(localStorage.getItem('messages'))
      const unreadMessages = localStoredMessages ? getUnreadMessages(localStoredMessages, property) : []

      // logMessageState(unreadMessages)
      await dispatch({
        type: FETCH_PROPERTY_MESSAGES_SUCCESS,
        messages: unreadMessages,
      })
    }, 2000)
  } catch (error) {
    dispatch({
      type: FETCH_PROPERTY_MESSAGES_FAILURE,
      error,
    })
  }
}

export function fetchMessageHistory(reservation = null, checkInOut = {}) {
  const reservationId = reservation ? reservation.id : null
  const { checkoutTime, checkinTime } = checkInOut

  const today = moment()
    .utc()
    .format('YYYY-MM-DD')
  const yesterday = moment()
    .subtract(1, 'day')
    .utc()
    .format('YYYY-MM-DD')
  const tomorrow = moment()
    .add(1, 'day')
    .utc()
    .format('YYYY-MM-DD')

  let start = moment(`${yesterday} ${checkoutTime}`)
    .utc()
    .format()
  let end = moment(`${today} ${checkoutTime}`)
    .utc()
    .format()

  const checkoutTimePassed = moment().isAfter(
    moment(`${today} ${checkoutTime}`)
      .utc()
      .format()
  )
  if (checkoutTimePassed) {
    start = moment(`${today} ${checkoutTime}`)
      .utc()
      .format()
    end = moment(`${tomorrow} ${checkoutTime}`)
      .utc()
      .format()
  }

  const noGuestMode = localStorage.getItem('noGuestMode')
  if (reservation && !noGuestMode) {
    // const { checkInOut } = customizations
    const { beginDate, endDate, checkoutTime } = reservation

    start = moment(`${beginDate}`)
      .utc()
      .format()
    end = moment(`${endDate}`)
      .utc()
      .format()
  }

  return async dispatch => {
    dispatch({
      type: FETCH_MESSAGE_HISTORY_REQUEST,
    })
    try {
      let url = `property/messages-history?from=${start}&to=${end}`
      if (reservationId && !noGuestMode) url += `&reservationId=${reservationId}`
      const history = await makeAPIRequest(url, {
        method: 'GET',
      })
      dispatch({
        type: FETCH_MESSAGE_HISTORY_SUCCESS,
        history: history.messages,
      })
    } catch (error) {
      dispatch({
        type: FETCH_MESSAGE_HISTORY_FAILURE,
        error,
      })
    }
  }
}

export function refreshMessages() {
  return async dispatch => {
    dispatch({
      type: REFRESH_MESSAGES_IN_STATE,
    })
  }
}

function logMessageState(unreadMessages = []) {
  const previousMessageState = JSON.parse(localStorage.getItem('previousMessageState'))
  if (!previousMessageState) {
    localStorage.setItem('previousMessageState', JSON.stringify(unreadMessages))
    const log = {
      module: 'app-messages',
      metadata: { messages: unreadMessages },
    }
    saveLogsToLocalStorage(log)
  } else {
    if (isEqual(previousMessageState, unreadMessages)) {
      return
    }

    if (!isEqual(previousMessageState, unreadMessages)) {
      localStorage.setItem('previousMessageState', JSON.stringify(unreadMessages))
      const log = {
        module: 'app-messages',
        metadata: { messages: unreadMessages },
      }
      saveLogsToLocalStorage(log)
    }
  }
}
