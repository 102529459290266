import React from 'react'
import Icon from './Icon'

const WifiIcon = props => (
  <Icon viewBox="0 0 48 39" fill="none" {...props}>
    <path
      d="M0 11.865l4.364 4.883c10.843-12.134 28.429-12.134 39.272 0L48 11.865c-13.244-14.82-34.734-14.82-48 0zm17.454 19.532L24 38.72l6.546-7.324c-3.6-4.053-9.47-4.053-13.091 0zM8.727 21.63l4.364 4.883c6.022-6.739 15.796-6.739 21.818 0l4.364-4.883c-8.422-9.424-22.102-9.424-30.546 0z"
      className="fill"
    />
  </Icon>
)

export default WifiIcon
