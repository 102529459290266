import React, { useState } from 'react'
import classNames from 'classnames'
import CarouselPreview from '../common/CarouselPreview'
import ClockIcon3 from '../icons/ClockIcon3'

const ServiceHeader = ({
  name,
  price,
  customFeeMessage,
  priceType,
  column,
  checkoutDuration,
  orderDeadline,
  serviceMedias,
}) => {
  const hours = Math.floor(orderDeadline / 60)
  const minutes = orderDeadline % 60
  const hourValue = hours > 1 ? ' hours' : ' hour'
  const minuteValue = minutes > 1 ? ' minutes' : ' minute'
  const [fullScreen, setfullScreen] = useState(false)
  return (
    <div>
      <div
        className={classNames('card-section ServiceHeader', {
          column,
          row: !column,
        })}
      >
        {/* {<img src={serviceMedias[0].mediaUrl} className="service-image" alt="service-img" />} */}
        <div className="service-header-info">
          <div className="service-name">{name}</div>
          {customFeeMessage && <div className="service-price-figure">{customFeeMessage}</div>}

          <div className="service-subname subname-item">
            {price && (
              <div className="service-price price-has-type">
                <div className="service-price-figure">{price}</div>
                {priceType && <div className="service-price-type">{priceType}</div>}
              </div>
            )}
            {/* {minPrice && (
              <div className="service-price price-has-type">
                <div className="service-price-figure">
                  {minPrice}
                  {maxPrice && ` to ${maxPrice}`}
                </div>
              </div>
            )} */}
            {checkoutDuration && (
              <div className="subname-item">
                <div className="checkout-duration">{checkoutDuration}</div>
              </div>
            )}
          </div>
          <div className={`carousel-container ${fullScreen ? 'has-fullscreen-video' : ''} `}>
            <CarouselPreview medias={serviceMedias} setFullScreen={setfullScreen} />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: '0 36px 10px 36px',
        }}
      >
        {orderDeadline && (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  marginRight: '6px',
                  marginTop: '6px',
                  position: 'relative',
                  top: '4px',
                }}
              >
                <ClockIcon3 />
                {' '}
              </span>
              {`${hours ? hours + hourValue : ''} ${minutes ? minutes + minuteValue : ''} before checkout time.`}
            </div>

            <hr />
          </>
        )}
      </div>
    </div>
  )
}

export default ServiceHeader

ServiceHeader.defaultProps = {
  column: false,
}
