import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { has, get } from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'
import { ArrowIcon } from '../icons'
import { OrganizationBranding } from '../onboarding'
import { Impressions, ReviewList } from '.'
import '../../styles/CheckInOutWizard.scss'
import { Mixpanel } from '../../api/mixpanel'
import WelcomeVideo from '../WelcomeVideo'
import { metrics } from '../../api'
import { ANALYTICS_ENTITY_TYPES } from '../../constants/appConstants'
import { MESSAGES } from '../../constants/messages'

const Step = props => {
  const initial = {
    opacity: 0,
  }
  const animate = {
    opacity: 1,
  }
  const exit = {
    opacity: 0,
  }
  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={{
        duration: 0.3,
      }}
      {...props}
    />
  )
}

const dynamicStep = (history, finishSteps) => [
  {
    type: 'dynamic',
    title: 'How are we doing?',
    // listPath: 'checkoutChecklist.checklist',
    body: (
      <div className="reminder-body">
        {' '}
        <p className="reminder-head-text">{MESSAGES.WE_ARE_GLAD_YOU_STAY_WITH_US}</p>
        <p>{MESSAGES.LEAVE_REVIEW}</p>
        <p>{MESSAGES.TAP_BUTTON_FOR_REVIEW_REMINDER}</p>
        <button
          type="button"
          className="reminder-btn"
          onClick={() => {
            finishSteps()
            history.push('/contact/phone/bifurcated')
          }}
        >
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="22.276" height="24.281" viewBox="0 0 22.276 24.281">
              <path
                className="cls-1"
                d="M1009.11,311.815h5.95a2.548,2.548,0,1,0,0-5.1h0a0.641,0.641,0,0,1-.64-0.638v-7.609a7.952,7.952,0,0,0-5.58-7.578,2.441,2.441,0,0,0,.15-0.849,2.515,2.515,0,0,0-5.03,0,2.434,2.434,0,0,0,.15.85,7.951,7.951,0,0,0-5.58,7.577v7.609a0.638,0.638,0,0,1-.638.638,2.548,2.548,0,1,0,0,5.1h5.948m1.66-21.783a0.965,0.965,0,1,1,1.93,0,1.044,1.044,0,0,1-.13.493,7.287,7.287,0,0,0-1.67,0,0.938,0.938,0,0,1-.13-0.493h0Zm-0.09,21.783h2.14m7.51-1.447H997.892a1.1,1.1,0,1,1,0-2.2,2.088,2.088,0,0,0,2.087-2.085v-7.609a6.5,6.5,0,0,1,12.991,0v7.609a2.091,2.091,0,0,0,2.09,2.085,1.1,1.1,0,0,1,0,2.2h0Zm0,0"
                transform="translate(-995.344 -287.531)"
              />
            </svg>
            {MESSAGES.SEND_TEXT_REMINDER}
          </span>
        </button>
        <p className="reminder-sub-text">{MESSAGES.WE_DONT_STORE_PHONE_NUMBER}</p>
      </div>
    ),
    nextLabel: 'Skip',
  },
]

const checkIfValid = (stepValues, stepData) => {
  if (has(stepData, 'agreeMessage')) {
    return get(stepValues, 'agreed')
  }
  return true
}

const CheckInOutWizard = ({
  steps,
  quitAction,
  finishAction,
  continueAction,
  headerBackground,
  history,
  property = {},
  welcomeVideo = null,
  checkin,
  includeConfirmation,
}) => {
  const checkoutFlowPrunedSteps = steps.filter(step => !(step.removeFromCheckOut))
  const [step, setStep] = useState(0)
  const [values, setValues] = useState({})
  const [error, setError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState()
  const [showWelcomeVideo, setShowWelcomeVideo] = useState(false)

  const currentTitle = get(checkoutFlowPrunedSteps, `${step}.title`, '')

  const nextLabel = get(checkoutFlowPrunedSteps, `${step}.nextLabel`, 'Next')
  const nextLabelToShow = nextLabel
  const backLabel = get(checkoutFlowPrunedSteps, `${step}.backLabel`)
  const feedBackRating = property
    && property.organization
    && property.organization.review_reminder
    && property.organization.review_reminder.feedback === '5star'
    ? 4
    : 3

  const reviewReminderStatus = property
    && property.organization
    && property.organization.review_reminder
    && property.organization.review_reminder.status

  useEffect(() => {
    if (!steps.length) {
      next()
    }
  }, [])

  const next = () => {
    if (!error) {
      // TODO: Improve with API what happens when skipping
      if (step === checkoutFlowPrunedSteps.length - 1 || !steps.length ) {
        return finishAction(values)
          .then(() => {
            if (welcomeVideo && welcomeVideo.status && !showWelcomeVideo) {
              metrics.track('videoPlayed', {
                extra: {
                  source: 'CheckIn',
                  name: 'Welcome Video',
                },
                entityId: welcomeVideo.id,
                entityType: ANALYTICS_ENTITY_TYPES.WELCOME_VIDEO,
              })
              return setShowWelcomeVideo(true)
            }
            continueAction()
          })
          .catch(setError(true))
      }
      let difference
      if (property && property.reservation) {
        const checkOutTime = new Date(property.reservation.endDate)
        const checkinTime = new Date()
        difference = (checkinTime.getTime() - checkOutTime.getTime()) / (1000 * 3600)
      }
      Mixpanel.track(`Click ${nextLabelToShow}`, {
        extra: {
          name: nextLabelToShow,
          source: checkoutFlowPrunedSteps[step]?.topic,
        },
        timeDifference: difference,
      })
      setStep(step + 1)
    } else {
      setStep(step + 1)
      setError(false)
    }
  }

  const back = () => {
    if (step === 0) {
      Mixpanel.track('Click Checkout Later Button', {
        name: 'Checkout Later Button',
        source: checkoutFlowPrunedSteps[step].topic,
      })
      quitAction()
    } else {
      setStep(step - 1)
    }
  }
  const finishSteps = () => {
    Mixpanel.track('Click Set a text Reminder Button', {
      name: 'Set a text Reminder Button',
      source: 'Bifurcated Checkout',
    })
    return finishAction(values)
      .then(continueAction())
      .catch(setError(true))
  }

  if (
    values[1]
    && values[1].rating > feedBackRating
    && steps[2].type !== 'dynamic'
    && !!reviewReminderStatus
    && property.reservation
  ) {
    steps.splice(2, 0, dynamicStep(history, finishSteps)[0])
    steps.pop()
    localStorage.setItem('Step3-rating', true)
    Mixpanel.track('Click Review Reminder', { name: 'Review Reminder', source: 'Checkout Button' })
  } else if (values[1] && values[1].rating <= feedBackRating && steps[2] && steps[2].type == 'dynamic') {
    steps.splice(2, 1)
    localStorage.setItem('Step3-rating', false)
  } else if (values[1] && values[1].rating <= feedBackRating) {
    localStorage.setItem('Step3-rating', false)
  }

  const isImpressions = has(checkoutFlowPrunedSteps[step], 'question')
  const isValid = checkIfValid(values[step], checkoutFlowPrunedSteps[step], isImpressions)
  const isSkippable = isImpressions
  const welcomeVideoContent = (
    <div className="CheckInOutWizard">
      <WelcomeVideo welcomeVideo onEnded={continueAction} {...welcomeVideo} openVideo source="CheckIn" />
    </div>
  )

  const wizardContent = (
    <div className="CheckInOutWizard">
      {checkoutFlowPrunedSteps.length > 0 ? (
        <>
          <div className="Card white">
            <div
              className="header"
              style={{
                background: `url(${headerBackground})`,
              }}
            >
              <OrganizationBranding />
              <div className="title">{currentTitle}</div>
            </div>
            <AnimatePresence exitBeforeEnter>
              <Step key={step} className="step card-section padding-y">
                {isImpressions ? (
                  <Impressions
                    stepKey={step}
                    question={checkoutFlowPrunedSteps[step]}
                    values={values}
                    setValues={setValues}
                    next={next}
                    setIsSubmitting={setIsSubmitting}
                  />
                ) : (
                  <ReviewList
                    stepKey={step}
                    list={checkoutFlowPrunedSteps[step]}
                    values={values}
                    setValues={setValues}
                    hasReservation={property?.reservation || null}
                    includeConfirmation={includeConfirmation}
                  />
                )}
              </Step>
            </AnimatePresence>
          </div>

          {!checkin && !property.reservation ? (
            <div
              onClick={back}
              style={{
                marginTop: '40px',
                fontWeight: 'bold',
                color: 'lightblue',
                textAlign: 'center',
              }}
            >
              {MESSAGES.BACK}
            </div>
          ) : (
            <div className="footer">
              <button
                className={classNames('footer-item button-wrapper', {
                  disabled: (!isValid && !isSkippable && includeConfirmation),
                })}
                onClick={next}
                disabled={(isSubmitting || !isValid) && includeConfirmation}
              >
                <div className="button round light">
                  <ArrowIcon />
                </div>
                <div className="button-text">{nextLabelToShow}</div>
              </button>
              {backLabel && (
                <div className="footer-item link bold uppercase" onClick={back}>
                  {backLabel}
                </div>
              )}
            </div>
          )}
        </>
      ) : ''}
    </div>
  )
  return showWelcomeVideo ? welcomeVideoContent : wizardContent
}

export default React.memo(CheckInOutWizard)
