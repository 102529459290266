import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'

import { redirectRequest } from '../utils/helpers'
import { ANALYTICS, ANALYTICS_ENTITY_TYPES } from '../constants/appConstants'
import QrFade from '../images/qr-fade.png'
import QrLoader from '../images/Spinner-qr.gif'
import QrInvalid from '../images/invalid-qr.png'
import { MESSAGES } from '../constants/messages'

const MAX_RETRY_TIMES = 4

const QR = props => {
  const [value, setValue] = useState()
  const [intervalId, setIntervalId] = useState(0)
  const [pollFinish, setPollFinish] = useState(false)

  useEffect(() => {
    const getUrl = async () => {
      const {
        url, name, source, advertisementId, recommendationId, messageId, referer = null,
      } = props
      const data = {
        referer: referer || window.location.href,
        extra: {
          name,
          source: advertisementId ? ANALYTICS.ADVERTISEMENT : source,
        },
        url,
        advertisementId,
      }
      if (advertisementId) {
        data.entityId = advertisementId
        data.entityType = ANALYTICS_ENTITY_TYPES.ADVERTISEMENT
      } else if (recommendationId) {
        data.entityId = recommendationId
        data.entityType = ANALYTICS_ENTITY_TYPES.RECOMMENDATION
      } else if (messageId) {
        data.entityId = messageId
        data.entityType = ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS
      }
      const value = await redirectRequest(data)
      if (value) {
        setValue(value)
      } else {
        qrInterval(data)
      }
    }
    getUrl()
    return () => {
      clearInterval(intervalId)
    }
  })

  const qrInterval = data => {
    let retryTime = 0
    if (!intervalId) {
      const intervId = setInterval(async () => {
        const value = await redirectRequest(data)
        if (value) {
          setValue(value)
          clearInterval(intervId)
        } else if (retryTime >= MAX_RETRY_TIMES) {
          setPollFinish(true)
          clearInterval(intervId)
        }
        retryTime += 1
      }, 3000)
      setIntervalId(intervId)
    } else {
      clearInterval(intervalId)
    }
  }

  if (pollFinish) {
    return (
      <div className={`qr-sm-load ${props.source === 'Advertisement' ? 'qrLoaderAds' : ''} `}>
        <img src={QrFade} width="auto" height="100%" />
        {' '}
        <img src={QrInvalid} className="qrFade" />
        <span>
          {' '}
          {MESSAGES.QR_ERROR}
          {' '}
        </span>
      </div>
    )
  }
  return value ? (
    props.source === 'Advertisement' ? (
      <div className="qr-sm">
        <QRCode value={value} />
      </div>
    ) : (
      <div className="qr-container">
        <QRCode value={value} />
      </div>
    )
  ) : (
    <div className={`qr-sm-load ${props.source === 'Advertisement' ? 'qrLoaderAds' : ''} `}>
      <img src={QrFade} alt="qrfade" width="auto" height="100%" />
      {' '}
      <img src={QrLoader} alt="qrloader" className="qrLoader" />
      <span className="qrLoaderText">
        {' '}
        {MESSAGES.LOADING_QR}
        {' '}
      </span>
    </div>
  )
}

export default QR
