import React, { Component } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input'

import '../styles/OnboardingContactField.scss'
import crossIcon from '../images/icons/x@2x.png'

export default class OnboardingContactField extends Component {
  onTextChanged = e => {
    const nextValue = e.target.value
    const validatedValue = formatPhoneNumberIntl(nextValue)
    this.props.onChange(validatedValue || nextValue)
  }

  render() {
    const {
      isValid, label, value, enabled, meta, className, type,
    } = this.props
    const {
      onSetEnabled, onValidate, onFocus, onBlur, onChange,
    } = this.props
    const invalid = onValidate(value) === false
    return (
      <div
        className={`OnboardingContactField ${enabled && 'enabled'} ${value
          && value.length === 0
          && 'empty'} ${invalid && 'invalid'} ${className}`}
      >
        <div className="top">
          <label>{label}</label>
        </div>
        <div
          className="x"
          onMouseDown={e => {
            this.props.onChange('')
            e.preventDefault()
          }}
        >
          <img src={crossIcon} alt="x" />
        </div>
        <PhoneInput
          defaultCountry="US"
          international
          type={type}
          autoFocus
          countryCallingCodeEditable={false}
          value={value}
          onInput={this.onTextChanged}
          onChange={onChange}
          inputRef={el => (this._inputEl = el)}
          onBlur={e => {
            if (enabled && value === '') {
              onSetEnabled(false)
            }
            onBlur(e)
          }}
          onFocus={e => {
            onSetEnabled(true)
            onFocus(e)
          }}
        />
        {!isValid && <p style={{ color: 'salmon', textIndent: '70px' }}>Please enter a valid phone number</p>}
        <div className="meta">{meta}</div>
      </div>
    )
  }
}

OnboardingContactField.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
  className: '',
  type: 'text',
}

OnboardingContactField.Validation = {
  Phone: value => {
    return formatPhoneNumberIntl(value)
  },

  Email: value => {
    if (value.includes('@') && value.includes('.')) {
      return value
    }
    return false
  },
}
