import React from 'react'
import { connect } from 'react-redux'
import { SendToPhoneContainer } from '../../containers'
import { setOverlay } from '../../actions/device'

const AboutUsSendToPhone = () => {
  return (
    <SendToPhoneContainer
      title="Please provide your mobile number and one of our host will connect with you soon."
      meta="Standard text messaging rates may apply."
      message="Learn more about the GuestView Guide by following the link below"
      link="https://guestviewguide.com"
    />
  )
}

export default connect(null, { setOverlay })(AboutUsSendToPhone)
