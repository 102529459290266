import React from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import WeatherIcon from './WeatherIcon'
import Temperature from './Temperature'

const CurrentWeather = ({ weather }) => {
  if (!weather || isEmpty(weather)) {
    return null
  }

  return (
    <div className="CurrentWeather">
      <WeatherIcon icon={weather.icon} />
      <div data-cy="current-temp">
        <Temperature value={weather.temperature} />
      </div>
    </div>
  )
}

const mapStateToProps = ({ weather: weatherState }) => {
  const { weather } = weatherState
  return {
    weather,
  }
}

export default connect(mapStateToProps)(CurrentWeather)
