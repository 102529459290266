import React from 'react'
import Icon from './Icon'

const SuitcaseTickIcon = props => (
  <Icon viewBox="0 0 101 81" fill="none" {...props} width="1em" height="1em">
    <path
      d="M51.762 49.625a5 5 0 0 1-6.93.835l-12.387-9.466a4.155 4.155 0 0 0-5.046 6.604l18.824 14.384a5 5 0 0 0 6.93-.837l38.496-47.81a4.142 4.142 0 1 0-6.45-5.197L51.761 49.626z"
      fill="#7BDE51"
    />
    <path
      d="M35.213 21.407V10.603a8 8 0 0 1 2.688-5.982l.68-.603A8 8 0 0 1 43.893 2H55.36a8 8 0 0 1 5.312 2.018l.679.603a8 8 0 0 1 2.688 5.982v10.804m-28.827 0H64.04m-28.827 0H24.56m39.48 0h9.4m-48.88 0H10.383a8 8 0 0 0-4.909 1.682l-.382.298A8 8 0 0 0 2 29.704v42.338A6.958 6.958 0 0 0 8.958 79H24.56m0-57.593V79m0 0h48.88m0-57.593h14.543A8.026 8.026 0 0 1 96 29.422v41.563A8.026 8.026 0 0 1 87.983 79H73.44m0-57.593V79"
      stroke="#000"
      strokeWidth={5}
    />
  </Icon>
)

export default SuitcaseTickIcon
