import { DEVICE_BLACKLISTED } from '../actions/actionTypes'
export const SAVE_SERIAL_NUMBER = 'device/SAVE_SERIAL_NUMBER'
export const UPDATE_VOLUME = 'device/UPDATE_VOLUME'
export const UPDATE_BRIGHTNESS = 'device/UPDATE_BRIGHTNESS'
export const VOLUME_FETCH_SUCCESS = 'device/VOLUME_FETCH_SUCCESS'
export const BRIGHTNESS_FETCH_SUCCESS = 'device/BRIGHTNESS_FETCH_SUCCESS'
export const SET_DAYTIME_MODE = 'device/SET_DAYTIME_MODE'
export const SET_NIGHTTIME_MODE = 'device/SET_NIGHTTIME_MODE'
export const SET_OVERLAY = 'SET_OVERLAY'
export const SET_HIDE_FOOTER = 'SET_HIDE_FOOTER'

const initialState = {
  volume: null,
  brightness: null,
  serialNumber: null,
  showOverlay: false,
  hideFooter: false,
  isBlacklisted: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SERIAL_NUMBER:
      return {
        ...state,
        serialNumber: action.payload.serialNumber,
      }

    case SET_OVERLAY:
      return {
        ...state,
        showOverlay: action.payload,
      }

    case SET_HIDE_FOOTER:
      return {
        ...state,
        hideFooter: action.payload,
      }

    case VOLUME_FETCH_SUCCESS:
      return {
        ...state,
        volume: action.deviceVolume.volumeLevel,
      }

    case BRIGHTNESS_FETCH_SUCCESS:
      return {
        ...state,
        brightness: action.deviceBrightness.brightnessLevel,
      }

    case UPDATE_BRIGHTNESS:
      return {
        ...state,
        brightness: action.updatedBrightnessLevel,
      }

    case UPDATE_VOLUME:
      return {
        ...state,
        volume: action.updatedVolumeLevel,
      }

    case DEVICE_BLACKLISTED:
      return {
        ...state,
        isBlacklisted: action.value,
      }
    default:
      return state
  }
}
