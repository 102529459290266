import React from 'react'

import { updateVolume, getVolume } from '../../actions/device'
import { connectWithPath } from '../../resources/utils'
import SliderSection from './SliderSection'

const VolumeSection = props => <SliderSection title="Volume" wait={200} {...props} />

const mapDispatchToProps = dispatch => ({
  updateValue: value => dispatch(updateVolume(value)).then(() => {
    const audio = new Audio('light2.mp3')
    audio.play()
  }),
  getValue: () => dispatch(getVolume()),
})

export default connectWithPath(
  {
    defaultValue: 'device.volume',
  },
  mapDispatchToProps,
)(VolumeSection)
