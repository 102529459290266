import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { MESSAGES } from '../constants/messages'

import '../styles/BackLink.scss'

const Style = {
  RoundButton: 'RoundButton',
  BottomCenter: 'BottomCenter',
}

class BackLink extends Component {
  render() {
    if (this.props.style === Style.RoundButton) {
      return (
        <div className="back-link-round-button" onClick={this.props.history.goBack}>
          <img src="/images/back.png" alt="arrow" />
        </div>
      )
    }
    if (
      window.previousLocation.pathname === 'property-info'
      || !window.previousLocation.pathname.includes('restaurants')
    ) {
      return (
        <Link to="/property-info" className="back-link" data-cy="link-back-property-info">
          {MESSAGES.BACK}
        </Link>
      )
    }
    return (
      <a className="back-link" onClick={this.props.history.goBack}>
        {MESSAGES.BACK}
      </a>
    )
  }
}

BackLink.Style = Style

export default withRouter(BackLink)
