import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { setDaytimeMode, setNighttimeMode } from '../actions/device'
import { SleepIcon } from '../components/icons'
import '../styles/SleepMode.scss'
import { Mixpanel } from '../api/mixpanel'
import { MESSAGES } from '../constants/messages'

class SleepModeContainer extends Component {
  state = {
    timeRemaining: 60,
  }

  componentDidMount() {
    const { setNighttimeMode } = this.props
    setNighttimeMode()

    this._interval = setInterval(this.tick, 1000)
    this.tick()
  }

  componentWillUnmount() {
    clearInterval(this._interval)
  }

  tick = () => {
    const { timeRemaining } = this.state
    this.setState(
      {
        timeRemaining: timeRemaining - 1,
      },
      () => {
        if (this.state.timeRemaining <= 0) {
          clearInterval(this._interval)
        }
      },
    )
  }

  goHome = () => {
    const { setDaytimeMode } = this.props
    setDaytimeMode()
    // history.push('/home')
    window.location.href = '/home'
  }

  onExitSleepMode = () => {
    // Mixpanel.track('Click Exit Sleep Mode', { name: 'Exit Sleep Mode Button', source: 'Sleep Mode Container' })
  }

  render() {
    const { timeRemaining } = this.state

    return (
      <div className="sleep-mode-fullscreen viewing-1">
        <div className="background" />
        <div className="message-card flex" onClick={this.goHome}>
          <div className="sleep-mode-card">
            <SleepIcon className="next-icon" style={{ fontSize: '60px' }} />
            <h1>{MESSAGES.SLEEP_MODE}</h1>
            <p>
              {MESSAGES.SCREEN_WILL_DARKEN_SOON}
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '28px',
                  marginTop: '1em',
                  visibility: timeRemaining > 0 ? 'visible' : 'hidden',
                }}
              >
                {timeRemaining}
              </div>
            </p>
          </div>
          <div onClick={this.onExitSleepMode} className="cancel-sleep-mode">
            {MESSAGES.EXIT_SLEEP_MODE}
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, { setDaytimeMode, setNighttimeMode })(withRouter(SleepModeContainer))
