import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'

const formatPhoneNumber = phoneNumber => {
  const inputPhone = new AsYouType('US')
  const formattedPhone = inputPhone.input(phoneNumber)
  return formattedPhone
}

const parseNumber = number => {
  try {
    const phoneNumber = parsePhoneNumber(number)
    number = phoneNumber.formatNational()
    return number
  } catch (error) {
    return null
  }
}

export { parseNumber, formatPhoneNumber }
