/* eslint-disable no-unused-vars */
import React from 'react'
import { TrackLink } from '../metrics'
import '../../styles/CheckoutPromotion.scss'
import { ChevronIcon } from '../icons'
import playOverLay from '../../images/play-overlay.png'

const CheckoutPromotion = ({
  promotion: {
    id, name, iconURL, summarySubtitle, serviceMedias = [],
  },
  setInteractive,
}) => {
  const media = serviceMedias && serviceMedias.length && serviceMedias[0]
  const isVideo = media.mediaType === 'VIDEO'
  return (
    <TrackLink
      className="CheckoutPromotion inner-card-section border-top"
      to={`/services/${id}`}
      name={name}
      source="CheckoutPromotion"
      onClick={setInteractive}
    >
      {!isVideo && <img src={media ? media.mediaUrl : null} className="image" alt="promotion-icon" />}
      {isVideo && (
        <div
          style={{
            position: 'relative',
          }}
        >
          {media.mediaUrl && <video className="image" src={media.mediaUrl} />}
          <img
            src={playOverLay}
            className="play-overlay-checkout"
            alt="play-overlay-icon"
          />
        </div>
      )}
      <div className="summary">
        <div className="title">{name}</div>
        <div className="subtitle">{summarySubtitle}</div>
      </div>
      <ChevronIcon />
    </TrackLink>
  )
}

export default CheckoutPromotion
