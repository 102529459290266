import React from 'react'
import { DetailFooter } from '../pages'
import {
  ServiceHeader, ServiceDescription, ServiceContact, RequestButton,
} from '.'
import '../../styles/ServiceDetailContainer.scss'
import { Mixpanel } from '../../api/mixpanel'
import { MoreIcon } from '../icons'
import { MESSAGES } from '../../constants/messages'

const RebookService = ({
  service: {
    id,
    name,
    summarySubtitle,
    detailHTML,
    iconURL,
    phone,
    promoExpirationDays,
    requestMessage,
    rebookUrl,
    promoCode,
    serviceMedias,
    serviceInstructions,
  },
  service,
  advertisementId,
  navigateToServices,
}) => {
  const useMessage = requestMessage || 'Ready to book your next stay?'
  const url = rebookUrl ? ` Visit ${rebookUrl}` : ''
  const needOr = rebookUrl && phone ? ' or' : ''
  const phoneNumber = phone ? ` Call ${phone}` : ''
  const usePromo = promoCode ? `Promo code: ${promoCode}` : ''
  const availabilities = ' to check availabilities. '
  const message = useMessage + url + needOr + phoneNumber + availabilities + usePromo
  const trackServiceRequest = () => {
    Mixpanel.track('Click Service Request', {
      name: 'Rebook Service Request',
      source: 'Service Detail Container',
      serviceName: name,
      serviceID: id,
    })
  }
  return (
    <div className="Card max-height ServiceDetailContainer">
      <ServiceHeader serviceImage={iconURL} name={name} serviceMedias={serviceMedias} />
      <ServiceDescription
        id={id}
        title={summarySubtitle}
        detailHTML={detailHTML}
        serviceInstructions={serviceInstructions}
      />
      <ServiceContact phone={phone} promoExpirationDays={promoExpirationDays} />
      <DetailFooter>
        <RequestButton
          to={{
            pathname: `/services/${id}/phone`,
            state: {
              message,
              onSuccess: `/services/${id}/phone/success`,
              name,
              section: 'services',
              advertisementId,
              template: 'rebook',
            },
          }}
          onClick={trackServiceRequest}
        >
          {MESSAGES.GET_INFO_ON_PHONE}
        </RequestButton>
        <div className="more-services" onClick={navigateToServices}>
          <MoreIcon />
          <span className="ml-2">{MESSAGES.MORE_SERVICES}</span>
        </div>
      </DetailFooter>
    </div>
  )
}

export default RebookService
