export const RECOMMENDATION_TYPE = {
  RESTAURANT: 'restaurant',
  ACTIVITY: 'activity',
  DIRECTORY: 'directory',
}

export const RECOMMENDATION_ROUTES = {
  Restaurant: 'restaurants',
  Activity: 'activities',
  Directory: 'directory',
}

export const ANALYTICS = {
  ADVERTISEMENT: 'Advertisement',
  VIEW_ADVERTISEMENT: 'View Advertisement',
  ANNOUNCEMENT: 'Announcement',
  VIEW_FEATURED_CONTENT: 'View Featured Content',
  VIEW_ANNOUNCEMENT: 'View Announcement',
}

export const ANALYTICS_ENTITY_TYPES = {
  ADVERTISEMENT: 'advertisements',
  WELCOME_VIDEO: 'welcomeVideo',
  RECOMMENDATION: 'recommendations',
  ANNOUNCEMENTS: 'announcements',
  FEATURED_CONTENT: 'featured content',
}

export const WEATHER_REFETCH_TIME = 30 * 60 * 1000 // Refetch weather every 30 mins

export const SERVICES_APPROVAL_PENDING_STATUSES = [
  'PAYMENT_CHECKOUT_PENDING',
  'APPROVAL_PENDING',
  'PAYMENT_PENDING',
  'CART_ABANDONED',
]

export const MESSAGE_STATUS = {
  READ: 'READ',
  UNREAD: 'UNREAD',
}

export const MESSAGE_CATEGORY = {
  ALERT: 'ALERT',
  REMINDER: 'REMINDER',
  INFORMATION: 'INFORMATION',
  GREETING: 'GREETING',
}
export const OPERATING_SYSTEM = {
  LINUX: 'linux',
}
