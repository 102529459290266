import React from 'react'
import '../../styles/Card.scss'
import AboutLogo from '../../images/about-gvg-logo.png'

const AboutUsContent = () => {
  const onVideoEnd = () => {
    if (document.exitFullscreen && document.fullscreenElement) {
      document.exitFullscreen(); // Standard
    } else if (document.webkitRequestFullscreen) { /* Safari */
      document.webkitRequestFullscreen();
    } else if (document.msRequestFullscreen) { /* IE11 */
      document.msRequestFullscreen();
    }
  };

  return (
    <div className="about-content-block">
      <div className="image-with-content">
        <img src={AboutLogo} alt='aboutUs' className="about-logo mb-3" />
        <div>
          {/* <h3>Who are we?</h3> */}
          <p className="pt-0">
            The Digital Concierge for Vacation Rental and Vacation Ownership Properties
            ensures guests have a delightful, local experience by providing recommendations from your host on local
            restaurants and activities, helpful information on how to use appliances and amenities, services from your
            host, and much more.
          </p>
          <p>
            GuestView Guide is a product from Sharp NEC Display Solutions, a leader in interactive display technology with
            over 100 years of innovation and experience.
          </p>
        </div>
      </div>
      <video onEnded={() => onVideoEnd()} width="100%" controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
        <source
          src="https://nec-assets.s3.amazonaws.com/uploaded-video-converted/b8a8aa80-56d1-4a89-8318-5f6b2f75bb01_guestview_guide_quick_overview.mp4720p2opt.mp4"
          type="video/mp4"
        />
        Your browser does not support HTML video.
      </video>
    </div>
  )
}

export default AboutUsContent
