import React from 'react'

const Topic = ({
  topic,
  onClick,
}) => {
  const { iconURL, text } = topic
  return (
    <li onClick={() => onClick(topic)}>
      <img alt="" src={iconURL} />
      <span>{text}</span>
    </li>
  )
}

export default Topic
