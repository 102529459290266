import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

import { TrackLink } from './metrics'
import '../styles/ListDetailNavigationFooter.scss'
import { MESSAGES } from '../constants/messages'

class ListDetailNavigationFooter extends Component {
  render() {
    const {
      previousItem, nextItem, section, item, backToPropertyInfo,
    } = this.props

    // remove the current item path component from the route
    const basePath = this.props.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/')
    const source = basePath
    const previousPath = window.previousLocation.pathname

    return (
      <div className="card-section footer row ListDetailNavigationFooter">
        <div className="nav-item left">
          {previousItem && previousItem.to && !previousPath.includes('/map') && !previousPath.includes('checkout') && (
            <TrackLink
              className="link"
              source={source}
              name={previousItem.name}
              to={`${basePath}/${previousItem.to}`}
              data-cy="link-previous"
              replace
            >
              <Icon name="chevron left" />
              {MESSAGES.PREVIOUS}
            </TrackLink>
          )}
        </div>
        <div className="nav-item center">
          {/* TODO: Simplifiy */}
          {section !== 'Amenities'
            && item
            && !previousPath.includes('/map')
            && backToPropertyInfo
            && !previousPath.includes('checkout') && (
              <Link className="link" to={`/property-info/${section}`} data-cy="link-back-property-info-section">
                {MESSAGES.BACK_TO_LIST}
              </Link>
          )}
          {section === 'Amenities'
            && item
            && !previousPath.includes('/map')
            && backToPropertyInfo
            && !previousPath.includes('checkout') && (
              <Link className="link" to="/property-info/" data-cy="link-back-a">
                {MESSAGES.BACK}
              </Link>
          )}
          {section
            && !item
            && !previousPath.includes('/map')
            && backToPropertyInfo
            && !previousPath.includes('checkout') && (
              <Link className="link" to="/property-info">
                {MESSAGES.BACK_TO_LIST}
              </Link>
          )}
          {previousPath.includes('map') && (
            <button className="link" onClick={this.props.history.goBack} data-cy="link-back-b">
              {MESSAGES.BACK}
            </button>
          )}
          {previousPath.includes('checkout') && (
            <button className="link" onClick={this.props.history.goBack} data-cy="link-back-c">
              {MESSAGES.BACK}
            </button>
          )}
        </div>
        <div className="nav-item right">
          {nextItem && nextItem.to && !previousPath.includes('/map') && !previousPath.includes('/checkout') && (
            <TrackLink
              source={source}
              name={nextItem.name}
              className="link"
              to={`${basePath}/${nextItem.to}`}
              data-cy="link-next"
              replace
            >
              {MESSAGES.NEXT}
              <Icon name="chevron right" />
            </TrackLink>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(ListDetailNavigationFooter)
