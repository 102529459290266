import React, { Component } from 'react'
import closeIcon from '../images/icons/icon-close.png'
import { MESSAGES } from '../constants/messages'

import '../styles/VideoPlayer.scss'
import overLayIcon from '../images/play-overlay.png'

export default class VideoPlayer extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false, isMuted: false }
  }

  componentDidMount() {
    if (this.props.welcomeVideo) {
      this._video.play()
      this._video.controls = false
      this.setState({ open: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.welcomeVideo) return
    if (this.state.open && !prevState.open) {
      this._video.play()
    } else if (!this.state.open && prevState.open) {
      this._video.pause()
    }
  }

  muteHandler = () => {
    this.setState({ isMuted: !this.state.isMuted })
  }

  onEnded = () => {
    const { onEnded: _onEnded = () => null } = this.props
    _onEnded()
  }

  render() {
    const { open } = this.state

    return (
      <div
        onClick={
          !open
            ? () => this.setState({
              open: true,
            })
            : undefined
        }
        className={`video-player ${open && 'fullscreen'}`}
      >
        {this.props.welcomeVideo && (
          <>
            {!this.props.preventSkip && (
              <div className="video-skip" onClick={this.onEnded}>
                {MESSAGES.SKIP}
              </div>
            )}
            {!this.props.preventMute && (
              <div className="video-mute" onClick={this.muteHandler}>
                {MESSAGES.MUTE}
              </div>
            )}
          </>
        )}
        <div className="video-placeholder" style={{ backgroundImage: `url(${this.props.poster})` }} />
        <div
          className="video-placeholder play-overlay"
          style={{ backgroundImage: `url(${overLayIcon})` }}
        />
        <video
          controls
          muted={this.state.isMuted}
          disablePictureInPicture="true"
          ref={el => (this._video = el)}
          controlsList="nodownload nofullscreen noremoteplayback"
          onEnded={this.onEnded}
          style={{ display: open ? 'block' : 'none' }}
        >
          <source src={this.props.video || this.props.url} />
        </video>
        {!this.props.welcomeVideo && (
          <div className="close" onClick={() => this.setState({ open: false })}>
            <img src={closeIcon} className="icon" alt="Close Button" />
            {' '}
            {MESSAGES.CLOSE}
          </div>
        )}
      </div>
    )
  }
}
