import React from 'react'
import { withRouter } from 'react-router-dom'

import BlueButton from '../BlueButton'
import CodeVersion from './CodeVersion'

const SettingsFooter = ({ history, label, to }) => {
  const done = () => history.replace(to)

  return (
    <div className="SettingsFooter">
      <CodeVersion />
      <BlueButton label={label} onClick={done} />
    </div>
  )
}

export default withRouter(SettingsFooter)
