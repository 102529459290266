import React from 'react'
import RadioList from '../../components/RadioList'
import PlusMinusToggle from '../../components/PlusMinusToggle'
import { bindOnChangeToValue } from '../helpers'

export default {
  Component: bindOnChangeToValue(RadioList),
  examples: [
    {
      name: 'Basic Use',
      props: {
        value: 1,
        options: [
          {
            value: 1,
            label: '228 Diamond St',
          },
          {
            value: 2,
            label: '252 Chattanooga St',
          },
        ],
        onChange: () => {},
      },
    },
    {
      name: 'With Extra',
      props: {
        value: 1,
        options: [
          {
            value: 1,
            label: '228 Diamond St',
            extra: (
              <div>
                <div>This is some extra stuff.</div>
                <PlusMinusToggle
                  value={4}
                  units={{
                    singular: 'night',
                    plural: 'nights',
                  }}
                  onChange={() => {}}
                />
              </div>
            ),
          },
          {
            value: 2,
            label: '252 Chattanooga St',
          },
        ],
        onChange: () => {},
      },
    },
  ],
}
