import React from 'react'
import Icon from './Icon'

//   <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M9 17.91C4.07901 17.91 0.09 13.921 0.09 9C0.09 4.07901 4.07901 0.09 9 0.09C13.921 0.09 17.91 4.07901 17.91 9C17.91 13.921 13.921 17.91 9 17.91ZM12.5088 9.99C12.4858 10.8883 12.119 11.7449 11.4819 12.3819C10.8237 13.0402 9.93091 13.41 9 13.41C8.06909 13.41 7.17631 13.0402 6.51805 12.3819C5.88105 11.7449 5.51417 10.8883 5.49115 9.99H12.5088ZM9 16.29C10.9334 16.29 12.7877 15.5219 14.1548 14.1548C15.5219 12.7877 16.29 10.9334 16.29 9C16.29 7.06657 15.5219 5.21233 14.1548 3.84519C12.7877 2.47805 10.9334 1.71 9 1.71C7.06657 1.71 5.21233 2.47805 3.84519 3.84519C2.47805 5.21233 1.71 7.06657 1.71 9C1.71 10.9334 2.47805 12.7877 3.84519 14.1548C5.21233 15.5219 7.06657 16.29 9 16.29ZM7.64095 7.64095C7.40466 7.87725 7.08417 8.01 6.75 8.01C6.41583 8.01 6.09534 7.87725 5.85905 7.64095C5.62275 7.40466 5.49 7.08417 5.49 6.75C5.49 6.41583 5.62275 6.09534 5.85905 5.85905C6.09534 5.62275 6.41583 5.49 6.75 5.49C7.08417 5.49 7.40466 5.62275 7.64095 5.85905C7.87725 6.09534 8.01 6.41583 8.01 6.75C8.01 7.08417 7.87725 7.40466 7.64095 7.64095ZM12.141 7.64095C11.9047 7.87725 11.5842 8.01 11.25 8.01C10.9158 8.01 10.5953 7.87725 10.359 7.64095C10.1227 7.40466 9.99 7.08417 9.99 6.75C9.99 6.41583 10.1227 6.09534 10.359 5.85905C10.5953 5.62275 10.9158 5.49 11.25 5.49C11.5842 5.49 11.9047 5.62275 12.141 5.85905C12.3773 6.09534 12.51 6.41583 12.51 6.75C12.51 7.08417 12.3773 7.40466 12.141 7.64095Z" fill="#5591C7" stroke="white" strokeWidth="0.18" />
//   </svg>

const GreetingIcon = () => (
  <Icon width="66" height="71" viewBox="0 0 18 18" fill="none">
    <path
      d="M9 17.91C4.07901 17.91 0.09 13.921 0.09 9C0.09 4.07901 4.07901 0.09 9 0.09C13.921 0.09 17.91 4.07901 17.91 9C17.91 13.921 13.921 17.91 9 17.91ZM12.5088 9.99C12.4858 10.8883 12.119 11.7449 11.4819 12.3819C10.8237 13.0402 9.93091 13.41 9 13.41C8.06909 13.41 7.17631 13.0402 6.51805 12.3819C5.88105 11.7449 5.51417 10.8883 5.49115 9.99H12.5088ZM9 16.29C10.9334 16.29 12.7877 15.5219 14.1548 14.1548C15.5219 12.7877 16.29 10.9334 16.29 9C16.29 7.06657 15.5219 5.21233 14.1548 3.84519C12.7877 2.47805 10.9334 1.71 9 1.71C7.06657 1.71 5.21233 2.47805 3.84519 3.84519C2.47805 5.21233 1.71 7.06657 1.71 9C1.71 10.9334 2.47805 12.7877 3.84519 14.1548C5.21233 15.5219 7.06657 16.29 9 16.29ZM7.64095 7.64095C7.40466 7.87725 7.08417 8.01 6.75 8.01C6.41583 8.01 6.09534 7.87725 5.85905 7.64095C5.62275 7.40466 5.49 7.08417 5.49 6.75C5.49 6.41583 5.62275 6.09534 5.85905 5.85905C6.09534 5.62275 6.41583 5.49 6.75 5.49C7.08417 5.49 7.40466 5.62275 7.64095 5.85905C7.87725 6.09534 8.01 6.41583 8.01 6.75C8.01 7.08417 7.87725 7.40466 7.64095 7.64095ZM12.141 7.64095C11.9047 7.87725 11.5842 8.01 11.25 8.01C10.9158 8.01 10.5953 7.87725 10.359 7.64095C10.1227 7.40466 9.99 7.08417 9.99 6.75C9.99 6.41583 10.1227 6.09534 10.359 5.85905C10.5953 5.62275 10.9158 5.49 11.25 5.49C11.5842 5.49 11.9047 5.62275 12.141 5.85905C12.3773 6.09534 12.51 6.41583 12.51 6.75C12.51 7.08417 12.3773 7.40466 12.141 7.64095Z"
      fill="#5591C7"
      stroke="white"
      strokeWidth="0.18"
    />
  </Icon>
)

export default GreetingIcon
