import HomeContainerInner from '../../containers/HomeContainer'

const property = {}

const activities = [
  {
    description: 'One of the highlights of our trip was visiting Chelsea Market and walking along the High Line.',
    id: 'JION8hhg7q6zyayHYwhxIw',
    alias: 'the-high-line-new-york',
    name: 'The High Line',
    image_url: 'https://s3-media3.fl.yelpcdn.com/bphoto/A-WMM_ocFEi2Su4_UaZIow/o.jpg',
    is_claimed: true,
    is_closed: false,
    url:
      'https://www.yelp.com/biz/the-high-line-new-york?adjust_creative=oj0judbJDVIHIVps_GJrXQ&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_lookup&utm_source=oj0judbJDVIHIVps_GJrXQ',
    phone: '+12122069922',
    display_phone: '(212) 206-9922',
    review_count: 2075,
    categories: [
      {
        alias: 'parks',
        title: 'Parks',
      },
      {
        alias: 'localflavor',
        title: 'Local Flavor',
      },
    ],
    rating: 4.5,
    location: {
      address1: '820 Washington St',
      address2: '',
      address3: '',
      city: 'New York',
      zip_code: '10014',
      country: 'US',
      state: 'NY',
      display_address: ['820 Washington St, ', 'New York, NY 10014'],
      cross_streets: 'Gansevoort St & Little W 12th St',
    },
    coordinates: {
      latitude: 40.7479831752801,
      longitude: -74.0048906107511,
    },
    photos: [
      'https://s3-media3.fl.yelpcdn.com/bphoto/A-WMM_ocFEi2Su4_UaZIow/o.jpg',
      'https://s3-media2.fl.yelpcdn.com/bphoto/SXyLvh81wcGtQD8HkSIkDA/o.jpg',
      'https://s3-media2.fl.yelpcdn.com/bphoto/Hj_bPzSEdZZL24JVtJq-XQ/o.jpg',
    ],
    hours: [
      {
        open: [
          {
            is_overnight: false,
            start: '0700',
            end: '2200',
            day: 0,
          },
          {
            is_overnight: false,
            start: '0700',
            end: '2200',
            day: 1,
          },
          {
            is_overnight: false,
            start: '0700',
            end: '2200',
            day: 2,
          },
          {
            is_overnight: false,
            start: '0700',
            end: '2200',
            day: 3,
          },
          {
            is_overnight: false,
            start: '0700',
            end: '2200',
            day: 4,
          },
          {
            is_overnight: false,
            start: '0700',
            end: '2200',
            day: 5,
          },
          {
            is_overnight: false,
            start: '0700',
            end: '2200',
            day: 6,
          },
        ],
        hours_type: 'REGULAR',
        is_open_now: true,
      },
    ],
    transactions: [],
  },
  {
    description:
      'People watching is something I do best, strolling on a beautiful evening really helps me release stress.',
    id: 'bJQZKARP-Xn2AEqNNMe9OA',
    alias: 'washington-square-park-new-york',
    name: 'Washington Square Park',
    image_url: 'https://s3-media1.fl.yelpcdn.com/bphoto/zDJPYfHy6Yum2hyexw336g/o.jpg',
    is_claimed: false,
    is_closed: false,
    url:
      'https://www.yelp.com/biz/washington-square-park-new-york?adjust_creative=oj0judbJDVIHIVps_GJrXQ&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_lookup&utm_source=oj0judbJDVIHIVps_GJrXQ',
    phone: '311',
    display_phone: '311',
    review_count: 507,
    categories: [
      {
        alias: 'parks',
        title: 'Parks',
      },
    ],
    rating: 4.5,
    location: {
      address1: '1 Washington Sq E',
      address2: '',
      address3: '',
      city: 'New York',
      zip_code: '10011',
      country: 'US',
      state: 'NY',
      display_address: ['1 Washington Sq E, ', 'New York, NY 10011'],
      cross_streets: '',
    },
    coordinates: {
      latitude: 40.7307873410831,
      longitude: -73.997597694397,
    },
    photos: [
      'https://s3-media1.fl.yelpcdn.com/bphoto/zDJPYfHy6Yum2hyexw336g/o.jpg',
      'https://s3-media3.fl.yelpcdn.com/bphoto/JS-kEkisjTUgHwoDlB0kog/o.jpg',
      'https://s3-media2.fl.yelpcdn.com/bphoto/RNzl1AVdQrIwEYiuKtnCSg/o.jpg',
    ],
    transactions: [],
  },
]

const restaurants = [
  {
    id: 'di8nRIJqHeXR3Sf9qdKPQQ',
    alias: 'mcnultys-tea-and-coffee-co-new-york',
    name: 'McNulty\'s Tea & Coffee Co',
    image_url: 'https://s3-media1.fl.yelpcdn.com/bphoto/g9qrjGXZIz5svwndLJ8R-w/o.jpg',
    is_closed: false,
    url:
      'https://www.yelp.com/biz/mcnultys-tea-and-coffee-co-new-york?adjust_creative=oj0judbJDVIHIVps_GJrXQ&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_search&utm_source=oj0judbJDVIHIVps_GJrXQ',
    review_count: 213,
    categories: [
      {
        alias: 'coffee',
        title: 'Coffee & Tea',
      },
    ],
    rating: 4.5,
    coordinates: {
      latitude: 40.7334023,
      longitude: -74.0049927,
    },
    transactions: [],
    price: '$$',
    location: {
      address1: '109 Christopher St',
      address2: '',
      address3: '',
      city: 'New York',
      zip_code: '10014',
      country: 'US',
      state: 'NY',
      display_address: ['109 Christopher St', 'New York, NY 10014'],
    },
    phone: '+12122425351',
    display_phone: '(212) 242-5351',
    distance: 142.73373211991256,
  },
  {
    id: 'j0n_poMVZnODYOFviLbh0Q',
    alias: 'taim-west-village-new-york',
    name: 'Taim West Village',
    image_url: 'https://s3-media3.fl.yelpcdn.com/bphoto/Z29o01m_KYYQ3HKEa5fppw/o.jpg',
    is_closed: false,
    url:
      'https://www.yelp.com/biz/taim-west-village-new-york?adjust_creative=oj0judbJDVIHIVps_GJrXQ&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_search&utm_source=oj0judbJDVIHIVps_GJrXQ',
    review_count: 1400,
    categories: [
      {
        alias: 'vegetarian',
        title: 'Vegetarian',
      },
      {
        alias: 'falafel',
        title: 'Falafel',
      },
    ],
    rating: 4.5,
    coordinates: {
      latitude: 40.736002376347,
      longitude: -74.0019588366439,
    },
    transactions: ['delivery', 'pickup'],
    price: '$',
    location: {
      address1: '222 Waverly Pl',
      address2: null,
      address3: '',
      city: 'New York',
      zip_code: '10014',
      country: 'US',
      state: 'NY',
      display_address: ['222 Waverly Pl', 'New York, NY 10014'],
    },
    phone: '+12126911287',
    display_phone: '(212) 691-1287',
    distance: 285.9491646785253,
  },
]
const today = new Date()
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000)
const thirdDay = new Date(tomorrow.getTime() + 24 * 60 * 60 * 1000)
export default {
  Component: HomeContainerInner,
  examples: [
    {
      name: 'First day 10 AM',
      props: {
        property,
        activities,
        currentTime: '10',
        currentDate: today,
        startDate: '2018-04-18',
        endDate: '2018-04-20',
        yelp: restaurants,
        hideWelcomeTips: () => console.log('uhh'),
      },
    },
    {
      name: 'Second Day 6 AM',
      props: {
        property,
        activities,
        currentTime: '6',
        currentDate: tomorrow,
        startDate: '2018-04-18',
        endDate: '2018-04-20',
        yelp: restaurants,
        hideWelcomeTips: () => console.log('uhh'),
      },
    },
    {
      name: 'Second Day 10 AM',
      props: {
        property,
        activities,
        currentTime: '10',
        currentDate: tomorrow,
        startDate: '2018-04-18',
        endDate: '2018-04-20',
        yelp: restaurants,
        hideWelcomeTips: () => console.log('uhh'),
      },
    },
    {
      name: 'Second Day 6 PM',
      props: {
        property,
        activities,
        currentTime: '18',
        currentDate: tomorrow,
        startDate: '2018-04-18',
        endDate: '2018-04-20',
        yelp: restaurants,
        hideWelcomeTips: () => console.log('uhh'),
      },
    },
    {
      name: 'Checkout Day 10 AM',
      props: {
        property,
        activities,
        currentTime: '10',
        currentDate: thirdDay,

        startDate: '2018-04-18',
        endDate: '2018-04-20',

        yelp: restaurants,
        hideWelcomeTips: () => console.log('uhh'),
      },
    },
  ],
}
