import { UPDATE_SETTINGS } from '../actions/actionTypes'
import * as PersistentState from '../utils/persistent-state'

let initialState = {
  pin: null,
  temperatureUnit: 'F',
}

try {
  // Retrieve saved state from local storage and apply it on top of the defaults
  // (so any keys in the defaults that are NEW get their default values)
  PersistentState.upgradeSettings()
  const savedState = PersistentState.getSettings()

  initialState = Object.assign(initialState, savedState)
} catch (err) {
  console.log('Saved settings not found.')
}

export function settings(state = initialState, action) {
  // Rather than immediately returning the next state, capture it in a local
  // variable so we can sync it to local storage before returning.
  let nextState = state
  if (action.type === UPDATE_SETTINGS) {
    nextState = {
      ...state,
      ...action.settings,
    }
  }
  if (nextState !== state) {
    PersistentState.setSettings(nextState)
  }
  return nextState
}
