import React from 'react'
import Icon from './Icon'

const DecrementIcon = props => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <rect stroke="#FFF" width={48} height={48} rx={24} />
      <path fill="#FFF" fillRule="nonzero" opacity={0.8} d="M29.754 25.914v-3.562H18.246v3.562z" />
    </g>
  </Icon>
)

export default DecrementIcon
