import React from 'react'

const SvgBan = props => (
  // <svg {...props}>
  //   <path d="M16 0a16 16 0 1016 16A16.019 16.019 0 0016 0zM3 16A12.982 12.982 0 0124.06 5.819L5.819 24.06A12.929 12.929 0 013 16zm13 13a12.931 12.931 0 01-8.06-2.819L26.181 7.94A12.982 12.982 0 0116 29z" />
  // </svg>
  <svg viewBox="0 0 48 48" {...props}>
    <g strokeWidth={4} fill="none" strokeMiterlimit={10}>
      <path data-cap="butt" d="M39.6 8.4L8.5 39.5" />
      <circle strokeLinecap="square" cx={24} cy={24} r={22} />
    </g>
  </svg>
)

export default SvgBan
