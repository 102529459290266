import React from 'react'

import { connectWithPath } from '../resources/utils'
import { TrackLink } from './metrics'
import { formatPhoneNumber } from '../utils/formatters'

import { PhoneIcon } from './icons'
import { customStyles } from '../utils/toast-style-helper'

import '../styles/SendToPhoneButton.scss'
import '../styles/Animate.scss'
import { MESSAGES } from '../constants/messages'

customStyles()

const formatValues = business => {
  business.name = business.name ? business.name : ''
  const { location } = business.content
  location.address1 = location.address1 ? location.address1 : ''
  location.city = location.city ? location.city : ''
  location.state = location.state ? location.state : ''
  location.zip = location.zip ? location.zip : ''
  business.content.location = location
  return business
}

const SendToPhoneButton = ({
  source, orgName, business, section, advertisementId, icon,
}) => {
  business = formatValues(business)
  const { name } = business
  const yelpLink = business.content.url
    ? business.content.url
    : business.content.providerData
      ? `\nhttps://www.yelp.com/biz/${business.content.providerData.id}`
      : ''
  const message = `From ${decodeURIComponent(encodeURIComponent(orgName))}: ${yelpLink}\n${business.name}\n${
    business.content.location.address1
  }, ${business.content.location.city}, ${business.content.location.state} ${business.content.location.zip}\n${
    business.content.phone ? formatPhoneNumber(business.content.phone) : ''
  }`
  return (
    <TrackLink
      className="SendToPhoneButton"
      to={{
        pathname: `/recommendations/${section}/${business.id}/phone`,
        search: `?message=${encodeURIComponent(message)}`,
        state: {
          name,
          advertisementId,
        },
      }}
      name={name}
      source={`${source}-SendToPhoneButton`}
      section={section}
    >
      {icon && (
        <div className="send-sms">
          <svg viewBox="0 0 32 52" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.2 0H4.8C2.15 0 0 2.18359 0 4.875V47.125C0 49.8164 2.15 52 4.8 52H27.2C29.85 52 32 49.8164 32 47.125V4.875C32 2.18359 29.85 0 27.2 0ZM16 48.75C14.23 48.75 12.8 47.2977 12.8 45.5C12.8 43.7023 14.23 42.25 16 42.25C17.77 42.25 19.2 43.7023 19.2 45.5C19.2 47.2977 17.77 48.75 16 48.75ZM27.2 37.7812C27.2 38.4516 26.66 39 26 39H6C5.34 39 4.8 38.4516 4.8 37.7812V6.09375C4.8 5.42344 5.34 4.875 6 4.875H26C26.66 4.875 27.2 5.42344 27.2 6.09375V37.7812Z" />
          </svg>
          <label>{MESSAGES.SEND_TO_PHONE}</label>
        </div>
      )}
      {!icon && (
        <div className="send-to-phone">
          <div className="icons">
            <PhoneIcon size="60" />
            <span className="text" data-cy="phone">
              {MESSAGES.SEND_INFO_TO_PHONE}
            </span>
          </div>
        </div>
      )}
    </TrackLink>
  )
}

export default connectWithPath({
  orgName: 'property.organization.name',
})(SendToPhoneButton)
