import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withRouter } from 'react-router'

import {
  Checkbox,
} from '../components'
import { setHideFooter } from '../actions/device'
import { DetailFooter, PageHeader } from '../components/pages'
import { RequestButton } from '../components/services'
import { MESSAGES } from '../constants/messages'
import Textarea from '../components/Textarea'

const ContactRequestContainer = ({
  setHideFooter, history, location: { state },
}) => {
  const topic = get(state, 'topic', {})
  const urgentEnabled = get(state, 'urgentEnabled', true)
  const [isUrgent, setIsUrgent] = useState(false)
  const [details, setDetails] = useState('')

  const updateDetails = (text) => {
    setDetails(text)
  }

  useEffect(() => {
    setHideFooter({ value: true })
    return () => {
      setHideFooter({ value: false })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    history.goBack()
  }

  return (
    <div className="Card ContactContainer">
      <div className="card-section header border-bottom icons-container-inner">
        <PageHeader title={topic.text}>
          <p>{MESSAGES.ENTER_DETAILS}</p>
        </PageHeader>
      </div>

      <Textarea value={details} autoFocus onChange={updateDetails} />

      {urgentEnabled && (
        <Checkbox
          title="Is this an URGENT request?"
          label="Yes, please respond as soon as possible"
          checked={isUrgent}
          onClick={() => setIsUrgent(!isUrgent)}
        />
      )}

      <DetailFooter className="bottom stretch position-top">
        <RequestButton
          to={{
            pathname: '/contact/phone',
            state: {
              isUrgent,
              topics: [topic],
              requestDetails: details,
            },
          }}
        >
          {MESSAGES.SEND_REQUEST}
        </RequestButton>
        <div onClick={goBack} className="link">
          {MESSAGES.CANCEL}
        </div>
      </DetailFooter>
    </div>
  )
}

export default connect(null, { setHideFooter })(withRouter(ContactRequestContainer))
