import React from 'react'
import { DecrementIcon, IncrementIcon } from '../icons'

const ServiceQuantity = ({ quantity, increment, decrement }) => (
  <div className="card-section padding-y center-vertically ServiceQuantity">
    <div className="service-quantity">Select quantity</div>
    <div className="quantity-selector">
      <DecrementIcon height="50px" width="50px" onClick={decrement} />
      <div className="quantity-total">{quantity}</div>
      <IncrementIcon height="50px" width="50px" onClick={increment} />
    </div>
  </div>
)

export default ServiceQuantity
