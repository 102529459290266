import React from 'react'

const Rain = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2_101)">
      <path d="M26.7184 14.6692C26.8641 14.2549 26.9402 13.8161 26.9402 13.3713C26.9402 11.2191 25.189 9.46785 23.0368 9.46785C22.517 9.46785 22.0063 9.57083 21.5317 9.76947C20.4897 8.0694 18.6129 7 16.596 7C13.4073 7 10.8133 9.59398 10.8133 12.7821C10.8133 12.8113 10.8133 12.84 10.8139 12.8692C8.63366 13.3055 7 15.2457 7 17.5216C7 20.1381 9.12844 22.2665 11.745 22.2665H25.4559C27.6081 22.2665 29.3593 20.5153 29.3593 18.3631C29.3593 16.6697 28.2674 15.1987 26.719 14.6692H26.7184ZM25.4552 21.0771H11.745C9.78471 21.0771 8.18944 19.4824 8.18944 17.5216C8.18944 15.6667 9.63725 14.1098 11.486 13.977L12.1057 13.9325L12.0344 13.3159C12.0137 13.1379 12.0033 12.9582 12.0033 12.7827C12.0033 10.2502 14.0635 8.18944 16.5966 8.18944C18.3625 8.18944 19.9925 9.22167 20.7493 10.8182L21.0357 11.422L21.6024 11.068C22.0326 10.7993 22.5292 10.6573 23.0374 10.6573C24.5339 10.6573 25.7514 11.8748 25.7514 13.3713C25.7514 13.8667 25.6167 14.3511 25.362 14.7728L24.8879 15.557L25.7971 15.671C27.1498 15.8404 28.1705 16.9975 28.1705 18.3631C28.1705 19.8596 26.953 21.0771 25.4565 21.0771H25.4552Z" fill="white" />
      <path d="M13.5614 13.3768C13.233 13.3768 12.9667 13.1105 12.9667 12.7821C12.9667 10.781 14.5949 9.15282 16.596 9.15282C16.9244 9.15282 17.1907 9.4191 17.1907 9.74754C17.1907 10.076 16.9244 10.3423 16.596 10.3423C15.2505 10.3423 14.1561 11.4366 14.1561 12.7821C14.1561 13.1105 13.8899 13.3768 13.5614 13.3768Z" fill="white" />
      <path d="M19.3916 24.788C19.3916 25.4577 18.8487 26 18.1797 26C17.5106 26 16.9677 25.4571 16.9677 24.788C16.9677 24.119 18.1797 22.9673 18.1797 22.9673C18.1797 22.9673 19.3916 24.1184 19.3916 24.788Z" fill="white" />
      <path d="M15.3279 24.788C15.3279 25.4577 14.785 26 14.1159 26C13.4469 26 12.9039 25.4571 12.9039 24.788C12.9039 24.119 14.1159 22.9673 14.1159 22.9673C14.1159 22.9673 15.3279 24.1183 15.3279 24.788Z" fill="white" />
      <path d="M23.4554 24.788C23.4554 25.4577 22.9124 26 22.2434 26C21.5743 26 21.0314 25.4571 21.0314 24.788C21.0314 24.119 22.2434 22.9673 22.2434 22.9673C22.2434 22.9673 23.4554 24.1184 23.4554 24.788Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2_101">
        <rect width="22.3593" height="19" fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
)

export default Rain
