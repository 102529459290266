import React from 'react'
import Icon from './Icon'

const HomeIcon = props => (
  <Icon viewBox="0 0 53 40" fill="none" {...props} width="1em" height="1em">
    <path
      d="M8 38h14v-5.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5V38h14V23a.5.5 0 0 1 .5-.5h5.08a.5.5 0 0 0 .313-.89L26.5 2 2.076 22.114a.5.5 0 0 0 .318.886H7a1 1 0 0 1 1 1v14z"
      stroke="#1AD2E6"
      strokeWidth={3}
    />
  </Icon>
)

export default HomeIcon
