import { Component } from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'

import { detachSerialNumber, attachSerialNumber } from '../actions/device'

class Device extends Component {
  componentDidMount() {
    this.updateDevice()
  }

  componentDidUpdate(prevProps) {
    const { hasProperty } = this.props
    if (hasProperty !== prevProps) {
      this.updateDevice()
    }
  }

  updateDevice = () => {
    const { hasProperty, attachSerialNumber, detachSerialNumber } = this.props
    if (hasProperty) {
      attachSerialNumber()
    } else {
      detachSerialNumber()
    }
  }

  render() {
    return null
  }
}

export default connect(({ property }) => ({ hasProperty: !isEmpty(property) }), {
  attachSerialNumber,
  detachSerialNumber,
})(Device)
