import React from 'react'

// const getServiceChoiceLabel = (serviceChoicesLength = [], redirectToServiceChoices = null) => {
//   if (!serviceChoicesLength) return null

//   const label =
//     serviceChoicesLength && serviceChoicesLength > 1 ? `${serviceChoicesLength} Service Choices` : "One Service Choice"
//   return (
//     <h3 className="mt-0 link-color alignself-start" onClick={redirectToServiceChoices}>
//       {label}
//     </h3>
//   )
// }
const ServiceDescription = ({
  title,
  detailHTML,
  price,
  priceType,
}) => {
  let subtitle = title
  if (price) {
    subtitle = price
    if (priceType) {
      subtitle = `${price} ${priceType}`
    }
  }

  return (
    <div className="card-section stretch ServiceDescription">
      {/* Removed service choices label as discussed after product team */}
      {/* {getServiceChoiceLabel(serviceChoicesLength, redirectToServiceChoices)} */}
      {subtitle && <div className="service-subtitle">{subtitle}</div>}
      <h3 className="mt-0">Description</h3>
      <div
        className="service-details"
        dangerouslySetInnerHTML={{
          __html: detailHTML,
        }}
      />
    </div>
  )
}

export default ServiceDescription
