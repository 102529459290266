import address from './address'
import formatPrice from './currency'
import { formatDuration, formatMinutes } from './duration'
import { formatPhoneNumber, parseNumber } from './phone'
import pluralize from './pluralize'

function formatCCode(cCode) {
  return `C - ${cCode}`
}

function checkoutDuration(additionalCheckoutTime) {
  return formatDuration(additionalCheckoutTime * 60)
}

export {
  address, formatPrice, checkoutDuration, formatPhoneNumber, pluralize, formatCCode, formatMinutes, parseNumber,
}
