import React from 'react'

import { setOverlay } from '../actions/device'

const Textarea = ({
  maxLength, value, onChange, ...props
}) => {
  return (
    <div className="textarea-wrapper">
      <textarea
        value={value}
        onInput={e => {
          if (maxLength >= e.target.value.length) onChange(e.target.value)
        }}
        onFocus={() => setOverlay(true)}
        onBlur={() => setOverlay(false)}
        maxLength={maxLength}
        {...props}
      />
      <span>{`${value.length}/${maxLength} characters`}</span>
    </div>
  )
}

export default Textarea

Textarea.defaultProps = {
  maxLength: 300,
  value: '',
  onChange: () => {},
}
