import React from 'react'

import SettingsSection from './SettingsSection'
import BlueButton from '../BlueButton'
import { Mixpanel } from '../../api/mixpanel'

const AdminSettingsSection = () => {
  const onAdminAccess = () => {
    // Mixpanel.track('Click Admin Access', {
    //   name: 'Admin Access',
    //   source: 'Settings Container',
    // })
  }
  return (
    <SettingsSection title="Admin Settings" subtitle="Change property ID, time zone and more">
      <BlueButton
        to={{
          pathname: '/passcode-screen-simple',
          state: {
            next: '/admin-settings',
          },
        }}
        label="Access Admin Settings"
        onClick={onAdminAccess}
      />
    </SettingsSection>
  )
}

export default AdminSettingsSection
