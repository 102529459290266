import PlusMinusToggle from '../../components/PlusMinusToggle'
import { bindOnChangeToValue } from '../helpers'

export default {
  Component: bindOnChangeToValue(PlusMinusToggle),
  examples: [
    {
      name: 'Basic Use',
      props: {
        value: 1,
        min: 0,
        max: 10,
        units: {
          singular: 'night',
          plural: 'nights',
        },
        onChange: () => {},
      },
    },
  ],
}
