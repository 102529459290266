/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux'
import { get } from 'lodash'

export function useCustomization(path, state) {
  const customizationsPath = 'property.customizations'
  const customizations = state ? get(state, customizationsPath) : useSelector(state => state.property.customizations) // TODO: Use path so one source of truth
  const customization = get(customizations, path)
  return customization
}
