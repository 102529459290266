import React from 'react'

import {
  WifiSection,
  SettingsFooter,
  RefreshSection,
  AdminSettingsSection,
  BrightnessSection,
  VolumeSection,
} from '../components/settings'
import { MESSAGES } from '../constants/messages'

import '../styles/SettingsContainer.scss'

const SettingsPublicContainer = () => {
  return (
    <div className="SettingsContainer Win10Theme">
      <h1>{MESSAGES.GVG_SETTINGS}</h1>

      <BrightnessSection />
      <VolumeSection />
      <RefreshSection />
      <WifiSection />
      <AdminSettingsSection />

      <SettingsFooter label="Done" to="/home" />
    </div>
  )
}

export default SettingsPublicContainer
