import React from 'react'
import Icon from './Icon'

const WelcomeVideoIcon = props => (
  <Icon width="88" height="88" viewBox="0 0 88 88" fill="none" {...props}>
    <path
      d="M39.3594 25.4375C38.6719 24.9792 37.9271 24.75 37.125 24.75C34.6042 24.9792 33.2292 26.3542 33 28.875V59.125C33.2292 61.6458 34.6042 63.0208 37.125 63.25C37.9271 63.25 38.6719 63.0208 39.3594 62.5625L64.1094 47.4375C65.3698 46.6354 66 45.4896 66 44C66 42.5104 65.3698 41.3646 64.1094 40.5625L39.3594 25.4375ZM62.5625 45.2031L37.9844 60.1562C37.6406 60.3854 37.3542 60.5 37.125 60.5C36.2083 60.3854 35.75 59.9271 35.75 59.125V28.875C35.75 28.0729 36.2083 27.6146 37.125 27.5C37.3542 27.5 37.5833 27.5573 37.8125 27.6719L62.5625 42.7969C63.0208 43.1406 63.25 43.5417 63.25 44C63.25 44.4583 63.0208 44.8594 62.5625 45.2031ZM44 0C35.75 0.114583 28.3594 2.11979 21.8281 6.01562C15.1823 9.91146 9.91146 15.1823 6.01562 21.8281C2.11979 28.3594 0.114583 35.75 0 44C0.114583 52.25 2.11979 59.6406 6.01562 66.1719C9.91146 72.8177 15.1823 78.0885 21.8281 81.9844C28.3594 85.8802 35.75 87.8854 44 88C52.25 87.8854 59.6406 85.8802 66.1719 81.9844C72.8177 78.0885 78.0885 72.8177 81.9844 66.1719C85.8802 59.6406 87.8854 52.25 88 44C87.8854 35.75 85.8802 28.3594 81.9844 21.8281C78.0885 15.1823 72.8177 9.91146 66.1719 6.01562C59.6406 2.11979 52.25 0.114583 44 0ZM44 85.25C36.3229 85.1354 29.3906 83.2448 23.2031 79.5781C17.0156 75.9115 12.0885 70.9844 8.42188 64.7969C4.75521 58.6094 2.86458 51.6771 2.75 44C2.86458 36.3229 4.75521 29.3906 8.42188 23.2031C12.0885 17.0156 17.0156 12.0885 23.2031 8.42188C29.3906 4.75521 36.3229 2.86458 44 2.75C51.6771 2.86458 58.6094 4.75521 64.7969 8.42188C70.9844 12.0885 75.9115 17.0156 79.5781 23.2031C83.2448 29.3906 85.1354 36.3229 85.25 44C85.1354 51.6771 83.2448 58.6094 79.5781 64.7969C75.9115 70.9844 70.9844 75.9115 64.7969 79.5781C58.6094 83.2448 51.6771 85.1354 44 85.25Z"
      fill="white"
    />
  </Icon>
)

export default WelcomeVideoIcon
