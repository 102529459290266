import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import '../styles/PasscodeScreen.scss'
import { Grid, Button } from 'semantic-ui-react'
import { updateSettingsPasscode } from '../actions/passcode'
import saveIcon from '../images/onboarding/save.png'
import okIcon from '../images/onboarding/ok.png'
import * as PersistentStorage from '../utils/persistent-state'
import { MESSAGES } from '../constants/messages'

const nos = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', '0', 'Clear']
class PasscodeContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passcodeEntered: [],
      passcodeValid: undefined,
    }
  }

  // renderButtons() {
  //   const components = []
  //   nos.forEach((index, no) => {
  //     components.push(<div key={index}>{no}</div>)
  //   })
  //   return components
  // }

  refreshSite = () => {
    this.props.history.go(0)
  }

  navToAdminSetting = () => {
    this.props.history.replace('/admin-passcode-screen')
  }

  setCode = () => {
    this.props.dispatch(updateSettingsPasscode(this.state.passcodeEntered))
    // PersistentStorage.setPasscode(this.state.passcodeEntered.join(''))
    // PersistentStorage.setSetPasscode(true)
    // after setting passcode, PM needs to pair this device with property
    this.props.history.push(`/device-pairing?passocode=${this.state.passcodeEntered.join('')}`)
  }

  enterDigit = no => {
    if (this.state.passcodeEntered.length !== 6) {
      this.setState({ passcodeEntered: [...this.state.passcodeEntered, no.toString()] }, () => {
        if (this.state.passcodeEntered.length === 6 && PersistentStorage.hasSetPasscode()) {
          this.validateCode()
        }
      })
    }
  }

  validateCode() {
    const passcode = PersistentStorage.getPasscode()
    const passcodeEntered = this.state.passcodeEntered.join('')
    if (this.props.match.path === '/admin-passcode-screen') {
      if (passcodeEntered === '266334') {
        this.setState({ passcodeValid: true })
      } else {
        this.setState({ passcodeValid: false })
      }
    } else {
      if (passcode === passcodeEntered || passcodeEntered === '266334') {
        this.setState({ passcodeValid: true })
      } else {
        this.setState({ passcodeValid: false })
      }
    }
  }

  render() {
    if (!PersistentStorage.hasSetPasscode()) {
      return (
        <div className="SettingsContainer Win10Theme PasscodeScreen">
          <div className="passcode-title">
            <h1>{MESSAGES.ENTER_PASSCODE}</h1>
            <div className="passcode-subtitle">{MESSAGES.THIS_WILL_ALLOW_TO_ACCESS_SETTINGS}</div>
          </div>
          <div className="passcode-items">
            {_.times(6, index => {
              return (
                <div key={index} className="passcode-item">
                  {' '}
                  {this.state.passcodeEntered[index] ? this.state.passcodeEntered[index] : ''}
                </div>
              )
            })}
          </div>
          <br />
          <br />
          <br />
          <br />
          <Grid className="number-pad" centered columns={3}>
            {nos.map((no, index) => {
              return (
                <Grid.Column key={index} className="number-pad-item">
                  <div
                    onClick={
                      no === ''
                        ? () => {}
                        : no !== 'Clear'
                          ? () => this.enterDigit(no)
                          : () => this.setState({ passcodeEntered: [], passcodeValid: undefined })
                    }
                  >
                    {no}
                  </div>
                </Grid.Column>
              )
            })}
          </Grid>
          <div className="buttons-container">
            <img
              onClick={() => {
                if (this.state.passcodeEntered.length === 6) {
                  this.setCode()
                }
              }}
              className="hide-when-editing"
              alt="next"
              src={saveIcon}
              style={{
                width: 96,
                height: 96,
                opacity: this.state.passcodeEntered.length === 6 ? 1.0 : 0.5,
              }}
            />
          </div>
        </div>
      )
    }
    return (
      <div className="SettingsContainer Win10Theme PasscodeScreen">
        <h1>{this.props.match.path === '/admin-passcode-screen' ? 'Advanced Settings' : 'Enter your Passcode'}</h1>
        <div className="passcode-subtitle">
          {this.props.match.path === '/admin-passcode-screen'
            ? 'Please contact your Display Administrator to access advanced settings.'
            : 'This will allow you to access settings on the GuestView Guide'}
        </div>
        <div className="passcode-items">
          {_.times(6, index => {
            return (
              <div
                key={index}
                className={`passcode-item ${this.state.passcodeValid && 'valid'} ${this.state.passcodeValid
                    === false && 'invalid'}`}
              >
                {' '}
                {this.state.passcodeEntered[index] ? this.state.passcodeEntered[index] : ''}
              </div>
            )
          })}
        </div>
        <br />
        <br />
        <br />
        <br />
        <Grid className="number-pad" centered columns={3}>
          {nos.map((no, index) => {
            return (
              <Grid.Column key={index} className="number-pad-item">
                <div
                  onClick={
                      no === ''
                        ? () => {}
                        : no !== 'Clear'
                          ? () => this.enterDigit(no)
                          : () => this.setState({ passcodeEntered: [], passcodeValid: undefined })
                    }
                >
                  {no}
                </div>
              </Grid.Column>
            )
          })}
        </Grid>
        <div className="buttons-container">
          <img
            onClick={() => {
              if (
                this.state.passcodeEntered.length === 6
                  && this.state.passcodeValid
                  && this.props.match.path === '/passcode-screen'
              ) {
                this.props.history.replace('/admin-settings')
              } else if (this.state.passcodeValid && this.state.passcodeEntered.length === 6) {
                this.props.history.replace('/settings')
              }
            }}
            className="hide-when-editing"
            alt="next"
            src={okIcon}
            style={{
              width: 96,
              height: 96,
              opacity: this.state.passcodeEntered.length === 6 && this.state.passcodeValid ? 1.0 : 0.5,
            }}
          />
        </div>

        <Link to="/home">{MESSAGES.BACK}</Link>

        {this.props.match.path === '/passcode-screen' && (
        <Button
          style={{
            width: '10%',
            color: 'darkgray',
            backgroundColor: 'rgb(66, 64, 64)',
            position: 'absolute',
            bottom: '5%',
            display: 'none',
          }}
          icon="setting"
          onClick={this.navToAdminSetting}
        />
        )}
        {this.props.match.path === '/passcode-screen-hide-for-now' && (
        <Button
          style={{
            color: 'darkgray',
            backgroundColor: 'rgb(66, 64, 64)',
            position: 'absolute',
            bottom: '5%',
            right: '5%',
          }}
          value="Refresh Site Data"
          onClick={this.refreshSite}
        >
          {MESSAGES.REFRESH}
        </Button>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    passcode: state.passcode ? state.passcode : [],
  }
}

export default withRouter(connect(mapStateToProps)(PasscodeContainer))
