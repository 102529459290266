import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import MediaDisplay from '../MediaDisplay'

const IMAGE_SHIFT_INTERVAL = 5 * 1000
// const IMAGE_SHIFT_INTERVAL = null

const CarouselPreview = ({ medias, setFullScreen, slide = true }) => {
  const [swipeEnabled, setswipeEnabled] = useState(true)
  const [videoActive, setVideoActive] = useState(false)
  const [index, setIndex] = useState(0)

  const setFullScreenHandler = isFullScreen => {
    setswipeEnabled(!isFullScreen)
    setFullScreen(isFullScreen)
  }

  const handleSelect = selectedIndex => {
    medias[index].mediaType === 'VIDEO' ? setVideoActive(true) : setVideoActive(false)
    setIndex(selectedIndex)
  }

  const MediaRerenderHandler = () => {
    setVideoActive(false)
  }

  return (
    <Carousel
      slide={slide}
      className={`ImagePreviewCarousel ${medias && medias.length === 1 && 'carousel-indicators-none'}`}
      controls={false}
      touch={!!swipeEnabled}
      interval={swipeEnabled ? IMAGE_SHIFT_INTERVAL : null}
      activeIndex={index}
      onSelect={handleSelect}
      onSlid={MediaRerenderHandler}
    >
      {medias
        && medias.map(media => (
          <Carousel.Item key={`imagePreview${media.mediaOrder}`}>
            <MediaDisplay
              item={media}
              setFullScreen={setFullScreenHandler}
              isVideo={videoActive}
              className="d-block w-100 ratio4-2"
            />
          </Carousel.Item>
        ))}
    </Carousel>
  )
}

export default CarouselPreview
