import React from 'react'
import '../styles/CodeInputOutputScreen.scss'

const CodeInputOutputScreen = ({
  title, subtitle, inputOutputComponent, moreOptionsComponent, backComponent,
}) => (
  <div className="SettingsContainer Win10Theme CodeInputOutputScreen">
    <div className="title-container">
      <div className="title">
        {title && <h1>{title}</h1>}
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
    </div>
    <div className="input-output-container">{inputOutputComponent}</div>
    {moreOptionsComponent && <div className="more-options-container">{moreOptionsComponent}</div>}
    <div className="back-buttons-container">{backComponent}</div>
  </div>
)

export default CodeInputOutputScreen
