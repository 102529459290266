import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import moment from 'moment'
import { CheckInOutWizard } from '../components/checkInOut'
import { makeAPIRequest } from '../utils/helpers'
import { transformFeedbackValues } from '../utils/feedback'
import { Mixpanel } from '../api/mixpanel'
import headerBackground from '../images/checkout-header.png'
import { getDeviceType } from '../utils/persistent-state'
import { useCustomization } from '../hooks/useCustomization'

const CHECKOUT_STEPS = (checkoutNow, removeSurveysFromCheckout) => [
  {
    title: 'Checkout Instructions',
    listPath: 'checkoutChecklist.checklist',
    nextLabel: checkoutNow ? 'Next' : 'Check Out Now',
    backLabel: 'Check Out Later',
    agreeMessage: 'I\'ve reviewed and completed items on the checkout list',
  },
  {
    title: 'How Are We Doing?',
    topic: 'checkout',
    question: 'Were you satisfied with your stay?',
    keywords: {
      question: 'Which of these words best describe your stay?',
      answers: {
        positive: ['Comfortable', 'Enjoyable', 'Great value', 'Welcoming', 'Would return'],
        negative: ['Uncomfortable', 'Too expensive', 'Poor service', 'Messy', 'Would not return'],
      },
    },
    removeFromCheckOut: removeSurveysFromCheckout,
  },
  {
    title: 'How Are We Doing?',
    topic: 'device',
    question: 'Was the GuestView Guide Display helpful?',
    keywords: {
      question: 'Which of these words best describe your experience?',
      answers: {
        positive: ['Helpful', 'Convenient', 'Easy to Use', 'Pleasant', 'Would use again'],
        negative: ['Not helpful', 'Inconvenient', 'Difficult to use', 'Intrusive', 'Unattractive'],
      },
    },
    removeFromCheckOut: removeSurveysFromCheckout,
  },
]

const CheckOutWizardContainer = ({ history, location: { state }, property }) => {
  const removeSurveysFromCheckout = useCustomization('checkoutSurvey.removeFromCheckOut')
  const checkoutNow = get(state, 'checkoutNow')
  const includeConfirmation = checkoutNow ? useCustomization('checkoutChecklist.includeConfirmation') === undefined ? true : useCustomization('checkoutChecklist.includeConfirmation') : true
  const STEPS = CHECKOUT_STEPS(checkoutNow, removeSurveysFromCheckout)

  const quitAction = () => {
    history.goBack()
  }

  const onCheckOutBtn = () => {
    Mixpanel.track('Click Check Out', {
      name: 'Check Out',
      source: 'Checkout Button',
    })
  }

  const finishAction = values => {
    const reservationId = get(property, 'reservation.id', null)
    // makeAPIRequest('reservation/final-checkout', { method: 'POST' })
    localStorage.setItem('hasCheckedOut', reservationId)
    localStorage.setItem('checkedoutTime', moment().format())
    localStorage.removeItem('reservationServices')
    onCheckOutBtn()
    return makeAPIRequest('reservation/checkout', {
      method: 'POST',
      data: {
        reservationId,
        feedbacks: transformFeedbackValues(STEPS, values),
        demoMode: getDeviceType() === 'demo',
      },
    })
  }

  const continueAction = () => {
    history.push('/home', { justCheckedOut: true })
  }

  return (
    <div className="onboarding-fullscreen">
      <div className="onboarding-content">
        <CheckInOutWizard
          steps={STEPS}
          quitAction={quitAction}
          finishAction={finishAction}
          continueAction={continueAction}
          headerBackground={headerBackground}
          history={history}
          property={property}
          includeConfirmation={includeConfirmation}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  property: state.property,
})

export default withRouter(connect(mapStateToProps)(CheckOutWizardContainer))
