import React from 'react'
import { Card } from 'semantic-ui-react'
import { PhoneReviewIcon, DialIcon } from '../components/icons'
import TimedLink from '../components/TimedLink'
import { withPropsFromLocation } from '../components/utils'
import { MESSAGES } from '../constants/messages'
import '../styles/SendToPhoneContainer.scss'

const getReviewIcon = type => {
  switch (type) {
    case 'CONNECT_WITH_HOST':
      return <DialIcon width="120px" height="120px" />
    default:
      return <PhoneReviewIcon width="291px" height="300px" />
  }
}
const SendToPhoneResponse = ({
  history,
  match,
  messageOnResponse = null,
  backLink = null,
  type = '',
  showApprovalText = true,
}) => {
  const response = messageOnResponse || 'Your request has been submitted. Please review the requested information on your mobile.'
  const { section, id } = match.params
  const redirectLink = backLink || `/${section}/${id}`
  return (
    <Card className="SendToPhoneContainer response">
      <div className="response-wrapper">
        {getReviewIcon(type)}
        <h3 className="response-text">{response}</h3>
        {showApprovalText && (
          <h3 className="approval-text">
            <span
              style={{
                color: '#5cd5e7',
              }}
            >
              *
            </span>
            {' '}
            {MESSAGES.SUBJECT_TO_APPROVAL}
          </h3>
        )}
      </div>
      <TimedLink label="Finish" action={() => history.push(redirectLink)} />
    </Card>
  )
}

export default withPropsFromLocation(['messageOnResponse', 'backLink', 'type', 'showApprovalText'])(SendToPhoneResponse)
