import React from 'react'
import '../../styles/Card.scss'

const AboutUsHeader = () => {
  return (
    <div className="">
      <h2 className="title">About GuestView Guide</h2>
    </div>
  )
}

export default AboutUsHeader
