import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { SendToPhoneContainer } from '../../containers'
import { withPropsFromLocation } from '../utils'
import { setOverlay } from '../../actions/device'
import { sendMessageDetailsRequestOnSMS } from '../../actions/property'

const MessageSendToPhone = ({
  history, setOverlay, sendMessageDetailsRequestOnSMS, ...props
}) => {
  const { state } = props.location

  useEffect(() => {
    setOverlay({ value: true })
    return () => setOverlay({ value: false })
  }, [])

  const onSend = async (phone) => {
    await sendMessageDetailsRequestOnSMS({
      phone, messageId: state.messageId, messageTitle: state.messageTitle, externalLink: state.externalLink,
    })
    history.push('/phone/success')
  }

  return (
    <SendToPhoneContainer
      {...props}
      name={state.messageTitle}
      meta="Standard text messaging rates may apply."
      title={state.messageTitle}
      description="Enter your number to receive this announcement on your phone"
      onSend={onSend}
    />
  )
}

export default connect(null, { setOverlay, sendMessageDetailsRequestOnSMS })(
  withPropsFromLocation([''])(MessageSendToPhone),
)
