import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setOverlay } from '../actions/device'
import MessagesHistory from '../components/messages/MessagesHistory'
import { fetchMessageHistory, markMessageAsRead } from '../actions/property'
import { CloseIcon } from '../components/icons'
import MessageItem from '../components/messages/MessageItem'
import { ANALYTICS_ENTITY_TYPES, MESSAGE_STATUS } from '../constants/appConstants'
import { metrics } from '../api'

const MessagesHistoryListingContainer = ({
  messagesHistory,
  property,
  setOverlay,
  fetchMessageHistory,
  history,
  markMessageAsRead,
}) => {
  const { reservation = null, customizations } = property
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [fullScreenMode, setFullScreenMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fetchHistory = useCallback(async () => {
    await fetchMessageHistory(reservation, customizations.checkInOut)
    setIsLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizations])

  useEffect(() => {
    setIsLoading(true)
    fetchHistory()

    // return () => {
    //   setFullScreenMode(false)
    // }
  }, [fetchHistory])

  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])

  const sendBack = () => {
    history.goBack()
  }

  const closeMessageView = async () => {
    const { title, category, datetime } = selectedMessage.content
    const {
      occurrence, messageId, reservationId, status, id,
    } = selectedMessage

    if (status === MESSAGE_STATUS.UNREAD) {
      metrics.track('click', {
        extra: {
          source: 'Read Announcement',
          name: 'Read Announcement',
          announcement_name: title,
        },
        entityId: messageId,
        entityType: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
        referer: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
        status: MESSAGE_STATUS.READ,
        title,
        occurrence,
        category,
        analytics: false,
      })

      const data = {
        title,
        category,
        status: MESSAGE_STATUS.READ,
        type: datetime.type,
        reservationId,
        occurrence,
        historyId: id,
      }
      await markMessageAsRead(messageId, data)
      await fetchHistory()
    }

    setSelectedMessage(null)
  }

  return (
    <>
      <motion.div className="Card full-height msg-history closeIcon-parent">
        <div onClick={sendBack} className="close-icon-aboutus">
          <CloseIcon />
        </div>
        <MessagesHistory isLoading={isLoading} messagesHistory={messagesHistory} setSelectedMessage={setSelectedMessage} />
      </motion.div>
      {selectedMessage && (
        <div
          className={`message-view-outer is-history interactive ${
            selectedMessage.content?.content?.length > 300 ? 'text-extend' : ''
          }`}
        >
          <div className="message-backdrop" />
          <div
            className={`message-outer interactive 
         ${fullScreenMode ? 'full-screen-mode' : ''}
        `}>
            <MessageItem
              key={selectedMessage.messageId}
              title={selectedMessage.content.title}
              id={selectedMessage.messageId}
              content={selectedMessage.content.content}
              category={selectedMessage.content.category}
              media={selectedMessage.content.media || []}
              qrCode={selectedMessage.content.qrCode}
              externalLink={selectedMessage.content.externalLink}
              onGotIt={closeMessageView}
              setFullScreenMode={setFullScreenMode}
            />
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  const { messagesHistory = [] } = state.messages
  const { property = {} } = state
  return {
    messagesHistory,
    property,
  }
}

export default connect(
  mapStateToProps,

  {
    setOverlay,
    fetchMessageHistory,
    markMessageAsRead,
  },
)(MessagesHistoryListingContainer)
