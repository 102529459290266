const PASSCODE_SET_KEY = 'passcode-set'
const PASSCODE_KEY = 'passcode'
const PAIRED_PROPERTY_KEY = 'property'
const LOCAL_STORAGE_KEY = 'settings-v2'
const OLD_LOCAL_STORAGE_KEY = 'settings-v1'
const TOKEN_KEY = 'token'
const VERSION = 'version'
const DEVICE_TYPE = 'deviceType'
const PROPERTY_CONFIRMED = 'propertyConfirmed'

// Token

export function getToken() {
  return localStorage.getItem(TOKEN_KEY)
}

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token)
  localStorage.removeItem(OLD_LOCAL_STORAGE_KEY)
}
export function clearToken() {
  localStorage.removeItem(TOKEN_KEY)
}

// Passcode

export function getPasscode() {
  return localStorage.getItem(PASSCODE_KEY)
}

export function setPasscode(passcode) {
  localStorage.setItem(PASSCODE_KEY, passcode)
}

export function clearPasscode() {
  localStorage.removeItem(PASSCODE_KEY)
  // localStorage.removeItem(PASSCODE_SET_KEY)
}

// Passcode Set (true/false)

export function hasSetPasscode() {
  return localStorage.getItem(PASSCODE_KEY)
}

// export function setSetPasscode(val) {
//   if (val === true) {
//     localStorage.setItem(PASSCODE_SET_KEY, 'true')
//   } else {
//     localStorage.removeItem(PASSCODE_SET_KEY)
//   }
// }

// Saved Property Info

export function getProperty() {
  try {
    return JSON.parse(localStorage.getItem(PAIRED_PROPERTY_KEY) || '{}')
  } catch (err) {
    console.log('no saved property')
  }
  return {}
}

export function setProperty(property) {
  localStorage.setItem(PAIRED_PROPERTY_KEY, JSON.stringify(property))
}

export function clearProperty() {
  localStorage.removeItem(PAIRED_PROPERTY_KEY)
}

// Device Type

export function setDeviceType(type) {
  return localStorage.setItem(DEVICE_TYPE, type)
}

export function getDeviceType() {
  return localStorage.getItem(DEVICE_TYPE)
}

export function setPropertyConfirmed(confirmation) {
  return localStorage.setItem(PROPERTY_CONFIRMED, confirmation)
}

export function getPropertyConfirmed() {
  return localStorage.getItem(PROPERTY_CONFIRMED)
}

// Settings

export function getSettings() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')
}

export function setSettings(settings) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settings))
}

export function upgradeSettings() {
  const old = localStorage.getItem(OLD_LOCAL_STORAGE_KEY)
  if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
    localStorage.setItem(LOCAL_STORAGE_KEY, old)
  }
}

export function clearSettings() {
  localStorage.removeItem(LOCAL_STORAGE_KEY)
}

export function clearAll() {
  localStorage.clear()
}

export function clearVersion() {
  localStorage.removeItem(VERSION)
}

export function isGrandfathered() {
  return 'settings-v1' in localStorage
}
