import { style } from 'react-toastify'

export function customStyles() {
  style({
    width: '100vw',
    colorDefault: '#09C0FF', // dodger blue
    colorProgressDefault: '#09C0FF', // dodger blue
    mobile: 'only screen and (max-width : 480px)',
    padding: '0',
    fontFamily: 'Open Sans',
    TOP_CENTER: {
      fontSize: '24px',
      letterSpacing: '0.05em',
      marginLeft: '-785px',
      padding: '0',
      position: 'absolute',
      textAlign: 'center',
      top: '-93',
    },
    BOTTOM_CENTER: {
      fontSize: '24px',
      letterSpacing: '0.05em',
      marginLeft: '-85px',
      padding: '0',
      textAlign: 'center',
      top: '0',
      marginBottom: '0',
    },
  })
}
