import { useEffect, useState } from 'react'

export const useCountdownTimer = startingSeconds => {
  const [seconds, setSeconds] = useState(startingSeconds)

  useEffect(() => {
    const timer = setInterval(() => setSeconds(seconds - 1), 1000)
    if (seconds < 0) {
      setSeconds(startingSeconds)
    }
    return function cleanup() {
      clearInterval(timer)
    }
  }, [seconds, startingSeconds])

  return seconds
}
