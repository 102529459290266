import * as React from 'react'
import Icon from './Icon'

const SvgServiceIcon = props => (
  <Icon viewBox="0 0 36 28" width="1em" height="1em" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2a2 2 0 012-2h8a2 2 0 110 4h-3v2h1c7.732 0 14 6.268 14 14v2H2v-2C2 12.268 8.268 6 16 6h1V4h-3a2 2 0 01-2-2zM2 24a2 2 0 100 4h32a2 2 0 100-4H2z"
      fill="currentColor"
    />
  </Icon>
)

export default SvgServiceIcon
