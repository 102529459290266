import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { get } from 'lodash'
import moment from 'moment'
import { connect, useSelector } from 'react-redux'
import { useQueryParam, NumberParam } from 'use-query-params'
import { InfoCard } from '../components/information'
import Map from '../components/Map'
import { PageHeader } from '../components/pages'
import SendToPhoneButton from '../components/SendToPhoneButton'
import TravelTimeEstimate from '../components/TravelTimeEstimate'
import { connectWithPath } from '../resources/utils'
import { MenuIcon, RecommendedBadgeIcon } from '../components/icons'
import yelpImageForRating from '../components/pages/YelpRating'
import PhoneNumber from '../components/PhoneNumber'
import yelpLogo from '../images/yelp-logo.png'
import { useCustomization } from '../hooks/useCustomization'
import Mapfscreen from '../images/mapfscreen.jpg'
import { setOverlay } from '../actions/device'
import '../styles/RecommendationContainer.scss'
import { Mixpanel } from '../api/mixpanel'
import QR from '../components/QRCode'
import { MESSAGES } from '../constants/messages'

const SECTION_TITLES = {
  restaurants: 'Restaurants',
  activities: 'Activities',
  directory: 'Directory',
}

const GoToMap = connectWithPath({
  isOnline: 'networkStatus.isOnline',
})(({ onClickIfOnline, isOnline, ...props }) => {
  const onClick = isOnline ? onClickIfOnline : null
  return <div className="map" onClick={onClick} {...props} />
})

const generateHoursComponent = ({ hours }) => {
  if (!hours || hours.length === 0) {
    return <span />
  }
  const todayIndex = moment(Date.now()).day()
  const todaysInfo = hours[0].open.filter(day => day.day === todayIndex)
  if (!todaysInfo[0]) {
    return <span className="hours">{MESSAGES.CLOSED_TODAY.toUpperCase()}</span>
  }

  const hourRanges = todaysInfo.map((info, i) => {
    const formattedStart = moment(info.start, 'HH:mm').format('LT')
    const formattedEnd = moment(info.end, 'HH:mm').format('LT')
    return <span key={info.end}>{`${formattedStart} -  ${formattedEnd}${i !== todaysInfo.length - 1 ? ', ' : ''}`}</span>
  })

  return (
    <span className="hours">
      Hours:
      {' '}
      {hourRanges}
    </span>
  )
}

const RecommendationMeta = ({ recommendation }) => {
  const phone = get(recommendation, 'phone')
  const providerData = get(recommendation, 'providerData')
  const rating = get(providerData, 'rating')
  const hours = get(providerData, 'hours')
  const review_count = get(providerData, 'review_count')
  return (
    <div className="RecommendationMeta">
      {rating && (
        <div className="rating-reviews">
          <div className="rating">{yelpImageForRating(rating)}</div>
          <div className="review-count">
            {' '}
            {review_count > 1 ? `${review_count} reviews` : `${review_count} review`}
          </div>
          <img className="yelp-logo" alt="yelp-logo" src={yelpLogo} />
        </div>
      )}
      <div className="hours-phone">
        {hours
          && generateHoursComponent({
            hours,
          })}
        {phone && <PhoneNumber>{phone}</PhoneNumber>}
      </div>
    </div>
  )
}

const RecommendationContainer = ({
  match: { params }, history, location, setOverlay,
}) => {
  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])
  const { section, id } = params
  const recommendations = useCustomization(section)
  const recommendation = recommendations.find(
    recommendation => recommendation.id === parseInt(id, 10) || recommendation.id === id,
  )

  const recommendationContent = get(recommendation, 'content')
  const name = get(recommendation, 'name')
  const isRecommended = get(recommendationContent, 'isRecommended')
  const image = get(recommendationContent, 'imageURL')
  const recommendationQuote = get(recommendationContent, 'hostQuote')
  const address = get(recommendationContent, 'location.displayAddress')

  const price = get(recommendationContent, 'providerData.price', '')
  const priceString = price ? `${price} • ` : ''
  const category = get(recommendationContent, 'category', '')
  const priceAndCategory = `${priceString} ${category}`

  const latitude = get(recommendationContent, 'location.coordinates.latitude')
  const longitude = get(recommendationContent, 'location.coordinates.longitude')

  const recommendationPosition = {
    lat: latitude,
    lng: longitude,
  }

  const homePosition = {
    lat: useSelector(state => state.property.latitude),
    lng: useSelector(state => state.property.longitude),
  }

  const [advertisementId] = useQueryParam('advertisementId', NumberParam)

  // const onSetMap = mapEl => {
  //   if (!this._mapEl && mapEl) {
  //     const initiallySelected = mapEl.props.markers
  //     if (initiallySelected) {
  //       mapEl.showInfoPanel(initiallySelected[0])
  //     }
  //   }
  //   this._mapEl = mapEl
  // }

  const buildInfoContent = () => {
    const marker = {
      position: {
        ...recommendationPosition,
      },
    }
    return (
      <div className="place-overlay">
        <TravelTimeEstimate origin={homePosition} destination={marker.position} />
      </div>
    )
  }

  const getUrlParameter = params => {
    // func added to reset advertisment route values
    params = params.replace(/[\?&]advertisementId=[^&]+/, '').replace(/^&/, '?')
    return params
  }

  const onBackButton = () => {
    Mixpanel.track(`Click All ${SECTION_TITLES[section]}`, {
      name: `All ${SECTION_TITLES[section]}`,
      source: 'RecommendationsContainer',
    })
  }
  const getRecommendationUrl = recommendation => {
    if (recommendation.content.url) {
      return recommendation.content.url
    }
    return null
  }

  return (
    <div className="Card full-height RecommendationContainer">
      <div className="card-section header">
        <PageHeader>
          <div className="title-container">
            <h1 className="title" data-cy="recommendation-name">
              {name}
            </h1>
            {isRecommended && <RecommendedBadgeIcon size={60} />}
          </div>
          <h4 className="subtitle" data-cy="priceAndCategory">
            {priceAndCategory}
          </h4>
        </PageHeader>
      </div>
      <div className="card-section stretch scroll">
        <div
          className={classNames('inner-card-section no-padding-x info-card-and-map', {
            large: !recommendationQuote,
          })}
        >
          <InfoCard background={image} meta={<RecommendationMeta recommendation={recommendationContent} />} />
          <div className="map-container">
            <GoToMap onClickIfOnline={() => history.push(`/map/${recommendation.id}`)}>
              <div id="fullscreeen" className="fullscreenmap">
                <img src={Mapfscreen} alt="full screen " />
              </div>
              <Map
                showDirections
                markerInfoContentBuilder={buildInfoContent}
                home={{
                  title: 'Home',
                  position: homePosition,
                }}
                markers={[
                  {
                    position: {
                      ...recommendationPosition,
                    },
                  },
                ]}
              />
              <div className="map-detail">
                <TravelTimeEstimate origin={homePosition} destination={recommendationPosition} hideDuration />
                {' '}
                <div className="address">{address}</div>
              </div>
            </GoToMap>
          </div>
        </div>
        <div className="qr-column-grid">
          {recommendationQuote && (
            <div className="short-description">
              <h4>Description</h4>
              <div className="short-description-scroller">
                <p>{recommendationQuote}</p>
              </div>
            </div>
          )}

          <div
            className={classNames(
              'sms-content inner-card-section no-padding-x',
              {
                stretch: !recommendationQuote,
              },
              // { 'border-bottom': recommendationQuote },
              {
                'direction-row': !recommendationQuote,
              },
            )}
          >
            {recommendation && (
              <SendToPhoneButton
                icon
                section={section}
                business={recommendation}
                source="RecommendationContainer"
                advertisementId={advertisementId}
              />
            )}
            {getRecommendationUrl(recommendation) && (
              <div className="qr-md">
                <QR
                  url={getRecommendationUrl(recommendation)}
                  source="RecommendationContainer-QR"
                  name={recommendation.name}
                  recommendationId={recommendation.id}
                />
                <label>Scan the Code</label>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="card-section footer border-top">
        <Link
          className="link with-icon"
          to={{
            pathname: `/recommendations/${section}`,
            search: getUrlParameter(location.search),
          }}
          onClick={onBackButton}
        >
          <MenuIcon />
          {' '}
          All
          {' '}
          {SECTION_TITLES[section]}
        </Link>
      </div>
    </div>
  )
}

export default connect(null, {
  setOverlay,
})(RecommendationContainer)
