import React from 'react'

const CheckinMarkIcon = props => (
  <svg
    className="WeatherMark"
    height="16"
    width="16"
    style={{
      top: 0,
      left: 0,
    }}
    {...props}
  >
    <polygon points="0,0 16,0 0,16" />
  </svg>
)

export default CheckinMarkIcon
