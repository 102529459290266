import React from 'react'

const Fog = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3_188)">
      <path d="M17.36 20.18C17.71 20.18 17.97 20.47 17.97 20.82C17.97 21.17 17.7 21.46 17.36 21.46H7.64C7.29 21.46 7 21.17 7 20.82C7 20.47 7.29 20.18 7.64 20.18H17.36ZM9.58 19.2C9.24 19.2 8.94 18.93 8.94 18.59C8.94 18.25 9.23 17.95 9.58 17.95H19.3C19.65 17.95 19.91 18.24 19.91 18.59C19.91 18.94 19.64 19.2 19.3 19.2H9.58ZM20.61 22.47C20.96 22.47 21.25 22.76 21.25 23.11C21.25 23.46 20.96 23.72 20.61 23.72H10.88C10.53 23.72 10.24 23.45 10.24 23.11C10.24 22.77 10.53 22.47 10.88 22.47H20.6H20.61ZM12.5 16.72C12.87 15.47 13.91 14.51 15.18 14.22C15.61 12.2 17.38 10.77 19.48 10.77C20.2 10.77 20.86 10.93 21.47 11.22C22.19 10.45 23.22 10 24.31 10C26.44 10 28.19 11.73 28.19 13.88C28.19 14.76 27.87 15.61 27.34 16.3C27.55 16.75 27.66 17.26 27.66 17.79C27.66 19.81 26.01 21.46 23.99 21.46H18.89C18.81 21.46 18.76 21.41 18.76 21.33V20.32C18.76 20.24 18.81 20.19 18.89 20.19H23.99C25.32 20.19 26.41 19.13 26.41 17.8C26.41 16.47 25.32 15.41 23.99 15.41H22.79C22.71 15.41 22.66 15.36 22.66 15.28L22.61 14.85C22.42 13.23 21.1 12.03 19.47 12.03C17.84 12.03 16.49 13.25 16.33 14.85L16.28 15.22C16.28 15.33 16.25 15.35 16.15 15.35L15.75 15.4C14.87 15.48 14.13 16.04 13.81 16.83C13.76 16.91 13.73 16.94 13.65 16.94H12.59C12.48 16.94 12.46 16.91 12.46 16.86L12.49 16.73L12.5 16.72ZM24 14.12C25.01 14.12 25.91 14.55 26.58 15.18C26.82 14.78 26.95 14.33 26.95 13.88C26.95 12.42 25.75 11.25 24.32 11.25C23.63 11.25 22.99 11.52 22.51 11.97C23.12 12.55 23.57 13.3 23.76 14.12H24Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3_188">
        <rect width="21.2" height="13.71" fill="white" transform="translate(7 10)" />
      </clipPath>
    </defs>
  </svg>
)

export default Fog
