import React from 'react'
import Icon from './Icon'

const MenuIcon = props => (
  <Icon viewBox="0 0 64 64" fill="none" {...props}>
    <path
      d="M56 4H8a4 4 0 0 0-4 4v48a4 4 0 0 0 4 4h48a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4zm-8 42H16a1 1 0 0 1 0-2h32a1 1 0 0 1 0 2zm0-13H16a1 1 0 0 1 0-2h32a1 1 0 0 1 0 2zm0-13H16a1 1 0 0 1 0-2h32a1 1 0 0 1 0 2z"
      fill="currentColor"
    />
  </Icon>
)

export default MenuIcon
