import React from 'react'

import { times } from 'lodash'
import { metrics } from '../../api'
import { useServices } from '../../resources/services'
import { Carousel } from '../ui'
import { InfoCard } from '../information'
import { useNextReservation, useReservation } from '../../hooks/useReservation'
import { formatPrice } from '../../utils/formatters'
import { filterLateCheckoutAvailability } from '../../utils/services'
import mapBackground from '../../images/map-background.png'
import houseRulesBackground from '../../images/house-rules-background.jpeg'
import { ANALYTICS,ANALYTICS_ENTITY_TYPES } from '../../constants/appConstants'

const MAP_CARD = {
  to: '/map',
  name: 'Map & Nearby',
  subtitle: 'View Map',
  background: mapBackground,
}

const HOUSE_RULES_CARD = {
  to: '/house-rules',
  name: 'Property Guidelines',
  subtitle: 'Things to know during your stay',
  background: houseRulesBackground,
}

const ServiceMeta = ({ summarySubtitle, service }) => {
  const { price, priceType } = formatPrice({
    currency: 'US',
    ...service,
  })
  return (
    <>
      {price && (
        <span>
          {price}
          {' '}
          {priceType}
        </span>
      )}
      {summarySubtitle && (
      <span>
        {' '}
        |
        {summarySubtitle}
      </span>
      )}
    </>
  )
}

const SERVICE_CARD = ({
  id, name, summarySubtitle, serviceMedias, ...service
}) => {
  const media = serviceMedias && serviceMedias.length && serviceMedias[0]
  return {
    to: `/services/${id}`,
    name,
    subtitle: <ServiceMeta summarySubtitle={summarySubtitle} service={service} />,
    background: media.mediaUrl,
    mediaType: media.mediaType,
    isPromotion: true,
  }
}

const FeaturedContent = () => {
  let { services } = useServices('homepage')
  const nextReservation = useNextReservation()
  const currentReservation = useReservation()
  if (nextReservation && currentReservation) {
    services = filterLateCheckoutAvailability(currentReservation, nextReservation, services)
  }

  let serviceCards = []
  if (services) {
    serviceCards = services.map(service => SERVICE_CARD(service))
  }

  // const { isCheckInDay } = useReservation()

  // const checkInDayItems = [MAP_CARD, HOUSE_RULES_CARD, ...serviceCards]

  const featuredItems = [...serviceCards]
  featuredItems.length < 2 && featuredItems.push(MAP_CARD)
  featuredItems.length < 2 && featuredItems.push(HOUSE_RULES_CARD)

  // const featuredItems = isCheckInDay ? checkInDayItems : duringStayItems

  const numColumns = 2
  const numColumnsToScroll = 2 // the impression tracking code below assumes this number will be 1

  // if a promotion, track it's impression
  const impress = ({ name, isPromotion }) => isPromotion
    && metrics.track('impression', {
      extra: {
        source: 'FeaturedContent',
        content_name: name,
        name : ANALYTICS.VIEW_FEATURED_CONTENT
      },
      entityType: ANALYTICS_ENTITY_TYPES.FEATURED_CONTENT,
    })

  const handleItemShown = index => {
    let itemIndex = numColumns + index - 1
    if (itemIndex >= featuredItems.length) {
      itemIndex -= featuredItems.length
    }
    const item = featuredItems[itemIndex]
    impress(item)
  }
  if (featuredItems.length === 0) {
    return null
  }

  // on init trigger impression for all promotions shown
  const onInit = () => {
    featuredItems.forEach((item, index) => {
      if (times(numColumns, Number).includes(index)) {
        impress(item)
      }
    })
  }

  return (
    <Carousel
      columns={numColumns}
      columnsToScroll={numColumnsToScroll}
      autoplay
      infinite
      afterChange={index => (featuredItems.length > numColumns ? handleItemShown(index) : () => {})}
      onInit={onInit}
    >
      {featuredItems.map(item => (
        <InfoCard
          type={item.type}
          key={item.name}
          to={item.to}
          name={item.name}
          meta={(
            <>
              <div className="title">{item.name}</div>
              {item.subtitle && <div className="subtitle">{item.subtitle}</div>}
            </>
          )}
          background={item.background}
          videoBackground={item.videoBackground}
          source="HomeContainer"
          mediaType={item.mediaType}
        />
      ))}
    </Carousel>
  )
}

export default FeaturedContent
