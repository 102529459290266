import React from 'react'
import Icon from './Icon'

const GVGIcon = props => (
  <Icon viewBox="0 0 26 36" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M12.87 17.21l8.667 4.998v-11.66l-3.668-2.115c-.265-.151-.746-.151-1.01 0l-5.39 3.109c-.024.013-.054.018-.078.031l-2.14 1.233a.412.412 0 0 1-.56-.149.409.409 0 0 1 .15-.56l.898-.516c-.032-.016-.07-.022-.099-.04l-1.289-.743c-.264-.151-.745-.152-1.01.001l-3.546 2.045v9.364l8.667-4.997a.405.405 0 0 1 .409 0zM11.119 29.599h3.095v-2.972h-3.095z"
        fill="#FFF"
      />
      <path fill="#FFF" d="M12.666 23.486l8.87 5.115v-.944l-8.87-5.115v-4.505l-8.871 5.115v5.45z" />
      <path d="M0 35.462h25.331V0H0v35.462zm1.148-1.145h23.037V1.147H1.148v33.17z" fill="#FFFFFE" />
      <path
        d="M8.76 10.09l1.29.743c.244.141.67.146.94.026l5.09-2.935c.02-.013.042-.014.063-.021l.307-.177c.52-.302 1.307-.302 1.829 0l3.258 1.878V3.79H3.795v8.11l3.136-1.81c.522-.3 1.308-.299 1.829 0"
        fill="#FFF"
      />
    </g>
  </Icon>
)

export default GVGIcon
