import React, { useEffect, useState } from 'react'
import { useMachine } from '@xstate/react'
import { Link, withRouter } from 'react-router-dom'
import { get } from 'lodash'
import serviceMachine, { canTransitionTo } from '../../machines/service'
import { ServiceHeader, ServiceDescription, RequestButton } from '.'
import { formatPrice, parseNumber } from '../../utils/formatters'
import { DetailFooter, DetailStatus } from '../pages'
import { MoreIcon } from '../icons'
import '../../styles/ServiceDetailContainer.scss'
import OrderDeadlineModal from './OrderDeadlineModal'
import { Mixpanel } from '../../api/mixpanel'
import { isApprovalPending } from '../../utils/services'

import { MESSAGES } from '../../constants/messages'

const MidstayCleanService = ({
  service,
  serviceRequest,
  canRequest,
  organizationName,
  isBeforeOrderDeadline,
  history,
  advertisementId,
  host,
  checkoutTime,
  isCheckOutDay,
  stayMode,
  navigateToServices,
}) => {
  const {
    id,
    name,
    iconURL,
    summarySubtitle,
    detailHTML,
    orderDeadline,
    terms,
    paymentProviderId,
    allowQuantity,
    requireAcceptTerms,
    serviceMedias,
    serviceInstructions,
    phone,
  } = service

  const context = {
    hasTerms: (terms || '').length > 0,
    needsTerms: requireAcceptTerms,
    hasQuantity: allowQuantity || false,
    quantity: 1,
    hasPayment: !!paymentProviderId,
    acceptTerms: false,
  }

  const [current, send] = useMachine(serviceMachine, { context })
  const { quantity, acceptTerms, hasPayment } = current.context

  useEffect(() => {
    if (current.matches('phone')) {
      const state = {
        quantity,
        acceptTerms,
        title: MESSAGES.MOBILE_NUMBER_REQUEST,
        onSuccess: `/services/${id}/phone/success`,
        name,
        section: 'services',
        meta: MESSAGES.MOBILE_NUMBER_TERMS_OF_USE,
        advertisementId,
      }
      if (hasPayment) {
        state.title = MESSAGES.MOBILE_PAYMENT_PROMPT
      }
      history.push(`/services/${id}/phone`, state)
    }

    if (!isAlreadyRequested && isApprovalPending(serviceRequest, checkoutTime, isCheckOutDay, stayMode)) {
      setIsAlreadyRequested(true)
    }
  }, [current, id, history, quantity, acceptTerms, hasPayment, name, advertisementId])

  const { price, priceType } = formatPrice({ currency: 'US', ...service })

  const trackServiceRequest = () => {
    Mixpanel.track('Click Service Request', {
      name: 'Mid Stay Service Request',
      source: 'Service Detail Container',
      serviceName: service.name,
      servicePrice: price,
      serviceID: service.id,
    })
  }
  const [showModal, setShowModal] = useState(false)

  const [isAlreadyRequested, setIsAlreadyRequested] = useState(false)

  const connectWithHostNumber = phone || host ? host.contactNumber : null

  const onRequest = () => {
    /**
     * If the same service is requested again before the approval/decline of the service from the PM side,
     * The Guest should get a message => This service has already been requested, Please call [Host number] for assistance
     */

    if (canRequest) {
      if (isBeforeOrderDeadline) {
        trackServiceRequest()
        send('REQUEST')
      }

      if (!isBeforeOrderDeadline) setShowModal(true)

      return
    }

    if (!canRequest) {
      send('REQUEST')
      trackServiceRequest()
    }
  }

  return (
    <div className="Card max-height ServiceDetailContainer">
      {showModal && <OrderDeadlineModal organizationName={organizationName} setShowModal={setShowModal} />}
      <ServiceHeader
        serviceImage={iconURL}
        name={name}
        price={price}
        priceType={priceType}
        orderDeadline={orderDeadline}
        serviceMedias={serviceMedias}
      />
      <ServiceDescription
        id={id}
        title={summarySubtitle}
        detailHTML={detailHTML}
        serviceInstructions={serviceInstructions}
      />
      <DetailFooter>
        {serviceRequest && current.matches('service') && (
          <DetailStatus
            status={isAlreadyRequested ? 'CONNECT_HOST' : serviceRequest.status}
            showIcon={!isAlreadyRequested}
            sentence={
              isAlreadyRequested
                ? `This service has already been requested, Please call ${connectWithHostNumber && parseNumber(connectWithHostNumber)
                  ? parseNumber(connectWithHostNumber)
                  : 'host'
                } for assistance.`
                : get(serviceRequest.text, ['guest', 'sentence'])
            }
          />
        )}
        <RequestButton onClick={onRequest} disabled={!canTransitionTo(current, 'REQUEST') || isAlreadyRequested}>
          {current.matches('service') ? 'Request Service' : 'Next'}
        </RequestButton>
        <div className="more-services" onClick={navigateToServices}>
          <MoreIcon />
          <span className="ml-2">{MESSAGES.MORE_SERVICES}</span>
        </div>
      </DetailFooter>
    </div>
  )
}

export default withRouter(MidstayCleanService)
