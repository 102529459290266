import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const formatTemperature = (celsius, unit = 'F') => Math.round(unit === 'F' ? (celsius * (9 / 5)) + 32 : celsius)

const Temperature = ({ value, unit, showUnit }) => {
  const converted = formatTemperature(value, unit)
  return (
    <>
      {Number.isNaN(converted) ? '' : converted}
      {' '}
      {showUnit ? `º${unit}` : 'º'}
    </>
  )
}

Temperature.defaultProps = {
  showUnit: true,
}

Temperature.propTypes = {
  showUnit: PropTypes.bool,
  value: PropTypes.number.isRequired,
  unit: PropTypes.oneOf(['C', 'F']).isRequired,
}

const mapStateToProps = ({ settings: { temperatureUnit } }) => ({
  unit: temperatureUnit,
})
export default connect(mapStateToProps)(Temperature)
