import React from 'react'
import Icon from './Icon'

const CheckedCircleFilledIcon = props => (
  <Icon viewBox="0 0 48 48" {...props}>
    <path fill="currentColor" d="M24 47C11.317 47 1 36.682 1 24S11.317 1 24 1s23 10.318 23 23-10.317 23-23 23z" />
    <path fill="#000" fillOpacity="1" d="M20 34.829L9.172 24 12 21.171l8 8 16-16L38.828 16z" />
  </Icon>
)

export default CheckedCircleFilledIcon
