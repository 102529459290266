import React, { Component } from 'react'

export default class DollarSignsText extends Component {
  render() {
    const { value, className } = this.props
    return (
      <div className={className}>
        {value}
        <span
          style={{
            opacity: 0.25,
          }}
        >
          {Array(4 - value.length)
            .fill('$')
            .join('')}
        </span>
      </div>
    )
  }
}
