import React from 'react'
import { MESSAGES } from '../../constants/messages'
import { formatPrice } from '../../utils/formatters'

const calculateTotalPrice = selectedChoices => {
  let total = 0
  for (const choice of selectedChoices) {
    const { quantity } = choice
    const feeRate = choice.feeRates[0]
    if (feeRate.feeType !== 'custom' && feeRate.feeType !== 'free') {
      total += feeRate.price ? quantity * Number(feeRate.price) : 0
    } else {
      total += 0
    }
  }
  return total
}

const getPriceValue = feeRate => {
  let priceString
  if (feeRate.feeType !== 'custom') {
    const { price } = formatPrice({
      price: feeRate.price ? Number(feeRate.price) : null,
      currency: 'US',
      feeType: feeRate.feeType,
    })
    priceString = price
  } else priceString = feeRate.customFeeMessage

  return priceString
}

const RequestSummaryModal = ({
  selectedChoices, serviceNote, onConfirm, onBack,
}) => {
  const total = calculateTotalPrice(selectedChoices)
  const { price: totalPrice } = formatPrice({
    price: total,
    currency: 'US',
  })

  return (
    <div className="modal-overlay">
      <div className="modal-container confirm-request-modal">
        <div className="confirm-request-body">
          <h2>{MESSAGES.REVIEW_AND_CONFIRM_REQUEST}</h2>
          <div className="request-list-container has-thin-scroll">
            {selectedChoices.map(choice => {
              const { title, quantity = null, feeRates } = choice
              const priceString = getPriceValue(feeRates[0])

              return (
                <p>
                  {title}
                  {' '}
                  <span>
                    {priceString}
                    {' '}
                    {quantity && `(${quantity})`}
                  </span>
                </p>
              )
            })}
          </div>
          <div className="price-total">
            Total :
            {' '}
            <span>{totalPrice}</span>
          </div>
        </div>

        <div className="confirm-request-footer">
          {serviceNote && (
            <>
              <h4>Note to our guests:</h4>
              <div className="request-note has-this-scroll">
                <p>{serviceNote}</p>
              </div>
            </>
          )}

          <div className="vertical-btn-group">
            <button onClick={onConfirm} className="btn btn-primary">
              {MESSAGES.CONFIRM_REQUEST}
            </button>
            <button onClick={onBack} className="button-link py-2 mt-2">
              {MESSAGES.BACK_TO_EDIT_REQUEST}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestSummaryModal
