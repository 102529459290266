import React, { Component } from 'react'

class Warning extends Component {
  render() {
    return (
      <svg
        width="30px"
        height="26px"
        viewBox="0 0 30 26"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        id="icon-warning"
      >
        <title>warning</title>
        <desc>Created with Sketch.</desc>
        <defs>
          <path
            d="M1.33333333,28 L30.6666667,28 L16,2.66666667 L1.33333333,28 Z M17.3333333,24 L14.6666667,24 L14.6666667,21.3333333 L17.3333333,21.3333333 L17.3333333,24 Z M17.3333333,18.6666667 L14.6666667,18.6666667 L14.6666667,13.3333333 L17.3333333,13.3333333 L17.3333333,18.6666667 Z"
            id="warning-path-1"
          />
        </defs>
        <g id="warning-Walkthrough" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="warning" transform="translate(-1.000000, -2.000000)">
            <g id="warning-Colors-/-White">
              <mask id="warning-mask-2" fill="white">
                <use href="#warning-path-1" />
              </mask>
              <use id="Shape" fill="#000000" fillRule="nonzero" href="#warning-path-1" />
              <g id="Colors-/-Orange" mask="url(#warning-mask-2)" fill="#F5A623">
                <rect id="Rectangle-2" x="0" y="0" width="32" height="32" />
              </g>
            </g>
            <rect id="Spacer" x="0" y="0" width="32" height="32" />
          </g>
        </g>
      </svg>
    )
  }
}
export default Warning
