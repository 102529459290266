import React, { Component } from 'react'
import * as serviceWorker from '../serviceWorker'

const initial = {
  status: null,
  isUpdating: false,
}

export const ServiceWorkerContext = React.createContext(initial)

class ServiceWorker extends Component {
  constructor(props) {
    super(props)
    this.state = initial

    this._waitingWorker = null
    this._registration = null
  }

  componentDidMount() {
    serviceWorker.register({
      onUpdate: this.onUpdate,
      onRegister: this.onRegister,
    })

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', this.onControllerChange)
    }
  }

  componentWillUnmount() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.removeEventListener('controllerchange', this.onControllerChange)
    }
  }

  onControllerChange = () => {
    // onControllerChange is triggered when the service worker controlling the
    // page changes, a new worker has skipped waiting nad become the new active
    // worker, so let's refresh the page

    // this is asynchronous, but this should be trigger right after
    // this.skipWaiting is called
    this.setState({ status: 'activated' })
    this.reload()
  }

  reload = force => window.location.reload(force)

  onRegister = registration => {
    this._registration = registration
  }

  onUpdate = registration => {
    // onUpdate is called when the new service worker has been installed
    // and is `waiting` to get activated
    const { isUpdating } = this.state
    this.setState({ status: 'installed' })

    const { waiting } = registration
    this._waitingWorker = waiting
    if (isUpdating === true) {
      this.skipWaiting()
    }
  }

  fetchUpdate = () => {
    this.setState(
      {
        isUpdating: true,
      },
      () => {
        if (this._registration) {
          this._registration
            .update()
            .then(registration => {
              // we didn't found an update
              if (registration.waiting === null && registration.installing === null) {
                this.reload()
              }
              // if there is a update, then the onUpdate flow will take care of
              // skip waiting and refresh the service worker
            })
            .catch(error => {
              console.log(error)
              this.reload()
            })
        }
      },
    )
  }

  skipWaiting = () => {
    this.setState({
      isUpdating: false,
    })
    if (this._waitingWorker) {
      this._waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    }
  }

  getContextValue = () => {
    const { status, isUpdating } = this.state
    return {
      skipWaiting: this.skipWaiting,
      fetchUpdate: this.fetchUpdate,
      status,
      isUpdating,
    }
  }

  render() {
    return <ServiceWorkerContext.Provider value={this.getContextValue()} {...this.props} />
  }
}

export default ServiceWorker
