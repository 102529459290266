import React from 'react'
import { useReservation } from '../../hooks/useReservation'
import { getFirst } from '../../utils/helpers'
import { Carousel, SectionTitle } from '../ui'
import { InfoCard } from '../information'
import { useCustomization } from '../../hooks/useCustomization'
import { MESSAGES } from '../../constants/messages'

const URL_SECTIONS = {
  amenity: 'amenities',
  supply: 'supplies',
  policy: 'policies',
  activity: 'activities',
}

const getBackground = amenity => {
  const image = getFirst(amenity.content, ['imageURL', ['steps', 0, 'imageURL'], 'iconURL'])
  return image
}

const KNOWLEDGEBASE_CARD = item => {
  const backgroundImage = getBackground(item)
  return {
    id: item.id,
    to: `/property-info/${URL_SECTIONS[item.type]}/${item.id}`,
    name: item.name,
    background: backgroundImage,
    mediaType: item.mediaType || item.content.mediaType,
    meta: <div>{decodeURIComponent(encodeURIComponent(item.name))}</div>,
    category: item.category,
  }
}

const ACTIVITY_CARD = activity => ({
  id: activity.id,
  to: `/recommendations/activities/${activity.id}`,
  name: activity.name,
  background: activity.content.imageURL,
  meta: activity.name,
  category: activity.content.category,
})

const ConvenienceContent = () => {
  const { isCheckInDay } = useReservation()
  const amenities = useCustomization('amenities').map(amenity => KNOWLEDGEBASE_CARD(amenity))

  const supplies = useCustomization('supplies').map(amenity => KNOWLEDGEBASE_CARD(amenity))
  const policies = useCustomization('policies').map(amenity => KNOWLEDGEBASE_CARD(amenity))

  const activities = useCustomization('activities').map(activity => ACTIVITY_CARD(activity))

  const checkinDayItems = amenities
  checkinDayItems.length < 2 && checkinDayItems.push(...supplies)
  checkinDayItems.length < 2 && checkinDayItems.push(...policies)
  checkinDayItems.length < 2 && checkinDayItems.push(...activities)

  const duringStayItems = [...activities]
  duringStayItems.length < 2 && duringStayItems.push(...checkinDayItems)

  const itemsToShow = isCheckInDay ? checkinDayItems.slice(0, 8) : duringStayItems.slice(0, 8)
  const hasItemsToShow = itemsToShow.length

  const activityFirst = activities.findIndex(activity => activity.id === itemsToShow[0].id) >= 0

  const viewAllLink = activityFirst ? '/recommendations/activities/' : '/property-info'
  const title = activityFirst ? 'For your convenience' : 'Our Property Info'

  return (
    <>
      <SectionTitle title={title} more={viewAllLink} />
      {hasItemsToShow ? (
        <Carousel rows={1} columns={2} columnsToScroll={2}>
          {itemsToShow.map(item => (
            <InfoCard key={item.id} {...item} />
          ))}
        </Carousel>
      ) : (
        <div className="info-grid-empty">
          {MESSAGES.PROPERTY_INFO_NOT_YET_ADDED}
          {' '}
        </div>
      )}
    </>
  )
}

export default ConvenienceContent
