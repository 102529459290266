import React, { useState } from 'react'
import classNames from 'classnames'
import { has, get } from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'
import { Mixpanel } from '../../api/mixpanel'
import faceAngry from '../../images/smileys/face-1-angry.png'
import faceSad from '../../images/smileys/face-2-sad.png'
import faceMeh from '../../images/smileys/face-3-meh.png'
import faceGood from '../../images/smileys/face-4-good.png'
import faceGreat from '../../images/smileys/face-5-great.png'

const faces = [
  {
    number: 1,
    img: faceAngry,
  },
  {
    number: 2,
    img: faceSad,
  },
  {
    number: 3,
    img: faceMeh,
  },
  {
    number: 4,
    img: faceGood,
  },
  {
    number: 5,
    img: faceGreat,
  },
]

const Question = props => {
  const initial = {
    opacity: 0,
    height: 0,
  }
  const animate = {
    opacity: 1,
    height: 'auto',
  }
  const exit = {
    opacity: 0,
    height: 0,
  }
  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={{
        duration: 0.1,
      }}
      {...props}
    />
  )
}

const Face = props => {
  const initial = {
    opacity: 1,
    scale: 1,
  }
  const whileTap = {
    scale: 0.8,
  }
  const variants = {
    initial: {
      opacity: 1,
      scale: 1,
    },
    selected: {
      opacity: 1,
      scale: 1,
    },
    unselected: {
      opacity: 0.5,
      scale: 0.8,
    },
  }
  return (
    <motion.img
      initial={initial}
      whileTap={whileTap}
      variants={variants}
      transition={{
        duration: 0.3,
      }}
      {...props}
    />
  )
}

const Impressions = ({
  stepKey, question, values, setValues, next, setIsSubmitting,
}) => {
  const currentFaceValue = get(values, `${stepKey}.rating`, null)
  const currentKeywords = get(values, `${stepKey}.keywords`, [])
  const showKeywords = has(question, 'keywords') && currentFaceValue
  const [otherKeyword, setOtherKeyword] = useState(false)

  const onSelectFace = faceValue => {
    let surveryType = 'Checkin Survey'
    if (question.question.includes('stay?')) {
      surveryType = 'Checkout Stay Survey'
    } else if (question.question.includes('Display helpful')) {
      surveryType = 'Checkout Display Survey'
    }
    Mixpanel.track(`Click ${surveryType}`, {
      source: question.topic,
      question: question.question,
      rating: faceValue,
    })
    setValues({
      ...values,
      [stepKey]: {
        ...values[stepKey],
        rating: faceValue,
      },
    })
  }

  const noKeywords = faceValue => {
    const rating = get(values, `[${stepKey}].rating`)
    return !has(question, 'keywords') && rating === faceValue
  }

  const onSelectFaceBegin = faceValue => {
    if (noKeywords(faceValue)) {
      setIsSubmitting(true)
    }
  }

  const onSelectFaceComplete = faceValue => {
    if (noKeywords(faceValue)) {
      setIsSubmitting(false)
      next()
    }
  }

  const onSelectKeyword = keyword => {
    let newKeywords
    const currentFaceValue = get(values, `${stepKey}.rating`, null)
    const currentKeywords = get(values, `${stepKey}.keywords`, [])

    if (currentKeywords.includes(keyword)) {
      newKeywords = currentKeywords.filter(v => v !== keyword)
    } else {
      newKeywords = [...currentKeywords, keyword]
    }
    const selectableKeywords = question.keywords.answers[currentFaceValue > 2 ? 'positive' : 'negative']
    const filteredKeywords = newKeywords.filter(keyword => selectableKeywords.includes(keyword))
    setValues({
      ...values,
      [stepKey]: {
        ...values[stepKey],
        keywords: filteredKeywords,
      },
    })
  }
  return (
    <>
      <div className="face-question-wrapper question-wrapper">
        <div className="question">{question.question}</div>
        <div className="faces">
          {faces.map(face => {
            let selectedStatus = null
            if (currentFaceValue) {
              selectedStatus = face.number === currentFaceValue ? 'selected' : 'unselected'
            }
            return (
              <Face
                key={face.number}
                animate={selectedStatus}
                onClick={() => onSelectFace(face.number)}
                onAnimationStart={() => onSelectFaceBegin(face.number)}
                onAnimationComplete={() => onSelectFaceComplete(face.number)}
                src={face.img}
              />
            )
          })}
        </div>
      </div>
      <AnimatePresence>
        {showKeywords && (
          <Question className="keyword-question-wrapper question-wrapper">
            <div className="question keyword-question">{question.keywords.question}</div>
            <div className="keywords">
              {question.keywords.answers[currentFaceValue > 2 ? 'positive' : 'negative'].map(keyword => {
                const color = currentFaceValue > 2 ? 'green' : 'red'
                const isSelected = currentKeywords.includes(keyword)
                return (
                  <div
                    key={keyword}
                    className={classNames(
                      'keyword',
                      {
                        selected: isSelected,
                      },
                      color,
                    )}
                    onClick={() => onSelectKeyword(keyword)}
                  >
                    {keyword}
                  </div>
                )
              })}
            </div>
          </Question>
        )}
      </AnimatePresence>
    </>
  )
}

export default Impressions
