import React, { useEffect, useMemo, useReducer } from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'

import useInterval from '../useInterval'
import SlideshowContext from './SlideshowContext'
import reducer from './reducer'

const DEFAULT_SLIDESHOW = [
  {
    image: {
      hiRes: '/static/media/onboarding-ocean.21937e3f.jpg',
    },
  },
]

const DEFAULT_SLIDESHOW_CONFIGURATION = {
  duration: 30,
  noOfAds: 1,
  noOfSlideshows: 3,
}

const mapStateToProps = state => {
  const slideshows = get(state.property, ['artModeBundle', 'images'], DEFAULT_SLIDESHOW).map(({ image, ...rest }) => ({
    type: 'background',
    props: {
      src: image.hiRes,
      ...rest,
    },
  }))
  const slideshowConfiguration = get(
    state.property.organization,
    'customizations.promos.content',
    DEFAULT_SLIDESHOW_CONFIGURATION,
  )
  let solution = []

  let hasToShowAdvertisement = false
  if (state.property.advertisement) {
    hasToShowAdvertisement = Object.values(state.property.advertisement).length > 0
  }

  if (hasToShowAdvertisement) {
    // TODO move to a `useCallback or some kind of caching
    let n1
    // let n2
    let slideshowIndex = 0
    let advertisementIndex = 0
    const EVERY = slideshows.length < slideshowConfiguration.noOfSlideshows
      ? slideshows.length
      : slideshowConfiguration.noOfSlideshows
    // const lastSlideshow = slideshows[slideshows.length - 1]
    do {
      solution.push(slideshows[slideshowIndex % slideshows.length])
      slideshowIndex += 1
      if (slideshowIndex % EVERY === 0) {
        do {
          solution.push({
            type: 'advertisementBackground',
            props: {
              isPlaceholder: true,
            },
          })
          advertisementIndex += 1
        } while (advertisementIndex < slideshowConfiguration.noOfAds)
        advertisementIndex = 0
      }
      n1 = solution[solution.length - 1]
      // if (solution.length >= 2) {
      //   n2 = solution[solution.length - 2]
      // }
    } while (slideshowIndex < slideshows.length || (n1 && n1.props && n1.props.isPlaceholder !== true))
  } else {
    solution = slideshows
  }
  return {
    slideshows: solution,
    slideshowConfiguration,
  }
}

const SlideshowProvider = connect(mapStateToProps)(({ slideshows, slideshowConfiguration, ...props }) => {
  const initialState = {
    index: 0,
    placeholderIndex: -1,
    isRunning: true,
    slideshows,
    current: {},
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const init = slideshows => dispatch({
    type: 'init',
    slideshows,
  })

  const next = () => dispatch({
    type: 'next',
  })
  const previous = () => dispatch({
    type: 'previous',
  })
  const stop = slideshow => dispatch({
    type: 'stop',
    slideshow,
  })
  const start = () => dispatch({
    type: 'start',
  })

  useEffect(() => {
    init(slideshows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(slideshows)])
  useInterval(next, state.isRunning ? slideshowConfiguration.duration * 1000 : null)

  const context = useMemo(() => ({
    next,
    previous,
    start,
    stop,
    placeholderIndex: state.placeholderIndex,
    index: state.index,
    isRunning: state.isRunning,
    current: {
      props: {},
      ...state.current,
    },
  }), [state])

  return <SlideshowContext.Provider value={context} {...props} />
})

export default SlideshowProvider
