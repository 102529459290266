import React, { useEffect, useState } from 'react'
import { throttle } from 'lodash'
import { Dimmer, Loader } from 'semantic-ui-react'

import SettingsSection from './SettingsSection'
import Slider from './Slider'
import { MESSAGES } from '../../constants/messages'

const Loading = () => (
  <Dimmer active>
    <Loader />
  </Dimmer>
)

const SliderSection = ({
  wait, title, defaultValue, getValue, updateValue,
}) => {
  const [isLoading, setLoading] = useState(true)
  const [hasError, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    getValue()
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, []) /* eslint-disable-line react-hooks/exhaustive-deps */

  const onChange = throttle(updateValue, wait)

  let content = null
  if (hasError) {
    content = <div style={{ opacity: 0.5 }}>{MESSAGES.NOT_AVAILABLE_ON_THIS_DISPLAY}</div>
  } else if (isLoading) {
    content = <Loading />
  } else {
    content = <Slider defaultValue={defaultValue || 0} onChange={onChange} />
  }

  return <SettingsSection title={title}>{content}</SettingsSection>
}

SliderSection.defaultProps = {
  wait: 500,
}

export default SliderSection
