import React, { Component } from 'react'
import classNames from 'classnames'
import '../styles/IFrameContainer.scss'
import { MESSAGES } from '../constants/messages'

class IFrameContainer extends Component {
  state = {
    fullscreen: false,
  }

  toggleFullScreen = () => {
    const { fullscreen } = this.state
    this.setState({ fullscreen: !fullscreen })
  }

  render() {
    const { resourceURL, action = 'View full screen', params = '#toolbar=0&navpanes=0&view=Fit' } = this.props
    const { fullscreen } = this.state
    return (
      <div className={classNames('IFrameContainer', { fullscreen })}>
        <div className="fullscreen-link" onClick={this.toggleFullScreen}>
          {action}
        </div>
        <iframe title="IFrameContainer" src={`${resourceURL}?${params}`} width="100%" height="100%" />
        <div className="back-link" onClick={this.toggleFullScreen}>
          {MESSAGES.BACK}
        </div>
      </div>
    )
  }
}

export default IFrameContainer
