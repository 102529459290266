import React from 'react'
import Icon from './Icon'

const ConnectHostIcon = props => (
  <Icon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M16.0721 6.85127L16.2051 6.14796C16.3382 5.45143 16.0823 4.70914 15.523 4.21597C14.1943 3.04322 12.2776 2.38566 9.45418 2.38905C6.63584 2.39244 4.4632 3.05339 3.25486 4.22614C3.00349 4.47201 2.82484 4.78247 2.73857 5.12335C2.65231 5.46422 2.66177 5.82229 2.76593 6.15813L2.97607 6.86144C3.07696 7.18327 3.28543 7.46062 3.56653 7.64699C3.84763 7.83336 4.18426 7.91741 4.51997 7.88506L5.89948 7.74694C6.49179 7.68762 6.75108 7.19022 6.91886 6.62249C7.16968 5.77173 7.34254 5.1396 7.34254 5.1396C7.98824 4.8981 8.60173 4.71591 9.46096 4.71591C10.3202 4.71591 10.9455 4.8803 11.5794 5.1396C11.5794 5.1396 11.7455 5.77088 12.0031 6.62249C12.1929 7.24954 12.6411 7.69101 13.2461 7.74948L14.6333 7.88336C15.3307 7.95115 15.9467 7.50883 16.0721 6.85043V6.85127Z"
      fill="#52CFE3"
    />
    <path
      d="M9.03745 10.012C9.14982 10.012 9.25759 10.0566 9.33704 10.1361C9.4165 10.2155 9.46113 10.3233 9.46113 10.4356V11.7067H10.3127C10.4251 11.7067 10.5329 11.7513 10.6123 11.8308C10.6918 11.9102 10.7364 12.018 10.7364 12.1304C10.7364 12.2427 10.6918 12.3505 10.6123 12.43C10.5329 12.5094 10.4251 12.5541 10.3127 12.5541H9.03745C8.92508 12.5541 8.81732 12.5094 8.73786 12.43C8.65841 12.3505 8.61377 12.2427 8.61377 12.1304V10.4356C8.61377 10.3233 8.65841 10.2155 8.73786 10.1361C8.81732 10.0566 8.92508 10.012 9.03745 10.012Z"
      fill="#52CFE3"
    />
    <path
      d="M5.64795 11.7066C5.64795 11.2058 5.74658 10.71 5.93821 10.2473C6.12984 9.78472 6.41071 9.36436 6.76479 9.01028C7.11888 8.65619 7.53923 8.37532 8.00187 8.18369C8.4645 7.99206 8.96034 7.89343 9.46109 7.89343C9.96184 7.89343 10.4577 7.99206 10.9203 8.18369C11.383 8.37532 11.8033 8.65619 12.1574 9.01028C12.5115 9.36436 12.7923 9.78472 12.984 10.2473C13.1756 10.71 13.2742 11.2058 13.2742 11.7066C13.2742 12.7179 12.8725 13.6878 12.1574 14.4029C11.4423 15.118 10.4724 15.5197 9.46109 15.5197C8.44978 15.5197 7.4799 15.118 6.76479 14.4029C6.04969 13.6878 5.64795 12.7179 5.64795 11.7066ZM9.46109 8.7408C8.67452 8.7408 7.92016 9.05326 7.36397 9.60945C6.80778 10.1656 6.49531 10.92 6.49531 11.7066C6.49531 12.4931 6.80778 13.2475 7.36397 13.8037C7.92016 14.3599 8.67452 14.6724 9.46109 14.6724C10.2477 14.6724 11.002 14.3599 11.5582 13.8037C12.1144 13.2475 12.4269 12.4931 12.4269 11.7066C12.4269 10.92 12.1144 10.1656 11.5582 9.60945C11.002 9.05326 10.2477 8.7408 9.46109 8.7408Z"
      fill="#52CFE3"
    />
  </Icon>
)

export default ConnectHostIcon
