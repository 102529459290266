import React from 'react'
import { connect } from 'react-redux'
import CrossFadingBackground from './CrossFadingBackground'
import SquareBackground from '../../images/bg_artmode_services.jpg'

const AdvertisementBackground = ({ advertisement, onClick }) => {
  const imageURL = advertisement.style === 'portrait' ? advertisement.imageURL : SquareBackground
  return <CrossFadingBackground src={imageURL} onClick={onClick} />
}

const mapStateToProps = (state, { placeholderIndex }) => {
  const advertisements = state.property.advertisement
  const index = Math.abs(placeholderIndex) % advertisements.length
  const advertisement = advertisements[index]
  return {
    advertisement,
  }
}

export default connect(mapStateToProps)(AdvertisementBackground)
