import moment from 'moment';

export const ROOT_API_URL = process.env.REACT_APP_API_URL

export function createRequestLogs(path, options) {
  const requestLog = {
    module: path,
    metadata: options.data,
    apiInfo: { endpoint: `${ROOT_API_URL}/${path}`, method: options.method },
  }
  const log = {
    module: 'api',
    metadata: { request: requestLog },
  }

  saveLogsToLocalStorage(log)
}

export function createResponseLogs(path, response) {
  const responseLog = {
    module: path,
    apiInfo: { endpoint: `${ROOT_API_URL}/${path}`, method: response.config.method },
    httpResponse: JSON.stringify(response.data),
  };
  const log = {
    module: 'api',
    metadata: { response: responseLog },
  }
  saveLogsToLocalStorage(log)
}

export function createErrorLogs(error, path, options) {
  const errorLog = {
    module: path || '',
    error: typeof error === 'string' ? (error || ''): JSON.stringify(error),
    metadata: options?.data || '',
  }
  const log = {
    module: 'api-error',
    metadata: { error: errorLog },
  }
  saveLogsToLocalStorage(log)
}

export function saveLogsToLocalStorage(log) {
  // const momentInstance = moment()
  // const logs = JSON.parse(localStorage.getItem('logs')) || []
  // const logObj = {
  //   ...log,
  //   dateTime: momentInstance,
  // }
  // logs.push(logObj)

  // localStorage.setItem('logs', JSON.stringify(logs));
}
