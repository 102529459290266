// This file defines constants for all action types
// See "Reducing Boilerplate" for why storing action types as constants
// is a good idea: http://redux.js.org/docs/recipes/ReducingBoilerplate.html
// Also - I stole this approach from the CRE client app :)

// Reservation

export const HAS_RESERVATION_INFO = 'HAS_RESERVATION_INFO'

// Property
// pairing
export const PIN_HAS_ERRORED = 'PIN_HAS_ERRORED'
export const PIN_IS_SENDING = 'PIN_IS_SENDING'
export const PIN_IS_LOADED = 'PIN_IS_LOADED'

export const PROPERTY_HAS_ERRORED = 'PROPERTY_HAS_ERRORED'
export const PROPERTY_IS_LOADING = 'PROPERTY_IS_LOADING'
export const PROPERTY_FETCH_SUCCESS = 'PROPERTY_FETCH_SUCCESS'
export const UNPAIRED = 'property/UNPAIRED'

export const CLEAR_SERVICE_STATUS = 'CLEAR_SERVICE_STATUS'

export const MARK_MESSAGE_AS_READ = 'MARK_MESSAGE_AS_READ'
// Settings
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'

// SMS
export const SMS_HAS_ERRORED = 'SMS_HAS_ERRORED'
export const SMS_IS_LOADING = 'SMS_IS_LOADING'
export const SMS_SUCCESS = 'SMS_SUCCESS'

// Filters
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const UPSERT_FILTERS = 'UPSERT_FILTERS'
export const SETUP_FILTERS_FOR_VIEW = 'SETUP_FILTERS_FOR_VIEW'

// passcode screen
export const UPDATE_SETTINGS_PASSCODE = 'UPDATE_SETTINGS_PASSCODE'
export const RESET_PASSCODE = 'RESET_PASSCODE'

export const DEVICE_STATE_FETCH_SUCCESS = 'DEVICE_STATE_FETCH_SUCCESS'
export const VOLUME_FETCH_SUCCESS = 'VOLUME_FETCH_SUCCESS'
export const BRIGHTNESS_FETCH_SUCCESS = 'BRIGHTNESS_FETCH_SUCCESS'
export const UPDATE_VOLUME = 'UPDATE_VOLUME'
export const UPDATE_BRIGHTNESS = 'UPDATE_BRIGHTNESS'

export const FETCH_PROPERTY_WEATHER_SUCCESS = 'FETCH_PROPERTY_WEATHER'
export const FETCH_PROPERTY_WEATHER_FAILURE = 'FETCH_PROPERTY_WEATHER_FAILURE'
export const FETCH_PROPERTY_WEATHER_REQUEST = 'FETCH_PROPERTY_WEATHER_REQUEST'

export const FETCH_PROPERTY_MESSAGES_SUCCESS = 'FETCH_PROPERTY_MESSAGES'
export const FETCH_PROPERTY_MESSAGES_FAILURE = 'FETCH_PROPERTY_MESSAGES_FAILURE'
export const FETCH_PROPERTY_MESSAGES_REQUEST = 'FETCH_PROPERTY_MESSAGES_REQUEST'
export const REFRESH_MESSAGES_IN_STATE = 'REFRESH_MESSAGES_IN_STATE'

export const CREATE_MESSAGES_HISTORY_SUCCESS = 'CREATE_MESSAGES_HISTORY'
export const CREATE_MESSAGES_HISTORY_FAILURE = 'CREATE_MESSAGES_HISTORY_FAILURE'
export const CREATE_MESSAGES_HISTORY_REQUEST = 'CREATE_MESSAGES_HISTORY_REQUEST'

export const FETCH_MESSAGE_HISTORY_REQUEST = 'FETCH_MESSAGE_HISTORY_REQUEST'
export const FETCH_MESSAGE_HISTORY_SUCCESS = 'FETCH_MESSAGE_HISTORY_SUCCESS'
export const FETCH_MESSAGE_HISTORY_FAILURE = 'FETCH_MESSAGE_HISTORY_FAILURE'

export const DEVICE_BLACKLISTED = 'DEVICE_BLACKLISTED'
