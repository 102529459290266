import React, { useEffect } from 'react'
import { connectWithPath } from '../resources/utils'
import { markMessageAsRead } from '../actions/property'
import { setOverlay } from '../actions/device'
import MessageList from '../components/messages/MessageList'

const MessageContainer = ({
  markMessageAsRead,
  interactive,
  messages,
  onTap,
  setOverlay,
  reservation,
}) => {
  const reservationId = reservation ? reservation.id : null
  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])

  if (window.location.href.includes('onboarding')) return null

  return (
    <>

      {messages.length && (
      <MessageList
        messages={messages}
        onTap={onTap}
        interactive={interactive}
        markMessageAsRead={markMessageAsRead}
        reservationId={reservationId}
      />
      )}
    </>
  )
}

export default connectWithPath(
  {
    reservation: 'property.reservation',
  },
  {
    markMessageAsRead,
    setOverlay,
  },
)(MessageContainer)
