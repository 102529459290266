import React from 'react'
import { Icon } from 'semantic-ui-react'
import '../../styles/HomePromotion.scss'
import { TrackLink } from '../metrics'
import { formatPrice } from '../../utils/formatters'
import { MESSAGES } from '../../constants/messages'

const HomePromotion = ({ promotion }) => {
  const {
    id, name, iconURL, summarySubtitle, feeType,
  } = promotion
  const { price, priceType } = formatPrice({
    currency: 'US',
    ...promotion,
  })

  return (
    <div className="promotion card-section">
      <TrackLink className="HomePromotion" to={`/services/${id}`} name={name} source="HomePromotion">
        <img src={iconURL} className="image" alt="promotion-icon" />
        <div className="summary detail-container">
          <div className="title top">{name}</div>
          {summarySubtitle && (
            <div className="bottom">
              <div className="subtitle">{summarySubtitle}</div>
            </div>
          )}
        </div>
        <div className="cta detail-container">
          {!!feeType && (
            <div className="price">
              <div className="price-figure top">{price}</div>
              <div className="price-type">{priceType}</div>
            </div>
          )}
          <div className="action link bottom">
            {MESSAGES.FIND_OUT_MORE}
            {' '}
            <Icon name="chevron right" />
          </div>
        </div>
      </TrackLink>
    </div>
  )
}

export default HomePromotion
