import React from 'react'

const SnowShowerIcon = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.6201 24.6887L19.3016 24.5047L19.6201 24.3208C19.8172 24.2069 19.8852 23.9544 19.7708 23.7573C19.6569 23.5598 19.4044 23.4922 19.2074 23.6061L18.8889 23.7901V23.4222C18.8889 23.1944 18.7039 23.0094 18.4761 23.0094C18.2483 23.0094 18.0634 23.1944 18.0634 23.4222V23.7901L17.7449 23.6061C17.5473 23.4922 17.2953 23.5598 17.1814 23.7573C17.0675 23.9549 17.1351 24.2069 17.3326 24.3208L17.6511 24.5047L17.3326 24.6887C17.1351 24.8026 17.0675 25.0551 17.1814 25.2521C17.258 25.3846 17.3966 25.4587 17.5392 25.4587C17.6093 25.4587 17.6804 25.4411 17.7454 25.4033L18.0639 25.2193V25.5872C18.0639 25.815 18.2483 26 18.4766 26C18.7049 26 18.8894 25.8155 18.8894 25.5872V25.2193L19.2079 25.4033C19.2729 25.4406 19.344 25.4587 19.414 25.4587C19.5566 25.4587 19.6952 25.3846 19.7718 25.2521C19.8857 25.0551 19.8182 24.8026 19.6211 24.6887H19.6201Z" fill="white" />
    <path d="M15.7562 24.6887L15.4377 24.5047L15.7562 24.3208C15.9538 24.2069 16.0213 23.9544 15.9074 23.7573C15.7935 23.5598 15.541 23.4923 15.344 23.6062L15.0255 23.7901V23.4222C15.0255 23.1944 14.8405 23.0095 14.6127 23.0095C14.3849 23.0095 14.2 23.1944 14.2 23.4222V23.7901L13.8815 23.6062C13.6839 23.4923 13.4319 23.5598 13.318 23.7573C13.2041 23.9549 13.2717 24.2069 13.4692 24.3208L13.7877 24.5047L13.4692 24.6887C13.2717 24.8026 13.2041 25.0551 13.318 25.2521C13.3946 25.3847 13.5332 25.4587 13.6758 25.4587C13.7459 25.4587 13.817 25.4411 13.882 25.4033L14.2005 25.2194V25.5873C14.2005 25.815 14.3854 26 14.6132 26C14.841 26 15.026 25.8156 15.026 25.5873V25.2194L15.3445 25.4033C15.4095 25.4406 15.4801 25.4587 15.5501 25.4587C15.6927 25.4587 15.8313 25.3847 15.9079 25.2521C16.0218 25.0551 15.9543 24.8026 15.7567 24.6887H15.7562Z" fill="white" />
    <path d="M23.0521 23.0099V25.5091C23.0521 25.7803 23.2719 26 23.543 26C23.8141 26 24.0339 25.7803 24.0339 25.5091V23.0099H23.0516H23.0521Z" fill="white" />
    <path d="M20.8775 23.0099V25.5091C20.8775 25.7803 21.0972 26 21.3684 26C21.6395 26 21.8592 25.7803 21.8592 25.5091V23.0099H20.877H20.8775Z" fill="white" />
    <path d="M24.8211 16.153C24.9385 15.8203 24.999 15.4686 24.999 15.1118C24.999 13.3847 23.5939 11.9796 21.8668 11.9796C21.4495 11.9796 21.0398 12.0622 20.6593 12.2215C19.8232 10.8583 18.3179 10 16.6991 10C14.141 10 12.0596 12.0814 12.0596 14.6395C12.0596 14.6627 12.0596 14.6859 12.0601 14.7096C10.3103 15.0593 9 16.6156 9 18.442C9 20.541 10.7079 22.249 12.807 22.249H23.8076C25.5347 22.249 26.9398 20.8439 26.9398 19.1168C26.9398 17.7581 26.0639 16.5778 24.8211 16.1535V16.153ZM23.8076 21.2939H12.807C11.2341 21.2939 9.95452 20.0144 9.95452 18.4415C9.95452 16.9533 11.1162 15.7039 12.5993 15.5971L13.0963 15.5613L13.0393 15.0664C13.0227 14.9233 13.0146 14.7796 13.0146 14.639C13.0146 12.607 14.6676 10.954 16.6996 10.954C18.1163 10.954 19.4241 11.782 20.0314 13.0631L20.2607 13.5474L20.7153 13.2637C21.0605 13.048 21.4586 12.9341 21.8668 12.9341C23.0678 12.9341 24.0445 13.9108 24.0445 15.1118C24.0445 15.5094 23.9366 15.8979 23.732 16.2361L23.3515 16.8651L24.0808 16.9563C25.1663 17.0918 25.9853 18.0207 25.9853 19.1163C25.9853 20.3172 25.0086 21.2939 23.8076 21.2939Z" fill="white" />
    <path d="M14.2644 15.1168C14.0009 15.1168 13.7872 14.9031 13.7872 14.6395C13.7872 13.0339 15.0935 11.7276 16.6991 11.7276C16.9627 11.7276 17.1764 11.9413 17.1764 12.2049C17.1764 12.4684 16.9627 12.6821 16.6991 12.6821C15.6196 12.6821 14.7412 13.5605 14.7412 14.64C14.7412 14.9036 14.5275 15.1173 14.2639 15.1173L14.2644 15.1168Z" fill="white" />
  </svg>
)

export default SnowShowerIcon
