import { get, isNumber } from 'lodash'
import { getMinMaxPrices } from '../services'

const FEE_TYPES = {
  fixed: '',
  hourly: 'hourly',
  daily: 'daily',
  free: 'Free',
}

const CURRENCIES = {
  US: '$',
}

const currency = ({
  price, feeType, fee, currency, template, serviceChoices,
}, amount) => {
  const currencySymbol = get(CURRENCIES, currency, CURRENCIES.US)
  const priceType = get(FEE_TYPES, feeType, FEE_TYPES.fixed)

  // priority:
  // amount > price > fee
  // amount is to compute (quantity * price) for service requests
  if (isNumber(amount) || isNumber(price)) {
    const decimalPoint = 2
    return {
      price: Number((amount || price) / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalPoint,
        maximumFractionDigits: decimalPoint,
      }),
      priceType,
    }
  }

  if (feeType === 'free') {
    return {
      price: 'Free',
      priceType: '',
    }
  }

  if (feeType && fee && isNumber(parseFloat(fee, 10))) {
    return {
      price: `${currencySymbol}${fee}`,
      priceType,
    }
  }

  if (template === 'bundle') {
    const { minPrice, maxPrice } = getMinMaxPrices(serviceChoices || []) || {}

    return {
      price: `${minPrice || ''}
                  ${maxPrice ? ` to ${maxPrice}` : ''}`,
      priceType: '',
    }
  }

  return {
    price: fee,
    priceType,
  }
}

export default currency
