import React from 'react'
import classNames from 'classnames'

import WeatherIcon from './WeatherIcon'
import Temperature from './Temperature'
import { CheckoutMarkIcon, CheckoutFutureMarkIcon, CheckinMarkIcon } from './icons'

const WeatherDay = ({
  isToday,
  isCheckout,
  isCheckin,
  day,
  temperatureMin,
  temperatureMax,
  icon,
  hasReservation,
  isCheckoutInTheFuture,
}) => (
  <div
    className={classNames('WeatherDay', {
      today: isToday,
      hasReservation,
    })}
  >
    <div className="date">{isToday ? 'Today' : day.format('ddd')}</div>
    <WeatherIcon icon={icon} />
    <div className="temperatures">
      <div className="max">
        <Temperature value={temperatureMax} showUnit={false} />
      </div>
      <div className="min">
        <Temperature value={temperatureMin} showUnit={false} />
      </div>
    </div>
    {isCheckin && !isToday && <CheckinMarkIcon />}
    {isCheckout && <CheckoutMarkIcon />}
    {isCheckoutInTheFuture && <CheckoutFutureMarkIcon />}
  </div>
)

export default WeatherDay
