import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../../styles/CrossFadingBackground.scss'

const IMAGE_FADE_DURATION = 700

class CrossFadingBackground extends Component {
  constructor(props) {
    super(props)
    this.preloadImage = null
    this.state = {
      imageUrl: props.src,
      imageReady: true,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextUrl = nextProps.src
    if (nextUrl === this.state.imageUrl) {
      return
    }

    // hide the current image (will fade out in CSS)
    this.setState({
      imageReady: false,
    })

    // start loading the new image in Javascript and update our
    // state to the new imageUrl when it's loaded, or after 300ms,
    // whichever is longer.
    const nextMinAppearTime = Date.now() + IMAGE_FADE_DURATION

    const image = new Image()
    this.preloadImage = image

    image.onload = () => {
      const msLeftInFade = Math.max(0, nextMinAppearTime - Date.now())
      setTimeout(() => {
        // if you cycle through images quickly, it's possible this one
        // that has loaded is not the one we want anymore. Just ignore.
        if (this.preloadImage !== image) {
          return
        }
        this.setState({
          imageUrl: nextUrl,
          imageReady: true,
        })
      }, msLeftInFade)
    }
    image.src = nextUrl
  }

  renderOverlay() {
    const { showOverlay } = this.props
    if (showOverlay) return <div className="grey-overlay" />
  }

  render() {
    const { onClick } = this.props
    const { imageUrl, imageReady } = this.state
    const style = {
      backgroundImage: `url(${imageUrl}`,
      transitionDuration: `${IMAGE_FADE_DURATION}ms`,
    }
    return (
      <div className={`CrossFadingBackground ${imageReady && 'ready'}`} style={style} onClick={onClick}>
        {this.renderOverlay()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  showOverlay: state.device.showOverlay,
})

export default connect(mapStateToProps)(CrossFadingBackground)
