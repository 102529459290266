import React from 'react'
import moment from 'moment'
import { useReservation } from '../hooks/useReservation'
import { MESSAGES } from '../constants/messages'

const formatReservationDate = date => moment(date).format('DD MMM')

const Reservation = () => {
  const { stayMode, beginDate, endDate } = useReservation()

  if (stayMode) {
    const formattedBeginDate = formatReservationDate(beginDate)
    const formattedEndDate = formatReservationDate(endDate)

    return (
      <div className="Reservation">
        <div className="label">
          {`${MESSAGES.YOUR_RESERVATION}`}
          {' '}
        </div>
        <div className="dates">
          {formattedBeginDate}
          {' '}
          -
          {formattedEndDate}
        </div>
      </div>
    )
  }
  return null
}

export default Reservation
