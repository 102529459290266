import React from 'react'
import { get } from 'lodash'
import { useCustomization } from '../../hooks/useCustomization'

const ReviewList = ({
  list, stepKey, values, setValues, hasReservation = false,includeConfirmation
}) => {
  const listItems = useCustomization(list?.listPath) || []

  const agreeMessage = get(list, 'agreeMessage')
  const currentValue = get(values, `${stepKey}.agreed`, false)
  const body = get(list, 'body')

  const onChange = () => {
    setValues({
      ...values,
      [stepKey]: {
        agreed: !currentValue,
      },
    })
  }

  return (
    <div className="ReviewList">
      {listItems.length ? (
        <div className="list">
          {listItems.map((listItem, index) => (
            <div key={index} className="list-item">
              {listItem.text}
            </div>
          ))}
        </div>
      ) : (
        <div>
          {body}
          {' '}
        </div>
      )}
      { includeConfirmation && agreeMessage && hasReservation && (
        <label className="list-confirm">
          <input type="checkbox" checked={currentValue} onChange={onChange} />
          <div className="pseudo-checkbox" />
          <div className="confirm-text">{agreeMessage}</div>
        </label>
      )}
    </div>
  )
}

export default ReviewList
