import { Machine, assign } from 'xstate'

const quantity = {
  on: {
    INCREMENT: {
      target: 'quantity',
      actions: 'incrementQuantity',
      cond: 'canIncrementQuantity',
    },
    DECREMENT: {
      target: 'quantity',
      actions: 'decrementQuantity',
      cond: 'canDecrementQuantity',
    },
  },
}
const terms = {
  on: {
    ACCEPT: {
      target: 'terms',
      cond: 'hasTerms',
      actions: 'acceptTerms',
    },
  },
}

const serviceMachine = Machine(
  {
    id: 'service',
    initial: 'service',
    context: {
      hasTerms: true,
      needsTerms: true,
      hasQuantity: true,
      quantity: 1,
      acceptTerms: false,
    },
    states: {
      service: {
        on: {
          REQUEST: 'request',
        },
      },
      request: {
        on: {
          '': [
            {
              target: 'phone',
              cond: 'hasNoQuantityOrNoTerms',
            },
            {
              target: 'extra',
              cond: 'hasQuantityAndTerms',
            },
            {
              target: 'quantity',
              cond: 'hasQuantityOnly',
            },
            {
              target: 'terms',
              cond: 'hasTermsOnly',
            },
          ],
        },
      },
      quantity: {
        ...quantity,
        on: {
          ...quantity.on,
          REQUEST: {
            target: 'phone',
          },
        },
      },
      terms: {
        ...terms,
        on: {
          ...terms.on,
          REQUEST: {
            target: 'phone',
            cond: 'didAcceptTerms',
          },
        },
      },
      extra: {
        type: 'parallel',
        on: {
          REQUEST: {
            target: 'phone',
            cond: 'hasValidExtra',
          },
        },
        states: {
          quantity,
          terms,
        },
      },
      phone: {
        on: {
          SEND: 'confirmation',
          BACK: 'service',
        },
      },
      confirmation: {
        after: {
          5000: 'success',
        },
        on: {
          DONE: 'success',
        },
      },
      success: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      incrementQuantity: assign({
        quantity: ({ quantity }) => quantity + 1,
      }),
      decrementQuantity: assign({
        quantity: ({ quantity }) => quantity - 1,
      }),
      acceptTerms: assign({
        acceptTerms: ({ acceptTerms }) => !acceptTerms,
      }),
    },
    guards: {
      canDecrementQuantity: ({ quantity }) => quantity > 1,
      canIncrementQuantity: () => true,
      hasValidExtra: ({ needsTerms, acceptTerms }) => (needsTerms ? acceptTerms : true),
      hasQuantityAndTerms: ({ hasTerms, hasQuantity }) => hasTerms && hasQuantity,
      hasNoQuantityOrNoTerms: ({ hasTerms, hasQuantity }) => !hasQuantity && !hasTerms,

      hasTerms: ({ hasTerms }) => hasTerms,
      didAcceptTerms: ({ acceptTerms, needsTerms }) => (needsTerms ? acceptTerms : true),
      hasTermsOnly: ({ hasTerms, hasQuantity }) => hasTerms && hasQuantity === false,
      hasQuantityOnly: ({ hasTerms, hasQuantity }) => hasTerms === false && hasQuantity,
    },
  },
)

export const canTransitionTo = (current, evt) => serviceMachine.transition(current, evt).changed

export default serviceMachine
