import React from 'react'
import Icon from './Icon'

const MapIcon = props => (
  <Icon viewBox="0 0 62 52" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.5 13c0 7.18-13 27-13 27s-13-19.82-13-27 5.82-13 13-13 13 5.82 13 13zm-8 0a5 5 0 1 1-10 0 5 5 0 0 1 10 0zM14.81 29.731a1.5 1.5 0 0 0-2.62-1.462l-12 21.5A1.5 1.5 0 0 0 1.5 52H60a1.5 1.5 0 0 0 1.31-2.231l-12-21.5a1.5 1.5 0 0 0-2.62 1.462L57.445 49H4.055L14.81 29.731z"
      fill="currentColor"
    />
  </Icon>
)
export default MapIcon
