import React from 'react'
import { motion } from 'framer-motion'

const AnimateFadeWrapper = props => {
  const initial = {
    opacity: 0,
  }
  const animate = {
    opacity: 1,
  }
  const exit = {
    opacity: 0,
  }
  return (
    <motion.div
      initial={initial}
      animate={animate}
      exit={exit}
      transition={{
        duration: 0.2,
      }}
      {...props}
    />
  )
}
export default AnimateFadeWrapper
