import React from 'react'

const CheckoutFutureMarkIcon = () => (
  <svg
    className="WeatherMark"
    height="20"
    width="21"
    style={{
      bottom: -4,
      right: -4,
    }}
  >
    <polygon points="16,16 16,0 0,16" />
    <polygon points="20,20 20,3 18,5 18,18 5,18 3,20" />
  </svg>
)

export default CheckoutFutureMarkIcon
