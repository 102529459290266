import React from 'react'
import classNames from 'classnames'
import { connectWithPath } from '../../resources/utils'
import { updateTemperatureUnit } from '../../resources/settings'

const TemperatureUnitToggle = ({ unit, updateTemperatureUnit }) => (
  <div className="TemperatureUnitToggle">
    <div
      className={classNames('toggle-option', {
        active: unit === 'F',
      })}
      onClick={() => updateTemperatureUnit('F')}
    >
      ºF
    </div>
    <div
      className={classNames('toggle-option', {
        active: unit === 'C',
      })}
      onClick={() => updateTemperatureUnit('C')}
    >
      ºC
    </div>
  </div>
)

export default connectWithPath(
  {
    unit: 'settings.temperatureUnit',
  },
  {
    updateTemperatureUnit,
  },
)(TemperatureUnitToggle)
