import React from 'react'
import Icon from './Icon'

const ContactIcon = props => (
  <Icon width="1em" height="1em" viewBox="0 0 25 25" {...props}>
    <path
      d="M4.988 10.735a20.875 20.875 0 0 0 9.081 9.08l3.032-3.031a1.37 1.37 0 0 1 1.405-.33c1.544.51 3.211.785 4.92.785.758 0 1.378.62 1.378 1.378v4.809c0 .758-.62 1.378-1.378 1.378C10.486 24.804 0 14.317 0 1.378 0 .62.62 0 1.378 0h4.823c.758 0 1.378.62 1.378 1.378 0 1.722.276 3.376.785 4.92.152.482.042 1.02-.344 1.405l-3.032 3.032z"
      className="fill"
    />
  </Icon>
)

export default ContactIcon
