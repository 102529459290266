import React, { Component } from 'react'
import { Card } from 'semantic-ui-react'
import { get, pick, toNumber } from 'lodash'
import { connect } from 'react-redux'

import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { withPropsFromLocation } from '../components/utils'
import { makeServiceRequest } from '../actions/property'
import { metrics } from '../api'
import OnboardingContactField from '../components/OnboardingContactField'
import { makeSendSMSRequest } from '../utils/helpers'

import '../styles/SendToPhoneContainer.scss'
import { ANALYTICS, ANALYTICS_ENTITY_TYPES } from '../constants/appConstants'
import sendIcon from '../images/icons/send.png'
import { MESSAGES } from '../constants/messages'
import { validatePhoneNumberLength } from 'libphonenumber-js'

const SAVED_PHONE_RES_ID = 'saved-phone-reservation-id'
const SAVED_PHONE = 'saved-phone'

class SendToPhoneContainer extends Component {
  constructor(props) {
    super(props)

    let phone = ''
    if (props.reservationId && localStorage.getItem(SAVED_PHONE_RES_ID) === `${props.reservationId}`) {
      phone = localStorage.getItem(SAVED_PHONE) || ''
    }

    this.state = {
      sending: false,
      isValid: true,
      phone,
    }
  }

  onBack = () => {
    const { history } = this.props
    history.goBack()
  }

  onSend = async () => {
    const { phone, sending } = this.state
    const {
      reservationId,
      onSend,
      history,
      location,
      match,
      makeServiceRequest,
      onSuccess,
      name,
      message,
      link,
      title,
      propertyId,
      selectedChoices = null,
    } = this.props

    if(!isValidPhoneNumber(phone)) {
      this.setState({ isValid: false })
      return
    }
    if (sending) {
      return false
    }
    this.setState({ sending: true, error: null })
    localStorage.setItem(SAVED_PHONE_RES_ID, `${reservationId}`)
    localStorage.setItem(SAVED_PHONE, phone)
    let entityData = {}

    if (location.state && location.state.advertisementId) {
      entityData = {
        entityId: location.state.advertisementId,
        entityType: ANALYTICS_ENTITY_TYPES.ADVERTISEMENT,
      }
    }

    if (location.state && location.state.messageId) {
      entityData = {
        entityId: location.state.messageId,
        entityType: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
        referer: location.state.referer,
      }
    }

    const extra = {
      name: name || title,
      source: location.state && location.state.advertisementId ? ANALYTICS.ADVERTISEMENT : 'SendToPhoneContainer',
      section:
        location.state && location.state.section
          ? location.state.section
          : name === 'Bifurcated checkout'
            ? name
            : match && match.params && match.params.section,
    }

    if (location.state && location.state.template) extra.template = location.state.template

    metrics.track('phone', {
      ...{ extra },
      ...entityData,
    })

    if (onSend) {
      // TODO THE FUTURE, every component should define their onSend function
      // or/and default to makeSendSMSRequest (or have a DefaultSendToPhoneContainer
      // that defines this onSend={makeSendSMSRequest}
      try {
        await onSend(phone, reservationId)
      } catch (exception) {
        // TODO It would be nice to have a better way to generate that
        const error = get(
          exception,
          ['response', 'data', 'fields', 'phone', 'message'],
          'Oops, something went wrong. Our engineers have been notified.',
        )
        this.setState({ sending: false, error })
      }
    } else {
      if (message) {
        let textMessage = message

        if (link) {
          textMessage = `${message}\n${link}`
        }
        try {
          await makeSendSMSRequest(phone, textMessage)
        } catch (exception) {
          this.setState({ sending: false, error: exception.message })
          return
        }
      } else {
        const id = toNumber(match.params.id)
        const parameters = pick(location.state, ['quantity', 'acceptTerms', 'selectedChoices'])
        await makeServiceRequest(reservationId, id, phone, parameters, propertyId)
      }

      if (onSuccess) {
        history.push(onSuccess)
      } else {
        // TODO Old way
        history.goBack()
      }
    }
  }

  render() {
    const { phone, sending, error } = this.state
    const { title, meta, description } = this.props
    // FIXME this validation doesn't work, and always return true
    // see: https://github.com/catamphetamine/libphonenumber-js#parse-phone-number
    // const valid = OnboardingContactField.Validation.Phone(phone) !== false

    // Temporary  fix
    const valid = isPossiblePhoneNumber(phone)

    return (
      <Card centered className="SendToPhoneContainer">
        <h2 className="title">{title}</h2>
        {description && <h4 className='send-phone-subtitle'>{description}</h4>}
        <OnboardingContactField
          label="Mobile Phone"
          meta={meta}
          value={phone}
          type="tel"
          enabled
          onSetEnabled={() => {}}
          onChange={value => this.setState({ phone: value })}
          onValidate={OnboardingContactField.Validation.Phone}
          isValid={this.state.isValid}
        />
        <div className={`send ${!valid && 'disabled'}`} onClick={valid ? this.onSend : undefined}>
          <img src={sendIcon} style={{ width: 104, height: 104 }} alt="send" />
          {sending ? 'SENDING...' : 'SEND'}
        </div>
        {error && <div className="error">{error}</div>}
        <div className="link" style={{ marginTop: '205px' }} onClick={this.onBack}>
          {MESSAGES.CANCEL}
        </div>
      </Card>
    )
  }
}

SendToPhoneContainer.defaultProps = {
  meta: 'Your phone number won\'t be shared. Your wireless carrier may charge you standard text messaging rates.',
}

export default connect(
  ({ property }) => ({
    reservationId:
      get(property, 'reservation.id') === parseInt(localStorage.getItem('hasCheckedOut'), 10)
        ? null
        : get(property, 'reservation.id'),
    propertyId: property.id,
  }),
  { makeServiceRequest },
)(
  withPropsFromLocation(['title', 'meta', 'name', 'onSuccess', 'section', 'message', 'link', 'selectedChoices'])(
    SendToPhoneContainer,
  ),
)
