import React from 'react'
import { MESSAGES } from '../constants/messages'
import { connectWithPath } from '../resources/utils'
import '../styles/OfflineWarning.scss'

const OfflineWarning = ({ isOnline }) => {
  if (!isOnline) {
    return (
      <div className="OfflineWarning">
        <span>{MESSAGES.TRYING_TO_RECONNECT}</span>
      </div>
    )
  }
  return null
}

export default connectWithPath({
  isOnline: 'networkStatus.isOnline',
})(OfflineWarning)
