import React from 'react'
import RCSlider from 'rc-slider'
import 'rc-slider/assets/index.css'

const handleStyle = {
  borderColor: '#d8d8d8',
  height: 28,
  marginLeft: -14,
  marginTop: -9,
  width: 28,
}
const railStyle = {
  backgroundColor: '#d8d8d8',
  height: 10,
}
const trackStyle = {
  backgroundColor: '#2A9D8F',
  height: 10,
}

const Slider = ({ defaultValue, onChange }) => (
  <RCSlider
    handleStyle={handleStyle}
    railStyle={railStyle}
    trackStyle={trackStyle}
    className="custom-slider"
    min={0}
    max={100}
    defaultValue={defaultValue}
    onChange={onChange}
  />
)

export default Slider
