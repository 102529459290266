import React from 'react'
import { withRouter } from 'react-router-dom'
import SettingsSection from './SettingsSection'
import BlueButton from '../BlueButton'

const DeviceSection = ({ history }) => {
  const onClick = () => {
    localStorage.removeItem('hasViewedOnboarding')
    history.push('/onboarding/checkin')
  }
  return (
    <SettingsSection title="Device">
      <BlueButton onClick={onClick} label="Restart at Walkthrough" />
    </SettingsSection>
  )
}

export default withRouter(DeviceSection)
