import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { SendToPhoneContainer } from '../../containers'
import { withPropsFromLocation } from '../utils'
import { setOverlay } from '../../actions/device'
import { makeReviewReminderRequest } from '../../actions/property'

const ContactSendToPhoneBifurcated = ({
  history,
  isUrgent,
  topics,
  setOverlay,
  makeReviewReminderRequest,
  ...props
}) => {
  useEffect(() => {
    setOverlay({ value: true })
    return () => setOverlay({ value: false })
  }, [])

  const onSend = async (phone, reservationId) => {
    reservationId = reservationId || parseInt(localStorage.getItem('hasCheckedOut'))
    await makeReviewReminderRequest(phone, reservationId)
    history.push('/phone/success')
  }

  return (
    <SendToPhoneContainer
      {...props}
      name="Bifurcated checkout"
      meta="Your phone number will be shared with the host so they can get back to you. Standard text messaging rates may apply."
      title=""
      onSend={onSend}
    />
  )
}

export default connect(null, { setOverlay, makeReviewReminderRequest })(
  withPropsFromLocation(['isUrgent', 'topics'])(ContactSendToPhoneBifurcated),
)
