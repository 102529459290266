import React, { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'
import { TrackLink } from '../metrics'
import { metrics } from '../../api'
import '../../styles/SlideshowServicePromo.scss'
import {
  ServiceIcon, PromoStar, RecommendedBadgeIcon, PhoneIcon,
} from '../icons'
import { ANALYTICS, RECOMMENDATION_ROUTES, ANALYTICS_ENTITY_TYPES } from '../../constants/appConstants'
import { formatPrice } from '../../utils/formatters'
import QR from '../QRCode'

import { MESSAGES } from '../../constants/messages'

class AdvertisementContent extends Component {
  componentDidMount() {
    const { advertisement } = this.props
    metrics.track('impression', {
      extra: {
        source: ANALYTICS.ADVERTISEMENT,
        name: ANALYTICS.VIEW_ADVERTISEMENT,
        ad_name: advertisement.title,
      },
      entityId: advertisement.id,
      entityType: ANALYTICS_ENTITY_TYPES.ADVERTISEMENT,
    })
  }

  render() {
    const {
      id, interactive, setInteractive, advertisement, orgName,
    } = this.props
    const {
      title,
      message,
      promotion,
      description,
      serviceId,
      customizationId,
      link,
      style,
      imageURL,
      subType,
      service,
    } = advertisement
    
    const className = classNames('SlideshowPromo', {
      interactive,
      'ad-original': style === 'original'
    })

    let to
    let heading
    let priceService = {
      price: '',
      priceType: '',
    }
    if (message || link) {
      const textMessage = `From ${decodeURIComponent(encodeURIComponent(orgName))}:\n${decodeURIComponent(
        encodeURIComponent(message),
      )}`
      to = {
        pathname: `/artwork/${id}/phone`,
        state: {
          message: textMessage,
          link,
          title,
          advertisementId: advertisement.id,
        },
      }
    }
    if (serviceId) {
      to = `/services/${serviceId}?advertisementId=${advertisement.id}`

      if (service.template === 'bundle') {
        const { price, priceType } = formatPrice({
          currency: 'US',
          ...service,
        })
        priceService.price = price
        priceService.priceType = priceType
      } else if (service.feeType === 'free') {
        priceService.price = 'Free'
      } else if (service.price) {
        priceService = formatPrice({
          currency: 'US',
          ...service,
        })
        if (priceService.priceType === 'daily') {
          priceService.priceType = 'per day'
        } else if (priceService.priceType === 'hourly') {
          priceService.priceType = 'per hour'
        }
      }

      heading = (
        <span>
          <ServiceIcon />
          {' '}
          {MESSAGES.OUR_SERVICES}
        </span>
      )
    }
    if (customizationId) {
      const customizationType = RECOMMENDATION_ROUTES[subType]
      to = `/recommendations/${customizationType}/${customizationId}?advertisementId=${advertisement.id}`
      heading = (
        <span>
          <RecommendedBadgeIcon />
          {' '}
          {MESSAGES.OUR_RECOMMENDATIONS}
        </span>
      )
    }
    let buttonText = 'View Details'
    if (!serviceId && !customizationId) {
      buttonText = (
        <span>
          <PhoneIcon />
          {' '}
          <span style={{ marginLeft: 8 }}>{MESSAGES.SEND_DETAILS_TO_PHONE}</span>
        </span>
      )
    }
    
    if (style === 'portrait') {
      return (
        <div>
          <Card centered className={className}>
            <section className="ad-content-section section-portrait">
              {promotion && (
                <h3>
                  <PromoStar />
                  {`${MESSAGES.PROMO}: ${promotion}`}
                </h3>
              )}
              <div className="service-name">{(serviceId || customizationId) && heading}</div>
              <Card.Content>
                <Card.Header>{title}</Card.Header>
                {serviceId && <h6>{`${priceService.price} ${priceService.priceType}`}</h6>}
                <p>{description}</p>
                {to ? (
                  <div className="btn-with-qr">
                    {advertisement.link && (
                      <QR
                        url={advertisement.link}
                        source="Advertisement"
                        name={title}
                        advertisementId={advertisement.id}
                      />
                    )}
                    <TrackLink
                      onClick={setInteractive}
                      className={classNames(
                        'button',
                        // { customAdBtn: !customizationId && !serviceId },
                        // { 'justify-left': advertisement.link }
                      )}
                      to={to}
                      name={title}
                      source="Advertisement"
                      entityId={advertisement.id}
                      entityType={ANALYTICS_ENTITY_TYPES.ADVERTISEMENT}
                    >
                      {buttonText}
                    </TrackLink>
                  </div>
                ) : null}
              </Card.Content>
            </section>
          </Card>
        </div>
      )
    }
    return (
      <div className={className}>
        <div className="ImageContainer">
          <img src={imageURL} alt="square-ad" />
        </div>
        <section className="ad-content-section">
          <div className="ad-content">
            {promotion && (
              <h3>
                <PromoStar />
                {`${MESSAGES.PROMO}: ${promotion}`}
              </h3>
            )}
            <div className="ad-main-content">
              <h1>
                {(serviceId || customizationId) && heading}
                <strong>{title}</strong>
              </h1>
              {serviceId && <h6>{`${priceService.price} ${priceService.priceType}`}</h6>}
              <p>{description}</p>
            </div>
            {to ? (
              <div className="btn-with-qr">
                {advertisement.link && (
                  <QR url={advertisement.link} source="Advertisement" name={title} advertisementId={advertisement.id} />
                )}
                <TrackLink
                  onClick={setInteractive}
                  className={classNames(
                    'button',
                    // { customAdBtn: !customizationId && !serviceId },
                    // { 'justify-left': advertisement.link }
                  )}
                  to={to}
                  name={title}
                  source="Advertisement"
                  entityId={advertisement.id}
                  entityType={ANALYTICS_ENTITY_TYPES.ADVERTISEMENT}
                >
                  {buttonText}
                </TrackLink>
              </div>
            ) : null}
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state, { placeholderIndex }) => {
  const advertisements = state.property.advertisement
  const index = Math.abs(placeholderIndex) % advertisements.length
  const advertisement = advertisements[index]
  const orgName = state.property.organization.name
  return {
    advertisement,
    orgName,
  }
}

export default connect(mapStateToProps)(AdvertisementContent)
