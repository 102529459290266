import React from 'react'
import Icon from './Icon'

const VideoPauseIcon = props => (
  <Icon viewBox="0 0 164 164" width={160} height={160} fill="none" {...props}>
    <circle cx="81.5" cy="81.5" r="76.5" stroke="white" strokeOpacity="0.5" strokeWidth="10" />
    <path
      d="M64.5312 48.5C62.0677 48.724 60.724 50.0677 60.5 52.5312V108.969C60.724 111.432 62.0677 112.776 64.5312 113C66.9948 112.776 68.3385 111.432 68.5625 108.969V52.5312C68.3385 50.0677 66.9948 48.724 64.5312 48.5ZM99.4688 48.5C97.0052 48.724 95.6615 50.0677 95.4375 52.5312V108.969C95.6615 111.432 97.0052 112.776 99.4688 113C101.932 112.776 103.276 111.432 103.5 108.969V52.5312C103.276 50.0677 101.932 48.724 99.4688 48.5Z"
      fill="white"
      fillOpacity="0.7"
    />
  </Icon>
)

export default VideoPauseIcon
