import React from 'react'

const FrigidIcon = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5_502)">
      <path d="M13.92 18.34C11.96 18.34 10.37 16.74 10.37 14.79C10.37 13.12 11.53 11.7 13.12 11.34C13.53 9.38 15.26 8 17.29 8C19.32 8 20.97 9.36 21.43 11.24H21.66C23.62 11.24 25.21 12.84 25.21 14.79C25.21 16.74 23.62 18.34 21.66 18.34H13.92ZM14.2 12.29C14.2 12.39 14.17 12.42 14.07 12.42L13.68 12.47C12.5 12.57 11.57 13.58 11.57 14.78C11.57 16.07 12.62 17.1 13.91 17.1H21.65C22.94 17.1 23.99 16.07 23.99 14.78C23.99 13.49 22.94 12.47 21.65 12.47H20.49C20.41 12.47 20.36 12.42 20.36 12.34L20.31 11.93C20.13 10.36 18.84 9.2 17.27 9.2C15.7 9.2 14.39 10.36 14.23 11.93L14.18 12.29H14.2Z" fill="white" />
      <path d="M13.45 19.57C13.28 19.4 13 19.4 12.83 19.57L12.54 19.86L12.25 19.57C12.08 19.4 11.8 19.4 11.63 19.57C11.55 19.65 11.5 19.76 11.5 19.88C11.5 20 11.55 20.11 11.63 20.19L11.92 20.48L11.63 20.77C11.55 20.85 11.5 20.96 11.5 21.08C11.5 21.2 11.55 21.31 11.63 21.39C11.79 21.56 12.08 21.56 12.25 21.39L12.54 21.1L12.83 21.39C12.91 21.47 13.02 21.52 13.14 21.52C13.26 21.52 13.37 21.47 13.45 21.39C13.62 21.22 13.62 20.94 13.45 20.77L13.16 20.48L13.45 20.19C13.62 20.02 13.62 19.74 13.45 19.57Z" fill="white" />
      <path d="M16.29 22.33C16.12 22.16 15.84 22.17 15.67 22.33L15.38 22.62L15.09 22.33C15.01 22.25 14.9 22.2 14.78 22.2C14.66 22.2 14.55 22.24 14.47 22.33C14.3 22.5 14.3 22.78 14.47 22.95L14.76 23.24L14.47 23.53C14.3 23.7 14.3 23.98 14.47 24.15C14.64 24.31 14.92 24.31 15.09 24.15L15.38 23.86L15.67 24.15C15.75 24.23 15.86 24.28 15.98 24.28C16.1 24.28 16.2 24.24 16.29 24.15C16.46 23.98 16.46 23.7 16.29 23.53L16 23.24L16.29 22.95C16.46 22.78 16.46 22.51 16.29 22.33Z" fill="white" />
      <path d="M18.79 19.57C18.62 19.4 18.34 19.4 18.17 19.57L17.88 19.86L17.59 19.57C17.42 19.4 17.14 19.4 16.97 19.57C16.8 19.74 16.8 20.02 16.97 20.19L17.26 20.48L16.97 20.77C16.8 20.94 16.8 21.22 16.97 21.39C17.05 21.47 17.16 21.52 17.28 21.52C17.4 21.52 17.51 21.47 17.59 21.39L17.88 21.1L18.17 21.39C18.33 21.56 18.62 21.56 18.79 21.39C18.96 21.22 18.96 20.94 18.79 20.77L18.5 20.48L18.79 20.19C18.96 20.02 18.96 19.74 18.79 19.57Z" fill="white" />
      <path d="M10.94 22.33C10.77 22.16 10.49 22.17 10.32 22.33L10.03 22.62L9.74 22.33C9.57 22.16 9.29 22.17 9.12 22.33C9.05 22.41 9 22.52 9 22.63C9 22.75 9.04 22.86 9.13 22.94L9.42 23.23L9.13 23.52C9.05 23.61 9 23.72 9 23.83C9 23.95 9.04 24.06 9.13 24.14C9.29 24.31 9.58 24.3 9.74 24.14L10.03 23.85L10.32 24.14C10.4 24.22 10.51 24.27 10.63 24.27C10.75 24.27 10.86 24.23 10.94 24.14C11.11 23.97 11.11 23.69 10.94 23.52L10.65 23.23L10.94 22.94C11.11 22.77 11.11 22.5 10.94 22.32V22.33Z" fill="white" />
      <path d="M21.46 22.32C21.29 22.15 21.01 22.15 20.84 22.32L20.55 22.61L20.26 22.32C20.09 22.15 19.81 22.15 19.64 22.32C19.56 22.4 19.51 22.51 19.51 22.63C19.51 22.75 19.56 22.86 19.64 22.94L19.93 23.23L19.64 23.52C19.56 23.6 19.51 23.71 19.51 23.83C19.51 23.95 19.56 24.06 19.64 24.14C19.8 24.3 20.09 24.31 20.26 24.14L20.55 23.85L20.84 24.14C20.92 24.22 21.03 24.27 21.15 24.27C21.27 24.27 21.38 24.22 21.46 24.14C21.63 23.97 21.63 23.69 21.46 23.52L21.17 23.23L21.46 22.94C21.63 22.77 21.63 22.49 21.46 22.32Z" fill="white" />
      <path d="M23.66 20.48L23.95 20.19C24.12 20.02 24.12 19.74 23.95 19.57C23.78 19.4 23.5 19.4 23.33 19.57L23.04 19.86L22.75 19.57C22.58 19.4 22.3 19.4 22.13 19.57C22.05 19.65 22 19.76 22 19.88C22 20 22.05 20.11 22.13 20.19L22.42 20.48L22.13 20.77C22.05 20.85 22 20.96 22 21.08C22 21.2 22.05 21.31 22.13 21.39C22.29 21.56 22.58 21.56 22.75 21.39L23.04 21.1L23.33 21.39C23.41 21.47 23.52 21.52 23.64 21.52C23.76 21.52 23.87 21.48 23.95 21.39C24.12 21.22 24.12 20.94 23.95 20.77L23.66 20.48Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_5_502">
        <rect width="16.22" height="16.27" fill="white" transform="translate(9 8)" />
      </clipPath>
    </defs>
  </svg>
)
export default FrigidIcon
