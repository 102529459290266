import React from 'react'
import classNames from 'classnames'
import { MoreLink } from '../information'
import '../../styles/SectionTitle.scss'
import '../../styles/MoreLink.scss'

const SectionTitle = ({
  title, bold, more, analytics,
}) => (
  <div className="SectionTitle">
    <div
      className={classNames('title', {
        bold,
      })}
    >
      {title}
    </div>
    {more && <MoreLink to={more} analytics={analytics} />}
  </div>
)

export default SectionTitle

SectionTitle.defaultProps = {
  bold: true,
  more: false,
  analytics: true,
}
