import React, { Component } from 'react'

import '../styles/TextSwitch.scss'

export default class TextSwitch extends Component {
  render() {
    const {
      on, off, value, onChange,
    } = this.props
    return (
      <div className="TextSwitch">
        <div className={`mode ${value && 'active'}`} onClick={() => onChange(true)}>
          {on}
        </div>
        <div className="sep">/</div>
        <div className={`mode ${!value && 'active'}`} onClick={() => onChange(false)}>
          {off}
        </div>
      </div>
    )
  }
}
