/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'

import { connectWithPath } from '../../resources/utils'
import HomeIcon from '../../images/icons/icon-map-home@2x.png'

let savedZoom = 17

// all these variables are needed in the Map Component
const shared = {
  loaded: true,
  map: null,
  Marker: null,
  TravelMode: null,
  markers: [],
  center: null,
  directions: {
    display: null,
    service: null,
  },
  infowindow: null,
  infoWindowContent: document.createElement('div'),
}

const sharedMapEl = document.createElement('div')
sharedMapEl.style.width = '100%'
sharedMapEl.style.height = '100%'

try {
  const {
    Map: GMap,
    MapTypeId,
    Marker,
    InfoWindow,
    TravelMode,
    DirectionsService,
    DirectionsRenderer,
    event: GEvent,
  } = window.google.maps

  shared.Marker = Marker
  shared.TravelMode = TravelMode
  shared.directions.service = new DirectionsService()
  shared.infowindow = new InfoWindow()

  shared.infowindow.setContent(shared.infoWindowContent)
  GEvent.addListener(shared.infowindow, 'closeclick', function () {
    shared.directions.display.setMap(null)
  })

  shared.map = new GMap(sharedMapEl, {
    mapTypeId: MapTypeId.ROADMAP,
    zoom: savedZoom,
    fullscreenControl: false,
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ],
  })

  // record changes in zoom and center so we can return to this viewport
  // if the user returns to the map
  shared.map.addListener('center_changed', () => {
    shared.center = shared.map.getCenter()
  })
  shared.map.addListener('zoom_changed', () => {
    savedZoom = shared.map.getZoom()
  })

  // block clicks on standard places of interest
  shared.map.addListener('click', event => {
    if (event.placeId) {
      event.stop()
    }
  })

  // ensure directions are shown on the active map
  shared.directions.display = new DirectionsRenderer()
} catch (error) {
  shared.loaded = false
}

const Map = withRouter(
  class extends Component {
    constructor(props) {
      super(props)
      if (!shared.loaded) {
        throw new Error('Couldnt load google maps')
      }
    }

    componentDidMount() {
      this._map = shared.map

      ReactDOM.findDOMNode(this).appendChild(sharedMapEl)
      const first = this.props.home ? this.props.home : this.props.markers[0]
      if (shared.center) {
        shared.map.setCenter(shared.center)
      } else {
        shared.map.setCenter(first.position)
      }
      shared.directions.display.setOptions({
        suppressMarkers: true,
        preserveViewport: !this.props.showDirections,
      })
      if (this.props.id) {
        this.props.setMapWithCenter && this.props.setMapWithCenter(shared.map, shared.center, savedZoom)
      } else {
        this.props.setMapWithCenter && this.props.setMapWithCenter(shared.map, first.position, 17)
      }
      this.buildMarkersForProps()
    }

    shouldComponentUpdate(nextProps) {
      return JSON.stringify(nextProps) !== JSON.stringify(this.props)
    }

    componentDidUpdate() {
      this.buildMarkersForProps()
    }

    componentWillUnmount() {
      shared.directions.display.setMap(null)
      shared.infowindow.close()
      shared.markers = shared.markers.forEach(m => m.setMap(null))
      shared.markers = []
      sharedMapEl.remove()
      this._map = null
    }

    showDirections = marker => {
      shared.directions.service.route(
        {
          origin: this.props.home.position,
          destination: marker.position,
          travelMode: shared.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK' && this._map) {
            shared.directions.display.setDirections(response)
            shared.directions.display.setMap(this._map)
          } else {
            console.error(`Directions request failed due to ${status}`)
          }
        },
      )
    }

    center = () => {
      const first = this.props.home ? this.props.home : this.props.markers[0]
      this._map.panTo(first.position)
    }

    showInfoPanel = markerOrMarkerConfig => {
      const marker = markerOrMarkerConfig instanceof shared.Marker
        ? markerOrMarkerConfig
        : shared.markers.find(m => m.id === markerOrMarkerConfig.id)

      ReactDOM.render(this.props.markerInfoContentBuilder(marker, this.props.history, this), shared.infoWindowContent)

      shared.infowindow.close()
      shared.infowindow.open(this._map, marker)
      shared.infowindow.markerId = marker.id

      if (!marker.home) {
        this.showDirections(marker)
      }
    }

    buildMarkersForProps() {
      const {
        home: homeConfig, markers: configs, showDirections, id,
      } = this.props
      // remove markers that no longer exist in the marker configs passed
      // in via props.
      shared.markers = shared.markers.filter(m => {
        if (configs.find(c => c.id === m.id) || m.home) {
          return true
        }
        m.setMap(null)
        return false
      })

      // create markers that we don't have that are present in the props
      configs.forEach(config => {
        if (shared.markers.find(m => config.id === m.id)) {
          return
        }
        const marker = new shared.Marker({ map: this._map, ...config })
        marker.addListener('click', () => this.showInfoPanel(marker))
        if (id && config.id.toString() === id) {
          this.showInfoPanel(marker)
        }
        shared.markers.push(marker)
      })

      // add the Home marker if it doesn't exist
      if (homeConfig && !shared.markers.find(m => m.home)) {
        const marker = new shared.Marker({
          map: this._map,
          icon: HomeIcon,
          home: true,
          ...homeConfig,
        })
        const onClick = () => {
          this.center()
          this.showInfoPanel(marker)
        }
        marker.addListener('click', onClick)
        shared.markers.push(marker)
      }

      // if we're supposed to immediately show directions, run the request
      if (showDirections) {
        this.showDirections(configs[0])
      }

      // if we're showing a marker that is no longer on the map, hide the directions and infowindow
      if (!shared.markers.find(m => m.id === shared.infowindow.markerId)) {
        shared.directions.display.setMap(null)
        shared.infowindow.close()
      }
    }

    // Public Component Methods

    render() {
      return <div className="map-element" />
    }
  },
)

export default connectWithPath({ isOnline: 'networkStatus.isOnline' })(({ setMapWithCenter, isOnline, ...props }) => {
  if (isOnline) {
    return <Map {...props} setMapWithCenter={setMapWithCenter} />
  }
  return <div className="map-offline-message">Unable to load map.</div>
})
