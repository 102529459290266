import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { metrics } from '../../api'
import {
  ANALYTICS_ENTITY_TYPES, MESSAGE_CATEGORY, MESSAGE_STATUS,
} from '../../constants/appConstants'
import { Carousel } from '../ui'
import MessageItem from './MessageItem'

const MessageList = ({
  messages, interactive, markMessageAsRead, reservationId,
}) => {
  const [alertExist, setAlertExist] = useState(false)
  const [isLargerContent, setIsLargerContent] = useState(false)
  const [fullScreenMode, setFullScreenMode] = useState(false)

  useEffect(() => {
    const isAlert = messages.findIndex(m => m.category === MESSAGE_CATEGORY.ALERT)
    isAlert > -1 && setAlertExist(true)

    // return () => {
    //   setFullScreenMode(false)
    // }
  }, [messages])

  useEffect(() => {
    const slickDots = document.getElementsByClassName('message-view-outer')[0].getElementsByClassName('slick-dots')
    if (slickDots.length) {
      if (fullScreenMode) {
        slickDots[0].style.visibility = 'hidden'
      } else {
        slickDots[0].style.visibility = 'visible'
      }
    }
  }, [fullScreenMode])

  const updateMessageStatus = (messageId, data) => {
    const {
      status, occurrence, title, category,
    } = data
    metrics.track('click', {
      extra: {
        source: 'Read Announcement',
        name: 'Read Announcement',
        announcement_name: title,
      },
      entityId: messageId,
      entityType: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
      referer: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
      status,
      title,
      occurrence,
      category,
      analytics: false,
    })

    markMessageAsRead(messageId, data)
  }

  return (
    <div className={classNames('message-view-outer is-history',
      { 'msg-type-alert': alertExist },
      { interactive })}
    >
      <div className="message-backdrop" />
      <div
        className={`message-outer
                ${interactive && 'interactive'} ${isLargerContent ? 'text-extend' : ''}
                ${fullScreenMode ? 'full-screen-mode' : ''}
                `}
      >
        <Carousel swipeToSlide={false} swipe={!fullScreenMode}>
          {messages.map(message => {
            const {
              title, content, category, datetime, receivedAt, id, media, qrCode, externalLink,
            } = message

            return (
              <MessageItem
                key={id}
                title={message.title}
                id={id}
                reservationId={reservationId}
                content={content}
                category={category}
                media={media}
                qrCode={qrCode}
                externalLink={externalLink}
                recordAnalytics
                onGotIt={() => {
                  const data = {
                    title,
                    category,
                    status: MESSAGE_STATUS.READ,
                    type: datetime.type,
                    reservationId,
                    occurrence: receivedAt || new Date().toISOString(),
                  }
                  updateMessageStatus(id, data)
                }}
                setIsLargerContent={setIsLargerContent}
                setFullScreenMode={setFullScreenMode}
              />
            )
          })}
        </Carousel>
      </div>
    </div>
  )
}

export default MessageList
