import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { authenticate } from '../utils/helpers'
import CodeInputOutputScreen from '../components/CodeInputOutputScreen'
import CodeInputOutput from '../components/CodeInputOutput'
import '../styles/Buttons.scss'
import okIcon from '../images/onboarding/ok.png'
import { MESSAGES } from '../constants/messages'

const PasscodeSimpleContainer = ({ history, location, pin }) => {
  const confirmFn = output => authenticate({
    passcode: output,
    pin,
  }).then(res => {
    const { next } = location.state
    if (next.startsWith('http')) {
      window.location.href = next
    } else {
      history.push(next)
    }
    return res
  })

  const confirmButton = <img className="confirm-button-img" alt="next" src={okIcon} />
  const inputOutputComponent = (
    <CodeInputOutput outputType="passcode" confirmFn={confirmFn} confirmButton={confirmButton} />
  )
  const backComponent = (
    <div className="link" onClick={history.goBack}>
      {MESSAGES.BACK}
    </div>
  )

  return (
    <CodeInputOutputScreen
      title="Enter your passcode"
      subtitle="This will allow you to access settings on the Virtual Concierge"
      inputOutputComponent={inputOutputComponent}
      moreOptionsComponent={(
        <Link className="link" to="/send-to-phone-simple">
          {MESSAGES.GET_PASSCODE_VIA_TEXT}
        </Link>
      )}
      backComponent={backComponent}
    />
  )
}

export default connect(state => ({
  pin: state.property.devicePIN,
}))(PasscodeSimpleContainer)
