import { keyBy } from 'lodash'

import { PROPERTY_FETCH_SUCCESS } from '../../actions/actionTypes'
import { isBeforeOrderDeadline } from '../../utils/services'

const initialState = {}

const smart = (service, reservation = {}) => {
  const {
    promote, orderDeadline, template, enabled,
  } = service

  let notTooLate = isBeforeOrderDeadline(reservation, orderDeadline)
  if (template === 'late_checkout') {
    notTooLate = !isBeforeOrderDeadline(reservation, 1440) // 24 Hours in minutes
  }

  return {
    homepage: enabled && notTooLate && promote.includes('homepage'),
    slideshow: enabled && notTooLate && promote.includes('slideshow'),
    checkout: enabled && notTooLate && promote.includes('checkout'),
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PROPERTY_FETCH_SUCCESS:
      const { property } = action
      const services = property.services.map(service => ({
        ...service,
        position: service.property_service.sortOrder,
        smart: smart(service, property.reservation || {}),
      }))
      return keyBy(services, 'id')

    default:
      return state
  }
}
