import React, { useEffect, useState } from 'react'
import {
  get, map, toLower, uniq,
} from 'lodash'
import classNames from 'classnames'
import { useQueryParam, ArrayParam } from 'use-query-params'
import { Link } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { PageHeader } from '../components/pages'
import { HostQuote } from '../components'
import { useCustomization } from '../hooks/useCustomization'
import { Carousel } from '../components/ui'

import { BackIcon, FilterIcon, RecommendedBadgeIcon } from '../components/icons'
import { RecommendationFilter } from '../components/recommendations'
import { sortRecommendationList } from '../utils/helpers'
import yelpImageForRating from '../components/pages/YelpRating'
import yelpLogo from '../images/yelp-logo.png'
import { setOverlay } from '../actions/device'
import '../styles/RecommendationsListContainer.scss'
import { Mixpanel } from '../api/mixpanel'
import placeholderImage from '../images/image-placeholder.png'
import { MESSAGES } from '../constants/messages'

const HEADER = {
  restaurants: {
    title: 'Local Restaurants',
    quoteKey: 'restaurant',
  },
  activities: {
    title: 'Local Activities',
    quoteKey: 'activity',
  },
  directory: {
    title: 'Directory',
    quoteKey: 'directory',
  },
}

const RecommendationsListContainer = ({
  match: { params }, location, setOverlay, resetIdleTimer,
}) => {
  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])

  const { yelpRecommendations } = useSelector(state => state.property.organization)

  const { section } = params
  const quotes = useCustomization('recommendationQuotes') // TODO: Change key to recommendationQuotes
  let recommendations = useCustomization(section)

  const categories = uniq(map(recommendations, 'content.category'))

  if (toLower(section) === 'restaurants') {
    const recommendedRestaurants = recommendations.filter(recommendation => recommendation.content.isRecommended)
    if (!yelpRecommendations) {
      if (recommendedRestaurants.length < 3) {
        recommendations = recommendations.slice(0, 3)
      } else {
        recommendations = recommendations.slice(0, recommendedRestaurants.length)
      }
    }
  }

  const [isFilter, setIsFiltering] = useState(false)
  const [filter, setFilter] = useQueryParam('filters', ArrayParam)
  const filteredItems = filter
    ? recommendations.filter(recommendation => filter.includes(recommendation.content.category))
    : recommendations

  const trackRecommendationDetails = recommName => {
    Mixpanel.track('Click Recommendation Detail', {
      name: 'Recommendation Detail',
      section,
      source: 'Recommendation List',
      recommendationName: recommName,
    })
  }
  const sortedItems = sortRecommendationList(filteredItems);

  return (
    <div className="RecommendationsListContainer Card full-height">
      <header className="card-section header">
        <PageHeader title={HEADER[section].title}>
          <div className="legend-position">
            <span>{MESSAGES.OUR_RECOMMENDED}</span>
            <RecommendedBadgeIcon className="recommend-status-legend" width={48} height={50} />
          </div>
          <HostQuote quote={quotes[HEADER[section].quoteKey]} />
        </PageHeader>
      </header>
      <section className="card-section stretch column recommendations">
        <div className="carousel-controls">
          <div>
            All
            {' '}
            {section}
          </div>
          <div
            className={classNames('filter-contol', {
              'filters-applied': filter,
            })}
            onClick={() => setIsFiltering(!isFilter)}
          >
            Filters
            {' '}
            <FilterIcon />
          </div>
        </div>
        {isFilter ? (
          <RecommendationFilter
            categories={categories}
            setIsFiltering={setIsFiltering}
            filter={filter}
            setFilter={setFilter}
          />
        ) : (
          <>
            <Carousel className="recommendations-carousel" rows={5} columns={1}>
              {sortedItems.map(recommendation => {
                const price = get(recommendation, 'content.providerData.price', '')
                const priceString = price ? `${price} •` : ''
                const priceAndCategory = `${priceString} ${recommendation.content.category}`
                const rating = get(recommendation, 'content.providerData.rating')
                const isRecommended = get(recommendation, 'content.isRecommended')
                return (
                  // TODO: Tracelink
                  <Link
                    className="recommdation-item"
                    to={{
                      pathname: `/recommendations/${section}/${recommendation.id}`,
                      search: location.search,
                    }}
                    data-cy="recommendation-item"
                    onClick={() => trackRecommendationDetails(recommendation.name)}
                  >
                    <img
                      className="recommendation-image"
                      data-cy="recommendation-image"
                      src={recommendation?.content?.imageURL || placeholderImage}
                      alt="recommendation"
                    />
                    <div className="recommendation-info">
                      <div className="title" data-cy="recommendation-name">
                        {recommendation.name}
                      </div>
                      <div className="price-category">{priceAndCategory}</div>
                      <div className="rating">{rating && yelpImageForRating(rating)}</div>
                    </div>
                    {isRecommended && (
                      <div className="recommend-status">
                        <RecommendedBadgeIcon width={38} height={40} />
                      </div>
                    )}
                  </Link>
                )
              })}
            </Carousel>
            {yelpRecommendations && <img className="yelp-logo" alt="yelp-logo" src={yelpLogo} />}
          </>
        )}
      </section>
      {!isFilter && (
        <footer className="card-section footer">
          <Link className="link with-icon" to="/recommendations" replace>
            <BackIcon />
            Recommendations
          </Link>
        </footer>
      )}
    </div>
  )
}

export default connect(null, {
  setOverlay,
})(RecommendationsListContainer)
