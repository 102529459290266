import React, { useState, useEffect } from 'react'
import OutputField from './OutputField'
import BackspaceIcon from './screen-keyboard/icons/BackspaceIcon'

import { formatPhoneNumber, formatCCode } from '../utils/formatters'

import '../styles/CodeInputOutput.scss'
import '../styles/CodeInputOutputScreen.scss'
import '../styles/NumberPad.scss'
import '../styles/Buttons.scss'
import { MESSAGES } from '../constants/messages'

const TYPE = {
  passcode: {
    maxLength: 6,
    outputFieldType: 'split',
  },
  phone: {
    minLength: 10,
    maxLength: 12,
    title: 'Mobile Phone',
    subtitle: 'Your phone number won\'t be shared. Your wireless carrier may charge you standard text messaging rates.',
    format: output => formatPhoneNumber(output),
  },
  'c-code': {
    maxLength: 6,
    format: output => formatCCode(output),
  },
}

const CodeInputOutput = ({ outputType, confirmButton, confirmFn }) => {
  const [output, setOutput] = useState('')
  const [error, setError] = useState('')
  const [isValid, setIsValid] = useState(false)

  const { maxLength } = TYPE[outputType]
  const minLength = TYPE[outputType].minLength ? TYPE[outputType].minLength : maxLength

  const backspaceComponent = (
    <div className="icon">
      <BackspaceIcon />
    </div>
  )
  const clearComponent = <div className="text">{MESSAGES.CLEAR}</div>

  const onConfirm = async output => {
    try {
      const response = await confirmFn(output) /* eslint-disable-line no-unused-vars */
    } catch (err) {
      if (err) {
        err.response ? setError(err.response.data.human || err.response.data.error) : setError(err.toString())
      } else {
        setError('Error, with your request!')
      }
    }
  }

  const addNumber = num => {
    if (output.length < maxLength) {
      const newOutput = output.concat(num)
      setOutput(newOutput)
    } else if (error) {
      setOutput(num)
    }
    setError('')
  }

  const removeNumber = () => {
    if (output.length > 0) {
      const newOutput = output.substr(0, output.length - 1)
      setOutput(newOutput)
    }
    setError('')
  }

  const clearOutput = () => {
    setOutput('')
    setError('')
  }

  const checkIsValid = () => {
    if (output.length >= minLength && output.length <= maxLength) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  useEffect(() => {
    checkIsValid(output)
  }, [output]) /* eslint-disable-line react-hooks/exhaustive-deps */

  const NUMS = [
    {
      label: '1',
      fn: num => addNumber(num),
    },
    {
      label: '2',
      fn: num => addNumber(num),
    },
    {
      label: '3',
      fn: num => addNumber(num),
    },
    {
      label: '4',
      fn: num => addNumber(num),
    },
    {
      label: '5',
      fn: num => addNumber(num),
    },
    {
      label: '6',
      fn: num => addNumber(num),
    },
    {
      label: '7',
      fn: num => addNumber(num),
    },
    {
      label: '8',
      fn: num => addNumber(num),
    },
    {
      label: '9',
      fn: num => addNumber(num),
    },
    {
      label: backspaceComponent,
      fn: () => removeNumber(),
    },
    {
      label: '0',
      fn: num => addNumber(num),
    },
    {
      label: clearComponent,
      fn: () => clearOutput(),
    },
  ]

  return (
    <div className="CodeInputOutput">
      <div className="output-container">
        <OutputField output={output} error={error} {...TYPE[outputType]} />
      </div>
      <div className="input-container">
        <div className="NumberPad">
          {NUMS.map((num, index) => {
            const value = num.label
            const onclick = num.fn
            return (
              <div key={index} className="number-pad-input-item" onClick={onclick ? () => onclick(value) : null}>
                {value}
              </div>
            )
          })}
        </div>
      </div>
      <div className="confirm-container">
        <button className="confirm-button" onClick={() => onConfirm(output)} disabled={!isValid}>
          {confirmButton}
        </button>
      </div>
    </div>
  )
}

export default CodeInputOutput
