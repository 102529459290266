import React from 'react'

import {
  SettingsFooter, PropertySection, TimezoneSection, WifiSection, DeviceSection,
} from '../components/settings'
import { MESSAGES } from '../constants/messages'

const SettingsPrivateContainer = () => {
  return (
    <div className="SettingsContainer Win10Theme">
      <h1>{MESSAGES.ADMIN_SETTINGS}</h1>

      <PropertySection />
      <TimezoneSection />
      <WifiSection requirePasscode={false} />
      <DeviceSection />

      <SettingsFooter label="Back" to="/settings" />
    </div>
  )
}

export default SettingsPrivateContainer
