import React, { Component } from 'react'
import classNames from 'classnames'
import '../../styles/PageComponents.scss'

class PageHeader extends Component {
  render() {
    const {
      title, subtitle, hr, children,
    } = this.props

    return (
      <div
        className={classNames('PageHeader', {
          hr,
        })}
      >
        {title && <h1 className="title not-truncate">{title}</h1>}
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {children}
      </div>
    )
  }
}

export default PageHeader

PageHeader.defaultProps = {
  hr: false,
}
