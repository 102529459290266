import React from 'react'
import Icon from './Icon'

{ /* <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1030_1584)">
<path d="M9 6.75V10.5M9 13.125V13.5" stroke="#5591C7" stroke-width="1.5" stroke-linecap="round"/>
<path d="M1.67415 14.2617L7.76265 2.2887C8.2974 1.23645 9.70515 1.23645 10.2391 2.2887L16.3269 14.2617C16.8376 15.2697 16.1581 16.4997 15.0871 16.4997H2.91239C1.84214 16.4997 1.1619 15.2697 1.6749 14.2617H1.67415Z" stroke="#5591C7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1030_1584">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg> */ }

const AlertIcon = () => (
  <Icon width="66" height="71" viewBox="0 0 18 18" fill="none">
    <g clipPath="url(#clip0_1030_1584)">
      <path d="M9 6.75V10.5M9 13.125V13.5" stroke="#5591C7" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M1.67415 14.2617L7.76265 2.2887C8.2974 1.23645 9.70515 1.23645 10.2391 2.2887L16.3269 14.2617C16.8376 15.2697 16.1581 16.4997 15.0871 16.4997H2.91239C1.84214 16.4997 1.1619 15.2697 1.6749 14.2617H1.67415Z"
        stroke="#5591C7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1030_1584">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)

export default AlertIcon
