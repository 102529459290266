export const EventName = {
  'Home Button': 'Click Home Button',
  'Property Info': 'Click Property Info',
  Policies: 'Click Policy',
  'House Rules': 'Click House Rules',
  Supplies: 'Click Supplies',
  'Checkout Instructions': 'Click Checkout Instructions',
  'Check In': 'Click Check In',
  Services: 'Click Services',
  Amenities: 'Click Amenities',
  'Check Out Now': 'Click Check Out Now',
  'Check Out': 'Click Check Out',
  'Contact Us': 'Click Contact Us',
  'Contact Us Submit': 'Contact Us Request',
  'Map & Nearby': 'Click Maps & Nearby',
  Restaurants: 'Click Restaurants',
  Directory: 'Click Directory',
  Activities: 'Click Activities',
  'View More': 'Click View More',
  'View More Recommendation': 'Click View More Recommendations',
  phone: 'Click Send',
  videoWatched: 'Video Watched',
  videoSkipped: 'Click Video Skip',
  videoPlayed: 'Welcome Video Played',
  videoRewatched: 'Welcome Video Rewatched',
  impression: 'Impression',
  'Display Announcement': 'Display Announcement',
  'Read Announcement': 'Click Read Announcement',
  'Expired Announcement': 'Expired Announcement',
}

export const ignoreEvents = ['Click HomeContainer', 'Click Next','Click Home Next Arrow','Click Home Previous Arrow']
