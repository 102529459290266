import React, { Component } from 'react'
import { DirectoryIcon } from './icons'
import walkBlueIcon from '../images/icons/icon-walk-blue@2x.png'
import carBlueIcon from '../images/icons/icon-car-blue@2x.png'

let DirectionsService = null
let loaded = true
let TravelMode = null
try {
  DirectionsService = window.google.maps.DirectionsService
  TravelMode = window.google.maps.TravelMode
} catch (error) {
  loaded = false
}

function approxDistanceInKilometers({ origin, destination }) {
  const olat = origin.lat instanceof Function ? origin.lat() : origin.lat
  const olng = origin.lat instanceof Function ? origin.lng() : origin.lng
  const dlat = destination.lat instanceof Function ? destination.lat() : destination.lat
  const dlng = destination.lng instanceof Function ? destination.lng() : destination.lng

  const deg2rad = deg => deg * (Math.PI / 180)
  const R = 6371 // Radius of the earth in km
  const deltaLat = deg2rad(dlat - olat) // deg2rad below
  const deltaLon = deg2rad(dlng - olng)
  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)
    + Math.cos(deg2rad(olat)) * Math.cos(deg2rad(dlat)) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

export default class TravelTimeEstimate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      distance: '— mi',
      duration: '— min',
    }

    if (!loaded) {
      throw new Error('Couldnt load google maps')
    }
  }

  componentDidMount() {
    this._mounted = true
    this._runRoute()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      this._runRoute(nextProps)
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  _runRoute({ origin, destination, forceTravelMode } = this.props) {
    const directionsService = new DirectionsService()

    const travelWalkingMax = 2.0 // 2km is a about 20-min walk
    const travelMode = forceTravelMode
                     || (approxDistanceInKilometers({
                       origin,
                       destination,
                     }) > travelWalkingMax
                       ? TravelMode.DRIVING
                       : TravelMode.WALKING)

    directionsService.route(
      {
        origin,
        destination,
        travelMode,
      },
      (response, status) => {
        if (!this._mounted) {
          return
        }
        if (status === 'OK' && response.routes.length > 0) {
          const leg = response.routes[0].legs[0]
          this.setState({
            travelMode,
            distance: leg.distance.text,
            duration: leg.duration.text,
          })
        } else {
          this.setState({
            travelMode,
            distance: 'Unknown',
            duration: 'Unknown',
          })
        }
      },
    )
  }

  render() {
    const { hideDuration } = this.props
    return (
      <div className="TravelTimeEstimate">
        {hideDuration ? (
          <DirectoryIcon size="45" fill="white" dotColor="black" />
        ) : (
          <img
            src={
                             this.state.travelMode === TravelMode.WALKING
                               ? walkBlueIcon
                               : carBlueIcon
                           }
            alt="distance"
            style={{
              height: 34,
              marginRight: 7,
              verticalAlign: 'bottom',
            }}
          />
        )}
        {' '}
        {hideDuration ? (
          <div>{this.state.distance}</div>
        ) : (
          <div>
            {this.state.duration}
            {' '}
            •
            {this.state.distance}
            {' '}
          </div>
        )}
      </div>
    )
  }
}
