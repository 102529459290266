import React from 'react'

import { connectWithPath } from '../../resources/utils'
import { address } from '../../utils/formatters'
import { unpair } from '../../actions/property'
import { detachSerialNumber } from '../../actions/device'
import SettingsSection from './SettingsSection'
import BlueButton from '../BlueButton'
import { MESSAGES } from '../../constants/messages'
import { sendLogsToServer } from '../../utils/helpers'

const PropertySection = ({ property, unpair, detachSerialNumber }) => {
  const [line1, line2] = address(property)

  const propertySubtitle = (
    <>
      <div>{`${MESSAGES.PAIRED_WITH}:`}</div>
      <strong>
        <div>{line1}</div>
        <div>{line2}</div>
      </strong>
    </>
  )

  const unpairDevice = async () => {
    // await sendLogsToServer()
    await detachSerialNumber()
    unpair()
    window.location.reload()
  }

  return (
    <SettingsSection title="Property" subtitle={propertySubtitle}>
      {property ? (
        <BlueButton label="Unpair Device" onClick={unpairDevice} />
      ) : (
        <BlueButton label="Pair Device" to="device-pair-enter" />
      )}
    </SettingsSection>
  )
}

export default connectWithPath(
  {
    property: 'property',
  },
  {
    detachSerialNumber,
    unpair,
  },
)(PropertySection)
