import React from 'react'

const MessageContent = ({ content }) => {
  const isShortText = content.length <= 300
  return (
    <div className="message-body has-grey-scroll">
      <div
        className={`announcement-description ${isShortText ? 'text-center' : ''}`}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  )
}

export default MessageContent;
