import React, { useState } from 'react'
import classNames from 'classnames'
import { MESSAGES } from '../../constants/messages'

const RecommendationFilter = ({
  categories, setIsFiltering, filter, setFilter,
}) => {
  const [filters, setFilters] = useState(filter || [])

  const onSelectFilter = category => {
    if (filters && filters.includes(category)) {
      const newFilters = filters.filter(filter => filter !== category)
      setFilters(newFilters)
    } else {
      const newFilters = [...filters, category]
      setFilters(newFilters)
    }
  }

  const onCancel = () => {
    setIsFiltering(false)
  }

  const onApply = () => {
    setFilter(filters)
    setIsFiltering(false)
  }

  const onClearAll = () => {
    setFilters([])
  }

  return (
    <div className="RecommendationFilter">
      <div className="inner-card-section header no-padding-x border-top">
        <h1 className="title">Filters</h1>
        <h3 className="meta-title">
          Categories
          {' '}
          <span>
            (
            {filters.length}
            {' '}
            Selected)
          </span>
        </h3>
      </div>
      <div className=" inner-card-section stretch no-padding-x">
        <div className="categories">
          {categories.map(category => (
            <div
              className={classNames('category', {
                selected: filters && filters.includes(category),
              })}
              onClick={() => onSelectFilter(category)}
            >
              {category}
            </div>
          ))}
        </div>
      </div>
      <div className="inner-card-section footer no-padding-x">
        <button className="button invert" onClick={onClearAll}>
          {MESSAGES.CLEAR_ALL}
        </button>
        <div className="cancel-apply">
          <button className="button invert" onClick={onCancel}>
            {MESSAGES.CANCEL}
          </button>
          <button className="button" onClick={onApply}>
            {MESSAGES.APPLY}
          </button>
        </div>
      </div>
    </div>
  )
}

export default RecommendationFilter
