import React from 'react'
import Icon from './Icon'

const MuteIcon = props => (
  <Icon viewBox="0 0 42 42" width={40} height={40} fill="none" {...props}>
    <path
      d="M28.4219 6V16.625L31 18.5V6C30.9479 4.95833 30.4531 4.20312 29.5156 3.73438C29.2031 3.57812 28.8646 3.5 28.5 3.5C27.875 3.5 27.3281 3.70833 26.8594 4.125L20.2188 10.0625L22.1719 11.625L28.4219 6ZM38.5 21C38.5 22.0417 38.1875 22.9531 37.5625 23.7344L39.5156 25.2969C40.4531 24.0469 40.9479 22.6146 41 21C40.9479 18.4479 39.8542 16.4427 37.7188 14.9844C37.0938 14.6198 36.5208 14.724 36 15.2969C35.6354 15.974 35.7656 16.5469 36.3906 17.0156C37.7448 18.0052 38.4479 19.3333 38.5 21ZM46 21C45.9479 23.7604 45.0885 26.2344 43.4219 28.4219L45.4531 29.9844C47.4323 27.3281 48.4479 24.3333 48.5 21C48.5 18.5 47.9271 16.1823 46.7812 14.0469C45.5833 11.9115 43.9427 10.1406 41.8594 8.73438C41.1823 8.36979 40.6094 8.47396 40.1406 9.04688C39.776 9.72396 39.9062 10.2969 40.5312 10.7656C42.25 11.9635 43.5781 13.4479 44.5156 15.2188C45.5052 16.9896 46 18.9167 46 21ZM28.4219 35.9219L17.1719 25.9219H9.75C8.96875 25.8698 8.55208 25.4531 8.5 24.6719V17.1719C8.55208 16.4427 8.96875 16.026 9.75 15.9219H11.625L8.65625 13.6562C6.98958 14.2292 6.10417 15.4271 6 17.25V24.75C6.05208 25.7917 6.41667 26.651 7.09375 27.3281C7.82292 28.0573 8.70833 28.4219 9.75 28.4219H16.3125L26.8594 37.7969C27.3281 38.2656 27.875 38.5 28.5 38.5C28.8646 38.5 29.2031 38.4219 29.5156 38.2656C30.4531 37.7969 30.9479 37.0417 31 36V31.3125L28.5 29.3594L28.4219 35.9219ZM50.5312 38.7344L3.03125 1.23438C2.40625 0.817708 1.80729 0.895833 1.23438 1.46875C0.817708 2.09375 0.895833 2.69271 1.46875 3.26562L48.9688 40.7656C49.2292 40.9219 49.4635 41 49.6719 41C50.0885 41 50.4271 40.8438 50.6875 40.5312C51.1562 39.8542 51.1042 39.2552 50.5312 38.7344Z"
      fill="white"
    />
  </Icon>
)

export default MuteIcon
