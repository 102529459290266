import { cloneDeep } from 'lodash'
import winstonLogger from './winstonLogger'

const partialMask = ['pin', 'passcode']

const partialMaskPattern = new RegExp(`\"(?:(${partialMask.join('|')})":\s?").*?(.{2})\"`, 'gm')

const mask = obj => {
  let str = JSON.stringify(obj)
  str = str.replace(partialMaskPattern, '"$1":"***$2"')
  return JSON.parse(str)
}

const parseLogObj = obj => {
  const parsedObj = {
    module: obj.module || null,
    dateTime: obj.dateTime || new Date(),
    sessionId: obj.sessionId || null,
    metadata: obj.metadata || null,
    message: obj.message || null,
    error: obj.error || null,
    apiInfo: obj.apiInfo || null,
    httpResponse: obj.httpResponse || null,
  }
  if (parsedObj.sessionId) {
    parsedObj.sessionId = mask(parsedObj.sessionId)
  }
  if (parsedObj.metadata) {
    parsedObj.metadata = mask(parsedObj.metadata)
  }
  return parsedObj
}

const Logger = {
  http(obj) {
    const httpObj = parseLogObj(cloneDeep(obj))
    const httpLog = `${httpObj.module} <=> ${httpObj.dateTime}
        ${httpObj.sessionId ? `Session ID: ${JSON.stringify(httpObj.sessionId)}` : ''}
        ${httpObj.metadata ? `Metadata: ${JSON.stringify(httpObj.metadata)}` : ''}
        API Details: ${JSON.stringify(httpObj.apiInfo)}
        ${httpObj.httpResponse ? `Response: ${JSON.stringify(httpObj.httpResponse)}` : ''}`

    winstonLogger.http(httpLog)
  },

  error(obj) {
    const errObj = parseLogObj(cloneDeep(obj))
    const errLog = `${errObj.module} <=> ${errObj.dateTime}
        ${errObj.sessionId ? `Session ID: ${JSON.stringify(errObj.sessionId)}` : ''}
        ${errObj.metadata ? `Metadata: ${errObj.metadata}` : ''}
        Error: ${errObj.error}`
    winstonLogger.error(errLog)
  },
}

export default Logger
