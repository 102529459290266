import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { get } from 'lodash'
import { Address, Reservation } from '..'
import '../../styles/Welcome.scss'
import { CheckedCircleFilledIcon } from '../icons'
import { MESSAGES } from '../../constants/messages'

const Welcome = () => {
  const { state } = useLocation()
  const justCheckedIn = get(state, 'justCheckedIn')
  const noGuestMode = localStorage.getItem('noGuestMode')

  const [showCheckedIn, setShowCheckedIn] = useState()

  useEffect(() => {
    let timeout
    if (justCheckedIn) {
      setShowCheckedIn(true)
      timeout = setTimeout(() => {
        setShowCheckedIn(false)
      }, 2500)
    }
    return () => clearTimeout(timeout)
  }, [justCheckedIn])

  return (
    <div className="Welcome">
      {showCheckedIn ? (
        <div className="checked-in-feedback">
          <CheckedCircleFilledIcon />
          {MESSAGES.YOU_ARE_CHECKED_IN}
        </div>
      ) : (
        <>
          <div className="welcome-text">{`${MESSAGES.WELCOME_TO}:`}</div>
          <Address />
          {!noGuestMode && <Reservation />}
        </>
      )}
    </div>
  )
}

export default Welcome
