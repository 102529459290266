import React from 'react'
import classNames from 'classnames'
import '../../styles/Icon.scss'

const Icon = props => {
  let { width } = props
  let { height } = props

  if (props.size) {
    width = `${props.size}px`
    height = `${props.size}px`
  }

  let { viewBox } = props
  if (!viewBox && props.size) {
    viewBox = `0 0 ${props.size} ${props.size}`
  }

  const { size, ...rest } = props

  return (
    <svg {...rest} className={classNames('Icon', props.className)} width={width} height={height} viewBox={viewBox} />
  )
}

Icon.defaultProps = {
  width: '24px',
  height: '24px',
}

export default Icon
