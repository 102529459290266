import { get } from 'lodash'
import { PROPERTY_FETCH_SUCCESS } from '../actions/actionTypes'

const TOGGLE_CHECKED = 'checklists/TOGGLE_CHECKED'
const RESET = 'checklists/RESET'

// TODO PR should we have actions for each checklist:
// toggleContact
// toggleCheckout
// resetContact
// resetCheckout
export const toggleChecked = (checklist, item) => ({
  type: TOGGLE_CHECKED,
  payload: {
    checklist,
    item,
  },
})

export const resetChecklist = checklist => ({
  type: RESET,
  payload: {
    checklist,
  },
})

const initialState = {
  checkout: [],
  contact: [],
}

export default function (state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case PROPERTY_FETCH_SUCCESS:
      const topics = get(action.property, ['customizations', 'contact', 'topics'], [])
      const checkoutItems = get(action.property, ['checkout', 'checklist'], [])
      return {
        ...state,
        checkout: checkoutItems.map(checkoutItem => {
          const existing = state.checkout.find(({ text }) => text === checkoutItem.text) || {}
          return {
            ...existing,
            ...checkoutItem,
          }
        }),
        contact: topics.map(topic => {
          const existing = state.contact.find(({ text }) => text === topic.text) || {}
          return {
            ...existing,
            ...topic,
          }
        }),
      }

    case RESET:
      return {
        ...state,
        [payload.checklist]: (state[payload.checklist] || []).map(item => ({
          ...item,
          checked: false,
        })),
      }

    case TOGGLE_CHECKED:
      return {
        ...state,
        [payload.checklist]: (state[payload.checklist] || []).map(item => {
          if (item === payload.item) {
            return {
              ...item,
              checked: !item.checked,
            }
          }
          return item
        }),
      }

    default:
      return state
  }
}
