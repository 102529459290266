import React from 'react'
import Icon from './Icon'

const SleepIcon = props => (
  <Icon width="1em" height="1em" viewBox="0 0 18 20" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-12.000000, -8.000000)">
        <path
          d="M19.9992676,14 C19.9992676,11.7487882 20.7431572,9.67132108 21.9985352,8.00000003 C16.4760243,8.00039582 11.9992676,12.4773966 11.9992676,18 C11.9992676,23.5228475 16.4764201,28 21.9992676,28 C25.2709033,28 28.1755873,26.4288934 30,24 C24.4764201,24 19.9992676,19.5228475 19.9992676,14 Z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </g>
  </Icon>
)

export default SleepIcon
