import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './styles/ErrorPage.scss'

class ErrorPage extends Component {
  navHomeAndReload = () => {
    this.props.history.push('/')
    window.location.reload()
  }

  render() {
    return (
      <div className="ErrorComponent">
        <h4>
          Oops, something went wrong at
          {this.props.component}
          .
        </h4>
        <h5>Our engineers have been notified.</h5>
        <button onClick={this.navHomeAndReload}>Return to the Home page.</button>
      </div>
    )
  }
}
export default withRouter(ErrorPage)
