import React from 'react'
import Icon from './Icon'

const PhoneReviewIcon = props => (
  <Icon viewBox="0 0 171 300" {...props}>
    <g stroke="#FEFEFE" strokeWidth={3} fill="none" fillRule="evenodd" strokeLinecap="square">
      <path d="M172.501 55.5H142.5M202.5 28.5h-60M115.5 15.048c0-7.483 6.004-13.548 13.412-13.548h87.176c7.407 0 13.412 6.065 13.412 13.548v54.194c0 7.483-6.005 13.548-13.412 13.548h-70.412L115.5 106.5V15.048z" />
      <path d="M172.5 114v162c0 12.428-10.073 22.5-22.5 22.5H24c-12.427 0-22.5-10.072-22.5-22.5V24C1.5 11.574 11.572 1.5 24 1.5h49.5M102 276H76.5" />
    </g>
  </Icon>
)

export default PhoneReviewIcon
