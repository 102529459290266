import axios from 'axios'
import Logger from '../logger/logger'
import { createErrorLogs, createRequestLogs, createResponseLogs } from '../logger'

const device = axios.create({
  // this can be useful if you want to fake device (on your laptop),
  // Go into nec-backend/routes/master/index.js and add a route like that:
  // router.get('/concierge', (req, res) => res.json({ serialNumber: '1234566' }))
  // baseURL: 'http://localhost:5000/api/master',
  baseURL: 'http://localhost:6001/api',
})

device.interceptors.request.use(config => {
  Logger.http({
    module: config.url,
    metadata: config.data,
    apiInfo: {
      endpoint: `${config.baseURL}${config.url}`,
      method: config.method,
    },
  })
  return config
})

device.interceptors.response.use(
  response => {
    Logger.http({
      module: response.url,
      apiInfo: {
        endpoint: `${response.baseURL}${response.url}`,
        method: response.method,
      },
      httpResponse: response.data,
    })
    return response
  },
  error => {
    createErrorLogs(error)
    return error
  },
)

export default device
