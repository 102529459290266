import React from 'react'

import {
  ClearIcon,
  CloudyIcon,
  FogIcon,
  NoForecastIcon,
  PartlyCloudyIcon,
  RainIcon,
  SnowIcon,
  ScatteredThunderstormsIcon,
  WindyIcon,
  HeavyRainIcon,
  SleetIcon,
  MostlyCloudyIcon,
  HailIcon,
  SevereThunderstormsIcon,
  HurricaneIcon,
  DustIcon,
  SmokeIcon,
  DrizzleIcon,
  FlurriesIcon,
  HeavySnowIcon,
  SnowShowerIcon,
  BlizzardIcon,
  FrigidIcon,
  HotIcon,
  TropicalStormIcon,
  ScatteredSnowShowersIcon,
} from './icons'

const ICONS = {
  Clear: ClearIcon,
  Rain: RainIcon,
  HeavyRain: HeavyRainIcon,
  Snow: SnowIcon,
  Sleet: SleetIcon,
  Windy: WindyIcon,
  Haze: FogIcon,
  Fog: FogIcon,
  Cloudy: CloudyIcon,
  MostlyCloudy: MostlyCloudyIcon,
  PartlyCloudy: PartlyCloudyIcon,
  Hail: HailIcon,
  ScatteredThunderstorms: ScatteredThunderstormsIcon,
  IsolatedThunderstorms: SevereThunderstormsIcon,
  SevereThunderstorm: SevereThunderstormsIcon,
  Tornado: HurricaneIcon,
  Dust: DustIcon,
  MostlyClear: ClearIcon,
  Smoke: SmokeIcon,
  Breezy: WindyIcon,
  Drizzle: DrizzleIcon,
  Showers: RainIcon,
  Flurries: FlurriesIcon,
  HeavySnow: HeavySnowIcon,
  MixedRainAndSleet: HeavySnowIcon,
  MixedRainAndSnow: HeavySnowIcon,
  MixedRainfall: ScatteredThunderstormsIcon,
  MixedSnowAndSleet: HeavySnowIcon,
  ScatteredShowers: ScatteredThunderstormsIcon,
  ScatteredSnowShowers: ScatteredSnowShowersIcon,
  SnowShowers: SnowShowerIcon,
  Blizzard: BlizzardIcon,
  BlowingSnow: HeavySnowIcon,
  FreezingDrizzle: HeavySnowIcon,
  FreezingRain: RainIcon,
  Frigid: FrigidIcon,
  Hot: HotIcon,
  Hurricane: HurricaneIcon,
  TropicalStorm: TropicalStormIcon,
}

const WeatherIcon = ({ icon, ...props }) => {
  const Icon = ICONS[icon] || undefined
  if(!Icon) return <div/> //temporary change
  return <Icon className="WeatherIcon" {...props} />
}

export default WeatherIcon
