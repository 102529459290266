import React from 'react'
import classNames from 'classnames'
import { Icon } from 'semantic-ui-react'
import '../styles/Checkbox.scss'

const Checkbox = ({
  title, label, onClick, checked,
}) => (
  <div className="checkbox">
    <div>{title}</div>
    <div>
      <div onClick={onClick} className="accept-checkbox standard-category">
        <Icon
          name={classNames('square', {
            check: checked,
          })}
        />
        {label}
      </div>
    </div>
  </div>
)

export default Checkbox
