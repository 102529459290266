import React from 'react'

import SettingsSection from './SettingsSection'
import BlueButton from '../BlueButton'

const TimezoneSection = () => {
  const now = new Date()
  const ampm = now.getHours() >= 12 ? 'pm' : 'am'

  const timezoneName = now.toString().match(/\(([A-Za-z\s].*)\)/)[1]
  const timezoneOffset = now.toString().match(/([-+][0-9]+)\s/)[1]
  const hours = now.getHours() % 12 || 12
  const minutes = `${now.getMinutes() < 10 ? '0' : ''}${now.getMinutes()}${ampm}`
  return (
    <SettingsSection title="Time Zone" subtitle={`${timezoneName}, ${timezoneOffset} (${hours}:${minutes})`}>
      <BlueButton label="Edit Time Zone" to="http://localhost:3000/timezonecontrol" />
    </SettingsSection>
  )
}

export default TimezoneSection
