import React from 'react'

const Clear = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2_3)">
      <path d="M18.4665 28.7858C14.3799 28.7858 10.5739 26.6028 8.53277 23.0883C5.37364 17.6487 7.25519 10.6679 12.7269 7.52735C14.5642 6.47327 16.6679 5.94414 18.7794 6.00495C19.0747 6.01334 19.3327 6.20416 19.4262 6.48306C19.5198 6.76126 19.4277 7.06812 19.1963 7.25055C15.9043 9.848 15.0085 14.5396 17.1137 18.1639C18.638 20.7886 21.4815 22.4194 24.5344 22.4194C25.6102 22.4194 26.6628 22.2201 27.6626 21.8273C27.9375 21.7197 28.2504 21.7938 28.4466 22.0132C28.6428 22.2327 28.68 22.5508 28.5394 22.8087C27.5339 24.6624 26.0286 26.2009 24.1871 27.2578C22.4455 28.2573 20.4676 28.7858 18.4679 28.7858H18.4665ZM16.9126 7.51547C15.6927 7.7042 14.5093 8.118 13.43 8.73731C8.6291 11.4927 6.97887 17.6173 9.74987 22.39C11.5407 25.4733 14.8806 27.3892 18.4665 27.3892C20.2201 27.3892 21.9547 26.9258 23.4833 26.0485C24.5654 25.4278 25.5146 24.6177 26.288 23.6629C25.7115 23.7656 25.1258 23.8173 24.5337 23.8173C20.9801 23.8173 17.6705 21.9189 15.8959 18.8629C14.6935 16.7932 14.285 14.3628 14.7442 12.0198C15.0718 10.3471 15.8241 8.79812 16.9126 7.51617V7.51547Z" fill="white" />
      <path d="M24.931 15.5545L23.2238 15.3078L22.461 13.77C22.437 13.7211 22.3871 13.691 22.3323 13.691C22.2774 13.691 22.2275 13.7218 22.2036 13.77L21.4407 15.3078L19.7335 15.5545C19.6794 15.5622 19.6344 15.5999 19.6175 15.6517C19.6007 15.7034 19.6147 15.76 19.6541 15.7978L20.8895 16.9944L20.5977 18.6846C20.5885 18.7377 20.6103 18.7922 20.6546 18.8244C20.6792 18.8426 20.7088 18.8517 20.739 18.8517C20.7615 18.8517 20.7847 18.8468 20.8058 18.8356L22.3323 18.038L23.8588 18.8356C23.8799 18.8468 23.9024 18.8517 23.9256 18.8517C23.9551 18.8517 23.9846 18.8426 24.0099 18.8244C24.0542 18.7922 24.076 18.7384 24.0669 18.6846L23.7751 16.9944L25.0105 15.7978C25.0498 15.76 25.0632 15.7034 25.047 15.6517C25.0302 15.5999 24.9852 15.5622 24.931 15.5545Z" fill="white" />
      <path d="M19.6485 12.3587C19.6386 12.4153 19.6618 12.4727 19.7089 12.5062C19.7349 12.5251 19.7666 12.5349 19.7982 12.5349C19.8221 12.5349 19.8467 12.5293 19.8685 12.5174L20.7081 12.0791L21.5476 12.5174C21.5701 12.5293 21.594 12.5349 21.6179 12.5349C21.6495 12.5349 21.6805 12.5251 21.7072 12.5062C21.7536 12.4727 21.7775 12.4153 21.7677 12.3587L21.6074 11.4298L22.2866 10.7713C22.3281 10.7315 22.3428 10.6714 22.3252 10.6168C22.3077 10.5623 22.2599 10.5225 22.2029 10.5141L21.2642 10.3785L20.8445 9.5327C20.8192 9.48098 20.7657 9.44882 20.7081 9.44882C20.6504 9.44882 20.5977 9.48167 20.5717 9.5327L20.1519 10.3785L19.2139 10.5141C19.157 10.5225 19.1092 10.5623 19.0916 10.6168C19.074 10.6714 19.0888 10.7315 19.1296 10.7713L19.8088 11.4298L19.6485 12.3587Z" fill="white" />
      <path d="M28.993 14.5857C28.9754 14.5312 28.9276 14.4913 28.8706 14.483L27.9319 14.3474L27.5122 13.5023C27.4869 13.4505 27.4341 13.4184 27.3758 13.4184C27.3174 13.4184 27.2654 13.4512 27.2394 13.5023L26.8196 14.3474L25.8816 14.483C25.8247 14.4913 25.7769 14.5312 25.7593 14.5857C25.7417 14.6402 25.7565 14.7003 25.798 14.7402L26.4772 15.3979L26.3169 16.3269C26.307 16.3835 26.3309 16.4401 26.3773 16.4744C26.4041 16.4933 26.435 16.503 26.4666 16.503C26.4905 16.503 26.5152 16.4975 26.5369 16.4856L27.3765 16.0466L28.216 16.4856C28.2378 16.4975 28.2624 16.503 28.2863 16.503C28.318 16.503 28.3489 16.4933 28.3756 16.4744C28.422 16.4408 28.4459 16.3835 28.4361 16.3269L28.2758 15.3979L28.955 14.7402C28.9965 14.7003 29.0112 14.6402 28.9937 14.5857H28.993Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2_3">
        <rect width="22" height="22.7857" fill="white" transform="translate(7 6)" />
      </clipPath>
    </defs>
  </svg>
)
export default Clear
