import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import 'semantic-ui-css/semantic.min.css'

import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import App from './App'

import OfflineListener from './components/OfflineListener'

import reducers from './reducers'
import Playground from './playground'
import { Device, ServiceWorker, Metadata } from './components'
import { SlideshowProvider } from './hooks/useSlideshow'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))

if (window.location.href.includes('/playground')) {
  ReactDOM.render(<Playground />, document.getElementById('root'))
} else {
  ReactDOM.render(
    <ServiceWorker>
      <Metadata />
      <Provider store={store}>
        <>
          <Device />
          <OfflineListener>
            <SlideshowProvider>
              <BrowserRouter>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <App />
                </QueryParamProvider>
              </BrowserRouter>
            </SlideshowProvider>
          </OfflineListener>
        </>
      </Provider>
    </ServiceWorker>,
    document.getElementById('root'),
  )
}
