import moment from 'moment'
import { SERVICES_APPROVAL_PENDING_STATUSES } from '../constants/appConstants'
import { formatPrice } from './formatters'

export const lateCheckoutThresholdHours = 10
export const lateCheckoutKey = 'late_checkout'

export function isBeforeOrderDeadline({ endDate, checkoutTime }, orderDeadline) {
  if (endDate && checkoutTime && orderDeadline) {
    const checkoutAt = moment(`${endDate} ${checkoutTime}`)
    const minutes = moment.duration(checkoutAt.diff()).asMinutes()
    return minutes >= parseInt(orderDeadline, 10)
  }
  return true
}

export function filterLateCheckoutAvailability(currentReservation, nextReservation, services) {
  /** If there is any reservation after 10 hours of the checkout,
   *  the system will disable the late checkout service and a service message will display.
   *  The guest will not be able to avail the late checkout in this case. */
  services = services.filter(service => {
    if (service.template !== lateCheckoutKey) return true

    const disableOnBtbRes = service.extra && service.extra.disableOnBtbRes
    const isLateCheckoutAvailable = getLateCheckoutAvailability(currentReservation, nextReservation, disableOnBtbRes)
    return isLateCheckoutAvailable
  })
  return services
}

export function getLateCheckoutAvailability(currentReservation, nextReservation, disableOnBtbRes) {
  if (nextReservation) {
    const nextArrivalInHours = moment(nextReservation.checkinDatetime).diff(
      currentReservation.checkoutDatetime,
      'hours',
      true,
    )
    if (nextArrivalInHours <= lateCheckoutThresholdHours && disableOnBtbRes) {
      return false
    }
  }
  return true
}

export function isApprovalPending(serviceRequest, checkoutTime, isCheckOutDay, stayMode) {
  const currentTime = moment()
  const today = moment()
    .utc()
    .format('YYYY-MM-DD')
  const tomorrow = moment()
    .add(1, 'day')
    .utc()
    .format('YYYY-MM-DD')

  const _checkoutTime = moment(`${today} ${checkoutTime}`).add(2, 'hours')
  const _nextCheckoutTime = moment(`${tomorrow} ${checkoutTime}`).add(2, 'hours')

  const _serviceRequestUpdatedTime = moment(serviceRequest?.updatedAt)
  const difference = _checkoutTime.diff(_serviceRequestUpdatedTime, 'minutes', true)
  const isPending = !!SERVICES_APPROVAL_PENDING_STATUSES.includes(serviceRequest?.status)

  if (stayMode) {
    if (difference > 0 && isCheckOutDay && isPending) return true
    if (!isCheckOutDay && isPending) return true
    return false
  }
  if (!stayMode && isPending) {
    if (difference > 0 && _checkoutTime.diff(currentTime, 'minutes', true) > 0) return true
    if (difference < 0 && _nextCheckoutTime.diff(_serviceRequestUpdatedTime, 'minutes', true) > 0) return true
    return false
  }
  return false
}

export function setSeriveChoiceFormValues(props) {
  const {
    serviceChoices, choiceId, key, value, formState, setFormState, allowMultiSelection,
  } = props
  const selectedChoiceObj = serviceChoices.find(sc => sc.id === choiceId)
  const isEntryExist = !!formState.find(entry => entry.id === selectedChoiceObj.id)

  // New entry
  if (!isEntryExist) {
    // SET DEFAULT VALUES FOR A CHOICE
    const inputObj = {
      feeRates: [
        {
          price: Number(selectedChoiceObj?.feeRates[0]?.price),
          feeType: selectedChoiceObj?.feeRates[0]?.feeType,
          customFeeMessage: selectedChoiceObj?.feeRates[0]?.customFeeMessage,
        },
      ],
      quantity: 1,
      title: selectedChoiceObj.title,
      description: selectedChoiceObj.detailHTML,
      id: choiceId,
    }

    if (key === 'quantity') inputObj.quantity = value

    if (key === 'feeRates') {
      const { price, feeType, customFeeMessage } = selectedChoiceObj?.feeRates?.find(fr => fr.feeType === value)
      inputObj.feeRates = [{ price: Number(price), feeType, customFeeMessage }]
    }

    if (key === 'choiceChecked' && allowMultiSelection) {
      inputObj.isChecked = value
    }

    const updatedFormValues = [...formState, inputObj]
    setFormState(updatedFormValues)
  }

  // Update existing choice
  if (isEntryExist) {
    let updatedEntries = JSON.parse(JSON.stringify(formState))
    const toBeUpdated = updatedEntries.find(entry => entry.id === choiceId)
    updatedEntries = updatedEntries.filter(entry => entry.id !== choiceId)

    if (key === 'quantity') toBeUpdated.quantity = value

    if (key === 'feeRates') {
      const { price, feeType, customFeeMessage } = selectedChoiceObj?.feeRates?.find(fr => fr.feeType === value)
      toBeUpdated.feeRates = [{ price: Number(price), feeType, customFeeMessage }]
    }

    if (key === 'choiceChecked' && allowMultiSelection) {
      toBeUpdated.isChecked = value
    }

    const updatedFormValues = [...updatedEntries, toBeUpdated]
    setFormState(updatedFormValues)
  }
}

export function getMinMaxPrices(serviceChoices) {
  if (!serviceChoices.length > 0) return null
  const allPrices = []
  serviceChoices.map(choice => choice.feeRates.map(fr => fr.price && allPrices.push(Number(fr.price))))

  if (!allPrices.length) return

  const { price: maxPrice } = formatPrice({ currency: 'US', price: Math.max(...allPrices) })
  const { price: minPrice } = formatPrice({ currency: 'US', price: Math.min(...allPrices) })
  return { maxPrice: maxPrice && maxPrice !== minPrice ? maxPrice : null, minPrice }
}
