import React from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { SectionTitle } from '../ui'
import { Weather } from '../Weather'
import { LocalRestaurants } from '../information'
import { Welcome, FeaturedContent, ConvenienceContent } from '.'
import ErrorComponent from '../../ErrorComponent'
import { logAppErrors } from '../../utils/helpers'

const HomeContent = ({ organization: { yelpRecommendations }, wifi }) => (
  <>
    <div className={`card-section padding-bottom ${wifi ? '' : 'welcomeBox'}`}>
      <Welcome />
    </div>
    <div className="card-section margin-x no-padding-x border-top border-bottom">
      <Weather />
    </div>
    <div className="HomeContent">
      <div className="main-home-content">
        <div className="featured card-section center-vertically">
          <SectionTitle title="For our guests" />
          <FeaturedContent />
        </div>
        <div className="restaurants card-section center-vertically">
          <LocalRestaurants yelpRecommendations={yelpRecommendations} />
        </div>
        <div className="amenities card-section center-vertically">
          <ConvenienceContent rows={1} columns={2} />
        </div>
      </div>
    </div>
  </>
)

const ComponentWithErrorBoundary = withErrorBoundary(HomeContent, {
  FallbackComponent: props => ErrorComponent({
    ...props,
    component: 'Home Content',
  }),
  onError(error, info) {
    logAppErrors(error, info)
    console.log({
      error,
      info,
    })
    // Do something with the error
    // E.g. log to an error logging client here
  },
})

export default ComponentWithErrorBoundary
