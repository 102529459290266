import React from 'react'
import classNames from 'classnames'

export const SplitField = ({ maxLength, output }) => {
  const outputItems = []

  for (let char = 0; char < maxLength; char++) {
    outputItems.push(
      <div key={char} className="char">
        {output[char]}
      </div>,
    )
  }

  return <div className="field">{outputItems}</div>
}

export const Field = ({ output, format }) => <div className="field normal">{format(output)}</div>

const OutputField = ({
  title, subtitle, outputFieldType, error, ...rest
}) => (
  <div
    className={classNames('output-field', {
      incorrect: error,
    })}
  >
    {title && <div className="field-title">{title}</div>}
    {outputFieldType === 'split' ? <SplitField {...rest} /> : <Field {...rest} />}
    {subtitle && <div className="field-subtitle">{subtitle}</div>}
    <div className="error-message">{error}</div>
  </div>
)

export default OutputField
