import React from 'react'
import Icon from './Icon'

{ /* <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.0002 12.4364L12.9232 11.8979V10.6153C12.9219 9.85252 12.651 9.11476 12.1583 8.53242C11.6656 7.95008 10.983 7.56066 10.2309 7.433V6.30762H9.154V7.433C8.40197 7.56066 7.71928 7.95008 7.22661 8.53242C6.73393 9.11476 6.463 9.85252 6.46169 10.6153V11.8979L5.38477 12.4364V14.923H8.61554V15.9999H10.7694V14.923H14.0002V12.4364ZM12.9232 13.8461H6.46169V13.1019L7.53861 12.5635V10.6153C7.53861 10.0441 7.76553 9.49623 8.16946 9.09231C8.57338 8.68839 9.12122 8.46146 9.69246 8.46146C10.2637 8.46146 10.8115 8.68839 11.2155 9.09231C11.6194 9.49623 11.8463 10.0441 11.8463 10.6153V12.5635L12.9232 13.1019V13.8461Z" fill="#5591C7"/>
<path d="M12.9231 3.0767C12.9231 2.79108 12.8096 2.51716 12.6077 2.3152C12.4057 2.11324 12.1318 1.99977 11.8462 1.99977H9.69231V0.922852H8.61538V1.99977H4.30769V0.922852H3.23077V1.99977H1.07692C0.791305 1.99977 0.517386 2.11324 0.315423 2.3152C0.113461 2.51716 0 2.79108 0 3.0767V13.8459C0 14.1315 0.113461 14.4055 0.315423 14.6074C0.517386 14.8094 0.791305 14.9229 1.07692 14.9229H3.23077V13.8459H1.07692V3.0767H3.23077V4.15362H4.30769V3.0767H8.61538V4.15362H9.69231V3.0767H11.8462V6.30747H12.9231V3.0767Z" fill="#5591C7"/>
</svg> */ }

const ReminderIcon = () => (
  <Icon width="66" height="71" viewBox="0 0 14 16" fill="none">
    <path
      d="M14.0002 12.4364L12.9232 11.8979V10.6153C12.9219 9.85252 12.651 9.11476 12.1583 8.53242C11.6656 7.95008 10.983 7.56066 10.2309 7.433V6.30762H9.154V7.433C8.40197 7.56066 7.71928 7.95008 7.22661 8.53242C6.73393 9.11476 6.463 9.85252 6.46169 10.6153V11.8979L5.38477 12.4364V14.923H8.61554V15.9999H10.7694V14.923H14.0002V12.4364ZM12.9232 13.8461H6.46169V13.1019L7.53861 12.5635V10.6153C7.53861 10.0441 7.76553 9.49623 8.16946 9.09231C8.57338 8.68839 9.12122 8.46146 9.69246 8.46146C10.2637 8.46146 10.8115 8.68839 11.2155 9.09231C11.6194 9.49623 11.8463 10.0441 11.8463 10.6153V12.5635L12.9232 13.1019V13.8461Z"
      fill="#5591C7"
    />
    <path
      d="M12.9231 3.0767C12.9231 2.79108 12.8096 2.51716 12.6077 2.3152C12.4057 2.11324 12.1318 1.99977 11.8462 1.99977H9.69231V0.922852H8.61538V1.99977H4.30769V0.922852H3.23077V1.99977H1.07692C0.791305 1.99977 0.517386 2.11324 0.315423 2.3152C0.113461 2.51716 0 2.79108 0 3.0767V13.8459C0 14.1315 0.113461 14.4055 0.315423 14.6074C0.517386 14.8094 0.791305 14.9229 1.07692 14.9229H3.23077V13.8459H1.07692V3.0767H3.23077V4.15362H4.30769V3.0767H8.61538V4.15362H9.69231V3.0767H11.8462V6.30747H12.9231V3.0767Z"
      fill="#5591C7"
    />
  </Icon>
)

export default ReminderIcon
