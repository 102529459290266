import { get, set } from 'lodash'

// Abstraction on top of localStorage to store a object and it takes care of [de]serializing
// localStorage value
class Storage {
  constructor(key, defaults = {}) {
    this._key = key
    this._defaults = defaults
  }

  set(path, value) {
    const data = this._json()
    set(data, path, value)
    return localStorage.setItem(this._key, JSON.stringify(data))
  }

  get(path, defaults) {
    return get(this._json(), path, defaults)
  }

  remove(path) {
    return this.set(path)
  }

  _json() {
    return JSON.parse(localStorage.getItem(this._key) || `${JSON.stringify(this._defaults)}`)
  }
}

export default Storage
