import React, { useEffect, useRef, useState } from 'react'
import {
  SoundMuteIcon, SoundIcon, VideoPauseIcon, VideoPlayIcon, CloseIcon,
} from '../icons'
import FullScreenVideoIcon from '../icons/FullScreenVideoIcon'

// let showControlsTimer
// const CONTROLS_HIDE_TIME = 5 * 1000

const ServiceVideoPlayer = ({ item: { mediaUrl }, setFullScreen, isVideo }) => {
  const [showControls, setShowControls] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [muted, setMuted] = useState(false)
  const videoRef = useRef(null)
  const [fullScreen, setfullScreen] = useState(false)

  const showControlHandler = () => {
    if (showControls === false) setShowControls(true)
    // setShowControls(!showControls)
    // clearTimeout(showControlsTimer)
    // showControlsTimer = setTimeout(() => {
    //   setShowControls(false)
    // }, CONTROLS_HIDE_TIME)
  }

  const playPauseHandler = () => {
    // event.stopPropagation()
    const _isPaused = videoRef.current.paused
    if (_isPaused) {
      videoRef.current.play()
      // showControlHandler()
      setShowControls(false)
    } else {
      videoRef.current.pause()
      setShowControls(true)
    }
    setIsPlaying(_isPaused)
  }

  const onEnded = () => {
    setShowControls(true)
    setIsPlaying(false)
  }

  const muteHandler = e => {
    e.stopPropagation()
    setMuted(!muted)
  }

  const fullScreenHandler = e => {
    e.stopPropagation()
    setfullScreen(!fullScreen)
    setFullScreen(!fullScreen)
  }

  useEffect(() => {
    if (isVideo && !videoRef.current.paused) {
      playPauseHandler()
    }
  }, [isVideo])

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(showControlsTimer)
  //   }
  // }, [])

  return (
    <div onClick={showControlHandler} className="video-player">
      <span className="exit-fullscreen-video" onClick={fullScreenHandler}>
        <CloseIcon />
      </span>
      <div className={`controls ${showControls ? 'showControls' : ''}`}>
        {!isLoading && (
          <div className="video-play-pause" onClick={playPauseHandler}>
            {isPlaying ? (
              <VideoPauseIcon className="video-play-pause" />
            ) : (
              <VideoPlayIcon className="video-play-pause" />
            )}
          </div>
        )}
      </div>
      <div
        className="mute-container"
        onClick={muteHandler}
        style={{
          zIndex: 11,
          position: 'absolute',
          right: 20,
          bottom: 20,
        }}
      >
        {muted ? <SoundMuteIcon /> : <SoundIcon />}
      </div>
      <div
        onClick={fullScreenHandler}
        className="full-screen-video"
        style={{
          zIndex: 11,
          position: 'absolute',
          right: 95,
          bottom: 32,
        }}
      >
        <FullScreenVideoIcon />
      </div>
      {isLoading && (
        <div className="controls showControls ">
          <div className="videoLoading">
            <div className="videoLoader" />
          </div>
        </div>
      )}
      <video
        disablePictureInPicture
        muted={muted}
        className="video-carousel"
        ref={videoRef}
        controlsList="nodownload nofullscreen noremoteplayback"
        onLoadStart={setIsLoading.bind(this, true)}
        onLoadedData={setIsLoading.bind(this, false)}
        onWaiting={setIsLoading.bind(this, true)}
        onCanPlay={setIsLoading.bind(this, false)}
        onEnded={onEnded}
        src={mediaUrl}
      />
    </div>
  )
}

export default ServiceVideoPlayer
