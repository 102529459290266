import React, { Component } from 'react'
import { get } from 'lodash'
import DisplayWifi from '../DisplayWifi'
import { TrackLink } from '../metrics'
import { OrganizationBranding, Greeting, CheckInButton } from '.'
import { GVGIcon } from '../icons'

import '../../styles/Checkin.scss'
import 'animate.css'
import { MESSAGES } from '../../constants/messages'

class CheckIn extends Component {
  render() {
    const { welcome } = this.props
    const text = get(welcome, 'text')
    const showWifiInfo = get(welcome, 'showWifiInfo')

    return (
      <div className="CheckIn">
        {showWifiInfo && <DisplayWifi theme="checkin" />}
        <OrganizationBranding />
        <Greeting />
        <div className="message">{text}</div>
        <CheckInButton />
        <div className="gvg-branding">
          <div className="gvg-icon">
            <GVGIcon />
          </div>
          <div className="gvg-text">{MESSAGES.YOU_ARE_VIEWING_GVG}</div>
        </div>
        <div className="terms-of-service card-padding">
          {MESSAGES.BY_TAPPING_CHECKIN}
          <TrackLink to="/onboarding/terms" data-cy="link-terms-of-service" name="Terms of Service" source="CheckIn">
            {MESSAGES.TERMS_AND_CONDITIONS}
          </TrackLink>
        </div>
      </div>
    )
  }
}
export default CheckIn
