import React, { useEffect, useRef, useState } from 'react'
import { metrics } from '../api'
import { ANALYTICS_ENTITY_TYPES } from '../constants/appConstants'
import '../styles/VideoPlayer.scss'
import {
  MuteIcon, SkipIcon, UnmuteIcon, VideoPauseIcon, VideoPlayIcon,
} from './icons'
import overLayIcon from '../images/play-overlay.png'

let showControlsTimer
let videoExitTimer
let countdownTimer
const CONTROLS_HIDE_TIME = 5 * 1000
const VIDEO_EXIT_TIME = 5 * 60 * 1000
const COUNTDOWN_TIME = 1 * 1000

const padLeft = (string, pad, length) => (new Array(length + 1).join(pad) + string).slice(-length)

const getCountdownTime = (duration, currentTime) => {
  if (!currentTime) return null
  const time = Math.round(duration - currentTime)
  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60
  const finalTime = `${padLeft(minutes, '0', 2)}:${padLeft(seconds, '0', 2)}`
  return finalTime
}

const WelcomeVideo = ({
  openVideo,
  preventSkip,
  preventMute,
  poster,
  video,
  url,
  id,
  message,
  source,
  onEnded = () => null,
}) => {
  const [open, setOpen] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [showControls, setShowControls] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)
  const [countdownTime, setCountdownTime] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const videoRef = useRef(null)

  const muteHandler = () => {
    setIsMuted(!isMuted)
    showControlHandler()
  }

  const onEndedHandler = () => {
    if (openVideo) {
      onEnded()
    } else {
      setOpen(false)
    }
  }

  const onSkip = () => {
    onEndedHandler()
    metrics.track('videoSkipped', {
      extra: {
        source,
        name: 'Welcome Video',
      },
      entityId: id,
      entityType: ANALYTICS_ENTITY_TYPES.WELCOME_VIDEO,
    })
  }

  const onVideoEnded = () => {
    source === 'CheckIn'
      && metrics.track('videoWatched', {
        extra: {
          source,
          name: 'Welcome Video',
        },
        entityId: id,
        entityType: ANALYTICS_ENTITY_TYPES.WELCOME_VIDEO,
      })
    onEndedHandler()
  }

  const showControlHandler = () => {
    if (!showControls) setShowControls(true)
    clearTimeout(showControlsTimer)
    showControlsTimer = setTimeout(() => {
      setShowControls(false)
    }, CONTROLS_HIDE_TIME)
  }

  const playPauseHandler = () => {
    const _isPaused = videoRef.current.paused
    showControlHandler()

    _isPaused ? videoRef.current.play() : videoRef.current.pause()
    setIsPlaying(_isPaused)

    if (!_isPaused) {
      videoExitTimer = setTimeout(() => {
        onEndedHandler()
      }, VIDEO_EXIT_TIME)
    } else clearTimeout(videoExitTimer)
  }

  const startCountDownTimer = () => {
    const timer = () => {
      if (!videoRef.current.paused) {
        const updatedCountdownTime = getCountdownTime(videoRef.current.duration, videoRef.current.currentTime)
        updatedCountdownTime && setCountdownTime(updatedCountdownTime)
      }
      countdownTimer = setTimeout(timer, COUNTDOWN_TIME)
    }
    timer()
  }

  const init = () => {
    videoRef.current.play()
    setOpen(true)
    startCountDownTimer()
  }

  useEffect(() => {
    if (openVideo) init()
    return () => {
      clearTimeout(showControlsTimer)
      clearTimeout(videoExitTimer)
      clearTimeout(countdownTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openVideo])

  return (
    <div onClick={!open ? init : showControlHandler} className={`video-player ${open && 'fullscreen'}`}>
      {open && (
        <>
          <div className={`controls ${showControls && 'showControls'}`}>
            {!preventMute && (
              <div className="video-mute" onClick={muteHandler}>
                {isMuted ? <MuteIcon className="video-mute-icon" /> : <UnmuteIcon className="video-mute-icon" />}
              </div>
            )}
            {!countdownTime ? null : (
              <div className="video-countdown-time">
                <p className="video-control-text">{`${countdownTime}`}</p>
              </div>
            )}
            {!preventSkip && (
              <div className="video-skip" onClick={onSkip}>
                <p className="video-control-text">Skip</p>
                <SkipIcon className="video-skip-icon" />
              </div>
            )}
            {!isLoading && (
              <div className="video-play-pause" onClick={playPauseHandler}>
                {isPlaying ? (
                  <VideoPauseIcon className="video-play-pause" />
                ) : (
                  <VideoPlayIcon className="video-play-pause" />
                )}
              </div>
            )}
          </div>
          <div className="controls showControls">
            <div className="video-message">
              <p
                className="video-control-text"
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            </div>
          </div>
        </>
      )}
      {isLoading && (
        <div className="controls showControls ">
          <div className="videoLoading">
            <div className="videoLoader" />
          </div>
        </div>
      )}
      <div
        className="video-placeholder"
        style={{
          backgroundImage: `url(${poster})`,
        }}
      />
      <div
        className="video-placeholder play-overlay"
        style={{
          backgroundImage: `url(${overLayIcon})`,
        }}
      />
      <video
        muted={isMuted}
        disablePictureInPicture="true"
        ref={videoRef}
        controlsList="nodownload nofullscreen noremoteplayback"
        onLoadStart={setIsLoading.bind(this, true)}
        onLoadedData={setIsLoading.bind(this, false)}
        onWaiting={setIsLoading.bind(this, true)}
        onCanPlay={setIsLoading.bind(this, false)}
        onEnded={onVideoEnded}
      >
        <source src={video || url} />
      </video>
    </div>
  )
}

export default WelcomeVideo
