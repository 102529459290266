import React from 'react'

const HeavyRainIcon = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2_12)">
      <path d="M12.1613 23.2624V26.3864C12.1613 26.7253 12.4359 27 12.7748 27C13.1138 27 13.3884 26.7253 13.3884 26.3864V23.2624H12.1606H12.1613Z" fill="white" />
      <path d="M14.8796 23.2625V26.3864C14.8796 26.7253 15.1542 27 15.4931 27C15.8321 27 16.1067 26.7253 16.1067 26.3864V23.2625H14.8789H14.8796Z" fill="white" />
      <path d="M20.3167 23.2625V26.3864C20.3167 26.7253 20.5914 27 20.9303 27C21.2692 27 21.5439 26.7253 21.5439 26.3864V23.2625H20.3161H20.3167Z" fill="white" />
      <path d="M23.0357 23.2625V26.3864C23.0357 26.7253 23.3103 27 23.6492 27C23.9882 27 24.2628 26.7253 24.2628 26.3864V23.2625H23.035H23.0357Z" fill="white" />
      <path d="M17.5985 23.2625V26.3864C17.5985 26.7253 17.8731 27 18.212 27C18.551 27 18.8256 26.7253 18.8256 26.3864V23.2625H17.5978H17.5985Z" fill="white" />
      <path d="M26.7764 14.6912C26.9231 14.2754 26.9987 13.8357 26.9987 13.3897C26.9987 11.2308 25.2424 9.47449 23.0835 9.47449C22.5626 9.47449 22.0498 9.5778 21.5741 9.77687C20.529 8.07282 18.6473 7 16.6239 7C13.4262 7 10.8245 9.60174 10.8245 12.7994C10.8245 12.8284 10.8245 12.8574 10.8251 12.887C8.6379 13.3242 7 15.2695 7 17.5525C7 20.1763 9.13494 22.3112 11.7587 22.3112H25.5095C27.6684 22.3112 29.4247 20.5549 29.4247 18.396C29.4247 16.6976 28.3298 15.2223 26.7764 14.6918V14.6912ZM25.5095 21.1174H11.7587C9.79262 21.1174 8.19315 19.518 8.19315 17.5518C8.19315 15.6916 9.64521 14.1299 11.4992 13.9963L12.1203 13.9516L12.0491 13.333C12.0283 13.1541 12.0176 12.9746 12.0176 12.7988C12.0176 10.2588 14.0839 8.19252 16.6239 8.19252C18.3947 8.19252 20.0295 9.22754 20.7886 10.8289L21.0752 11.4343L21.6434 11.0796C22.075 10.81 22.5726 10.6676 23.0829 10.6676C24.5841 10.6676 25.805 11.8885 25.805 13.3897C25.805 13.8867 25.6702 14.3724 25.4144 14.7951L24.9394 15.5813L25.851 15.6954C27.2079 15.8648 28.2316 17.0258 28.2316 18.3954C28.2316 19.8966 27.0107 21.1174 25.5095 21.1174Z" fill="white" />
      <path d="M13.5806 13.396C13.2511 13.396 12.984 13.1289 12.984 12.7994C12.984 10.7924 14.6169 9.1595 16.6239 9.1595C16.9534 9.1595 17.2205 9.4266 17.2205 9.75607C17.2205 10.0855 16.9534 10.3526 16.6239 10.3526C15.2745 10.3526 14.1765 11.4507 14.1765 12.8C14.1765 13.1295 13.9094 13.3966 13.5799 13.3966L13.5806 13.396Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2_12">
        <rect width="22.4241" height="20" fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
)

export default HeavyRainIcon
