import React from 'react'
import Icon from './Icon'

const SkipIcon = props => (
  <Icon viewBox="0 0 34 34" fill="none" width={40} height={40} {...props}>
    <path
      d="M19.25 0C18.4688 0.0520833 18.0521 0.46875 18 1.25V11.7188L4.40625 0.546875C3.9375 0.182292 3.44271 0 2.92188 0C2.45312 0 1.93229 0.182292 1.35938 0.546875C0.838542 0.911458 0.552083 1.5625 0.5 2.5V27.5C0.552083 28.4375 0.838542 29.0885 1.35938 29.4531C1.93229 29.8177 2.45312 30 2.92188 30C3.44271 30 3.9375 29.8177 4.40625 29.4531L18 18.2812V28.75C18.0521 29.5312 18.4688 29.9479 19.25 30C20.0312 29.9479 20.4479 29.5312 20.5 28.75V1.25C20.4479 0.46875 20.0312 0.0520833 19.25 0ZM3 27.5V2.5L18 15L3 27.5Z"
      fill="white"
    />
  </Icon>
)

export default SkipIcon
