import React, { useEffect } from 'react'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { withErrorBoundary } from 'react-error-boundary'
import { connect } from 'react-redux'
import { PageHeader } from '../components/pages'
import '../styles/TipsContainer.scss'
import { useCustomization } from '../hooks/useCustomization'
import { LIST_OPTIONS } from './PropertyInfoContainer'
import { setOverlay } from '../actions/device'
import ErrorComponent from '../ErrorComponent'
import { MESSAGES } from '../constants/messages'
import { logAppErrors } from '../utils/helpers'

const TipsContainer = ({ setOverlay }) => {
  const rules = useCustomization('rules')
  const tips = get(rules, 'ruleList', []) // TODO: Fix rules.rules

  const { title, subtitle } = LIST_OPTIONS.find(({ key }) => key === 'house-rules')

  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])

  if (tips.length > 0) {
    return (
      <div className="tips">
        <div className="Card full-height PropertyInfoListCard">
          <div className="card-section header border-bottom">
            <PageHeader title={title}>
              <strong>{subtitle}</strong>
            </PageHeader>
          </div>
          <div className="card-section stretch">
            <div className="TipsContainer">
              {tips.length > 0 && (
                <div className="tip-list-container">
                  <div className="tip-list" id="tip-list">
                    {tips.map((tip) => (
                      <div className="tip-container" key={tip.text}>
                        <Icon name="circle" size="small" className="tip-dot" />
                        <div type="text" className="tip-label">
                          {tip.text}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card-section footer">
            <Link className="link" to="/property-info">
              <Icon name="chevron left" />
              {' '}
              {MESSAGES.PROPERTY_INFO}
            </Link>
          </div>
        </div>
      </div>
    )
  }
  return null
}
const ComponentWithErrorBoundary = withErrorBoundary(TipsContainer, {
  FallbackComponent: props => ErrorComponent({
    ...props,
    component: 'Tips Container',
  }),
  onError(error, info) {
    logAppErrors(error, info)
    console.log({
      error,
      info,
    })
    // Do something with the error
    // E.g. log to an error logging client here
  },
})
export default connect(null, {
  setOverlay,
})(ComponentWithErrorBoundary)
