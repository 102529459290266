export const MESSAGES = {
  // Others
  FROM_YOUR_HOST: 'From Your Host',
  VIEW_DETAILS: 'View Details',
  SUBMIT: 'Submit',
  APPLY: 'Apply',
  CANCEL: 'Cancel',
  BACK: 'Back',
  SLEEP: 'Sleep',
  CLEAR: 'Clear',
  CLEAR_ALL: 'Clear All',
  WIFI: 'Wi-Fi',
  PASSWORD: 'Password',
  SOMETHING_WENT_WRONG: 'Oops, something went wrong.',
  ENGINEERS_WILL_BE_NOTIFIED: 'Our engineers have been notified.',
  RETURN_TO_PAGE: 'Return to the Home page.',
  PREVIOUS: 'Previous',
  BACK_TO_LIST: 'Back to List',
  NEXT: 'Next',
  NO_IMAGE: 'No Image',
  TRYING_TO_RECONNECT: 'GuestView Guide is trying to connect to the Internet...',
  ENTER_PASSCODE: 'Enter your passcode',
  THIS_WILL_ALLOW_TO_ACCESS_SETTINGS: 'This will allow you to access settings on the Virtual Concierge',
  REFRESH: 'Refresh',
  ADDRESS: 'Address',
  CUSTOMER_SERVICE: 'Customer Service',
  EMERGENCIES: 'Emergencies',
  QR_ERROR: 'QR Code Error',
  LOADING_QR: 'Loading QR Code',
  SEND_TO_PHONE: 'Send to Phone',
  SEND_INFO_TO_PHONE: 'Send information to my phone',
  SKIP: 'Skip',
  MUTE: 'Mute',
  CLOSE: 'Close',
  ADMIN_SETTINGS: 'Admin Settings',
  CONTACT_HOST: 'Contact Us',
  NEW_MESSAGE: 'You have a new message.',
  YOUR_HOST: 'Your host',
  GET_PASSCODE_VIA_TEXT: 'Get Passcode via Text Message',
  SUBJECT_TO_APPROVAL: ' Subject to approval from the Host',
  SEND: 'Send',
  GVG_SETTINGS: 'GuestView Guide Settings',
  SLEEP_MODE: 'Sleep Mode',
  SCREEN_WILL_DARKEN_SOON: 'Screen will darken soon.',
  EXIT_SLEEP_MODE: 'Exit Sleep Mode',
  PROPERTY_INFO: 'Property Info',
  CLOSED_TODAY: 'Closed Today',
  OK: 'OK',

  // device pairing
  IS_THIS_PROPERTY_CORRECT: 'Is this the correct property?',
  ENTER_PROPERTY_ID: 'Enter your Property ID',
  PROPERTY_ID_INFO:
           'The Property ID will pair and sync the GuestView Guide with your property information. Find your ID on the property information page at manage.guestviewguide.com.',
  DEMO_MODE: 'Demo Mode',
  ERROR_IN_PAIRING_DEVICE:
           'There was an error pairing the device. Make sure you entered the passcode and/or Property ID correctly.',

  // SERVICES Messages //
  UNAVAILABLE_SERVICE: 'The service request is not available ',
  ALREADY_REQUESTED_SERVICE: '',
  MOBILE_NUMBER_REQUEST: 'Provide your mobile number to complete the request',
  MOBILE_NUMBER_TERMS_OF_USE:
           'Your phone number will be shared with the host to facilitate fulfillment of the service. Standard text messaging rates may apply.',
  MOBILE_PAYMENT_PROMPT: 'Complete secure payment on your mobile device',
  MORE_SERVICES: 'More Services',
  UNAVAILABLE_SERVICE_DUE_TO_DEADLINE:
           'We cannot accept the service request at this time due to the service request deadline. We sincerely apologize for your inconvenience.',
  UNAVAILABLE_SERVICE_DUE_TO_NEXT_RESERVATION: 'Service unavailable due to next reservation',
  GET_INFO_ON_PHONE: 'Get Information on your phone',
  REVIEW_AND_CONFIRM_REQUEST: 'Review & Confirm your Request',
  CONFIRM_REQUEST: 'Confirm Request',
  BACK_TO_EDIT_REQUEST: 'Back to Edit Request',
  SERVICE_INSTRUCTIONS: 'Service Instructions',
  BACK_TO_SERVICE_DETAILS: 'Back to service details',
  TERMS_AND_CONDITION_PROMPT: 'I accept these Terms and Conditions',
  ADDITIONAL_SERVICE: 'We offer a number of additional services you can request during your stay.',
  NO_ADDITIONAL_SERVICE: 'We do not currently offer additional services through this display.',

  // home
  PROPERTY_INFO_NOT_YET_ADDED: 'We have not yet added property info to this display.',
  VIEW_ALL: 'View All',
  YOU_ARE_CHECKED_IN: 'You\'re Checked In',
  WELCOME_TO: 'Welcome To',
  YOUR_RESERVATION: 'Your reservation:',
  FIND_OUT_MORE: 'Find Out More',

  // slideshow
  OUR_SERVICES: 'OUR SERVICES',
  OUR_RECOMMENDATIONS: 'OUR RECOMMENDATIONS',
  OUR_RECOMMENDED: 'Our recommended',
  SEND_DETAILS_TO_PHONE: 'Send Details to Phone',
  PROMO: 'Promo',
  SPECIAL_OFFER_FOR_GUEST: 'Special Offer for our Guests',
  SEND_OFFER_DETAILS_TO_PHONE: 'Send offer details to your phone',

  // settings
  NOT_AVAILABLE_ON_THIS_DISPLAY: 'Not available on this display.',
  MAP_DATA_BY_GOOGLE: 'Map data powered by Google Maps',
  WEATHER_DATA_BY_DARK_SKY: 'Weather data powered by Dark Sky',
  VERSION: 'Version',
  PAIRED_WITH: 'Paired with',

  // checkInOut
  WE_ARE_GLAD_YOU_STAY_WITH_US: 'We\'re glad you enjoyed your stay with us!',
  LEAVE_REVIEW: 'We invite you to share your experience with others by leaving a review.',
  TAP_BUTTON_FOR_REVIEW_REMINDER: 'Tap the button below to receive a text reminder in 2 days.',
  SEND_TEXT_REMINDER: 'Send a text reminder',
  WE_DONT_STORE_PHONE_NUMBER: 'We do not store your phone number or personal data.',

  // checkout
  CHECKOUT_TIME: 'Checkout Time',
  THANKS_FOR_FEEDBACK: 'Thank you for your feedback!',
  CHECKOUT_INSTRUCTIONS: 'Checkout Instructions',
  CHECKOUT_NOW: 'Check Out Now',
  THANK_YOU: 'Thank you',
  FOR_STAYING_WITH_US: 'for staying with us',
  YOU_ARE_ALL_SET: 'You\'re All Set',

  // contact
  SEND_REQUEST: 'Send Request',
  DEFAULT_CONTACT_MESSAGE:
           'For life threatening emergency situations, please call 911 or go to the nearest emergency room. For all other matters contact host using the phone number above and our service team will be in touch with you',
  CONTACT_TERMS_OF_USE:
           'Your phone number will be shared with the host so they can get back to you. Standard text messaging rates may apply.',
  SELECT_TOPIC: 'What type of issue would you like to report?',
  ENTER_DETAILS: 'Please provide any relevant details that you may deem necessary with respect to your issue at hand.',

  // information
  ITEM: 'Item',

  // map
  RECENTER_MAP: 'Re-center Map',
  RECOMMENDED: 'Recommended',

  // onboarding
  YOU_ARE_VIEWING_GVG: 'You are currently viewing GuestView Guide',
  BY_TAPPING_CHECKIN: 'By tapping CHECK-IN you agree to our',
  TERMS_AND_CONDITIONS: 'Terms of Service',
  TAP_TO_CHECKIN: 'Tap To Check In',
  WELCOME: 'Welcome',

  // announcement
  VIEW_ON_YOUR_PHONE: 'View on your phone',
  SCAN_FOR_DETAILS: 'Scan for details',
  GOT_IT: 'Got it',
  MESSAGE_FROM_YOUR_HOST: 'Message from your host',
  NO_NOTIFICATION_TO_READ: 'No notifications to read',
  ANNOUNCEMENTS: 'Announcements',
  UNREAD: 'unread',
}
