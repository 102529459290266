import React from 'react'
import Icon from './Icon'

const DialIcon = props => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g clipPath="url(#clip0_102_6271)">
      <path
        d="M43.6547 6.34938C39.4584 2.15041 33.565 -0.171718 27.4832 0.0099121C27.2624 0.0161277 27.0531 0.109847 26.9014 0.270452C26.7497 0.431057 26.6679 0.645393 26.6742 0.866307C26.6804 1.08722 26.774 1.29662 26.9345 1.44843C27.095 1.60025 27.3092 1.68205 27.53 1.67583C33.1434 1.53033 38.6016 3.6513 42.4768 7.52803C46.3521 11.4048 48.4835 16.8566 48.3254 22.4852C48.3193 22.706 48.4011 22.9203 48.5528 23.0808C48.7045 23.2414 48.9137 23.3352 49.1344 23.3416H49.1588C49.3754 23.3411 49.5834 23.2565 49.7388 23.1054C49.8941 22.9543 49.9846 22.7487 49.9912 22.532C50.161 16.4474 47.8511 10.5484 43.6547 6.34938Z"
        fill="white"
      />
      <path
        d="M44.1369 23.3414H44.1623C44.3786 23.3412 44.5865 23.2569 44.7418 23.1062C44.8972 22.9555 44.9879 22.7502 44.9947 22.5338C45.1382 17.7382 43.362 13.1291 40.1211 9.88512C36.9514 6.71245 32.3803 4.95181 27.493 5.00943C27.2769 5.01839 27.0728 5.11103 26.9238 5.26778C26.7747 5.42453 26.6923 5.63312 26.6941 5.84949C26.6959 6.06586 26.7816 6.27307 26.9333 6.42735C27.0849 6.58163 27.2905 6.67091 27.5067 6.67632H27.5203C31.9255 6.59723 36.0906 8.20748 38.9441 11.0628C41.866 13.9835 43.4596 18.1473 43.3298 22.483C43.3232 22.7039 43.4045 22.9183 43.5559 23.0793C43.7072 23.2402 43.9162 23.3345 44.1369 23.3414Z"
        fill="white"
      />
      <path
        d="M27.5065 11.6752C29.3666 11.6752 32.8691 12.056 35.4113 14.5989C37.9535 17.1417 38.3331 20.6473 38.3331 22.5085C38.3405 22.7247 38.4315 22.9294 38.5869 23.0797C38.7423 23.23 38.9499 23.3139 39.166 23.3139C39.3821 23.3139 39.5898 23.23 39.7452 23.0797C39.9006 22.9294 39.9916 22.7247 39.999 22.5085C39.999 20.3895 39.5559 16.3898 36.5892 13.4202C33.6225 10.4507 29.6252 10.0093 27.5075 10.0093C27.2867 10.0093 27.075 10.097 26.9189 10.2532C26.7628 10.4095 26.675 10.6213 26.675 10.8422C26.675 11.0632 26.7628 11.275 26.9189 11.4312C27.075 11.5874 27.2867 11.6752 27.5075 11.6752H27.5065Z"
        fill="white"
      />
      <path
        d="M35.2777 30.4259C35.1185 30.2755 34.9078 30.1916 34.6888 30.1916C34.4698 30.1916 34.259 30.2755 34.0998 30.4259L29.356 35.1737C27.2871 37.2429 22.998 37.1267 18.0776 32.2071C13.1571 27.2874 13.0429 22.9918 15.1108 20.9216C15.1883 20.8444 15.2498 20.7526 15.2918 20.6516C15.3337 20.5505 15.3553 20.4422 15.3553 20.3328C15.3553 20.2233 15.3337 20.115 15.2918 20.014C15.2498 19.9129 15.1883 19.8211 15.1108 19.7439L7.99461 12.6213C7.83841 12.465 7.62656 12.3772 7.40566 12.3772C7.18476 12.3772 6.97291 12.465 6.81671 12.6213C6.66051 12.7776 6.57275 12.9896 6.57275 13.2106C6.57275 13.4317 6.66051 13.6436 6.81671 13.7999L13.3864 20.3748C11.2941 23.1803 11.5918 28.0725 16.8997 33.3847C22.633 39.1227 27.8775 39.0094 30.5329 36.3523L34.6883 32.1934L43.0019 40.5093L41.257 42.2563C41.1794 42.3336 41.1178 42.4255 41.0757 42.5266C41.0336 42.6278 41.0118 42.7363 41.0116 42.8458C41.0113 42.9554 41.0327 43.064 41.0744 43.1653C41.1161 43.2666 41.1773 43.3587 41.2546 43.4364C41.3319 43.514 41.4237 43.5757 41.5248 43.6178C41.6259 43.66 41.7343 43.6818 41.8438 43.682C41.9533 43.6822 42.0618 43.6609 42.163 43.6192C42.2643 43.5774 42.3564 43.5162 42.434 43.4388L44.7673 41.1011C44.8448 41.0239 44.9063 40.9321 44.9482 40.8311C44.9902 40.73 45.0118 40.6217 45.0118 40.5122C45.0118 40.4028 44.9902 40.2945 44.9482 40.1934C44.9063 40.0924 44.8448 40.0006 44.7673 39.9234L35.2777 30.4259Z"
        fill="white"
      />
      <path
        d="M32.9061 37.5486C32.75 37.3923 32.5383 37.3044 32.3175 37.3043C32.0967 37.3042 31.8849 37.3919 31.7287 37.5481C31.5725 37.7042 31.4847 37.9161 31.4846 38.1371C31.4845 38.358 31.5721 38.5699 31.7282 38.7262L38.2443 45.2473C34.7867 48.4083 31.2032 49.1309 27.2869 47.4523C27.2293 47.4298 21.4208 45.0491 13.3316 36.9549C5.2746 28.8889 2.86317 23.0484 2.81242 22.9322C0.728882 18.7586 1.51545 15.5333 5.62202 11.4261L9.77738 7.26713L18.0901 15.585L16.3061 17.3662C16.1499 17.5225 16.0622 17.7344 16.0622 17.9555C16.0622 18.1765 16.1499 18.3885 16.3061 18.5448C16.4623 18.7011 16.6742 18.7889 16.8951 18.7889C17.116 18.7889 17.3278 18.7011 17.484 18.5448L19.8574 16.1709C20.013 16.0143 20.1003 15.8024 20.1003 15.5816C20.1003 15.3608 20.013 15.1489 19.8574 14.9923L10.3668 5.49966C10.2076 5.3492 9.99688 5.26538 9.77787 5.26538C9.55887 5.26538 9.34815 5.3492 9.18892 5.49966L4.44509 10.2474C-0.210905 14.9054 -1.11751 18.7938 1.29393 23.6099C1.39152 23.858 3.83125 29.8 12.1547 38.1335C20.4781 46.467 26.4203 48.9004 26.6516 48.9922C28.2169 49.6647 29.754 50.0006 31.2627 50C34.328 50 37.2606 48.6133 40.0233 45.8489C40.1007 45.7715 40.1621 45.6796 40.204 45.5785C40.2459 45.4774 40.2675 45.369 40.2675 45.2595C40.2675 45.1501 40.2459 45.0417 40.204 44.9406C40.1621 44.8394 40.1007 44.7476 40.0233 44.6702L32.9061 37.5486Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_102_6271">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)

export default DialIcon
