import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { resetChecklist } from '../../resources/checklists'
import { makeContactRequest } from '../../actions/property'
import { SendToPhoneContainer } from '../../containers'
import { withPropsFromLocation } from '../utils'
import { setOverlay } from '../../actions/device'
import { MESSAGES } from '../../constants/messages'
import { Mixpanel } from '../../api/mixpanel'
import { EventName } from '../../constants/eventsConstants'

const ContactSendToPhone = ({
  history,
  makeContactRequest,
  resetChecklist,
  isUrgent,
  topics,
  setOverlay,
  requestDetails,
  ...props
}) => {
  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])
  const onSend = async (phone, reservationId) => {
    const parameters = {
      isUrgent,
      requestDetails,
      topics,
    }
    await makeContactRequest(phone, parameters, reservationId)
    Mixpanel.track(EventName['Contact Us Submit'], {
      name: (parameters.topics.map(topic => topic.text)).toString(),
      source: 'Contact Send To Phone',
    })
    history.go(-2)
    toast('Thank you, we will get back to you soon.', {
      className: 'toast',
      autoClose: 10000,
    })
  }

  return (
    <SendToPhoneContainer
      {...props}
      meta={MESSAGES.CONTACT_TERMS_OF_USE}
      title="Provide a number where we can easily reach you"
      onSend={onSend}
    />
  )
}

export default connect(null, {
  resetChecklist,
  makeContactRequest,
  setOverlay,
})(withPropsFromLocation(['isUrgent', 'topics', 'requestDetails'])(ContactSendToPhone))
