import React from 'react'

import { connectWithPath } from '../../resources/utils'
import SettingsSection from './SettingsSection'
import BlueButton from '../BlueButton'

const WifiSection = ({ isOnline, requirePasscode }) => {
  const localURL = 'http://localhost:3000/survey'
  let to = {
    pathname: '/passcode-screen-simple',
    state: {
      next: localURL,
    },
  }
  if (!isOnline || requirePasscode === false) {
    to = localURL
  }

  return (
    <SettingsSection title="Wi-Fi">
      <BlueButton label="Edit Wi-Fi information" to={to} />
    </SettingsSection>
  )
}
WifiSection.defaultProps = {
  requirePasscode: true,
}

export default connectWithPath({
  isOnline: 'networkStatus.isOnline',
})(WifiSection)
