import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'
import { useReservation } from '../../hooks/useReservation'
import { CheckoutOption } from '.'

const SlideshowCheckoutMessage = () => {
  const reservation = useSelector(state => state.property.reservation)
  const { isCheckOutDay, checkoutTime } = useReservation()
  const reservationId = get(reservation, 'id', 0)
  const hasCheckedOut = reservationId === parseInt(localStorage.getItem('hasCheckedOut'), 10)
  const noGuestMode = localStorage.getItem('noGuestMode')

  if (noGuestMode) {
    return null
  }
  if (isCheckOutDay) {
    const checkedoutTime = localStorage.getItem('checkedoutTime')
    if (hasCheckedOut) {
      const timeDiff = moment().diff(checkedoutTime, 'minute')
      if (timeDiff >= 15) {
        localStorage.setItem('noGuestMode', true)
        return null
      }
    }
    const overTime = moment().diff(moment(checkoutTime, 'h:mm'), 'minute')
    if (overTime >= 60) {
      localStorage.setItem('hasCheckedOut', reservationId)
      localStorage.setItem('noGuestMode', true)
      return null
    }
  }
  if (!isCheckOutDay) {
    return null
  }

  return (
    <div className="SlideshowModeCheckoutMessage Card white">
      <div className="card-section no-padding-x">
        <CheckoutOption className="artmode" />
      </div>
    </div>
  )
}

export default SlideshowCheckoutMessage
