import * as React from 'react'
import Icon from './Icon'

const SvgArrow = props => (
  <Icon width={52} height={52} fill="none" {...props}>
    <mask id="Arrow_svg__a" maskUnits="userSpaceOnUse" x={0.544} y={0.343} width={50.912} height={50.912} fill="#000">
      <path fill="#fff" d="M.544.343h50.912v50.912H.544z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.586 44.184a2 2 0 002.828-2.829L11.858 25.8l15.556-15.556a2 2 0 10-2.828-2.829l-16.97 16.97a2 2 0 000 2.83l16.97 16.97z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.586 44.184a2 2 0 002.828-2.829L11.858 25.8l15.556-15.556a2 2 0 10-2.828-2.829l-16.97 16.97a2 2 0 000 2.83l16.97 16.97z"
      fill="#fff"
    />
    <path
      d="M11.858 25.799L9.029 22.97l-2.828 2.828 2.828 2.828 2.829-2.828zm12.728 15.556a2 2 0 012.828 0l-5.657 5.657a6 6 0 008.486 0l-5.657-5.657zm0 2.829a2 2 0 010-2.829l5.657 5.657a6 6 0 000-8.485l-5.657 5.657zM9.029 28.627l15.557 15.557 5.657-5.657L14.686 22.97 9.03 28.627zM24.586 7.414L9.029 22.971l5.657 5.656 15.557-15.556-5.657-5.657zm0 2.829a2 2 0 010-2.829l5.657 5.657a6 6 0 000-8.485l-5.657 5.657zm2.828 0a2 2 0 01-2.828 0l5.657-5.657a6 6 0 00-8.486 0l5.657 5.657zm-16.97 16.97l16.97-16.97-5.657-5.657-16.97 16.97 5.657 5.657zm0-2.828a2 2 0 010 2.828l-5.657-5.657a6 6 0 000 8.486l5.657-5.657zm16.97 16.97l-16.97-16.97-5.657 5.657 16.97 16.97 5.657-5.657z"
      fill="#000"
      fillOpacity={0.2}
      mask="url(#Arrow_svg__a)"
    />
  </Icon>
)

export default SvgArrow
