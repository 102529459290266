import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { useServices } from '../resources/services'
import { PageHeader } from '../components/pages'
import { ServiceListItem } from '../components/services'
import '../styles/ServicesContainer.scss'
import { setOverlay } from '../actions/device'
import { useNextReservation, useReservation } from '../hooks/useReservation'
import { MESSAGES } from '../constants/messages'

const ServicesContainer = ({ setOverlay }) => {
  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])
  const { services } = useServices()
  const nextReservation = useNextReservation()
  const currentReservation = useReservation()
  return (
    <div className="Card full-height">
      <div className="card-section header">
        <PageHeader
          title="Additional Services"
          subtitle={
            services.length !== 0 && MESSAGES.ADDITIONAL_SERVICE
          }
          hr
        />
      </div>
      <div className="card-section no-padding-x margin-x margin-bottom scroll scroll-extreme-right">
        {services
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
          .map(service => (
            <ServiceListItem
              {...service}
              key={service.id}
              serviceMedias={service.serviceMedias}
              nextReservation={nextReservation}
              currentReservation={currentReservation}
            />
          ))}
      </div>
    </div>
  )
}

export default connect(null, {
  setOverlay,
})(ServicesContainer)
