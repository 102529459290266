import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { address } from '../utils/formatters'

const Address = () => {
  const property = useSelector(state => state.property)

  const nickName = get(property, 'nickname')
  const addressParts = address(property)
  if (!addressParts) {
    return null
  }
  let [line1, line2] = addressParts

  if (nickName) {
    line1 = nickName
    line2 = addressParts.join(', ')
  }

  return (
    <div className="Address">
      <div className="line-one">{line1}</div>
      <div className="line-two">{line2}</div>
    </div>
  )
}

export default Address
