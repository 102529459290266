import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class TermsOfService extends Component {
  render() {
    return (
      <div className="TermsOfService">
        <div className="terms-header">
          <h2>Terms of Service</h2>
        </div>
        <div className="terms-content">
          <div className="terms-copy">
            <div>
              <strong>SHARP NEC DISPLAY SOLUTIONS OF AMERICA, Inc.</strong>
              <br />
              3250 Lacey Road, Suite 500
              <br />
              Downers Grove, Illinois 60515
            </div>

            <div>
              Our application, apps, and software
              {' '}
              <strong>(Website, or Site)</strong>
              {' '}
              is preloaded on our GuestView
              Guide displays
              {' '}
              <strong>(Display)</strong>
              {' '}
              at a host property
              <strong>(Host, or Host Property) </strong>
              {' '}
              together: our
              {' '}
              <strong>Services.</strong>
            </div>

            <div>
              <strong>Please read these terms of use carefully before using our Services.</strong>
            </div>

            <div>
              By clicking “Check In” you confirm that you accept these terms of use and that you agree to comply with
              them. If you do not agree to these terms of use, please do not use the Services.
            </div>

            <div>
              <strong>Changes.</strong>
              {' '}
              We may update our Services from time to time and may change the content at any
              time. Any of the content on our Website may be out of date at any given time and we are under no
              obligation to update it. We do not guarantee that our Website, or any content on them, will be free from
              errors or omissions.
            </div>

            <div>
              <strong>Data.</strong>
              {' '}
              The Guestview Guide Display enables the gathering of anonymized data
              {' '}
              <strong>(Data)</strong>
              of how guests of the Host
              {' '}
              <strong>(Guests)</strong>
              {' '}
              interact and transact through
              the display. This data is available to the Host through their management console. The data is owned by
              Sharp NEC and licensed to the Host.
            </div>

            <div>
              <strong>
                Phone numbers, email addresses, and any other personal data that is entered into the Display is not
                collected or shared with any parties including the Host and is not saved on the Display after the guest
                stay is complete.
              </strong>
            </div>

            <div>
              As part of a Guest stay, the Display may collect personal data about you from the Host, Online Travel
              Agents, or through the Display Interface. This data is used to personalize the check-in/check-out process,
              your date of stay, and to share the content of the Website with you. This data is not shared with any
              third party and is not saved on the Display at the end of your stay.
            </div>

            <div>
              <strong>
                Privacy
                <br />
                What Information We Collect.
              </strong>
              {' '}
              The information we collect on our Site generally falls into the following two categories: Personally
              Identifiable Information and Non-Personally Identifiable Information.
            </div>

            <div>
              <strong>Personally Identifiable Information:</strong>
              {' '}
              This refers to information that lets us know the
              specifics of who you are. The Personally Identifiable Information we collect when you use our Site may
              include your name, mailing address, phone number, e-mail address, credit card number and its expiration
              date, and shipping address. We request Personally Identifiable Information when you order a product or
              service, participate in certain online promotional activities, fill out online surveys, and correspond
              with us.
            </div>

            <div>
              <strong>Non-Personally Identifiable Information:</strong>
              {' '}
              This refers to information that does not by
              itself identify a specific individual. The non-personally identifiable data we collect when you visit our
              Site may include which pages you visit on our Site and surveys.
            </div>

            <div>
              <strong>Why We Collect Your Information</strong>
              <br />
              Our primary goals in collecting information are to provide customers with superior service and to provide
              all visitors a smooth, efficient, and personalized experience while using our Site. For example, if you
              purchase products or supplies we use your Personally Identifiable Information to fill your order or to
              provide you with the requested information. We may use Non-Personally Identifiable Information to analyze
              customer behavior to help us understand how visitors use our Site and to measure interest in the various
              pages. We use this information to improve the content of our Site, to make our Site easier to use, and to
              customize our advertising.
            </div>

            <div>
              We do not connect the Personally Identifiable information and Non-Personally identifiable information that
              we collect on our Site.
            </div>

            <div>
              <strong>How We Use Your Information.</strong>
              {' '}
              We gather and use information in a number of ways, including
              the following:
            </div>

            <div>
              Surfing: We collect certain Non-Personally Identifiable Information when you visit our Site. We collect
              this information to help run our Site more efficiently, to gather broad demographic information, to
              monitor the level of activity on our Site, and to evaluate the effectiveness of our service.
            </div>

            <div>
              Ordering: We collect Personally Identifiable Information when you purchase products, supplies or services
              online. We collect this information to communicate your order to the Host Property who may be responsible
              for fulfilling your order, to deliver your order, to obtain payment, and to communicate with you about the
              status of your order.
            </div>

            <div>
              Online surveys and polling: We collect Personally Identifiable Information from customers who volunteer to
              complete surveys or participate in polls. We use this information to improve our products and services.
            </div>

            <div>
              Contact information: If you contact us, Sharp NEC may keep a record of your correspondence or comments,
              including Personally Identifiable Information, in a file specific to you. We use this information to help
              us provide better customer service in the event you contact us again.
            </div>

            {/* &apos used instead of apostrophe charachter in line 141, as suggested by ESLint. Refer to rule no-unescaped-entities */}
            <div>
              <strong>Sharing Your Information.</strong>
              {' '}
              We only share your Personally Identifiable Information outside
              the Sharp NEC family of companies if it is required by law, or to protect Sharp NEC Display Solutions, its
              customers, or the public, or with companies that help Sharp NEC Display Solutions fulfill its obligations
              with you, and then only with partners who share Sharp NEC Display Solutions&apos; commitment to protecting your
              privacy and data. Except as previously stated, we do not sell, trade, or rent to others the Personally
              Identifiable Information we collect online. Where Sharp NEC Display Solutions engages third parties to
              perform services on our behalf, we will require them to observe the intent of this Online Privacy
              Statement.
            </div>

            <div>
              Personally Identifiable Information previously collected by Sharp NEC Display Solutions may be transferred
              to any new corporate entity formed as a result of corporate reorganization of Sharp NEC Display Solutions,
              or any of Sharp NEC Display Solutions’ divisions, provided that such new corporate entity agrees to abide
              by the terms of this Policy.
            </div>

            <div>
              From time to time, we may be required to provide Personally Identifiable Information in response to a
              court order, subpoena, or government investigation. We also reserve the right to report to law enforcement
              agencies any activities that we in good faith believe to be unlawful. We may release certain Personally
              Identifiable Information when we believe that such release is reasonably necessary to enforce or apply our
              Terms of Use or to protect the rights, property, and safety of others and ourselves.
            </div>

            <div>
              <strong>Your Choices.</strong>
              {' '}
              Sharp NEC Display Solutions will not use or share the Personally
              Identifiable Information collected on our Site in ways unrelated to the purpose for which you provided the
              information, including those described in Section titled &quot;How We Use Your Information&quot;, without providing
              you a choice whether to permit any such unrelated uses. At the time you provide your information, we will
              offer you a choice as to whether you want to receive (&quot;opt-in&quot;) further communications about special
              offers, product information or other marketing messages. If you choose not to receive these communications
              (&quot;opt-out&quot;), we will not use the information you provide for this purpose. You can opt out at any time as
              all e-mail communications from us.
            </div>

            <div>
              You can correct factual errors in your personally identifiable information by sending us a request that
              credibly shows error. To protect your privacy and security, we will also take reasonable steps to verify
              your identity before granting access or making corrections.
            </div>

            <div>
              <strong>Protecting Your Information.</strong>
              {' '}
              Sharp NEC Display Solutions safeguards the security of the
              data you send us with physical, electronic, and managerial procedures. We urge you to take every
              precaution to protect your personal data.
              {' '}
            </div>

            <div>
              Sharp NEC Display Solutions uses industry-standard Secure Sockets Layer (SSL) encryption on all Web pages
              where personal information is required. To make purchases on our Site, you must use an SSL-enabled
              browser. This protects the confidentiality of your personal and credit card information while it is
              transmitted over the Internet.
            </div>

            <div>
              In order to most efficiently serve you, credit card transactions and order fulfillment are handled by
              established third-party banking, processing agents and distribution institutions. They receive the
              information needed to verify and authorize your credit card or other payment information and to process
              and ship your order.
            </div>

            <div>
              When you access your account information, the information is kept on a secure server. All of the customer
              data we collect is protected against unauthorized access by physical security means.
            </div>

            <div>
              <strong>Children&apos;s privacy.</strong>
              {' '}
              Sharp NEC Display Solutions takes special care to protect the privacy
              needs of children under the age of 13 and encourages parents to be an active participant in their child&apos;s
              online activities and interests. We abide by the Children&apos;s Online Privacy Protection Act (COPPA)
              requirements.
              <br />
              GuestView Guide does not target and is not intended for children under the age of 13. You must be at least
              18 years old to have our permission to use this Site. Our policy is that we do not knowingly collect, use
              or disclose Personally Identifiable Information about visitors under 18 years of age. If you are the
              parent or guardian of a minor under 18 years of age and believe that they have disclosed personally
              identifiable information to us, please contact us at privacy.policy@necdisplay.com so that we may delete
              the minor&apos;s information.
            </div>

            <div>
              <strong>Intended Use of Sharp NEC Services.</strong>
              {' '}
              The Services are intended to be accessed and used for
              non-time-critical information and control of Sharp NEC Products. While we aim for the Services to be
              highly reliable and available, we cannot guarantee 100% reliability or availability. The Services are
              subject to sporadic interruptions and failures for a variety of reasons beyond Sharp NEC’s control,
              including Wi-Fi intermittency, service provider uptime, mobile notifications and operators, among others.
              You acknowledge these limitations and agree that Sharp NEC is not responsible for any damages allegedly
              caused by the failure or delay of the Services.
            </div>

            <div>
              <strong>Unauthorized use.</strong>
              {' '}
              The Display and the Services running on the display are provided for
              the sole use of managing guest services for hotels and vacation rentals. The Services are locked down to
              prevent unauthorized use. Any tampering with the Services, hacking, or attempts to circumvent the
              protections put in place to limit the Services to the intended use are strictly prohibited. Any violation
              of these restrictions may result in legal action at the sole discretion of Sharp NEC Display Solutions.
            </div>

            <div>
              <strong>System Requirements.</strong>
              {' '}
              The Services will not be accessible without a working Wi-Fi network
              in the Host Property that is positioned to communicate reliably with the Display. You acknowledge that the
              Services may not work as described when the requirements and compatibility have not been met.
            </div>

            <div>
              <strong>Equipment, ISP and Operator.</strong>
              {' '}
              You acknowledge that the availability of the Services is
              dependent on (i) your computer, mobile device, and (iii) your mobile device operator (“Operator”). You
              acknowledge that you are responsible for all fees charged by your ISP and Operator in connection with your
              use of the Services. You also acknowledge that you are responsible for compliance with all applicable
              agreements, terms of use/service and other policies of your ISP and Operator.
            </div>

            <div>
              <strong>Registration and Passwords.</strong>
              {' '}
              In order to access certain services on the Site, you will be
              required to provide specific information. All information about you must be truthful, and you may not use
              any aliases or other means to mask your true identity. Any access codes or passwords provided should be
              safeguarded at all times. You are responsible for the security of your access codes and passwords and will
              be solely liable for any use or unauthorized use under such access codes or passwords. We may suspend or
              terminate your access at any time with or without notice. To understand how we use information collected
              from you, please read The Sharp NEC Privacy Policy.
            </div>

            <div>
              <strong>Affiliate Services.</strong>
              {' '}
              GuestView Guide (GVG) displays may come pre-installed with a range of
              partnerships with companies providing on-demand services, (including but not limited to), taxi services,
              tourist tickets, and food and grocery delivery. These relationships operate on an affiliate commercial
              relationship between Sharp NEC and the on-demand service provider. Sharp NEC provides no warranty to the
              quality of the service provided by any partner, affiliate, or third party provider. Any complaints about
              the service from any partner, affiliate, or third party should be directed at them.
            </div>

            <div>
              <strong>Cross Promotion and Selling Services.</strong>
              {' '}
              The GVG display can be used as a platform that
              enables owners and property managers to promote their properties and services to guests. These services
              are offered by property managers or guests at their sole discretion. Sharp NEC is not responsible for
              ensuring fulfillment of services offered on the platform or for ensuring that any services conform to
              local laws.
            </div>

            <div>
              <strong>Warranties.</strong>
              {' '}
              SHARP NEC MAKES NO WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF
              MERCHANTABILITY OR FITNESS FOR USE OR FOR A PARTICULAR PURPOSE. YOU AGREE THAT SHARP NEC IS NOT
              RESPONSIBLE FOR EQUIPMENT DAMAGE OR DEFECT THAT IS CAUSED BY ACCIDENT, ABUSE, OR MISUSE.
            </div>
          </div>
          <div className="terms-buttons">
            <Link className="button" to="/onboarding/checkin">
              Back
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
