import React from 'react'
import Icon from './Icon'

const CloseIcon = props => (
  <Icon viewBox="0 0 19 19" fill="none" className="stroke" {...props}>
    <path d="M1.5 1l16 16.5M17.5 1L1 17.5" strokeWidth={2} />
  </Icon>
)

export default CloseIcon
