import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { MESSAGES } from '../constants/messages'
import '../styles/ErrorPage.scss'

class ErrorPage extends Component {
  navHomeAndReload = () => {
    this.props.history.push('/')
    window.location.reload()
  }

  render() {
    return (
      <div className="ErrorPage">
        <h4>{MESSAGES.SOMETHING_WENT_WRONG}</h4>
        <h5>{MESSAGES.ENGINEERS_WILL_BE_NOTIFIED}</h5>
        <button onClick={this.navHomeAndReload}>{MESSAGES.RETURN_TO_PAGE}</button>
      </div>
    )
  }
}
export default withRouter(ErrorPage)
