import React from 'react'
import moment from 'moment'
import { get, range } from 'lodash'
import { connect } from 'react-redux'

import TemperatureUnitToggle from './TemperatureUnitToggle'
import WeatherDay from './WeatherDay'
import '../../styles/Weather.scss'

const DAY_FORMAT = moment.HTML5_FMT.DATE
const DAYS = 5

const Weather = ({
  forecast, beginDate, endDate,
}) => {
  if (forecast && forecast.length < DAYS) {
    // something went wrong and we didn't get the full forecast
    return null
  }

  const today = moment()

  const days = range(0, DAYS).map(index => {
    const { time, ...rest } = forecast[index]
    const day = moment(time)
    return {
      ...rest,
      key: day.format(DAY_FORMAT),
      day,
      isToday: day.format(DAY_FORMAT) === today.format(DAY_FORMAT),
      isCheckin: day.format(DAY_FORMAT) === beginDate,
      isCheckout: day.format(DAY_FORMAT) === endDate,
      hasReservation: day.isBetween(beginDate, endDate, null, '[]'),
    }
  })

  return (
    <div className="Weather">
      <div className="todays-date">
        <div className="week-day">{moment(today).format('ddd')}</div>
        <div className="day-and-month">{moment(today).format('MMM DD')}</div>
      </div>
      <div className="WeatherDays">
        {days.map(day => (
          <WeatherDay {...day} />
        ))}
      </div>
      <div className="WeatherFooter">
        <TemperatureUnitToggle />
      </div>
    </div>
  )
}

// we can provide values as props for the Playground
const mapStateToProps = ({ property, weather }, { beginDate, endDate }) => {
  const { forecast = [] } = weather
  return {
    forecast: Array.isArray(forecast) ? forecast : [],
    beginDate: beginDate || get(property, ['reservation', 'beginDate']),
    endDate: endDate || get(property, ['reservation', 'endDate']),
  }
}

export default connect(mapStateToProps)(Weather)
