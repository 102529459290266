import { pickBy } from 'lodash'
import axios from 'axios'
import Storage from '../utils/storage'
import { getDeviceType, getProperty, getToken } from '../utils/persistent-state'
import Logger from '../logger/logger'
import { Mixpanel } from './mixpanel'
import { EventName } from '../constants/eventsConstants'

const domain = process.env.REACT_APP_API_URL

const storage = new Storage('metrics')
const property = new Storage('property')
const api = axios.create({
  baseURL: `${domain}/metrics`,
  responseType: 'json',
})

api.interceptors.request.use(config => {
  const headers = pickBy(
    {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'X-GVG-PropertyId': property.get('id'),
      'X-GVG-Metric-SessionId': storage.get('sessionId'),
      'X-GVG-Device-SerialNumber': localStorage.getItem('serialNumber'),
      'X-GVG-ReservationId': property.get('reservation.id'),
    },
    value => value,
  )
  config.data = {
    ...config.data,
  }
  config.headers.common = {
    ...config.headers.common,
    ...headers,
  }

  // log request
  Logger.http({
    module: 'metrics',
    metadata: config.data,
    sessionId: {
      propertyId: property.get('id'),
    },
    apiInfo: {
      endpoint: config.baseURL + config.url,
      method: config.method,
    },
  })

  return config
})

api.interceptors.response.use(
  response => {
    const { data, config } = response
    // debug(data)
    // log response
    Logger.http({
      module: 'metrics',
      sessionId: {
        propertyId: property.get('id'),
      },
      apiInfo: {
        endpoint: config.baseURL + config.url,
        method: config.method,
      },
      httpResponse: data,
    })
    storage.set('sessionId', data.sessionId)
    if (data.action === 'end') {
      storage.remove('sessionId')
    }
    return response
  },
  error => {
    Logger.error({
      error,
      module: 'metrics',
    })
  },
)

const metrics = {
  track: (name, data) => {
    const property = getProperty()
    const deviceType = getDeviceType()
    if (deviceType !== 'demo' && !property.isArchived) {
      const eventData = {
        property: property.nickname,
        organization: property.organization.name,
      }
      let eventName = data && data.extra && EventName[data.extra.name] ? EventName[data.extra.name] : EventName[name]
      if (!eventName && data && data.extra && data.extra.source) {
        eventName = `Click ${data.extra.source}`
      }
      if (data?.analytics) {
        if (EventName.impression !== eventName) {
          Mixpanel.track(eventName, {
            ...eventData,
            ...data,
          })
        }
      } else {
        console.log('not recorded', eventName)
      }
      return api.post(`/${name}`, data).catch(error => {
        console.warn('ignoring metrics error', error)
      })
    }
    return null
  },
}

const ACTIONS = ['start', 'end']

ACTIONS.forEach(method => {
  metrics[method] = function (...rest) {
    const args = Array.from(rest)
    metrics.track.apply(metrics.track, [method, ...args])
  }
})

export default metrics
