import React from 'react'
import classNames from 'classnames'
import { Card } from 'semantic-ui-react'

import { connectWithPath } from '../../resources/utils'
import { TrackLink } from '../metrics'

import '../../styles/SlideshowInfoCard.scss'
import phoneSmallIcon from '../../images/icons/phone-white-small.png'
import { MESSAGES } from '../../constants/messages'

const SlideshowInfoCard = ({
  masterpiece, interactive, orgName, id, setInteractive,
}) => {
  const { enablePromo, title, description } = masterpiece

  const hasInfo = enablePromo || !!title || !!description

  const className = classNames('SlideshowInfoCard', {
    interactive,
    'if-interactive': !enablePromo,
    'shift-down': !interactive && enablePromo,
    empty: !hasInfo,
  })

  if (!hasInfo) {
    return <Card centered className={className} />
  }

  const name = masterpiece.title
  const message = `From ${decodeURIComponent(encodeURIComponent(orgName))}: ${
    masterpiece.textmessage ? decodeURIComponent(encodeURIComponent(masterpiece.textmessage)) : ''
  }  ${masterpiece.smspromourl ? decodeURIComponent(encodeURIComponent(masterpiece.smspromourl)) : ''}`
  return (
    <Card centered className={className}>
      <Card.Content>
        {masterpiece.promo && <h4>{masterpiece.promo.type}</h4>}
        {enablePromo && <h3>{MESSAGES.SPECIAL_OFFER_FOR_GUEST}</h3>}
        <Card.Header>{title}</Card.Header>
        <Card.Description>
          {!masterpiece.promo || interactive ? description : masterpiece.promo.description}
        </Card.Description>
        {enablePromo === true && (
          <TrackLink
            name={name}
            to={{
              pathname: `/artwork/${id}/phone`,
              state: {
                message,
                name,
              },
            }}
            onClick={setInteractive}
          >
            <div className="art-promo-sms-button">
              <div className="button-link">
                <img
                  className="icon-phone-white-small"
                  src={phoneSmallIcon}
                  alt="phone"
                />
                <span className="promo-button-copy">{MESSAGES.SEND_OFFER_DETAILS_TO_PHONE}</span>
              </div>
            </div>
          </TrackLink>
        )}
      </Card.Content>
    </Card>
  )
}

export default connectWithPath({
  orgName: 'property.organization.name',
})(SlideshowInfoCard)
