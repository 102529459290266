import DollarSignsText from '../../components/DollarSignsText'

export default {
  Component: DollarSignsText,
  examples: [
    {
      name: 'Basic Use',
      props: {
        value: '$$$',
      },
    },
    {
      name: 'Basic Use',
      props: {
        value: '$',
      },
    },
  ],
}
