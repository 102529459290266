import React from 'react';
import { ReactComponent as LogoIcon } from '../images/guestview-logo-white.svg';
import { ReactComponent as BackiconCurve } from '../images/icons/back-icon-curve.svg';

function OutOfOrder() {
  return (
    <div className="outoforder">
      <div className='odr-content'>
        <div className='odr-content-inner'>
          <span className='odr-logo'><LogoIcon /></span>
          <span className='divider'> </span>
          <h1>Service Suspended</h1>
          <span className='divider last'> </span>
          <br />
        </div>
      </div>
      {/* <div className='odr-footer'>
        <span>
          <BackiconCurve />
          Back to Home
        </span>
      </div> */}
    </div>
  );
}

export default OutOfOrder;
