import _debug from 'debug'

const debug = _debug('slideshow')

function reducer(state, action) {
  let current
  let index = 0
  let { placeholderIndex } = state

  debug(action.type, action)
  switch (action.type) {
    case 'init':
      return {
        ...state,
        index: 0,
        current: state.current && Object.keys(state.current).length > 0 ? state.current : action.slideshows[index],
        slideshows: action.slideshows,
      }

    case 'next':
      if (state.index === state.slideshows.length - 1) {
        index = 0
      } else {
        index = state.index + 1
      }
      current = state.slideshows[index]
      if (current && current.props && current.props.isPlaceholder) {
        placeholderIndex = state.placeholderIndex + 1
      }
      return {
        ...state,
        index,
        placeholderIndex,
        isRunning: true, // restart automatic rotation
        current,
      }

    case 'previous':
      if (state.index === 0) {
        index = state.slideshows.length - 1
      } else {
        index = state.index - 1
      }
      current = state.slideshows[index]
      if (current && current.props && current.props.isPlaceholder) {
        placeholderIndex = state.placeholderIndex - 1
      }
      return {
        ...state,
        placeholderIndex,
        index,
        isRunning: true,
        current,
      }

    case 'start':
      return {
        ...state,
        current: state.slideshows[state.index],
        isRunning: true,
      }

    case 'stop':
      return {
        ...state,
        current: action.slideshow || state.current,
        isRunning: false,
      }

    default:
      return state
  }
}

export default reducer
