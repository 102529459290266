import React from 'react'
import '../../styles/Card.scss'
import QR from '../QRCode'
import phoneIcon from '../../images/icons/phoneIcon.svg'

const AboutUsFooter = ({ sendLinkToPhone }) => {
  return (
    <div className="about-footer">
      <div className="qr-md phone" onClick={sendLinkToPhone}>
        <img src={phoneIcon} alt="phoneIcon" />
        <label>View on your phone</label>
      </div>
      <div className="qr-md">
        <QR url='https://guestviewguide.com/' source="AboutUsFooter" name='GVG' />
        <label>Scan for details</label>
      </div>
    </div>
  )
}

export default AboutUsFooter
