import React from 'react'

const MostlyCloudyIcon = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.333 13.3935C18.4488 11.9509 16.8563 11.0434 15.144 11.0434C12.4379 11.0434 10.2366 13.2452 10.2366 15.9508C10.2366 15.9752 10.2366 16.0002 10.2366 16.0246C8.3863 16.3949 7 18.0413 7 19.9729C7 22.1934 8.80657 24 11.0271 24H22.6633C24.4903 24 25.9766 22.5137 25.9766 20.6867C25.9766 19.2493 25.0503 18.001 23.7356 17.5518C23.8594 17.1997 23.9236 16.8277 23.9236 16.4506C23.9236 14.6236 22.4373 13.1373 20.6103 13.1373C20.169 13.1373 19.7357 13.2248 19.333 13.3935ZM22.8246 16.4654C22.8246 16.8799 22.7116 17.2854 22.4986 17.6381L22.1022 18.2946L22.8632 18.39C23.9957 18.5315 24.8498 19.5003 24.8498 20.643C24.8498 21.8958 23.831 22.9147 22.5781 22.9147H11.1027C9.46195 22.9147 8.12733 21.5801 8.12733 19.9393C8.12733 18.3866 9.33928 17.0838 10.8863 16.9725L11.4048 16.935L11.3452 16.4188C11.3281 16.27 11.3191 16.1195 11.3191 15.973C11.3191 13.8535 13.0438 12.1287 15.1633 12.1287C16.6411 12.1287 18.0052 12.9925 18.639 14.3288L18.8787 14.8343L19.3529 14.5378C19.713 14.3129 20.1287 14.1942 20.5541 14.1942C21.8063 14.1942 22.8258 15.2131 22.8258 16.4659L22.8246 16.4654Z" fill="white" />
    <path d="M15.2582 12.984C13.5595 12.984 12.1778 14.3657 12.1778 16.0644C12.1778 16.3433 12.4038 16.5693 12.6826 16.5693C12.9615 16.5693 13.1875 16.3433 13.1875 16.0644C13.1875 14.9223 14.1167 13.9938 15.2582 13.9938C15.537 13.9938 15.7631 13.7677 15.7631 13.4889C15.7631 13.21 15.537 12.984 15.2582 12.984Z" fill="white" />
    <path d="M26.8898 15.509C27.0136 15.1569 27.0778 14.7849 27.0778 14.4078C27.0778 12.5808 25.5915 11.0945 23.7651 11.0945C23.3238 11.0945 22.8905 11.182 22.4878 11.3506C21.603 9.90754 20.0105 9 18.2988 9C16.9829 9 15.7863 9.52079 14.9038 10.367C14.9839 10.3636 15.0639 10.3619 15.1446 10.3619C15.6103 10.3619 16.0703 10.4221 16.5144 10.5362C17.0522 10.2489 17.6662 10.0859 18.3176 10.0859C19.7959 10.0859 21.16 10.9497 21.7933 12.286L21.9903 12.7023C22.0284 12.7165 22.0659 12.7318 22.1033 12.7472L22.5066 12.495C22.8666 12.2701 23.2824 12.1514 23.7077 12.1514C24.96 12.1514 25.9794 13.1703 25.9794 14.4231C25.9794 14.8377 25.8664 15.2432 25.6534 15.5959L25.257 16.2524L26.0175 16.3478C27.1499 16.4892 28.0041 17.4581 28.0041 18.6007C28.0041 19.5236 27.4503 20.3193 26.6581 20.6742C26.6581 20.6788 26.6581 20.6833 26.6581 20.6879C26.6581 21.1076 26.5928 21.5125 26.4718 21.8924C27.9865 21.588 29.1308 20.2472 29.1308 18.6445C29.1308 17.2071 28.2046 15.9588 26.8898 15.5095V15.509Z" fill="white" />
  </svg>
)
export default MostlyCloudyIcon
