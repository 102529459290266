import mixpanel from 'mixpanel-browser'
import { ignoreEvents } from '../constants/eventsConstants'
import { getProperty, getDeviceType } from '../utils/persistent-state'

mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, {
  debug: true,
  ignore_dnt: true,
})

const env_check = true // process.env.NODE_ENV === 'production';

const actions = {
  identify: id => {
    if (env_check) mixpanel.identify(id)
  },
  alias: id => {
    if (env_check) mixpanel.alias(id)
  },
  track: (name, props) => {
    if (ignoreEvents.includes(name)) {
      return
    }
    const property = getProperty()
    if (getDeviceType() !== 'demo' && !property.isArchived) {
      if (env_check) {
        const organizationName = property && property.organization
          ? typeof property.organization === 'string'
            ? property.organization
            : property.organization.name
          : ''
        const eventData = {
          property: property && property.nickname,
          propertyId: property && property.id,
          organization: organizationName,
        }
        props.property
          ? mixpanel.track(name, {
            ...props,
            environment: 'Display',
          })
          : mixpanel.track(name, {
            ...props,
            ...eventData,
            environment: 'Display',
          })
      }
    }
  },
  people: {
    set: props => {
      if (env_check) mixpanel.people.set(props)
    },
  },
}

export const Mixpanel = actions
