import React from 'react'
import Icon from './Icon'

const ServicesIcon = props => (
  <Icon viewBox="0 0 60 47" {...props}>
    <path
      d="M20 3.357C20 1.503 21.492 0 23.333 0h13.334C38.507 0 40 1.503 40 3.357c0 1.854-1.492 3.357-3.333 3.357h-5v3.409c13.95.867 25 12.537 25 26.806H3.333c0-14.27 11.05-25.94 25-26.806V6.714h-5C21.493 6.714 20 5.211 20 3.357zM3.333 40.286C1.493 40.286 0 41.789 0 43.643 0 45.497 1.492 47 3.333 47h53.334C58.507 47 60 45.497 60 43.643c0-1.854-1.492-3.357-3.333-3.357H3.333z"
      fill="currentColor"
    />
  </Icon>
)
export default ServicesIcon
