import React from 'react'
import Icon from './Icon'

const CheckoutIcon = props => (
  <Icon width={67} height={80} viewBox="0 0 67 80" fill="none" {...props}>
    <path
      d="M57 2H10a8 8 0 00-8 8v59.6a8 8 0 008 8h47a8 8 0 008-8V10a8 8 0 00-8-8zM32.24 22.16H52.4"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="square"
    />
    <path
      d="M13.34 22.16l2.52 2.52 7.56-7.56M32.24 39.8H52.4M13.34 39.8l2.52 2.52 7.56-7.56M32.24 57.44H52.4M17.12 61.22a3.78 3.78 0 100-7.56 3.78 3.78 0 000 7.56z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="square"
    />
  </Icon>
)
export default CheckoutIcon
