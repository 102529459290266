import React, { Component } from 'react'

import '../styles/RadioList.scss'

export default class RadioList extends Component {
  render() {
    const { value, options, onChange } = this.props
    return (
      <div className="RadioList">
        {options.map(o => (
          <div key={o.label} className={`item ${o.value === value && 'selected'}`} onClick={() => onChange(o.value)}>
            <div className="bullet">
              <div>
                <div />
              </div>
            </div>
            <div className="label">{o.label}</div>
            {o.extra && <div className="extra">{o.extra}</div>}
          </div>
        ))}
      </div>
    )
  }
}
