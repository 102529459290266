import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { get, flatten, sortBy } from 'lodash'
import { withRouter } from 'react-router'
import classNames from 'classnames'

import {
  PropertyInfoOverview,
} from '../components'
import {
  Topic,
} from '../components/contact'
import { setHideFooter } from '../actions/device'
import { DetailFooter, PageHeader } from '../components/pages'
import { MESSAGES } from '../constants/messages'

const getGridClassByTopicsCount = topicsCount => {
  switch (topicsCount) {
    case 4:
      return 'count4'
    case 5:
      return 'count5'
    case 6:
      return 'count6'
    case 7:
      return 'count7'
    case 8:
      return 'count8'
    default:
      return ''
  }
}

const ContactHostContainer = ({
  setHideFooter,
  requestEnabled,
  message,
  property,
  history,
  urgentEnabled,
  topics,
}) => {
  const additionalClass = ''
  useEffect(() => {
    setHideFooter({ value: true })
    return () => {
      setHideFooter({ value: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectTopic = topic => {
    history.push('/contact/request', {
      topic,
      urgentEnabled,
    })
  }

  const goBack = () => {
    history.goBack()
  }

  return (
    <div
      className={classNames('Card contact-host-card', {
        'contact-host-preview-switch-on': requestEnabled,
        'contact-host-preview-switch-off': !requestEnabled,
      })}
    >
      <div className="card-section header icons-container-inner">
        <PageHeader title="Contact Us">
          {requestEnabled ? (
            <p className="has-top-separatorline">{message}</p>
          ) : (
            <div>
              <PropertyInfoOverview property={property} />
              <div
                className="contact-us-request-off"
                dangerouslySetInnerHTML={{
                  __html: message || MESSAGES.DEFAULT_CONTACT_MESSAGE,
                }}
              />
            </div>
          )}
        </PageHeader>
      </div>

      {requestEnabled && (
        <>
          <p>{MESSAGES.SELECT_TOPIC}</p>
          <div className={`icons-grid ${additionalClass}`}>
            <ul>
              {topics?.map(topic => (
                <Topic topic={topic} onClick={onSelectTopic} />
              ))}
            </ul>
          </div>
        </>
      )}

      <DetailFooter className="bottom stretch">
        <div onClick={goBack} className="link">
          {requestEnabled ? MESSAGES.BACK : MESSAGES.OK}
        </div>
      </DetailFooter>
    </div>
  )
}

const mapStateToProps = ({ property }) => {
  const {
    message, requestEnabled, urgentEnabled, contacts,
  } = get(property, ['customizations', 'contact'], {})

  const topics = flatten(contacts.map(contact => contact?.topics.map(topic => { return { ...topic, contactId: contact.id } })))
  const sortedTopics = sortBy(topics, t => t.isDefault)

  return {
    property,
    message,
    requestEnabled,
    urgentEnabled,
    topics: sortedTopics,
  }
}

export default connect(mapStateToProps, { setHideFooter })(withRouter(ContactHostContainer))
