import React, { Component } from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import ChecklistItem from './ChecklistItem'
import '../../styles/Checklist.scss'

class Checklist extends Component {
  shouldComponentUpdate(nextProps) {
    if (JSON.stringify(nextProps.items) === JSON.stringify(this.props.items)) {
      return false
    }
    return true
  }

  render() {
    const {
      items, subtitle, amenities, toggleChecked,
    } = this.props
    if (items.length === 0) {
      return null
    }
    return (
      <div className='Checklist'>
        {subtitle && <div>{subtitle}</div>}
        <div className='checklist-page'>
          {items
            .filter(({ text }) => text.length > 0)
            .map((item, index) => (
              <ChecklistItem toggleChecked={toggleChecked} item={item} key={index} amenities={amenities} />
            ))}
        </div>
      </div>
    )
  }
}
const mapStateToProps = ({ property }) => ({
  amenities: get(property, ['knowledgebase', 0, 'items'], []),
})
export default connect(mapStateToProps)(Checklist)
