/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { makeAPIRequest } from '../../utils/helpers'
import { transformFeedbackValues } from '../../utils/feedback'
import { CheckInOutWizard } from '../checkInOut'
import { useCustomization } from '../../hooks/useCustomization'
import headerBackground from '../../images/checkin-header.jpeg'
import { getDeviceType } from '../../utils/persistent-state'
import { fetchPropertyMessages } from '../../actions/property'

const CHECKIN_STEPS = [
  {
    key: 'checkin',
    topic: 'checkin',
    title: 'We\'d love to hear your feedback',
    question: 'What are your first impressions of the property?',
  },
  {
    key: 'rules',
    title: 'Property Guidelines',
    listPath: 'rules.ruleList',
  },
]

const CheckInWizard = ({
  history, property, welcomeVideo, rules,
  dispatch, checkinSurvey,
}) => {
  const quitAction = () => {
    localStorage.removeItem('hasViewedOnboarding')
    history.goBack()
  }

  const finishAction = values => {
    const reservationId = get(property, 'reservation.id', null)
    localStorage.removeItem('checkedoutTime')
    localStorage.removeItem('noGuestMode')
    localStorage.removeItem('reservationServices')
    return makeAPIRequest('reservation/checkin', {
      method: 'POST',
      data: {
        reservationId,
        feedbacks: transformFeedbackValues(CHECKIN_STEPS, values),
        demoMode: getDeviceType() === 'demo',
      },
    })
  }

  const continueAction = () => {
    fetchPropertyMessages(dispatch, property)
    history.push('/home', {
      justCheckedIn: true,
    })
  }

  const removeGuidelinesFromCheckIn = rules ? !rules.includeInCheckIn : true
  const removeSurveyFromCheckIn = checkinSurvey ? checkinSurvey.removeFromCheckIn : false
  const checkinPrunedSteps = CHECKIN_STEPS.filter(
    (step) => (!removeGuidelinesFromCheckIn || step.key !== 'rules')
              && (!removeSurveyFromCheckIn || step.key !== 'checkin'),
  );
  return (
    <CheckInOutWizard
      checkin
      steps={checkinPrunedSteps}
      welcomeVideo={welcomeVideo}
      quitAction={quitAction}
      finishAction={finishAction}
      continueAction={continueAction}
      headerBackground={headerBackground}
    />
  )
}

const mapStateToProps = state => {
  return {
    property: state.property,
    welcomeVideo: useCustomization('welcomeVideo', state),
    rules: useCustomization('rules', state),
    checkinSurvey: useCustomization('checkinSurvey', state),
  }
}

export default withRouter(connect(mapStateToProps)(CheckInWizard))
