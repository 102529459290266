import React from 'react'
import { PlayIcon } from './icons'
import ServiceVideoPlayer from './services/ServiceVideoPlayer'

const MediaDisplay = ({
  item, className, setFullScreen, isVideo, preview, ...props
}) => {
  const addPreviewSrc = (media, event) => {
    if (media.preview) event.target.src = media.preview
  }

  return item.mediaType === 'VIDEO' ? (
    <div
      style={{
        position: 'relative',
      }}
    >
      {preview ? (
        <>
          <video onError={addPreviewSrc.bind(this, item)} className={className} src={item.mediaUrl} {...props} />
          <div
            style={{
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
            }}
          >
            <PlayIcon className="" />
          </div>
        </>
      ) : (
        <ServiceVideoPlayer item={item} setFullScreen={setFullScreen} isVideo={isVideo} {...props} />
      )}
    </div>
  ) : (
    <img
      onError={addPreviewSrc.bind(this, item)}
      alt="MediaIcon"
      className={className}
      src={item.mediaUrl}
      {...props}
    />
  )
}

export default MediaDisplay
