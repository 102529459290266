import React from 'react'
import { get } from 'lodash'
import { TrackLink } from './metrics'
import {
  PropertyIcon, RecommendedBadgeIcon, ServicesIcon, MapIcon,
} from './icons'
import { useServices } from '../resources/services'

import '../styles/HomeNavigation.scss'

const TABS = [
  {
    to: '/property-info',
    icon: PropertyIcon,
    label: 'Property Info',
    options: {
      iconDimensions: {
        width: 78,
        height: 54,
      },
    },
  },
  {
    to: '/recommendations',
    label: 'Local Recommendations',
    icon: RecommendedBadgeIcon,
    options: {
      iconDimensions: {
        width: 68,
        height: 44,
        fill: 'white',
      },
    },
  },
  {
    to: '/map',
    icon: MapIcon,
    label: 'Map & Nearby',
    options: {
      iconDimensions: {
        width: 62,
        height: 52,
      },
    },
  },
  {
    to: '/services',
    icon: ServicesIcon,
    label: 'Services',
    options: {
      iconDimensions: {
        width: 60,
        height: 47,
      },
    },
    condition: ({ services }) => services.length > 0,
  },
]

const HomeNavigation = () => {
  const { services } = useServices()
  return (
    <div className="tab-nav">
      {TABS.map(({
        to, label, icon: TabIcon, options, condition,
      }) => {
        const show = condition
          ? condition({
            services,
          })
          : true
        const iconDimensions = get(options, 'iconDimensions', null)
        return (
          show && (
            <TrackLink
              name={label}
              key={to}
              to={to}
              data-cy={`link${to.replace('/', '-')}`}
              className="tab"
              source="HomeNavigation"
            >
              <TabIcon {...iconDimensions} />
              <div className="label">{label}</div>
            </TrackLink>
          )
        )
      })}
    </div>
  )
}

export default HomeNavigation
