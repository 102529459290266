import {
  local, device, kiosk,
} from '../api'
import { createErrorLogs, createRequestLogs } from '../logger'
import {
  UPDATE_BRIGHTNESS,
  UPDATE_VOLUME,
  VOLUME_FETCH_SUCCESS,
  BRIGHTNESS_FETCH_SUCCESS,
  SAVE_SERIAL_NUMBER,
  SET_DAYTIME_MODE,
  SET_NIGHTTIME_MODE,
  SET_OVERLAY,
  SET_HIDE_FOOTER,
} from '../reducers/device'

export const setPairedProperty = async (pin, serialNumber = '') => {
  try {
    const response = await device.get(`/system/setpairedproperty/${pin}`)

    const logObj = {
      module: 'device-set-paired-property-call',
      apiInfo: {
        endpoint: `${response.config.baseURL}${response.config.url}`,
        method: response.method,
      },
      httpResponse: response,
      metadata: { propertyPin: pin, serialNumber },
      message: '[LOG] <=> setpairproperty call success',
    }

    createRequestLogs(logObj.module, {
      data: logObj.metadata,
      method: logObj.apiInfo.method,
    })

    return response
  } catch (error) {
    const errObj = {
      error,
      module: 'device-set-paired-property-call',
      metadata: { propertyPin: pin },
      message: '[LOG] <=> setpairproperty call error',
    }
    createErrorLogs(errObj.error, errObj.module, {
      data: errObj.metadata,
    })
    console.log('Error in pairing the property with linux check', error)
  }
}

export const getDeviceState = async (pin = null) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = (await device.get('/concierge'))
    if (pin) {
      const logObj = {
        module: 'device-get-state-call',
        apiInfo: {
          endpoint: `${response.config.baseURL}${response.config.url}`,
          method: response.config.method,
        },
        httpResponse: response,
        metadata: { serialNumber: response?.data?.serialNumber, propertyPin: pin },
        message: '[LOG] <=> get conceirge data call',
      }
      createRequestLogs(logObj.module, {
        data: logObj.metadata,
        method: logObj.apiInfo.method,
      })
    }

    return response.data
  } catch (error) {
    if (pin) {
      const errObj = {
        error,
        module: 'device-get-state-call',
        metadata: { propertyPin: pin },
        message: '[LOG] <=> error in get conceirge data call',
      }
      createErrorLogs(errObj.error, errObj.module, {
        data: errObj.metadata,
      })
    }
    console.error(error)
  }
}

export const attachSerialNumber = () => {
  return async dispatch => {
    try {
      const { serialNumber } = await getDeviceState()
      if (serialNumber) {
        localStorage.setItem('serialNumber', serialNumber)
        const response = await kiosk.post('/device', { serialNumber })
        dispatch({
          type: SAVE_SERIAL_NUMBER,
          payload: response.data,
        })
        const logObj = {
          module: 'attach-serial-number',
          apiInfo: {
            endpoint: `${response.config.baseURL}${response.config.url}`,
            method: response.config.method,
          },
          metadata: { serialNumber: response?.data?.serialNumber, propertyPin: pin },
        }
        createRequestLogs(logObj.module, {
          data: logObj.metadata,
          method: logObj.apiInfo.method,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const detachSerialNumber = () => {
  return async dispatch => {
    try {
      const { serialNumber } = await getDeviceState()
      if (serialNumber) {
        const response = await kiosk.delete('/device', { data: { serialNumber } })
        dispatch({
          type: SAVE_SERIAL_NUMBER,
          payload: response.data,
        })
        const logObj = {
          module: 'detach-serial-number',
          apiInfo: {
            endpoint: `${response.config.baseURL}${response.config.url}`,
            method: response.config.method,
          },
          metadata: { serialNumber: response?.data?.serialNumber, propertyPin: pin },
        }
        createRequestLogs(logObj.module, {
          data: logObj.metadata,
          method: logObj.apiInfo.method,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export function getVolume() {
  return async dispatch => {
    const response = await device.get('/audiodevice/volume')
    const deviceVolume = response.data
    dispatch({
      type: VOLUME_FETCH_SUCCESS,
      deviceVolume,
    })
    const logObj = {
      module: 'get-volume',
      apiInfo: {
        endpoint: `${response.config.baseURL}${response.config.url}`,
        method: response.config.method,
      },
      metadata: { serialNumber: response?.data?.serialNumber, propertyPin: pin },
    }
    createRequestLogs(logObj.module, {
      data: logObj.metadata,
      method: logObj.apiInfo.method,
    })
  }
}

export function getBrightness() {
  return async dispatch => {
    const response = await device.get('/displaydevice/brightness')
    const deviceBrightness = response.data
    dispatch({
      type: BRIGHTNESS_FETCH_SUCCESS,
      deviceBrightness,
    })
    const logObj = {
      module: 'get-brightness',
      apiInfo: {
        endpoint: `${response.config.baseURL}${response.config.url}`,
        method: response.config.method,
      },
      metadata: { serialNumber: response?.data?.serialNumber, propertyPin: pin },
    }
    createRequestLogs(logObj.module, {
      data: logObj.metadata,
      method: logObj.apiInfo.method,
    })
  }
}

export function updateBrightness(value) {
  return async dispatch => {
    const response = await device.get(`/displaydevice/brightness/${value}`)
    const updatedBrightnessLevel = response.data.brightnessLevel
    dispatch({
      type: UPDATE_BRIGHTNESS,
      updatedBrightnessLevel,
    })
    const logObj = {
      module: 'update-brightness',
      apiInfo: {
        endpoint: `${response.config.baseURL}${response.config.url}`,
        method: response.config.method,
      },
      metadata: { serialNumber: response?.data?.serialNumber, propertyPin: pin },
    }
    createRequestLogs(logObj.module, {
      data: logObj.metadata,
      method: logObj.apiInfo.method,
    })
  }
}

export function updateVolume(value) {
  return async dispatch => {
    const response = await device.get(`/audiodevice/volume/${value}`)
    const updatedVolumeLevel = response.data.volumeLevel
    dispatch({
      type: UPDATE_VOLUME,
      updatedVolumeLevel,
    })
    const logObj = {
      module: 'update-volume',
      apiInfo: {
        endpoint: `${response.config.baseURL}${response.config.url}`,
        method: response.config.method,
      },
      metadata: { serialNumber: response?.data?.serialNumber, propertyPin: pin },
    }
    createRequestLogs(logObj.module, {
      data: logObj.metadata,
      method: logObj.apiInfo.method,
    })
  }
}

export function setDaytimeMode() {
  return async dispatch => {
    const response = await local.get('setdaytime')
    dispatch({
      type: SET_DAYTIME_MODE,
      payload: response && response.data,
    })
    const logObj = {
      module: 'set-daytime-mode',
      apiInfo: {
        endpoint: `${response.config.baseURL}${response.config.url}`,
        method: response.config.method,
      },
      metadata: { serialNumber: response?.data?.serialNumber, propertyPin: pin },
    }
    createRequestLogs(logObj.module, {
      data: logObj.metadata,
      method: logObj.apiInfo.method,
    })
  }
}

export function setNighttimeMode() {
  return async dispatch => {
    const response = await local.get('setnighttime')
    dispatch({
      type: SET_NIGHTTIME_MODE,
      payload: response && response.data,
    })
    const logObj = {
      module: 'set-nighttime-mode',
      apiInfo: {
        endpoint: `${response.config.baseURL}${response.config.url}`,
        method: response.config.method,
      },
      metadata: { serialNumber: response?.data?.serialNumber, propertyPin: pin },
    }
    createRequestLogs(logObj.module, {
      data: logObj.metadata,
      method: logObj.apiInfo.method,
    })
  }
}

export function setOverlay({ value }) {
  return dispatch => {
    dispatch({
      type: SET_OVERLAY,
      payload: value,
    })
  }
}

export function setHideFooter({ value }) {
  return dispatch => {
    dispatch({
      type: SET_HIDE_FOOTER,
      payload: value,
    })
  }
}
