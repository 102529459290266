/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { isArray } from 'lodash'
import { PageHeader } from '../components/pages'
import PropertyInfoOverview from '../components/PropertyInfoOverview'
import {
  CheckoutIcon,
  HouseRulesIcon,
  AmenitiesIcon,
  PoliciesIcon,
  SuppliesIcon,
  WelcomeVideoIcon,
} from '../components/icons'
import { TrackLink } from '../components/metrics'
import { PropertyInfoListItem } from '../components/propertyInfo'
import '../styles/PropertyInfoContainer.scss'
import { setOverlay } from '../actions/device'
import WelcomeVideo from '../components/WelcomeVideo'
import { useCustomization } from '../hooks/useCustomization'
import { ANALYTICS_ENTITY_TYPES } from '../constants/appConstants'

export const LIST_OPTIONS = [
  {
    key: 'house-rules',
    title: 'Property Guidelines',
    subtitle: 'Things to know during your stay',
    icon: HouseRulesIcon,
    link: '/house-rules',
    hasData: property => property.customizations?.rules?.ruleList,
  },
  {
    key: 'amenities',
    title: 'Amenities',
    subtitle: 'Information about features of this property',
    icon: AmenitiesIcon,
    link: '/property-info/amenities',
    hasData: property => property.customizations.amenities,
  },
  {
    key: 'supplies',
    title: 'Supplies',
    subtitle: 'Where to find things around the property',
    link: '/property-info/supplies',
    icon: SuppliesIcon,
    hasData: property => property.customizations.supplies,
  },
  {
    title: 'Checkout Instructions',
    subtitle: 'To-do list before you leave',
    link: '/checkout',
    icon: CheckoutIcon,
  },
  {
    key: 'policies',
    title: 'Policies',
    subtitle: 'Our policies at this property',
    link: '/property-info/policies',
    icon: PoliciesIcon,
    hasData: property => property.customizations.policies,
  },
  {
    key: 'welcomeVideo',
    title: 'Welcome Video',
    subtitle: '',
    link: '/property-info/welcomeVideo',
    icon: WelcomeVideoIcon,
    hasData: property => (property.customizations.welcomeVideo && property.customizations?.welcomeVideo?.status
      ? isArray(property.customizations.welcomeVideo)
        ? property.customizations.welcomeVideo
        : [property.customizations.welcomeVideo]
      : []),
  },
]

const PropertyInfoContainer = ({ property, setOverlay, welcomeVideo }) => {
  const [openVideo, setOpenVideo] = useState(false)

  const welcomeVideoClickHandler = evt => {
    evt.preventDefault()
    setOpenVideo(true)
  }

  useEffect(() => {
    setOverlay({ value: true })
    return () => setOverlay({ value: false })
  }, [setOverlay])

  return (
    <div className="Card full-height PropertyInfoCard PropertyInfoContainer">
      <div className="card-section header border-bottom">
        <PageHeader title="Property Info">
          <PropertyInfoOverview property={property} />
        </PageHeader>
      </div>
      <div className="property-info-list">
        {LIST_OPTIONS.map(({
          icon, link, title, subtitle, hasData, key,
        }) => {
          const shouldShow = hasData ? hasData(property)?.length > 0 : true
          const isWelcomeVideo = key === 'welcomeVideo'
          if (shouldShow) {
            return (
              <TrackLink
                className="option"
                to={{ pathname: link, state: { title, subtitle } }}
                source="PropertyInfoContainer"
                action={isWelcomeVideo ? 'videoRewatched' : 'click'}
                name={title}
                key={title}
                onClick={isWelcomeVideo && welcomeVideoClickHandler}
                entityId={isWelcomeVideo && welcomeVideo ? welcomeVideo.id : null}
                entityType={isWelcomeVideo ? ANALYTICS_ENTITY_TYPES.WELCOME_VIDEO : null}
                analytics={false}
              >
                <PropertyInfoListItem title={title} subtitle={subtitle} icon={icon} />
              </TrackLink>
            )
          }
          return null
        })}
      </div>
      {openVideo && (
        <WelcomeVideo
          onEnded={setOpenVideo.bind(this, false)}
          openVideo={openVideo}
          source="PropertyInfoContainer"
          {...welcomeVideo}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    property: state.property,
    section: state.property.knowledgebase.find(s => s.name === ownProps.match.params.section),
    welcomeVideo: useCustomization('welcomeVideo', state),
  }
}

export default connect(mapStateToProps, { setOverlay })(PropertyInfoContainer)
