import React, { Component } from 'react'
import OnboardingContactField from '../../components/OnboardingContactField'

class OnboardingContactFieldWrap extends Component {
  constructor(props) {
    super()
    this.state = {
      ...props,
    }
  }

  render() {
    return (
      <OnboardingContactField
        {...this.state}
        onChange={v => this.setState({
          value: v,
        })}
        onSetEnabled={e => this.setState({
          enabled: e,
        })}
      />
    )
  }
}

export default {
  Component: OnboardingContactFieldWrap,
  namedJSONChunks: {
    'OnboardingContactField.Validation.Email': OnboardingContactField.Validation.Email,
    'OnboardingContactField.Validation.Phone': OnboardingContactField.Validation.Phone,
  },
  examples: [
    {
      name: 'Phone Number (Off)',
      props: {
        label: 'Mobile Phone',
        meta: 'Standard text messaging rates may apply.',
        value: '',
        enabled: false,
        onChange: () => false,
        onFocus: () => false,
        onBlur: () => false,
        onSetEnabled: () => false,
        onValidate: OnboardingContactField.Validation.Phone,
      },
    },
    {
      name: 'Phone Number',
      props: {
        label: 'Mobile Phone',
        meta: 'Standard text messaging rates may apply.',
        value: '',
        enabled: true,
        onChange: () => false,
        onFocus: () => false,
        onBlur: () => false,
        onSetEnabled: () => false,
        onValidate: OnboardingContactField.Validation.Phone,
      },
    },
    {
      name: 'Email Address',
      props: {
        label: 'Email Address',
        value: '$$$',
        enabled: true,
        onChange: () => false,
        onFocus: () => false,
        onBlur: () => false,
        onSetEnabled: () => false,
        onValidate: OnboardingContactField.Validation.Email,
      },
    },
  ],
}
