import moment from 'moment'
import { RRule } from 'rrule'
import { ANALYTICS_ENTITY_TYPES, MESSAGE_STATUS } from '../constants/appConstants'
import { makeAPIRequest } from './helpers'
import { metrics } from '../api'

export const sycnMessagesWithServer = async (messagesFromServer, reservation) => {
  // Filtering out one time messages that has been read or expired
  const unreadMessages = messagesFromServer.filter(
    ms =>
      !(
        (ms.datetime.type === 'now' || ms.datetime.type === 'datetime') &&
        (ms.propertyMessage.status === 'READ' || !!ms.propertyMessage.isExpired)
      )
  )
  // Setting messages received time on device storage
  const updatedMessageArray = syncMessagesToStorage(unreadMessages, reservation)

  for (let i = 0; i < updatedMessageArray.length; i++) {
    const message = updatedMessageArray[i]
    // eslint-disable-next-line no-await-in-loop
    await maintainMessageHistory(message, reservation)
  }
}

/**
 * Syncing messages on local storage on each api call.
 * Since Message received time is different for each device,
 * So we are settting the message received time here for each device seperately.
 */
const syncMessagesToStorage = (messagesFromServer, reservation) => {
  // Check if we have any messages in local storage
  const messagesFromLocal = JSON.parse(localStorage.getItem('messages')) || []

  let updatedMessageArray = []
  for (const incomingMessage of messagesFromServer) {
    const localMessage = messagesFromLocal.find(ml => ml.id === incomingMessage.id)

    // Re-sync local message if it gets updated, without updating the received at time
    if (
      localMessage &&
      (moment(incomingMessage.updatedAt) > moment(localMessage.updatedAt) ||
        moment(incomingMessage.propertyMessage.updatedAt) > moment(localMessage.propertyMessage.updatedAt))
    ) {
      updatedMessageArray.push({
        ...incomingMessage,
        receivedAt: localMessage.receivedAt,
      })
    } else {
      if (localMessage) {
        // Return same message from local storage if not get updated
        updatedMessageArray.push(localMessage)
      }

      // Message appear first time
      if (!localMessage) {
        const messageWithReceivedTime = getMessageReceivedTimeOnDevice(incomingMessage, reservation)

        if (messageWithReceivedTime) {
          updatedMessageArray.push(messageWithReceivedTime)

          // Record occurence for first time message received.
          const reservationId = reservation ? reservation.id : null
          updateMessageStatus(
            messageWithReceivedTime,
            MESSAGE_STATUS.UNREAD,
            reservationId,
            messageWithReceivedTime.receivedAt
          )
        }
      }
    }
  }

  // Sync messages to storage
  updatedMessageArray = updatedMessageArray.filter(m => !!m)
  setLocalStorage('messages', updatedMessageArray)
  return updatedMessageArray
}

const maintainMessageHistory = async (message, reservation = null) => {
  const { datetime, receivedAt } = message

  const reservationId = reservation ? reservation.id : null

  if (datetime.type === 'recurring') {
    setOccurenceForReccuredMessage(message, reservationId)
  }

  // After checkin / Before Checkout message
  if (datetime.type === 'relative' && reservation?.hasViewedOnboarding) {
    const checkinDatetime = moment(`${reservation.checkinTime}`)
    const checkoutDatetime = moment(`${reservation.endDate} ${reservation.checkoutTime}:00`).utc()

    const next = moment(datetime.to === 'checkinTime' ? checkinDatetime : checkoutDatetime).add(
      datetime.value,
      'minutes'
    )

    // Message re-occurred
    if (receivedAt && moment(receivedAt).isBefore(checkinDatetime) && moment().isAfter(next)) {
      const receivedAt = new Date().toISOString()
      updateMessageStatus(message, MESSAGE_STATUS.UNREAD, reservationId, receivedAt)

      // Reset message expiry for recurring message
      makeAPIRequest(`property/message-expired/${message.id}`, {
        method: 'PUT',
        data: { isExpired: false },
      })

      // Updating state of locally stored message
      updateLocalMessageData(message, receivedAt)
    }
  }
}

const updateMessageStatus = async (message, status, reservationId, receivedAt) => {
  try {
    const noGuestMode = localStorage.getItem('noGuestMode')

    if (!localStorage.getItem('isPreviewMode')) {
      const data = {
        status,
        type: message.datetime.type,
        reservationId: reservationId && !noGuestMode ? reservationId : null,
        occurrence: receivedAt || new Date().toISOString(),
      }

      // Update history will always be true for a recurring type message
      const updateHistory = true

      makeAPIRequest(`property/message-read/${message.id}`, {
        method: 'PUT',
        data,
        params: {
          updateHistory,
        },
      })
    }
  } catch (err) {
    console.log(err)
  }
}

const setOccurenceForReccuredMessage = async (message, reservationId) => {
  const { receivedAt, datetime, createdAt } = message
  const showAt = getShowAtTimeForReccuredMessage(datetime, createdAt)

  // Is current time is after show time,
  if (moment().isAfter(showAt)) {
    // Message re-occured, Set entry in database and update received at time
    if (receivedAt && moment(receivedAt).isBefore(moment(showAt))) {
      const receivedAt = new Date().toISOString()
      updateMessageStatus(message, MESSAGE_STATUS.UNREAD, reservationId, receivedAt)

      // Reset message expiry for recurring message
      makeAPIRequest(`property/message-expired/${message.id}`, {
        method: 'PUT',
        data: { isExpired: false },
      })

      // Updating state of locally stored message
      updateLocalMessageData(message, receivedAt)
    }
  }
}

const getShowAtTimeForReccuredMessage = (datetime, createdAt) => {
  const time = datetime.time.split(':')
  const options = RRule.parseString(datetime.value)
  options.dtstart = moment(createdAt)
    .hours(time[0])
    .minutes(time[1])
    .toDate()
  const rule = new RRule(options)

  // next occurence date
  const next = rule.after(
    moment()
      .subtract(1, 'day')
      .toDate()
  )

  // next occurence date time
  const showAt = moment(next)
    .hours(time[0])
    .minutes(time[1])
    .utc()
    .format()
  return showAt
}

export const isMessageActive = (message, property = {}) => {
  const {
    datetime,
    propertyMessage: { status, updatedAt, isExpired },
    enabled,
    createdAt,
  } = message

  const {
    reservation = null,
    customizations: { checkInOut },
  } = property

  // Hide message if its not enabled
  if (!enabled) return false

  // Hide message, if already marked expired
  if (isExpired) return false

  // Evaluate message expiry
  const messageExpired = isMessageExpired(message, reservation, checkInOut)

  if (messageExpired) {
    if (!localStorage.getItem('isPreviewMode')) {
      // Mark message as expired on server
      makeAPIRequest(`property/message-expired/${message.id}`, {
        method: 'PUT',
        data: { isExpired: true },
      })

      // Update local message on expiry,
      updateLocalMessageExpiry(message, true)

      // Record expired event
      metrics.track('announcementExpired', {
        extra: {
          source: 'Expired Announcement',
          name: 'Expired Announcement',
          announcement_name: message.title,
        },
        entityId: message.id,
        entityType: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
        referer: ANALYTICS_ENTITY_TYPES.ANNOUNCEMENTS,
        isImpressionEvent: false,
        analytics: true,
      })
    }

    return false
  }

  if (datetime.type === 'recurring') {
    const time = datetime.time.split(':')
    const options = RRule.parseString(datetime.value)
    options.dtstart = moment(createdAt)
      .hours(time[0])
      .minutes(time[1])
      .toDate()
    const rule = new RRule(options)

    if (status?.toUpperCase() === MESSAGE_STATUS.UNREAD) {
      const next = rule.after(
        moment()
          .subtract(1, 'day')
          .toDate()
      )
      const showAt = moment(next)
        .hours(time[0])
        .minutes(time[1])
        .utc()
        .format()
      return moment().isAfter(showAt)
    }
  }

  if (datetime.type === 'datetime') {
    return moment(datetime.value).isBefore() && status?.toUpperCase() === MESSAGE_STATUS.UNREAD
  }

  if (datetime.type === 'relative' && reservation?.hasViewedOnboarding) {
    const checkinDatetime = moment(`${reservation.checkinTime}`)
    const checkoutDatetime = moment(`${reservation.endDate} ${reservation.checkoutTime}:00`).utc()

    const next = moment(datetime.to === 'checkinTime' ? checkinDatetime : checkoutDatetime)
      .add(datetime.value, 'minutes')
      .format()

    if (status?.toUpperCase() === MESSAGE_STATUS.UNREAD || moment(updatedAt).isBefore(checkinDatetime)) {
      return moment().isAfter(next)
    }
  }

  if (datetime.type === 'now') {
    return status?.toUpperCase() === MESSAGE_STATUS.UNREAD
  }

  return false
}

export const getUnreadMessages = (messages = [], property) =>
  messages.filter(message => {
    return !!isMessageActive(message, property)
  })

const getMessageReceivedTimeOnDevice = (message, reservation) => {
  const { datetime, createdAt } = message

  let receivedAt

  if (datetime.type === 'now') {
    receivedAt = new Date().toISOString()
    return {
      ...message,
      receivedAt,
    }
  }

  if (datetime.type === 'datetime' && moment(datetime.value).isBefore()) {
    receivedAt = new Date().toISOString()
    return {
      ...message,
      receivedAt,
    }
  }
  if (datetime.type === 'recurring') {
    const showAt = getShowAtTimeForReccuredMessage(datetime, createdAt)

    if (moment().isAfter(showAt)) {
      receivedAt = new Date().toISOString()
      return {
        ...message,
        receivedAt,
      }
    }
    return false
  }

  if (datetime.type === 'relative') {
    if (reservation?.checkinTime) {
      const checkinDatetime = moment(`${reservation.checkinTime}`)
      const checkoutDatetime = moment(`${reservation.endDate} ${reservation.checkoutTime}:00`).utc()

      const next = moment(datetime.to === 'checkinTime' ? checkinDatetime : checkoutDatetime).add(
        datetime.value,
        'minutes'
      )

      if (moment().isAfter(next) && reservation.hasViewedOnboarding) {
        receivedAt = new Date().toISOString()
        return {
          ...message,
          receivedAt,
        }
      }
    }
  }
}

// Check if message clear at time is passed or not
const isMessageExpired = (message, reservation, checkInOut) => {
  const {
    clearAt,
    propertyMessage: { status },
    receivedAt
  } = message

  const { checkinDatetime } = getCheckInDateTime(checkInOut)
  switch (clearAt.type) {
    case 'AFTER_READ':
      return status?.toUpperCase() === MESSAGE_STATUS.READ
    case 'AFTER_RESERVATION_END':
      return reservation
        ? getExpiryForReservationMode(reservation, receivedAt, checkInOut)
        : getExpiryForNonReservationMode(receivedAt, checkinDatetime)
    case 'DAY':
      return moment().diff(moment(receivedAt), 'hours', true) > 24
    case 'HOUR':
      return moment().diff(moment(receivedAt), 'hours', true) > 1
    case 'CUSTOM':
      switch (clearAt.value) {
        case 'HOURS':
          return moment().diff(moment(receivedAt), 'hours', true) > clearAt.interval
        case 'DAYS':
          return moment().diff(moment(receivedAt), 'days', true) > clearAt.interval
        case 'WEEKS':
          return moment().diff(moment(receivedAt), 'weeks', true) > clearAt.interval
        default:
          return true
      }
    default:
      return true
  }
}

const setLocalStorage = async (key, value) => localStorage.setItem(key, JSON.stringify(value))

export const updateLocalMessageData = (message, receivedAt) => {
  const localStroredMessages = JSON.parse(localStorage.getItem('messages'))
  const updatedArray = localStroredMessages.map(localMessage =>
    localMessage.id === message.id
      ? {
          ...message,
          receivedAt,
          propertyMessage: { ...message.propertyMessage, status: MESSAGE_STATUS.UNREAD, isExpired: false },
        }
      : localMessage
  )
  setLocalStorage('messages', updatedArray)
}

export const updateLocalMessageExpiry = (message, isExpired) => {
  const localStroredMessages = JSON.parse(localStorage.getItem('messages'))
  const updatedArray = localStroredMessages.map(localMessage =>
    localMessage.id === message.id
      ? { ...message, propertyMessage: { ...message.propertyMessage, isExpired } }
      : localMessage
  )
  setLocalStorage('messages', updatedArray)
}

export const updateLocalMessageStatus = (message, status) => {
  const localStroredMessages = JSON.parse(localStorage.getItem('messages'))
  const updatedArray = localStroredMessages.map(localMessage =>
    localMessage.id === message.id
      ? { ...message, propertyMessage: { ...message.propertyMessage, status, isExpired: false } }
      : localMessage
  )
  setLocalStorage('messages', updatedArray)
}

const getCheckInDateTime = (checkInOut = {}) => {
  const { checkinTime } = checkInOut
  const today = moment().format('YYYY-MM-DD')

  const checkinDatetime = moment(`${today} ${checkinTime}`)
    .utc()
    .format()

  return { checkinDatetime }
}

const getExpiryForNonReservationMode = (receivedAt, checkinDatetime) => {
  const now = moment()
    .utc()
    .format()
  // Message received before next check in time, and check in time has been passed, message is expired
  if (moment(receivedAt).isBefore(moment(checkinDatetime)) && moment(now).isAfter(moment(checkinDatetime))) {
    return true
  }

  return false
}

const getExpiryForReservationMode = (reservation, receivedAt, checkInOut) => {
  const { checkoutTime } = checkInOut

  const reservationStartTime = moment(`${reservation.beginDate}`)
    .utc()
    .format()
  const reservationEndTime = moment(`${reservation.endDate} ${checkoutTime}`)
    .utc()
    .format()

  if (moment(receivedAt).isBetween(reservationStartTime, reservationEndTime)) {
    return false
  }
  return true
}
