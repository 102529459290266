/* eslint-disable prefer-spread */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { isArray } from 'lodash'
import { withErrorBoundary } from 'react-error-boundary'
import { PageHeader } from '../components/pages'
import PropertyInfoOverview from '../components/PropertyInfoOverview'
import ListDetailNavigationFooter from '../components/ListDetailNavigationFooter'
import VideoPlayer from '../components/VideoPlayer'
import '../styles/PropertyInfoContainer.scss'
import { IFrameContainer } from '.'
import { setOverlay } from '../actions/device'
import defaultBackground from '../images/default-property-info-background.jpg'
import ErrorComponent from '../ErrorComponent'
import { logAppErrors, makeAPIRequest } from '../utils/helpers'
import phoneIcon from '../images/icons/phoneIcon.svg'
import QRCode from 'react-qr-code'

const getMediaType = item => {
  if (item.url) return 'video'
  return item.mediaType
}
class PropertyInfoDetailContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      propertyDetailUrl: '',
    }
  }

  async componentDidMount() {
    this.props.setOverlay({
      value: true,
    })

    this.getUrl()
  }

  getUrl = async () => {
    if (this.props.item.resourceURL && this.props.item.mediaType === 'pdf') {
      this.setState({ propertyDetailUrl: this.props.item.resourceURL })
      return
    }
    const result = await makeAPIRequest('property/property-info-detail-url/' + this.props.match?.params?.id, {
      method: 'GET',
    })
    this.setState({ propertyDetailUrl: result.url })
  }

  componentWillUnmount() {
    this.props.setOverlay({
      value: false,
    })
  }

  render() {
    const { property, name, item = {}, nextItem, previousItem, history } = this.props

    const { steps, description = '', imageURL, message, videoURL, resourceURL } = item

    const mediaType = getMediaType(item)
    const videoLink = videoURL.length ? videoURL[0].mediaUrl : null

    return (
      <div className="Card full-height PropertyInfoDetailCard">
        <div className="card-section header border-bottom">
          <PageHeader title="Property Information">
            <PropertyInfoOverview property={property} />
          </PageHeader>
        </div>
        <div className="card-section stretch vertically property-info-card">
          {name && (
            <div>
              <h3 className="title">{decodeURIComponent(encodeURIComponent(name))}</h3>
            </div>
          )}
          {mediaType === 'video' && videoLink && <VideoPlayer video={videoLink} poster={imageURL} key={videoLink} />}
          {(description || message) && (
            <div
              className={classNames('text', {
                pdf: mediaType === 'pdf',
              })}>
              <p
                className="kb-content"
                dangerouslySetInnerHTML={{
                  __html: description || message,
                }}
              />
            </div>
          )}
          {mediaType === 'pdf' && resourceURL && <IFrameContainer resourceURL={resourceURL} />}

          <div className="images">
            {mediaType === 'default' && imageURL && (
              <div className="kb-description-image-container">
                <img className="kb-description-image" src={imageURL} alt="large illustration" />
              </div>
            )}
            {mediaType === 'steps' && (
              <div className="steps-carousel">
                {
                  // <Carousel className="owl-theme" dots>
                }
                {Array.apply(null, Array(Math.ceil(steps.length / 2))).map((_, idx) => {
                  const a = steps[idx * 2]
                  const b = steps[idx * 2 + 1]
                  return (
                    <div className="steps" key={a.text}>
                      {a && (
                        <div className="step">
                          <div className="step-image-frame">
                            <img src={a.imageURL || defaultBackground} alt="instructions" className="step-image" />
                          </div>
                          <p>{a.text}</p>
                        </div>
                      )}
                      {b && (
                        <div className="step">
                          <div className="step-image-frame">
                            <img src={b.imageURL || defaultBackground} alt="instructions" className="step-image" />
                          </div>
                          <p>{b.text}</p>
                        </div>
                      )}
                    </div>
                  )
                })}
                {
                  // </Carousel>
                }
              </div>
            )}
          </div>
        </div>
        <div className="qr-column-grid RecommendationContainer">
          <div className={'sms-content inner-card-section no-padding-x direction-row'}>
            <div
              className="qr-md phone"
              onClick={() => {
                history.push('/contact/phone/propertyInfo', {
                  id: this.props.match.params.id,
                  section: this.props.match.params.section,
                  name,
                  resourceURL,
                  mediaType
                })
              }}>
              <img src={phoneIcon} alt="phoneIcon" />
              <label>View on your phone</label>
            </div>
            <div className="qr-md">
              <div className="qr-container">
                <QRCode value={this.state.propertyDetailUrl} />
              </div>
              <label>Scan the Code</label>
            </div>
          </div>
        </div>

        <ListDetailNavigationFooter
          previousItem={previousItem}
          nextItem={nextItem}
          backToPropertyInfo
          section={this.props.match.params.section}
          item={this.props.match.params.item}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match
  const { customizations } = state.property

  const section = state.property && customizations[params.section]
  const items = section ? (isArray(section) ? section : [section]) : []
  const itemIdx = section && items.findIndex(s => s.id === parseInt(params.id, 10))
  const previousItem = items[itemIdx - 1]
  const nextItem = items[itemIdx + 1]

  const item = items[itemIdx].content || items[itemIdx]

  return {
    property: state.property,
    name: items[itemIdx].name,
    section: params.section,
    item,
    previousItem: previousItem
      ? {
        ...previousItem,
        to: previousItem.id,
      }
      : undefined,
    nextItem: nextItem
      ? {
        ...nextItem,
        to: nextItem.id,
      }
      : undefined,
  }
}

const ComponentWithErrorBoundary = withErrorBoundary(PropertyInfoDetailContainer, {
  FallbackComponent: props => ErrorComponent({
    ...props,
    component: 'Property Info Detail Container',
  }),
  onError(error, info) {
    logAppErrors(error, info)
    console.log({
      error,
      info,
    })
  },
})
export default connect(mapStateToProps, {
  setOverlay,
})(ComponentWithErrorBoundary)
