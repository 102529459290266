import React from 'react'
import Icon from './Icon'

const IncrementIcon = props => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <rect stroke="#FFF" width={48} height={48} rx={24} />
      <path
        fill="#FFF"
        fillRule="nonzero"
        opacity={0.8}
        d="M25.582 34.68v-9.985h9.656v-3.234h-9.656V11.43h-3.258v10.03h-9.61v3.235h9.61v9.985z"
      />
    </g>
  </Icon>
)

export default IncrementIcon
