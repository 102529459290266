import React from 'react'
import moment from 'moment'
import { MESSAGE_STATUS } from '../../constants/appConstants'
import CalendarClock from '../icons/CalenderClock'
import GREETING_ICON from '../icons/GreetingIcon'
import ALERT_ICON from '../icons/AlertIcon'
import INFORMATION_ICON from '../icons/InformationIcon'
import REMINDER_ICON from '../icons/ReminderIcon'
import { Loader } from 'semantic-ui-react'

import { MESSAGES } from '../../constants/messages'

const getIconByCategory = (category) => {
  switch (category) {
    case 'GREETING':
      return <GREETING_ICON />
    case 'ALERT':
      return <ALERT_ICON />
    case 'INFORMATION':
      return <INFORMATION_ICON />
    case 'REMINDER':
      return <REMINDER_ICON />
    default:
      return <CalendarClock />
  }
}

const Header = ({ unreadCount }) => {
  return (
    <div className="announcement-header">
      <h2>{MESSAGES.ANNOUNCEMENTS}</h2>
      {unreadCount ? (
        <p>
          {unreadCount}
          {' '}
          {MESSAGES.UNREAD}
        </p>
      ) : ''}
    </div>
  )
}

const HistoryItem = ({
  content: { title, category }, status, viewMessage, occurrence,
}) => {
  const statusClass = status === MESSAGE_STATUS.UNREAD ? 'item-unread' : 'item-read'
  return (
    <li onClick={viewMessage} className={statusClass}>
      {getIconByCategory(category)}
      <div>
        <h4>{title}</h4>
        <p>
          {moment(occurrence).fromNow()}
        </p>
        {/*
        <p
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        /> */}
      </div>
    </li>
  )
}

const MessagesHistory = ({ messagesHistory, setSelectedMessage , isLoading }) => {
  const totalUnReadMessages = messagesHistory.filter(msg => msg.status === 'UNREAD')?.length
  // const [selectedMessage, setSelectedMessage] = useState(null)

  const viewMessage = (message) => {
    setSelectedMessage(message)
  }

  return (
    <>
      <div className="announcement-container">
        <Header unreadCount={totalUnReadMessages} />
        { isLoading ? <Loader active /> : messagesHistory?.length ? (
          <div className="announcement-list">
            <ul>
              {messagesHistory.map(message => (
                <HistoryItem
                  content={message?.content}
                  status={message.status}
                  occurrence={message?.occurrence}
                  viewMessage={() => viewMessage(message)}
                />
              ))}
            </ul>
          </div>
        ) : (
          <p style={{ marginTop: '-60px' }}>{MESSAGES.NO_NOTIFICATION_TO_READ}</p>
        )}
      </div>
    </>
  )
}

export default MessagesHistory
