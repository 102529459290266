import React from 'react'
import { useSelector } from 'react-redux'
import { useCustomization } from '../../hooks/useCustomization'
import '../../styles/OrganizationBranding.scss'

const OrganizationBranding = () => {
  const welcome = useCustomization('welcome')
  const {
    1: { url },
  } = Object.entries(welcome).find(([key, value]) => (key === 'custom' && value.status) || key === 'default')
  const { name } = useSelector(state => state.property.organization)
  const logo = url && url[0]?.mediaUrl

  return (
    <div className="OrganizationBranding">
      {logo ? (
        <div className="logo card-padding">
          <img src={logo} alt="welcome-logo" />
        </div>
      ) : (
        <div className="org-name">{name}</div>
      )}
    </div>
  )
}

export default OrganizationBranding
