import React from 'react'
import Icon from './Icon'

const CalenderClock = () => (
  <Icon width="66" height="71" viewBox="0 0 66 71" fill="none">
    <path d="M65.0705 54.437L60.0651 51.9343V45.9728C60.059 42.4274 58.7997 38.9983 56.5098 36.2916C54.2199 33.585 51.0468 31.775 47.5514 31.1816V25.9509H42.546V31.1816C39.0506 31.775 35.8775 33.585 33.5876 36.2916C31.2977 38.9983 30.0384 42.4274 30.0323 45.9728V51.9343L25.0269 54.437V65.9946H40.0432V71.0001H50.0542V65.9946H65.0705V54.437ZM60.0651 60.9892H30.0323V57.5304L35.0378 55.0276V45.9728C35.0378 43.3177 36.0925 40.7714 37.9699 38.894C39.8473 37.0166 42.3936 35.9618 45.0487 35.9618C47.7038 35.9618 50.2501 37.0166 52.1275 38.894C54.0049 40.7714 55.0596 43.3177 55.0596 45.9728V55.0276L60.0651 57.5304V60.9892Z" fill="#5591C7" />
    <path d="M60.0655 10.9338C60.0655 9.60624 59.5382 8.33308 58.5995 7.39438C57.6608 6.45567 56.3876 5.92831 55.0601 5.92831H45.0491V0.922852H40.0437V5.92831H20.0218V0.922852H15.0164V5.92831H5.00546C3.67793 5.92831 2.40477 6.45567 1.46607 7.39438C0.527359 8.33308 0 9.60624 0 10.9338V60.9884C0 62.3159 0.527359 63.5891 1.46607 64.5278C2.40477 65.4665 3.67793 65.9938 5.00546 65.9938H15.0164V60.9884H5.00546V10.9338H15.0164V15.9392H20.0218V10.9338H40.0437V15.9392H45.0491V10.9338H55.0601V25.9502H60.0655V10.9338Z" fill="#5591C7" />
  </Icon>
)

export default CalenderClock
