import { combineReducers } from 'redux'

import { property } from './property_reducer'
import device from './device'
import { settings } from './settings_reducer'
import passcodeSetting from './passcode_reducer'
import { weather } from './weather_reducer'
import { messages } from './message_reducer'
import { networkStatus, checklists, services } from '../resources'

const rootReducer = combineReducers({
  property,
  device,
  settings,
  passcodeSetting,
  networkStatus,
  checklists,
  services,
  weather,
  messages,
})

export default rootReducer
