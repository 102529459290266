import React from 'react'

const Snow = props => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.2935 12.044V15.8238C17.2935 16.2165 17.61 16.5352 18 16.5352C18.39 16.5352 18.7058 16.2165 18.7058 15.8238V12.044L21.3099 9.42168C21.5854 9.14422 21.5854 8.69389 21.3099 8.41573C21.0344 8.13827 20.5872 8.13827 20.3109 8.41573L18.7058 10.0321V7.71071C18.7058 7.31801 18.39 7 18 7C17.61 7 17.2935 7.31801 17.2935 7.71071V10.0328L15.6883 8.41644C15.4128 8.13899 14.9656 8.13899 14.69 8.41644C14.4145 8.69389 14.4145 9.14422 14.69 9.42239L17.2942 12.0447L17.2935 12.044Z" fill="white" />
    <path d="M18.7058 24.1389V20.3591C18.7058 19.9664 18.39 19.6477 18 19.6477C17.61 19.6477 17.2935 19.9664 17.2935 20.3591V24.1389L14.6893 26.7612C14.4138 27.0386 14.4138 27.4889 14.6893 27.7671C14.8271 27.9058 15.008 27.9756 15.1888 27.9756C15.3697 27.9756 15.5506 27.9058 15.6883 27.7671L17.2935 26.1508V28.4728C17.2935 28.8655 17.61 29.1843 18 29.1843C18.39 29.1843 18.7058 28.8663 18.7058 28.4728V26.1508L20.3109 27.7671C20.4487 27.9058 20.6296 27.9756 20.8104 27.9756C20.9913 27.9756 21.1721 27.9058 21.3099 27.7671C21.5854 27.4897 21.5854 27.0393 21.3099 26.7612L18.7058 24.1389Z" fill="white" />
    <path d="M8.70531 16.6675C8.76607 16.6675 8.82753 16.6597 8.88829 16.6433L12.4455 15.6836L15.6968 17.5739C15.8077 17.6386 15.9292 17.6692 16.0493 17.6692C16.2938 17.6692 16.5305 17.5418 16.6619 17.3135C16.8569 16.9734 16.741 16.5387 16.4033 16.3424L13.152 14.4521L12.1989 10.8701C12.0979 10.4909 11.7107 10.2654 11.3342 10.3672C10.9576 10.4689 10.7337 10.8588 10.8347 11.2379L11.4225 13.4462L9.42523 12.2852C9.08752 12.0888 8.65585 12.2055 8.46086 12.5455C8.26587 12.8856 8.38173 13.3203 8.71944 13.5166L10.7167 14.6777L8.52374 15.2696C8.14718 15.3713 7.92322 15.7612 8.02425 16.1403C8.10903 16.4583 8.39445 16.6675 8.70602 16.6675H8.70531Z" fill="white" />
    <path d="M27.111 19.5395L23.5538 20.4992L20.3025 18.609C19.9647 18.4126 19.5331 18.5293 19.3381 18.8694C19.1431 19.2094 19.259 19.6441 19.5967 19.8405L22.848 21.7307L23.801 25.3127C23.8858 25.6307 24.1705 25.8399 24.4828 25.8399C24.5436 25.8399 24.605 25.832 24.6658 25.8157C25.0423 25.7139 25.2663 25.3241 25.1653 24.9449L24.5775 22.7366L26.574 23.8977C26.6849 23.9624 26.8065 23.993 26.9266 23.993C27.171 23.993 27.4077 23.8657 27.5391 23.6373C27.7341 23.2972 27.6182 22.8626 27.2805 22.6662L25.2833 21.5052L27.4762 20.9133C27.8528 20.8115 28.0767 20.4217 27.9757 20.0425C27.8747 19.6633 27.4875 19.4378 27.111 19.5395Z" fill="white" />
    <path d="M19.3381 17.3135C19.4688 17.5418 19.7062 17.6692 19.9499 17.6692C20.07 17.6692 20.1915 17.6386 20.3025 17.5739L23.5537 15.6836L27.111 16.6433C27.1724 16.6597 27.2339 16.6675 27.2939 16.6675C27.6055 16.6675 27.8909 16.4576 27.9757 16.1403C28.0767 15.7612 27.8535 15.3713 27.4762 15.2696L25.2833 14.6777L27.2798 13.5166C27.6175 13.3203 27.7334 12.8856 27.5384 12.5455C27.3434 12.2055 26.9117 12.0888 26.574 12.2852L24.5768 13.4462L25.1646 11.2379C25.2656 10.8588 25.0423 10.4689 24.6651 10.3672C24.2885 10.2654 23.9013 10.4909 23.8003 10.8701L22.8473 14.4521L19.596 16.3424C19.2582 16.5387 19.1424 16.9734 19.3374 17.3135H19.3381Z" fill="white" />
    <path d="M16.6619 18.8694C16.4669 18.5293 16.0352 18.4126 15.6975 18.609L12.4469 20.4992L8.8897 19.5395C8.51314 19.4378 8.12598 19.6626 8.02495 20.0425C7.92392 20.4217 8.14788 20.8115 8.52444 20.9133L10.7174 21.5052L8.72014 22.6662C8.38244 22.8626 8.26657 23.2972 8.46157 23.6373C8.59227 23.8657 8.82965 23.993 9.07339 23.993C9.1935 23.993 9.31431 23.9624 9.42593 23.8977L11.4232 22.7366L10.8354 24.9449C10.7344 25.3241 10.9576 25.7139 11.3349 25.8157C11.3964 25.832 11.4578 25.8399 11.5179 25.8399C11.8294 25.8399 12.1149 25.63 12.1996 25.3127L13.1527 21.7307L16.404 19.8405C16.7417 19.6441 16.8576 19.2094 16.6626 18.8694H16.6619Z" fill="white" />
  </svg>
)

export default Snow
