import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeConnectHostRequest } from '../../actions/property'
import { SendToPhoneContainer } from '../../containers'
import { withPropsFromLocation } from '../utils'
import { setOverlay } from '../../actions/device'
import { parseNumber } from '../../utils/formatters'

const ConnectWithHost = ({
  history,
  makeConnectHostRequest,
  serviceName,
  customerServiceNumber,
  setOverlay,
  serviceId,
  ...props
}) => {
  useEffect(() => {
    setOverlay({
      value: true,
    })
    return () => setOverlay({
      value: false,
    })
  }, [setOverlay])
  const onSend = async (phone) => {
    const parameters = {
      serviceName,
      guestNumber: parseNumber(phone),
      customerServiceNumber,
    }
    await makeConnectHostRequest(parameters)
    history.push(`/services/${serviceId}/phone/success`, {
      messageOnResponse: 'Your host will connect with you over the phone shortly regarding your service request',
      backLink: '/services',
      type: 'CONNECT_WITH_HOST',
      showApprovalText: false,
    })
  }

  return (
    <SendToPhoneContainer
      {...props}
      meta="Standard text messaging rates may apply."
      title="Please provide your mobile number and our service host will connect with you soon."
      onSend={onSend}
    />
  )
}

export default connect(null, {
  makeConnectHostRequest,
  setOverlay,
})(withPropsFromLocation(['serviceName', 'customerServiceNumber', 'serviceId'])(ConnectWithHost))
