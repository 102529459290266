import React, { useContext } from 'react'
import { ServiceWorkerContext } from './ServiceWorker'

const withCodeRefresh = WrappedComponent => {
  const WithCodeRefresh = props => {
    const serviceWorkerContext = useContext(ServiceWorkerContext)

    const refresh = () => {
      if (serviceWorkerContext.status === 'installed') {
        serviceWorkerContext.skipWaiting()
      } else {
        serviceWorkerContext.fetchUpdate()
      }
    }

    return <WrappedComponent {...props} refresh={refresh} />
  }

  return WithCodeRefresh
}

export default withCodeRefresh
