import React from 'react'

import { MESSAGES } from '../../constants/messages'
import RecenterIcon from '../../images/icons/map/re-center@2x.png'

const MapCenterButton = ({ onClick }) => (
  <div className="p-l recenter-link" onClick={onClick}>
    <img
      style={{
        height: 20,
      }}
      src={RecenterIcon}
      alt="re-center"
    />
    {' '}
    {MESSAGES.RECENTER_MAP}
  </div>
)

export default MapCenterButton
