import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { Grid, Loader, Icon } from 'semantic-ui-react'
import { fetchPropertyAndState, fetchPropertyWeather, unpair } from '../actions/property'
import { updateSettings } from '../actions/settings'
import BlueButton from '../components/BlueButton'
import * as PersistentState from '../utils/persistent-state'

import '../styles/PasscodeScreen.scss'
import { makeAPIRequest, authenticate } from '../utils/helpers'
import okIcon from '../images/onboarding/ok.png'
import { getDeviceState, setPairedProperty } from '../actions/device'
import { OPERATING_SYSTEM } from '../constants/appConstants'
import { MESSAGES } from '../constants/messages'

const nos = [1, 2, 3, 4, 5, 6, 7, 8, 9, '', '0', 'Clear']
class DevicePINContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      property: null,
      isSending: false,
      hasError: null,
      pinEntered: [],
      isTestDevice: false,
    }
  }

  enterDigit = no => {
    if (this.state.pinEntered.length !== 5) {
      this.setState({ pinEntered: [...this.state.pinEntered, no.toString()] })
    }
  }

  reenterPropId = () => {
    this.setState({
      property: null,
      pinEntered: [],
    })
    unpair()
    this.goToPasscode()
  }

  submit = async () => {
    const pin = this.state.pinEntered.join('')
    const passcode = this.props.location.search ? this.props.location.search.split('=')[1] : null
    const nextSettings = { ...this.props.settings, pin }
    await this.props.dispatch(updateSettings(nextSettings))

    this.setState({ isSending: true, hasError: null, property: null })

    authenticate({ pin, passcode })
      .then(async () => {
        try {
          const data = await makeAPIRequest('property', { pin })
          this.setState({ isSending: false, property: data })
          PersistentState.setPasscode(passcode)
        } catch (err) {
          this.setState({ isSending: false, hasError: err.toString() })
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({ isSending: false, hasError: err.toString() })
      })
  }

  goToPasscode = () => {
    PersistentState.clearPasscode()
    this.props.history.push('/passcode-screen')
  }

  confirm = async () => {
    const pin = this.state.pinEntered.join('')
    const nextSettings = { ...this.props.settings, pin }
    const { isTestDevice } = this.state
    PersistentState.setPropertyConfirmed('yes')

    if (isTestDevice) {
      PersistentState.setDeviceType('demo')
    }
    localStorage.setItem('demoUpdated', true)

    try {
      const deviceType = localStorage.getItem('deviceType')
      const data = await getDeviceState(pin)

      if (data) {
        /**
        * SPECIFICATION OF POWERSAVING MODE:
        * The device display goes turn off after 10 minutes if no touch event happened.
        * Additionally, after 4 minutes from above (total 14 minutes later), The device
        * power off automatically.
        * When touch event happened, this timer will be reset
        */
        const serialNumber = data.serialNumber || ''
        if (deviceType !== 'demo' && pin && data.os && data.os.toLowerCase() === OPERATING_SYSTEM.LINUX) {
          const response = await setPairedProperty(pin, serialNumber)
          if (response?.status) {
            console.log('Successfully paired the property with linux check')
          }
        }
      }
    } catch (error) {
      // window.alert(`Error in concierge api ${JSON.stringify(error)}`)
    }

    await this.props.dispatch(updateSettings(nextSettings))
    await this.props.dispatch(fetchPropertyAndState(nextSettings))
    await this.props.dispatch(fetchPropertyWeather())
    this.props.history.push('/home')
  }

  toggleTestDeviceCheckmark = () => {
    const { isTestDevice } = this.state
    this.setState({ isTestDevice: !isTestDevice })
  }

  render() {
    const {
      property, isSending, hasError, isTestDevice,
    } = this.state
  
    if (property) {
      return (
        <div className="Win10Theme SettingsContainer DevicePairing">
          <div className="pairing-confirm">
            <div className="property-subtitle">{MESSAGES.IS_THIS_PROPERTY_CORRECT}</div>
            <div className="pairing-address">
              {property.addressLine1}
              {' '}
              {property.addressLine2}
              {' '}
              <br />
              {property.addressCity}
              ,
              {property.addressState}
              {' '}
              {property.addressZIP}
            </div>
            <div className="button-group">
              <BlueButton label="NO" onClick={this.reenterPropId} size="large" />
              <BlueButton primary label="YES" onClick={this.confirm} size="large" />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="SettingsContainer Win10Theme DevicePairing">
        <h1>{MESSAGES.ENTER_PROPERTY_ID}</h1>
        <div className="passcode-subtitle">{MESSAGES.PROPERTY_ID_INFO}</div>
        <div className="passcode-items-device">
          <div className="passcode-item-device prefix">C</div>
          {/* <div className="passcode-item-device prefix">-</div> */}
          {_.times(5, index => {
            return (
              <div key={index} className="passcode-item-device">
                {' '}
                {this.state.pinEntered[index] ? this.state.pinEntered[index] : ''}
              </div>
            )
          })}
        </div>
        <div className="test-device-mark">
          {isTestDevice ? (
            <Icon onClick={this.toggleTestDeviceCheckmark} name="check square" />
          ) : (
            <Icon onClick={this.toggleTestDeviceCheckmark} name="square outline" />
          )}
          {MESSAGES.DEMO_MODE}
          {' '}
          - Disables Analytics
        </div>
        {isSending ? <Loader active inline="centered" /> : <Loader inline="centered" />}
        <div className="pin-error">
          {hasError ? (
            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '40px',
            }}
            >
              <p>{MESSAGES.ERROR_IN_PAIRING_DEVICE}</p>
              <BlueButton label="Back to passcode screen" size="large" onClick={this.goToPasscode} />
            </div>
          ) : (
            ''
          )}
        </div>
        <Grid className="number-pad" centered columns={3} style={{ marginTop: '10%' }}>
          {nos.map((no, index) => {
            return (
              <Grid.Column key={index} className="number-pad-item">
                <div
                  onClick={
                    no === ''
                      ? () => {}
                      : no !== 'Clear'
                        ? () => this.enterDigit(no)
                        : () => this.setState({ pinEntered: [] })
                  }
                >
                  {no}
                </div>
              </Grid.Column>
            )
          })}
        </Grid>
        <div className="buttons-container">
          <img
            onClick={this.submit}
            className="hide-when-editing"
            alt="next"
            src={okIcon}
            style={{
              width: 96,
              height: 96,
              opacity: this.state.pinEntered.length === 5 ? 1.0 : 0.5,
            }}
          />
        </div>
        <a onClick={e => e.stopPropagation()} href="http://localhost:3000/survey">
          {MESSAGES.ADMIN_SETTINGS}
        </a>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    settings: state.settings,
  }
}

export default withRouter(connect(mapStateToProps)(DevicePINContainer))
