import React from 'react'

import zero from '../../images/yelp-stars/small/small_0@2x.png'
import one from '../../images/yelp-stars/small/small_1@2x.png'

import oneHalf from '../../images/yelp-stars/small/small_1_half@2x.png'

import two from '../../images/yelp-stars/small/small_2@2x.png'

import twoHalf from '../../images/yelp-stars/small/small_2_half@2x.png'

import three from '../../images/yelp-stars/small/small_3@2x.png'

import threeHalf from '../../images/yelp-stars/small/small_3_half@2x.png'

import four from '../../images/yelp-stars/small/small_4@2x.png'

import fourHalf from '../../images/yelp-stars/small/small_4_half@2x.png'

import five from '../../images/yelp-stars/small/small_5@2x.png'

export default function yelpImageForRating(rating) {
  let src
  switch (rating) {
    case 0:
      src = zero
      break
    case 1:
      src = one
      break
    case 1.5:
      src = oneHalf
      break
    case 2:
      src = two
      break
    case 2.5:
      src = twoHalf
      break
    case 3:
      src = three
      break
    case 3.5:
      src = threeHalf
      break
    case 4:
      src = four
      break
    case 4.5:
      src = fourHalf
      break
    case 5:
      src = five
      break
    default:
      console.log('ERROR: no rating')
  }

  return <img src={src} alt="rating" height="24" />
}
