import { UPDATE_SETTINGS_PASSCODE, RESET_PASSCODE } from '../actions/actionTypes'
import * as PersistentState from '../utils/persistent-state'

let initialState = {
  settingsPasscode: null,
}
try {
  // const savedState = localStorage.getItem(LOCAL_STORAGE_KEY_PS)
  // initialState = Object.assign(initialState, savedState)
  initialState = true
} catch (err) {
  console.log('saved passcode not found')
}

export default function passcodeSetting(state = initialState, action) {
  // Rather than immediately returning the next state, capture it in a local
  // variable so we can sync it to local storage before returning.
  let nextState = state
  if (action.type === UPDATE_SETTINGS_PASSCODE) {
    // nextState = Object.assign([], state, action.passcode)
    nextState = true
  } else if (action.type === RESET_PASSCODE) {
    nextState = false
    PersistentState.clearPasscode()
  }
  return nextState
}
