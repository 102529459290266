import moment from 'moment'
import { get } from 'lodash'

import {
  PROPERTY_FETCH_SUCCESS, PROPERTY_HAS_ERRORED, MARK_MESSAGE_AS_READ, UNPAIRED,
} from '../actions/actionTypes'

import { preloadPropertyAssets } from '../utils/helpers'
import * as PersistentState from '../utils/persistent-state'
import { getLateCheckoutAvailability, lateCheckoutKey } from '../utils/services'

let initialState = null
let fetchCount = 0
try {
  const savedProperty = JSON.parse(localStorage.getItem('property'))
  initialState = { ...savedProperty }
} catch (err) {
  console.log('no saved property')
}

// State Retrieval Helpers
// - Use these in your getStateFromProps methods to make retrieving specific parts
//   of property state easier.

export function getPlacesForSectionName(storeState, section) {
  if (section === 'restaurants') {
    return storeState.property.customizations.restaurants
  }
  if (section === 'activities') {
    return storeState.property.customizations.activities
  }
  if (section === 'directory') {
    return storeState.property.customizations.directory
  }
}

// Reducers

export function property(state = initialState, action) {
  switch (action.type) {
    case PROPERTY_FETCH_SUCCESS:
      if (JSON.stringify(action.property) !== 'null') {
        PersistentState.setProperty(action.property)
        const deviceType = PersistentState.getDeviceType()

        try {
          const { property } = action
          window.gtag('set', 'userId', property.id)
          const DIMENSIONS = {
            reservationId: 'dimension1',
            deviceSerialNumber: 'dimension2',
            deviceType: 'dimension3',
          }

          window.gtag('set', DIMENSIONS.reservationId, `${get(property, 'reservation.id', '')}`)
          window.gtag('set', DIMENSIONS.deviceSerialNumber, localStorage.getItem('serialNumber'))
          window.gtag('set', DIMENSIONS.deviceType, deviceType)
        } catch (exception) {
          console.error('Failed to setup Google Analytics', exception)
        }

        // If we're receiving new property JSON and it has a different "refresh bit",
        // the server wants us to do a hard refresh of the page.
        if (state && state.clientRefreshBit && state.clientRefreshBit !== action.property.clientRefreshBit) {
          // TODO: Have an interval here to randomize when the reload runs... potential concern about hundreds of devices realizing they need a full browser refresh at the same time.
          window.location.reload()
        }

        // Remove Late Checkout Service Advertisement in case of Back to back reservations
        // eslint-disable-next-line prefer-const
        let {
          advertisement, reservation, nextReservation, messages,
        } = { ...action.property }
        if (reservation && nextReservation) {
          nextReservation.checkinDatetime = moment(`${nextReservation.beginDate}T${nextReservation.checkinTime}:00`)
          reservation.checkoutDatetime = moment(`${reservation.endDate}T${reservation.checkoutTime}:00`)
          /** If there is any reservation after 10 hours of the checkout,
           *  the system will disable the late checkout service and a service message will display.
           *  The guest will not be able to avail the late checkout in this case. */

          advertisement = advertisement.filter(ad => {
            if (ad?.service?.template === lateCheckoutKey) {
              return getLateCheckoutAvailability(reservation, nextReservation, ad?.service?.extra?.disableOnBtbRes)
            }
            return true
          })
        }

        const syncMessages = JSON.parse(localStorage.getItem('messages'))

        const updatedProperty = { ...action.property, advertisement, messages: syncMessages }

        fetchCount === 0 && preloadPropertyAssets(action.property)
        fetchCount++

        return updatedProperty
      }
      return action.property

    case 'services/REQUEST':
      const reservationServices = [...(state.reservationServices || []), action.payload]
      // localStorage.setItem('reservationServices', JSON.stringify(reservationServices))
      return {
        ...state,
        reservationServices: reservationServices.sort((a, b) => {
          return moment.utc(a.updatedAt).diff(moment.utc(b.updatedAt))
        }),
      }
    // case CLEAR_SERVICE_STATUS:
    //   localStorage.setItem('reservationServices', JSON.stringify([]))
    //   return {
    //     ...state,
    //     reservationServices: [],
    //   }
    case PROPERTY_HAS_ERRORED:
      return state

    case UNPAIRED:
      return initialState

    default:
      return state
  }
}
