import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronIcon } from '../icons'
import '../../styles/RecommendationsListItem.scss'

const RecommendationsInfoListItem = ({
  img, title, subtitle, categories, link, cypress,
}) => (
  <div className="RecommendationsListItem" data-cy={cypress}>
    <img className="category-img" src={img} alt={title} />
    <div className="category-info">
      <div className="title">
        {title}
        <ChevronIcon />
      </div>
      <div className="subtitle">{subtitle}</div>
      <div className="sub-category-buttons">
        {categories.map(category => (
          <Link className="sub-category-button" to={`${link}?filters=${encodeURIComponent(category)}`}>
            {category}
          </Link>
        ))}
      </div>
    </div>
  </div>
)

export default RecommendationsInfoListItem
