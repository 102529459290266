import React from 'react'
import Icon from './Icon'

const RestaurantsIcon = props => (
  <Icon viewBox="0 0 50 56" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0h21v25a8 8 0 0 1-8 8h-1v20h7.5a1.5 1.5 0 0 1 0 3h-18a1.5 1.5 0 0 1 0-3H9V33H8a8 8 0 0 1-8-8V0zm34.5 0A1.5 1.5 0 0 1 36 1.5V15a3 3 0 0 0 3 3h1V1.5a1.5 1.5 0 0 1 3 0V18h1a3 3 0 0 0 3-3V1.5a1.5 1.5 0 0 1 3 0V15a6 6 0 0 1-6 6h-1v33.5a1.5 1.5 0 1 1-3 0V21h-1a6 6 0 0 1-6-6V1.5A1.5 1.5 0 0 1 34.5 0z"
      fill="currentColor"
    />
  </Icon>
)
export default RestaurantsIcon
