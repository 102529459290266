import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.scss'
import 'slick-carousel/slick/slick.scss'
import '../../styles/Carousel.scss'

const Carousel = ({
  columns, columnsToScroll = columns, className, children, ...rest
}) => {
  const settings = {
    ...rest,
    slidesToShow: columns,
    slidesToScroll: columnsToScroll,
  }

  return (
    <Slider className={className} {...settings}>
      {children}
    </Slider>
  )
}

export default Carousel

Carousel.defaultProps = {
  dots: true,
  infinite: false,
  speed: 500,
  rows: 1,
  columns: 1,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 45000,
}
