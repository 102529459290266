import React, { useState } from 'react'
import classNames from 'classnames'
import { Icon } from 'semantic-ui-react'
import TermsConditionModal from './TermsConditionModal'
import { MESSAGES } from '../../constants/messages'

const termsCharacterLimit = 160
const ServiceTerms = ({
  terms, acceptsTerms, onClick = null,
}) => {
  const [showModal, setShowModal] = useState(false)

  const onAccept = () => {
    onClick && onClick();
    closeModal()
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const onCancel = () => {
    setShowModal(false)
  }
  return (
    <>
      {showModal && (
        <TermsConditionModal
          terms={terms}
          onAccept={onAccept}
          onCancel={onCancel}
          acceptsTerms={acceptsTerms}
          closeModal={closeModal}
        />
      )}

      <div className="card-section shrink padding-y ServiceTerms">
        <div className="service-subtitle">Terms and Conditions</div>
        <div
          className="service-terms"
          dangerouslySetInnerHTML={{
            __html: terms.slice(0, termsCharacterLimit),
          }}
        />
        {terms.length > termsCharacterLimit && (
          <button
            type="button"
            onClick={() => setShowModal(true)}
            style={{
              color: '#5591C7',
              paddingTop: 10,
            }}
          >
            Read more &gt;
          </button>
        )}
        {onClick && (
          <div onClick={onClick} className="accept-checkbox standard-category">
            <Icon
              name={classNames('square', {
                check: acceptsTerms,
              })}
            />
            {MESSAGES.TERMS_AND_CONDITION_PROMPT}
          </div>
        )}
      </div>
    </>
  )
}

export default ServiceTerms
