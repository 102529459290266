import React from 'react'
import { MESSAGES } from '../../constants/messages'

const CodeVersion = () => (
  <div className="CodeVersion">
    <strong>{MESSAGES.MAP_DATA_BY_GOOGLE}</strong>
    <div> https://www.google.com/intl/en-US_US/help/terms_maps/ </div>
    <strong>{MESSAGES.WEATHER_DATA_BY_DARK_SKY}</strong>
    <div>https://darksky.net/</div>
    <div>
      Version:
      {' '}
      {(process.env.REACT_APP_SENTRY_RELEASE || '').substring(0, 7)}
      {' '}
      (
      {process.env.REACT_APP_SENTRY_ENV}
      )
    </div>
  </div>
)

export default CodeVersion
